import axios, { AxiosResponse } from "axios";
import { API, getUserHeaders } from "../constants/requestConstants";
import * as Model from "../models/be_models";

// GET /api/v1/country
// getCountries

// GET /api/v1/country/{countryId}
// getStateProvinces

export const getCompanyFarms = (
  // a list of farms and it's last survey to which the user has access
  companyExtId: string,
  scored = true
): Promise<AxiosResponse<Model.IIqDataResponse[]>> => {
  const url = `${API}/iq/company/${companyExtId}`;

  return axios.get<Model.IIqDataResponse[]>(url, {
    headers: getUserHeaders(),
    params: { scored },
  });
};

export const getCompanyRisk = (
  // an object with summary of risk level and it`s percentage for company
  companyExtId: string
): Promise<AxiosResponse<Record<string, number>>> => {
  const url = `${API}/iq/company/${companyExtId}/risk`;

  return axios.get(url, {
    headers: getUserHeaders(),
  });
};

// GET /api/v1/iq/country/{countryId}
// getCountryFarms

// GET /api/v1/iq/country/{countryId}/province/{provinceId}
// getCountryStateProvinceFarms

// GET /api/v1/iq/global
// getGlobalFarms

export const getMyFarmsByCountry = (
  // a list of countries from farms to which the user has access
  companyExtId: string
): Promise<AxiosResponse<Model.IqFarmsByCountryResponse[]>> => {
  const url = `${API}/iq/my/company/${companyExtId}/countries`;

  return axios.get<Model.IqFarmsByCountryResponse[]>(url, {
    headers: getUserHeaders(),
  });
};

export const getMyFarmCountByCountry = (
  // an object of countries with farm count. all farms in the company.
  companyExtId: string
): Promise<AxiosResponse<Model.IqFarmCountByCountryResponse>> => {
  const url = `${API}/iq/my/company/${companyExtId}/countries/farm/count`;

  return axios.get<Model.IqFarmCountByCountryResponse>(url, {
    headers: getUserHeaders(),
  });
};

// GET /api/v1/year
// getYears
