import React, { useContext, useEffect, useState } from "react";
import {
  ButtonToolbar,
  Button,
  Row,
  Col,
  Container,
  Spinner,
} from "react-bootstrap";
import { PDFExport } from "@progress/kendo-react-pdf";
import get from "lodash/get";

import { showFetchError } from "../../utils/alerts";
import { formatDate } from "../../utils/date";
import {
  getRiskLevelClassName,
  getRiskLevelName,
  changePdfHtmlTags,
} from "../../utils/string";
import { AlertsContext } from "../../contexts/AlertsContext";
import { ErrorPopupContext } from "../../contexts/ErrorPopupContext";
import { getSurveyReport } from "../../network/request";
import { DATE_ISO_DEFAULT } from "../../constants/constants";
import { ANSWER_TYPES } from "../../constants/answerTypes";
import {
  CATEGORY_ICONS,
  AQUARISK_REPORT_LOGO,
} from "../../constants/imagesConstants";

import Loader from "../common/Loader";
import Icon from "../common/Icon";
import Tile from "../general/Tile";
import PdfFooter from "./PdfFooter";

const pdfRef = React.createRef();

const farmDetails = [
  { title: "Site ID", value: "farm.siteId" },
  { title: "Site Type", value: "farm.siteType" },
  { title: "Structure Count", value: "farm.structureCount" },
  { title: "Max Value", value: "farm.maxValue" },
  { title: "Avg Value", value: "farm.avgValue" },
  { title: "Actual Value", value: "farm.actualValue" },
  { title: "Country", value: "farm.country" },
  { title: "Region", value: "farm.stateProvince" },
  { title: "Animal Count", value: "farm.animalCount" },
  { title: "Harvest Weight", value: "farm.harvestWeight" },
  { title: "Species", value: "farm.species" },
  { title: "Density (kg/m3)", value: "farm.stockingDensity" },
  { title: "Stocking Weight", value: "farm.stockingWeight" },
  { title: "Audit Date", value: "farm.surveyDate" },
  { title: "Tonnage", value: "farm.tonnage" },
  { title: "Start Year", value: "farm.startYear" },
];

const SurveyPdf = (props) => {
  let report;
  const [loading, setLoading] = useState(false);
  const [fileSaving, setFileSaving] = useState(false);
  const [reportData, setReportData] = useState({
    name: null,
    farmName: null,
    companyName: null,
    createdBy: null,
    createdAt: null,
    updatedBy: null,
    updatedAt: null,
    completedBy: null,
    completedAt: null,
    scoring: null,
    farm: null,
    company: null,
    survey: null,
  });

  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);

  const surveyId = props.match.params.surveyId;
  const summary = props.summary;

  const renameEquipmentToEngineering = (arr) => {
    arr.map((section) => {
      if (section.name === "Equipment") section.name = "Engineering";
      return section;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data: result } = await getSurveyReport(surveyId);
        setReportData(result);

        renameEquipmentToEngineering(result.survey.sections);
        renameEquipmentToEngineering(result.scoring.children);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        showFetchError({
          error,
          customMsg: "API error while loading the audit report.",
          object: "audit report",
          objectName: "unknown",
          operation: "loaded",
          dispatchAlert,
          dispatchPopup,
          onRetry: () => fetchData(),
        });
      }
    };

    fetchData();
  }, [surveyId]);

  const exportPDF = () => {
    setFileSaving(true);

    report.save(() => setFileSaving(false));
  };

  const pageTemplate = (props) => {
    return (
      <PdfFooter footerName={get(reportData, "farmName")} pdfProps={props} />
    );
  };

  const returnScoring = (array, section, item) => {
    const result = array.find((el) => {
      return el.name === section.name;
    });
    if (result) {
      const step = result.children.find((el) => el.name === item.name);
      return step;
    }
  };

  const renderMultiSelectAnswer = (question) => {
    let answer = "";
    question.answer.answerStr
      .replace(/\[/, "")
      .replace(/\]/, "")
      .replace(/\s+/, "")
      .split(",")
      .map((multiSelectOrdinal) => {
        const ordinal = parseInt(multiSelectOrdinal);
        const option = question.answerOptions.find(
          (o) => o.ordinal === ordinal
        );
        answer += `<li>${option.text}</li>`;

        return answer;
      });

    answer = `<ul class="multi-select-answer">${answer}</ul>`;

    return answer;
  };

  const renderSingleSelectAnswer = (question) => {
    let answer = "";
    question.answer.answerStr
      .replace(/\[/, "")
      .replace(/\]/, "")
      .replace(/\s+/, "")
      .split(",")
      .map((multiSelectOrdinal) => {
        const ordinal = parseInt(multiSelectOrdinal);
        const option = question.answerOptions.find(
          (o) => o.ordinal === ordinal
        );
        answer += `<p class="text-center">${option.text}</p>`;

        return answer;
      });

    return answer;
  };

  const renderStepsWithAnswers = (section) => {
    if (section.steps) {
      let listOfStepsToRender = section.steps.filter((currentStep) => {
        const step = currentStep.questions.find((question) => {
          let answer = get(question, "answer.answerStr");
          return answer !== null;
        });
        return step !== currentStep;
      });

      return listOfStepsToRender.map((step) => {
        const stepScoring = returnScoring(
          reportData.scoring.children,
          section,
          step
        );
        const shouldShowTile = step.questions.find(
          (question) => !!question.answer.answerStr
        );
        return (
          step && (
            <Container key={step.id} className="px-0 mt-4">
              {shouldShowTile && (
                <div className="section-header d-flex align-items-center justify-content-between">
                  <div
                    className={`section-header-wrap section-header-wrap-lg border-${getRiskLevelClassName(
                      stepScoring.risk
                    )} pb-2`}
                  >
                    <h5 className="section-header-title my-0 d-flex justify-content-between align-items-end text-start">
                      <span className="step-name">{stepScoring.title}</span>
                      <span className="pdf-font-italic-light">
                        {getRiskLevelName(stepScoring.risk)}
                      </span>
                    </h5>
                  </div>

                  <img
                    alt={section.name}
                    src={CATEGORY_ICONS[section.name.toLowerCase()]}
                    className="img-icon img-icon-md"
                  />
                </div>
              )}

              <Container fluid>
                {step.questions.map((question, index) => {
                  let answer = get(question, "answer.answerStr");

                  if (answer == null) return <div key={question.id}></div>;

                  if (answer !== undefined && answer.length > 0) {
                    switch (question.answerType) {
                      case ANSWER_TYPES.MULTI_SELECT: {
                        answer = renderMultiSelectAnswer(question);
                        break;
                      }

                      case ANSWER_TYPES.SINGLE_SELECT: {
                        answer = renderSingleSelectAnswer(question);
                        break;
                      }

                      case ANSWER_TYPES.SHORT_TEXT:
                        break;

                      case ANSWER_TYPES.LONG_TEXT: {
                        answer = answer.replaceAll(
                          "<li>",
                          '<li class="keep-together">'
                        );
                        answer = changePdfHtmlTags(answer);
                        break;
                      }

                      default:
                        answer = `<p class="text-center">${answer}</p>`;
                    }
                  }

                  return (
                    <Row key={question.id} className="keep-together">
                      <Col xs={12} className="pdf-font-light px-0 mb-1 mt-2">
                        {index + 1}. {question.text}
                      </Col>
                      <Col
                        xs={12}
                        className={`mb-2 ${
                          question.answerType === ANSWER_TYPES.LONG_TEXT
                            ? "py-3"
                            : "py-1"
                        } answer-wrap`}
                      >
                        <div
                          dangerouslySetInnerHTML={{ __html: answer }}
                          className="answer"
                        ></div>
                      </Col>
                    </Row>
                  );
                })}
              </Container>
            </Container>
          )
        );
      });
    }
  };

  return loading ? (
    <div className="loader-container">
      <Loader status="Generating report" />
    </div>
  ) : (
    <Container className="aqua-container report-container scored-report-pdf pt-4">
      <div className="header">
        <ButtonToolbar className="form-group ms-auto">
          <Button
            variant="primary"
            size="sm"
            onClick={() => exportPDF()}
            className="save-pdf-btn"
          >
            {fileSaving ? (
              <>
                <Spinner animation="border" variant="light" size="sm" />
                Downloading
              </>
            ) : (
              <>
                <Icon className="me-1" iconString="download" />
                Download
              </>
            )}
          </Button>
        </ButtonToolbar>
      </div>

      <div className="pdf-export-wrapper mb-5">
        <PDFExport
          paperSize={"A4"}
          fileName={`AquaRisk ${reportData.farmName} ${
            summary ? "Summary" : "Report"
          }.pdf`}
          title=""
          subject=""
          keywords=""
          ref={(r) => (report = r)}
          pageTemplate={pageTemplate}
          keepTogether={".keep-together"}
          forcePageBreak={".page-break"}
          margin={{ bottom: 70, top: 20, left: 0, right: 0 }}
        >
          <div className="report" ref={pdfRef}>
            <div className="pdf-header">
              <div className="text-end">
                <img
                  src={AQUARISK_REPORT_LOGO}
                  height="40"
                  alt="AquaRisk Logo"
                  className="img-logo"
                />
              </div>

              <Row className="d-flex align-items-end justify-content-start my-4">
                <Col className="pe-0">
                  <div className="section-score">
                    <h2 className="pdf-title mb-0">{reportData.farmName}</h2>
                    <div className="pdf-subtitle mb-1">
                      {reportData.companyName}
                    </div>
                    <div
                      className={`bar bar-xls mb-2 risk-${getRiskLevelClassName(
                        reportData.scoring?.risk || "LOW"
                      )}`}
                    ></div>
                    <div className="norm-score text-end">
                      Overall Score: {reportData.scoring?.normScore}%
                    </div>
                  </div>
                </Col>

                <Col className="d-flex ps-0">
                  {reportData.scoring?.children.map((sectionScore, index) => {
                    return (
                      <div
                        key={sectionScore.externalId}
                        className="section-score text-center ps-3 d-flex flex-column"
                      >
                        <div className="d-flex align-items-center justify-content-center flex-grow-1">
                          <img
                            alt={sectionScore.name}
                            src={
                              CATEGORY_ICONS[sectionScore.name.toLowerCase()]
                            }
                            className="mb-2 img-icon img-icon-sm"
                          />
                        </div>
                        <div
                          className={`bar bar-xls mb-2 risk-${getRiskLevelClassName(
                            sectionScore.risk
                          )}`}
                        ></div>
                        <div className="norm-score">
                          {Math.round(sectionScore.normScore)}%
                        </div>
                      </div>
                    );
                  })}
                </Col>
              </Row>
            </div>

            <div className="farm-details farm-details-odd-rows d-flex flex-wrap mb-4 py-1">
              {farmDetails.map((item) => {
                let value = get(reportData, item.value);

                if (item.value === "farm.surveyDate") {
                  value = value !== DATE_ISO_DEFAULT ? formatDate(value) : "";
                }

                return (
                  <Row
                    className="farm-details-item align-items-center mx-0"
                    key={item.title}
                  >
                    <Col xs={6} className="pdf-font-bold">
                      {item.title}
                    </Col>
                    <Col xs={6} className="text-end">
                      {value}
                    </Col>
                  </Row>
                );
              })}
            </div>

            {reportData.scoring?.children.map((sectionScore) => {
              return (
                <div
                  key={sectionScore.externalId}
                  className="section keep-together"
                >
                  <div className="section-header d-flex align-items-center justify-content-between">
                    <div
                      className={`section-header-wrap border-${getRiskLevelClassName(
                        sectionScore.risk
                      )} pb-2`}
                    >
                      <h5 className="section-header-title my-0 d-flex justify-content-between">
                        <span>{sectionScore.name}</span>
                        <span className="pdf-font-italic-light">
                          {Math.round(sectionScore.normScore)}%
                        </span>
                      </h5>
                    </div>

                    <img
                      alt={sectionScore.name}
                      src={CATEGORY_ICONS[sectionScore.name.toLowerCase()]}
                      className="img-icon img-icon-md"
                    />
                  </div>

                  <div className="tile-wrapper">
                    {sectionScore.children.map((step) => {
                      return (
                        <div className="tile-wrapper-report" key={step.name}>
                          <Tile
                            isReport={true}
                            text={step.title}
                            riskLevel={getRiskLevelClassName(step.risk)}
                            score={step.risk.replace(/_/g, " ")}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div className="divider"></div>
                </div>
              );
            })}

            {!summary ? (
              <>
                <div className="page-break"></div>

                {reportData.survey?.sections.map((section) => {
                  return (
                    <div key={section.id}>
                      {renderStepsWithAnswers(section)}
                    </div>
                  );
                })}
              </>
            ) : null}

            <div className="pb-4"></div>
          </div>
        </PDFExport>
      </div>
    </Container>
  );
};

export default SurveyPdf;
