import React, { useRef, useMemo, useEffect } from "react";
import MapGL, {
  Marker,
  NavigationControl,
  MapRef,
  MapboxEvent,
} from "react-map-gl";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import {
  SATELLITE_MAP_STYLE,
  DARK_MAP_STYLE,
  SATELLITE_MAP_LAYERS_FOR_DISABLE as layersForDisable,
} from "../../constants/constants";
import { INITIAL_MAP_ZOOM } from "../../constants/initialData";
import {
  getPinCoordinates,
  StyleSwitcher as StyleSwitcherControl,
} from "../../utils/map";
import { MAP_MARKER_SVG_PATH } from "../../constants/imagesConstants";
import { HEX_COLORS } from "../../constants/enums";
import { Marker as MarkerType, MapPosition } from "../../models";

// @ts-expect-error: The following is required to stop "npm build" from transpiling mapbox code.
mapboxgl.workerClass =
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

type AquaMapBoxGlProps = {
  callingContainer?: "ActionsContainer";
  position: MapPosition;
  markers: MarkerType[];
  selectedMarkerId?: string;
  onMarkerClick?: (id: string) => void;
};

const AquaMapBoxGl: React.FC<AquaMapBoxGlProps> = ({
  callingContainer,
  position,
  markers,
  selectedMarkerId,
  onMarkerClick,
}) => {
  const mapRef = useRef<MapRef>(null);

  useEffect(() => {
    mapRef.current?.flyTo({
      zoom: position.zoom || 0,
      center: [position.lng || 0, position.lat || 0],
      pitch: position.pitch || 0,
      speed: 0.2,
      duration: position.duration || 2000,
    });
  }, [position, selectedMarkerId]);

  const farmsPins = useMemo(
    () =>
      markers.map((farm, index) => {
        const { longitude, latitude } = getPinCoordinates([farm.lng, farm.lat]);

        const isMarkerSelected = farm?.id === selectedMarkerId;
        const color = isMarkerSelected ? HEX_COLORS.GREEN : HEX_COLORS.BLUE;

        return (
          <Marker
            key={`marker-${index}`}
            longitude={longitude}
            latitude={latitude}
            anchor="bottom"
            onClick={() => (onMarkerClick ? onMarkerClick(farm.id) : undefined)}
            style={{ zIndex: isMarkerSelected ? 1 : 0 }}
          >
            <svg
              height={30}
              viewBox="-1 -2 26 30"
              style={{
                fill: color,
                strokeWidth:
                  callingContainer === "ActionsContainer" ? "3" : "0",
                stroke:
                  callingContainer === "ActionsContainer" ? "white" : "none",
              }}
            >
              <path d={MAP_MARKER_SVG_PATH} />
            </svg>
          </Marker>
        );
      }),
    [markers, onMarkerClick, selectedMarkerId]
  );

  const addCustomControls = (event: MapboxEvent) => {
    const map = event?.target;
    if (map) {
      map.addControl(
        new StyleSwitcherControl(SATELLITE_MAP_STYLE, DARK_MAP_STYLE),
        "top-left"
      );
    }

    mapRef.current?.flyTo({
      pitch: 50,
      zoom: position.zoom,
      center: [position.lng, position.lat],
      speed: 0.2,
      duration: 2000,
    });
  };

  return (
    <MapGL
      ref={mapRef}
      initialViewState={{
        longitude: position.lng,
        latitude: position.lat,
        zoom: position.zoom,
        bearing: 0,
        pitch: 50,
      }}
      minZoom={INITIAL_MAP_ZOOM.min}
      maxZoom={INITIAL_MAP_ZOOM.max}
      mapStyle={SATELLITE_MAP_STYLE}
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      onLoad={addCustomControls}
      onStyleData={(event) => {
        const map = event?.target;
        if (map) {
          layersForDisable.forEach((layerID) => {
            if (map.getLayer(layerID)) {
              map.setLayoutProperty(layerID, "visibility", "none");
            }
          });
        }
      }}
    >
      {farmsPins}
      <NavigationControl position="top-left" />
    </MapGL>
  );
};

export default AquaMapBoxGl;
