import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useInView } from "react-intersection-observer";

import { riskCategories } from "../../constants/landing";

import Icon from "../common/Icon";

type RiskCategoriesProps = {
  /* Is this component is in the viewport? Helps to add 'in-view' class name. */
  isInViewport: boolean;
};

const RiskCategories: React.FC<RiskCategoriesProps> = ({ isInViewport }) => {
  const riskCategoriesLength = riskCategories.length;
  const carouselAnimationDelay = 5000;
  const carouselAnimationDuration = 1000; // $carousel-animation-duration in scss variables

  const [carousel, inViewCarousel] = useInView();
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState<number>(0);

  const carouselItemRef = useRef<HTMLDivElement | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const setCategoryIndex = (index: number) => {
    setCurrentCategoryIndex(index);
  };

  const setPreviousCategory = () => {
    setCurrentCategoryIndex((prevIndex: number) =>
      prevIndex === 0 ? riskCategoriesLength - 1 : prevIndex - 1
    );
  };

  const setNextCategory = () => {
    setCurrentCategoryIndex((prevIndex: number) =>
      prevIndex === riskCategoriesLength - 1 ? 0 : prevIndex + 1
    );
  };

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  useEffect(() => {
    if (inViewCarousel) {
      resetTimeout();

      timeoutRef.current = setTimeout(
        () => setNextCategory(),
        currentCategoryIndex === 0
          ? carouselAnimationDelay + carouselAnimationDuration
          : carouselAnimationDelay
      );

      const carouselItemStyle = carouselItemRef.current?.style;
      if (carouselItemStyle) {
        if (currentCategoryIndex === 0) {
          carouselItemStyle.transition = `all 0s ease-in-out`;
        } else {
          carouselItemStyle.transition = `all ${
            carouselAnimationDuration / 1000
          }s ease-in-out`;
        }

        carouselItemStyle.transform = `translateX(-${currentCategoryIndex}00%)`;
      }

      return () => resetTimeout();
    } else {
      setCategoryIndex(0);
    }
  }, [currentCategoryIndex, inViewCarousel, setCategoryIndex, setNextCategory]);

  return (
    <Row className="layout-container risk-categories flex-column">
      <Col className="flex-grow-0">
        <Row className="layout-header flex-column">
          <Col className="flex-grow-0">
            <Row>
              <h2
                className={`layout-header-title layout-wrapper ${
                  isInViewport ? "in-view" : ""
                }`}
              >
                Risk Categories
              </h2>
            </Row>
          </Col>

          <Col>
            <Row className="layout-header-content layout-wrapper justify-content-center align-items-center">
              <Col xs={12} xl={8} className="py-4 layout-inner">
                <div>
                  We work towards reducing farmers financial exposure by
                  addressing every aspect of the operation, evaluating the
                  performance and providing improvements that reduce financial
                  exposure.
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Col className="layout-wrapper">
        <Row className="layout-content justify-content-center align-items-center py-3">
          <Col xs={12} lg={8} className="layout-inner">
            <Row>
              <Col xs={12} className="risk-carousel-container" ref={carousel}>
                <Row
                  className="risk-carousel-items flex-nowrap"
                  ref={carouselItemRef}
                >
                  {riskCategories.map((category, index) => {
                    return (
                      <Col
                        xs={12}
                        className="risk-category risk-carousel-item"
                        key={`${index}-${category.name}`}
                      >
                        <Row className="justify-content-between align-items-end">
                          <Col xs={12} lg={5} className="pb-2">
                            <div className="risk-category-indicators d-flex flex-wrap justify-content-center">
                              {category.indicators.map((indicator) => {
                                const levelClassName = indicator.level
                                  .toLowerCase()
                                  .replace(" ", "-");
                                return (
                                  <div
                                    className="risk-category-container"
                                    key={`${category.name}-${indicator.name}`}
                                  >
                                    <div className="risk-category-indicator">
                                      <div className="risk-indicator-inner d-flex flex-column align-items-center text-center">
                                        <div className="risk-indicator-name d-flex align-items-center flex-grow-1">
                                          {indicator.name}
                                        </div>
                                        <div
                                          className={`bar bar-xl w-100 risk-${levelClassName}`}
                                        ></div>
                                        <div className="risk-indicator-level d-flex align-items-center flex-grow-1">
                                          {indicator.level}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </Col>
                          <Col xs={12} lg={6} className="mt-4 mt-lg-0">
                            <div className="d-flex justify-content-between align-items-center mb-5">
                              <div className="category-name">
                                {category.name}
                              </div>
                              <div>
                                <img
                                  className="category-icon"
                                  src={category.icon}
                                  alt={category.iconAlt}
                                />
                              </div>
                            </div>
                            <div className="d-flex">
                              <div className="category-description">
                                {category.description}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
              <Col xs={12} className="mt-4">
                <Row className="justify-content-end flex-lg-row-reverse">
                  <Col xs={12} lg={3}>
                    <div className="carousel-buttons d-flex justify-content-end">
                      <Button
                        onClick={() => setPreviousCategory()}
                        className="button me-3"
                      >
                        <Icon iconString="chevron-left" />
                      </Button>
                      <Button
                        onClick={() => setNextCategory()}
                        className="button"
                      >
                        <Icon iconString="chevron-right" />
                      </Button>
                    </div>
                  </Col>
                  <Col xs={12} lg={9}>
                    <Row>
                      {riskCategories.map((category, index) => {
                        const isActive = `${index === currentCategoryIndex}`;

                        const animationStyle = () => {
                          if (index < currentCategoryIndex) {
                            return { width: "calc(100% + 15px * 2)" };
                          }

                          if (index === currentCategoryIndex) {
                            return {
                              animationName: "timeline-effect-left",
                              animationTimingFunction: "linear",
                              animationFillMode: "forwards",
                              animationDuration:
                                carouselAnimationDuration / 1000 + "s",
                            };
                          }
                        };

                        return (
                          <Col
                            xs={2}
                            lg={2}
                            className={`dot ${inViewCarousel ? "in-view" : ""}`}
                            active={isActive}
                            key={`${index}-${category.name}`}
                          >
                            <div className={"dot-spot-container"}>
                              <div
                                className={"dot-spot-line"}
                                style={index !== 0 ? animationStyle() : {}}
                              ></div>
                              <div
                                className="dot-spot"
                                onClick={() => setCategoryIndex(index)}
                              ></div>
                            </div>
                            <div
                              className="dot-name d-none d-lg-block text-center"
                              onClick={() => setCategoryIndex(index)}
                            >
                              {category.name}
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default RiskCategories;
