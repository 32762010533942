import React, { createContext, useReducer } from "react";
import { errorPopupReducer } from "../reducers/errorPopupReducer";
import { ErrorPopupContextType, ErrorPopupState } from "../models";

export const initialPopup: ErrorPopupState = {
  show: false,
  onClose: (): void => undefined,
  onRetry: (): void => undefined,
  message: "",
  showRetryBtn: false,
};

const initialValues: ErrorPopupContextType = {
  popup: initialPopup,
  dispatch: (): void => undefined,
};

export const ErrorPopupContext =
  createContext<ErrorPopupContextType>(initialValues);

const ErrorPopupContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [popup, dispatch] = useReducer(errorPopupReducer, initialValues.popup);

  return (
    <ErrorPopupContext.Provider value={{ popup, dispatch }}>
      {children}
    </ErrorPopupContext.Provider>
  );
};

export default ErrorPopupContextProvider;
