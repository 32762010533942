// from ISO to LocaleDate
// 2020-01-01T00:00:00 => 01/01/2020
export const formatDate = (date?: Date): string => {
  if (date) return new Date(date).toLocaleDateString();
  return new Date().toLocaleDateString();
};

// from ISO to LocaleDateTime
// 2020-01-01T05:06:07 => 01/01/2020, 05:06:07 UTC
export const formatDateAndTime = (date: Date): string => {
  const x = date ? new Date(date) : new Date();
  return `${x.toLocaleDateString()}, ${x.toLocaleTimeString()} UTC`;
};

// cut UTC
// 2023-05-16T11:19:16Z[UTC] => 2023-05-16T11:19:16Z
export const formatDateCutUTC = (date: string): string => {
  //TODO: refactor it
  let x = new Date().toISOString();
  if (date?.indexOf("[UTC]") >= 0) x = date.substring(0, date.indexOf("[UTC]"));
  if (date && date[date.length - 1] === "Z") x = date;
  return x;
};
