import { USER_ROLES_ENUM as roles } from "../constants/enums";
import { CurrentUser } from "../models";

export const isAdmin = (user: CurrentUser): boolean => {
  return user.authorities.includes(roles.ADMIN);
};

export const isSurveyor = (user: CurrentUser): boolean => {
  return user.authorities.includes(roles.SURVEYOR);
};

export const isCompany = (user: CurrentUser): boolean => {
  return user.authorities.includes(roles.COMPANY);
};

export const isFarmManager = (user: CurrentUser): boolean => {
  return user.authorities.includes(roles.MANAGER);
};
