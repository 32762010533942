import { ErrorPopupState, ErrorPopupReducerAction } from "../models";
import { ERROR_POPUP_ACTION_KIND } from "../constants/enums";
import { initialPopup } from "../contexts/ErrorPopupContext";

export const errorPopupReducer = (
  state: ErrorPopupState,
  action: ErrorPopupReducerAction
): ErrorPopupState => {
  switch (action.type) {
    case ERROR_POPUP_ACTION_KIND.SHOW_ERROR_POPUP:
      if (action.popup)
        return {
          ...state,
          show: true,
          onRetry: action.popup?.onRetry,
          message: action.popup.message,
          showRetryBtn: action.popup.showRetryBtn,
        };
      return state;

    case ERROR_POPUP_ACTION_KIND.CLOSE_POPUP:
      return initialPopup;

    default:
      return state;
  }
};
