import React from "react";

type TileProps = {
  // title string placed in the top of the tile
  text: string;
  // risk level class name. Get with getRiskLevelClassName()
  // using for generating border and bar color.
  riskLevel: string | null;
  // score string placed in the bottom of the tile. low/hight/moderate.
  score: string;
  // triggered when Tile is clicked
  onClick?: () => void;
  // is this component used in the report?
  isReport?: boolean;
};

const Tile: React.FC<TileProps> = ({
  text,
  riskLevel,
  score,
  onClick,
  isReport,
}) => {
  return (
    <div
      className={`tile
        ${isReport ? `tile-report border-${riskLevel}` : ""}
        ${onClick ? "clickable" : ""}
      `}
      onClick={onClick}
    >
      <div className="tile-inner">
        <div className="text">{text}</div>
        <div className={`bar bar-xl risk-${riskLevel}`}></div>
        <div className="score">{score}</div>
      </div>
    </div>
  );
};

export default Tile;
