import { ANSWER_UNITS } from "./answerTypes";

export const PRETTY_ANSWER_UNITS: Record<string, ANSWER_UNITS | string> =
  Object.freeze({
    MG_: "mg/l",
    PERCENT: "%",
    PPM: "parts per million",
    PPT: "parts per thousand",
    FEET_INCHES: "feet.inches",
    METERS: "meters",
    FAHRENHEIT: "fahrenheit",
    CELSIUS: "celcius",
    YEAR: "years",
    KILOGRAMS: "kilograms",
    GRAMS: "grams",
    LITRES: "litres",
    MILLILITERS: "millilitres",
    NONE: "",
  });
