import React from "react";

type ProfileImage = {
  name: string;
  classNameProp?: string;
};

const ProfileImage: React.FC<ProfileImage> = ({ name, classNameProp = "" }) => {
  const getInitials = (name: string) => {
    let initials;
    const nameSplit = name.split(" ");
    const nameLength = nameSplit.length;
    if (nameLength > 1) {
      initials =
        nameSplit[0].substring(0, 1) +
        nameSplit[nameLength - 1].substring(0, 1);
    } else if (nameLength === 1) {
      initials = nameSplit[0].substring(0, 1);
    } else return "";

    return initials.toUpperCase();
  };

  return (
    <div className={`profile-image ${classNameProp}`}>{getInitials(name)}</div>
  );
};

export default ProfileImage;
