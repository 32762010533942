import { AuthReducerAction } from "../models";
import { AUTH_ACTION_KIND } from "../constants/enums";

export const authReducer = (
  state: boolean,
  action: AuthReducerAction
): boolean => {
  switch (action.type) {
    case AUTH_ACTION_KIND.LOGIN:
      if (action.token) localStorage.setItem("accessToken", action.token);
      if (action.refreshToken)
        localStorage.setItem("refreshToken", action.refreshToken);
      return !!localStorage.accessToken;

    case AUTH_ACTION_KIND.LOGOUT:
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      return !!localStorage.accessToken;

    default:
      return state;
  }
};
