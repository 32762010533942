import React, { useState } from "react";
import { Accordion } from "react-bootstrap";

import { pluralize } from "../../utils/pluralize";
import {
  ActiveSurveyFarmResponse,
  SurveyInfoResponse,
} from "../../models/be_models";

import SurveysList from "./SurveysList";
import ScoreTitles from "../general/ScoreTitles";

type FarmsWithSurveysListProps = {
  farms: ActiveSurveyFarmResponse[];
  selectedSurvey: SurveyInfoResponse;
  handleSurveyClick: (survey: SurveyInfoResponse) => void;
  handleViewSurveyClick: (
    e: React.MouseEvent,
    survey: SurveyInfoResponse
  ) => void;
  handleResultsClick: (e: React.MouseEvent, survey: SurveyInfoResponse) => void;
  handleSurveyUsersClick: (
    e: React.MouseEvent,
    survey: SurveyInfoResponse
  ) => void;
};

const FarmsWithSurveysList: React.FC<FarmsWithSurveysListProps> = ({
  farms,
  selectedSurvey,
  handleSurveyClick,
  handleViewSurveyClick,
  handleResultsClick,
  handleSurveyUsersClick,
}) => {
  const defaultExpanded: Record<string, boolean> = {};
  for (const farm of farms) {
    defaultExpanded[`${farm.externalId}`] = false;
  }
  const [expandedFarms, setExpandedFarms] = useState(defaultExpanded);

  return !farms.length ? (
    <p className="px-3">No results.</p>
  ) : (
    <div>
      <Accordion
        className="cards px-2 farm-with-survey surveys-toggle"
        defaultActiveKey={farms[0].externalId}
      >
        {farms.map((farm) => {
          return (
            <Accordion.Item
              className="farm mb-4"
              key={farm.externalId}
              eventKey={farm.externalId}
            >
              <Accordion.Header
                onClick={() => {
                  handleSurveyClick(farm.surveys[0]);
                  setExpandedFarms({
                    ...expandedFarms,
                    [farm.externalId]: !expandedFarms[farm.externalId],
                  });
                }}
              >
                <div className="d-flex justify-content-between flex-grow-1 me-3">
                  <span className="survey-name">{farm.name}</span>
                  <span>
                    {farm.surveys.length}{" "}
                    {pluralize(farm.surveys.length, "Audits")}
                  </span>
                </div>
              </Accordion.Header>

              <Accordion.Body>
                <ScoreTitles />
                <SurveysList
                  farmSurveys={farm.surveys}
                  selectedSurvey={selectedSurvey}
                  handleSurveyClick={handleSurveyClick}
                  handleViewSurveyClick={handleViewSurveyClick}
                  handleResultsClick={handleResultsClick}
                  handleSurveyUsersClick={handleSurveyUsersClick}
                />
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </div>
  );
};

export default FarmsWithSurveysList;
