import {
  React,
  useEffect,
  useContext,
  useState,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { Button, Row, Col } from "react-bootstrap";
import MapGL, {
  Marker,
  Popup,
  Source,
  Layer,
  NavigationControl,
} from "react-map-gl";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
// The following is required to stop "npm build" from transpiling mapbox code.
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import { AlertsContext } from "../../contexts/AlertsContext";
import { ErrorPopupContext } from "../../contexts/ErrorPopupContext";
import { showFetchError } from "../../utils/alerts";
import {
  getCompanyRisk,
  getMyFarmsByCountry,
  getMyFarmCountByCountry,
  getMyCompanyFarms,
  getCompanyFarms,
} from "../../network/request";
import {
  SATELLITE_MAP_STYLE,
  DARK_MAP_STYLE,
  SATELLITE_MAP_LAYERS_FOR_DISABLE as layersForDisable,
} from "../../constants/constants";
import { INITIAL_MAP_ZOOM } from "../../constants/initialData";
import {
  HEX_COLORS,
  RISK_LEVELS_ENUM,
  CATEGORIES as categoriesTypes,
  CHARTS_ENUM as chartsTypes,
  CHARTS,
  CATEGORIES_FILTERS,
} from "../../constants/enums";
import { MAP_MARKER_SVG_PATH } from "../../constants/imagesConstants";
import { getRiskLevelClassName } from "../../utils/string";
import {
  calculateMapCenter,
  getPinCoordinates,
  circles,
  labels,
  StyleSwitcher as StyleSwitcherControl,
  ZoomExtend as ZoomExtendControl,
} from "../../utils/map";

import MarkerIcon from "../../images/Marker.svg";
import Loader from "../common/Loader";
import Icon from "../common/Icon";
import GeocoderControl from "./GeoCoderControl";
import AccordionCustomComponent from "./AccordionCustomComponent";
import RiskScoreTotalBlock from "./RiskScoreTotalBlock";
import AmountsTotalBlock from "./AmountsTotalBlock";

const farmsEmptyCollection = {
  type: "FeatureCollection",
  features: [],
};

const initialZoom = 2;
const initialPitch = 50;
const initialPadding = { top: 0, bottom: 50, left: 150, right: 150 };

const IntelligenceContainer = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [countriesData, setCountriesData] = useState([]);
  const [farmCountByCountryData, setFarmCountByCountryData] = useState([]);
  const [farmsData, setFarmsData] = useState([]);
  const [stepsData, setStepsData] = useState({
    HIGH: 122,
    LOW: 22,
    MODERATE: 22,
    MODERATE_HIGH: 122,
    MODERATE_LOW: 22,
  });

  const [currentMapType, setCurrentMapType] = useState(chartsTypes.RISK_SCORE);
  const [currentFilter, setCurrentFilter] = useState(categoriesTypes.ALL);
  const [filtersBtnDisabled, setFiltersBtnDisabled] = useState(false);
  const [clickIsActive, setClickIsActive] = useState(false);

  const [markers, setMarkers] = useState([]);
  const [currentPitch, setCurrentPitch] = useState(initialPitch);
  const [mapPosition, setMapPosition] = useState({
    duration: 2000,
    center: [0, 0],
    padding: initialPadding,
  });

  const [popupInfoRiskScores, setPopupInfoRiskScores] = useState(null);
  const [surveyInfoInTotalBlock, setSurveyInfoInTotalBlock] = useState(null);
  const [popupInfoAllFarms, setPopupInfoAllFarms] = useState(null);
  const [toggle, setToggle] = useState(false);

  const { currentUser } = useContext(CurrentUserContext);
  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);
  const mapRef = useRef(null);

  useEffect(() => {
    const fetchCompanyFarms = async () => {
      try {
        setLoading(true);
        const { data: farmsResult } = await getCompanyFarms(
          currentUser.currentCompany.externalId
        );
        const farmsCollection = farmsEmptyCollection;
        const simplifyNumber = (number) =>
          Math.round(number / 1000).toLocaleString() + "k";

        farmsResult.forEach((farm) => {
          farmsCollection.features.push({
            type: "Feature",
            properties: {
              id: farm.farmExtId,
              actualValue: farm.actualValue,
              avgValue: farm.avgValue,
              maxValue: farm.maxValue,
              animalCount: farm.animalCount,
              harvestWeight: farm.harvestWeight,
              stockingDensity: farm.stockingDensity,
              stockingWeight: farm.stockingWeight,
              tonnage: farm.tonnage,
              surveyId: farm.surveyId,
              surveyName: farm.surveyName,
              farmName: farm.farmName,
              score: farm.score,
              normScore: farm.normScore,
              risk: farm.risk,
              animalCountText: simplifyNumber(farm.animalCount),
              avgValueText: simplifyNumber(farm.avgValue),
              maxValueText: simplifyNumber(farm.maxValue),
              tonnageText: farm.tonnage.toLocaleString(),
              steps: farm.steps,
              sections: farm.sections,
            },
            geometry: {
              type: "Point",
              coordinates: [farm.longitude, farm.latitude],
            },
          });
        });

        setData({ ...farmsCollection });

        if (farmsCollection?.features.length) {
          const markersList = farmsCollection.features.map((item) => {
            return {
              lng: item.geometry.coordinates[0],
              lat: item.geometry.coordinates[1],
            };
          });

          setMarkers(markersList);
          setMapPosition((prev) => ({
            ...prev,
            center: calculateMapCenter(markersList),
          }));
        }
      } catch (error) {
        showFetchError({
          error,
          customMsg: "API error while loading company farms with the audits.",
          object: "the farms with the audits",
          objectName: "plural",
          operation: "loaded",
          dispatchAlert,
          dispatchPopup,
          onRetry: () => fetchCompanyFarms(),
        });
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyFarms();
  }, [currentUser.currentCompany.externalId]);

  useEffect(() => {
    const fetchCompanyFarms = async () => {
      try {
        setLoading(true);

        const { data: farmsResult } = await getMyCompanyFarms(
          currentUser.currentCompany.externalId,
          true
        );
        setFarmsData(farmsResult);
      } catch (error) {
        showFetchError({
          error,
          customMsg: "API error while loading the farms.",
          object: "farms",
          objectName: "plural",
          operation: "loaded",
          dispatchAlert,
          dispatchPopup,
          onRetry: () => fetchCompanyFarms(),
        });
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyFarms();
  }, [currentUser.currentCompany.externalId]);

  useEffect(() => {
    const fetchCompanyCountries = async () => {
      try {
        setLoading(true);

        const { data: countiesResult } = await getMyFarmsByCountry(
          currentUser.currentCompany.externalId
        );
        setCountriesData(countiesResult);
      } catch (error) {
        showFetchError({
          error,
          customMsg: "API error while loading the countries.",
          object: "countries",
          objectName: "plural",
          operation: "loaded",
          dispatchAlert,
          dispatchPopup,
          onRetry: () => fetchCompanyCountries(),
        });
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyCountries();
  }, [currentUser.currentCompany.externalId]);

  useEffect(() => {
    const fetchSteps = async () => {
      try {
        setLoading(true);
        const { data: stepsResult } = await getCompanyRisk(
          currentUser.currentCompany.externalId
        );

        setStepsData(stepsResult);
      } catch (error) {
        showFetchError({
          error,
          customMsg: "API error while loading the summary of risk level.",
          object: "risk levels",
          objectName: "plural",
          operation: "loaded",
          dispatchAlert,
          dispatchPopup,
          onRetry: () => fetchSteps(),
        });
      } finally {
        setLoading(false);
      }
    };

    fetchSteps();
  }, [currentUser.currentCompany.externalId]);

  useEffect(() => {
    const fetchCompanyFarms = async () => {
      try {
        setLoading(true);

        const { data: result } = await getMyFarmCountByCountry(
          currentUser.currentCompany.externalId
        );
        // it is easier to work with array and with the same data structure as in IqFarmsByCountryResponse
        // if the API returns a lot of data and it influence performance, ask to change API return array
        let countriesArr = Object.keys(result.countryFarms).map((key) => {
          return { country: key, farmCount: result.countryFarms[key] };
        });

        setFarmCountByCountryData(countriesArr);
      } catch (error) {
        showFetchError({
          error,
          customMsg: "API error while loading the countries with farms.",
          object: "countries with farms",
          objectName: "plural",
          operation: "loaded",
          dispatchAlert,
          dispatchPopup,
          onRetry: () => fetchCompanyFarms(),
        });
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyFarms();
  }, [currentUser.currentCompany.externalId]);

  const flyToMarkers = useCallback(() => {
    if (markers.length === 0 || markers.length === 1) {
      mapRef.current?.easeTo({ ...mapPosition });
      return;
    }

    let north = markers[0].lng,
      east = markers[0].lat,
      south = markers[0].lng,
      west = markers[0].lat;

    markers.forEach((spot) => {
      // location in [long,lat] format
      if (spot.lng > north) north = spot.lng;
      if (spot.lat > east) east = spot.lat;
      if (spot.lng < south) south = spot.lng;
      if (spot.lat < west) west = spot.lat;
    });

    if (north === 0 && east === 0 && south === 0 && west === 0) {
      mapRef.current?.easeTo({ ...mapPosition });
      return;
    }

    mapRef.current?.fitBounds(
      [
        [north, east],
        [south, west],
      ],
      {
        padding: mapPosition.padding,
        pitch: currentPitch,
        duration: mapPosition.duration,
      }
    );
  }, [markers, mapPosition]); // eslint-disable-line

  useEffect(() => {
    if (mapRef.current) {
      flyToMarkers();
    }
  }, [flyToMarkers]);

  const handleMapDataTypeBtnClick = (type) => {
    let pitch = 0;
    let padding = initialPadding;
    let markersList = [];

    switch (type) {
      case chartsTypes.ALL_FARMS: {
        pitch = 50;
        if (farmsData.length) {
          markersList = farmsData.map((item) => {
            return {
              lng: item.longitude,
              lat: item.latitude,
            };
          });
        }
        setFiltersBtnDisabled(true);
        setPopupInfoRiskScores(null);
        break;
      }

      case chartsTypes.RISK_SCORE: {
        pitch = 50;
        if (data?.features.length) {
          markersList = data.features.map((item) => {
            return {
              lng: item.geometry.coordinates[0],
              lat: item.geometry.coordinates[1],
            };
          });
        }
        setFiltersBtnDisabled(false);
        setPopupInfoAllFarms(null);
        break;
      }

      default: {
        padding = { ...padding, top: 100, bottom: 100 };

        if (data?.features.length) {
          markersList = data.features.map((item) => {
            return {
              lng: item.geometry.coordinates[0],
              lat: item.geometry.coordinates[1],
            };
          });
        }
        setPopupInfoRiskScores(null);
        setPopupInfoAllFarms(null);
        setFiltersBtnDisabled(true);
      }
    }

    setMarkers(markersList);

    setCurrentPitch(pitch);
    setMapPosition((prev) => ({
      ...prev,
      center: calculateMapCenter(markersList),
      padding: padding,
    }));

    setCurrentMapType(type);
    setSurveyInfoInTotalBlock(null);
    setClickIsActive(false);
  };

  const pins = useMemo(
    () =>
      data.features?.map((feature, index) => {
        const { longitude, latitude } = getPinCoordinates(
          feature.geometry.coordinates
        );

        let color = HEX_COLORS.GRAY;
        let riskLevel = feature.properties.risk;

        if (currentFilter && feature.properties.sections) {
          const currentSection = feature.properties.sections.filter(
            ({ name }) => name === currentFilter
          );
          if (currentSection.length) riskLevel = currentSection[0].risk;
        }

        switch (riskLevel) {
          case RISK_LEVELS_ENUM.HIGH: {
            color = HEX_COLORS.RED;
            break;
          }
          case RISK_LEVELS_ENUM.MODERATE_HIGH: {
            color = HEX_COLORS.ORANGE;
            break;
          }
          case RISK_LEVELS_ENUM.MODERATE: {
            color = HEX_COLORS.YELLOW;
            break;
          }
          case RISK_LEVELS_ENUM.MODERATE_LOW: {
            color = HEX_COLORS.CYAN;
            break;
          }
          case RISK_LEVELS_ENUM.LOW: {
            color = HEX_COLORS.GREEN;
            break;
          }
          default: {
            color = HEX_COLORS.GRAY;
            break;
          }
        }

        return (
          <Marker
            key={`marker-${index}`}
            longitude={longitude}
            latitude={latitude}
            anchor="bottom"
            onClick={(e) => {
              // If we let the click event propagates to the map, it will not work
              e.originalEvent.stopPropagation();
              setClickIsActive(true);
              setPopupInfoRiskScores(feature);
              setSurveyInfoInTotalBlock(
                color !== HEX_COLORS.GRAY ? feature : null
              );
            }}
          >
            <svg
              height={30}
              viewBox="0 0 24 24"
              style={{ fill: color, stroke: "none" }}
              onMouseEnter={() =>
                !clickIsActive && setPopupInfoRiskScores(feature)
              }
              onMouseLeave={() =>
                !clickIsActive && setPopupInfoRiskScores(null)
              }
            >
              <path d={MAP_MARKER_SVG_PATH} />
            </svg>
          </Marker>
        );
      }),
    [data, currentFilter, clickIsActive]
  );

  const farmsPins = useMemo(
    () =>
      farmsData.map((farm, index) => {
        const { longitude, latitude } = getPinCoordinates([
          farm.longitude,
          farm.latitude,
        ]);

        return (
          <Marker
            key={`marker-${index}`}
            longitude={longitude}
            latitude={latitude}
            anchor="bottom"
            onClick={() => {
              mapRef.current?.flyTo({
                zoom: 15,
                center: [longitude, latitude],
                speed: 0.2,
                duration: 5000,
              });
            }}
          >
            <img
              src={MarkerIcon}
              alt="icon"
              height="30"
              onMouseEnter={() => setPopupInfoAllFarms(farm)}
              onMouseLeave={() => setPopupInfoAllFarms(null)}
            />
          </Marker>
        );
      }),
    [farmsData]
  );

  const allFarms = useMemo(() => {
    return (
      <Source
        id="mapbox-dem"
        type="raster-dem"
        url="mapbox://mapbox.mapbox-terrain-dem-v1"
        tileSize={512}
        maxzoom={14}
      >
        {farmsPins}
      </Source>
    );
  }, [farmsPins]);

  const popupRiskScores = useMemo(() => {
    if (popupInfoRiskScores) {
      const { longitude, latitude } = getPinCoordinates(
        popupInfoRiskScores.geometry.coordinates
      );

      return (
        <Popup
          anchor="top"
          longitude={longitude}
          latitude={latitude}
          onClose={() => setPopupInfoRiskScores(null)}
          closeButton={false}
        >
          <p className="mb-2 d-flex justify-content-between align-items-start">
            <a
              href={`/audit/audit-result/${popupInfoRiskScores.properties.surveyId}`}
              target="_blank"
              rel="noreferrer"
              className="popup-title"
            >
              {popupInfoRiskScores.properties.surveyName}
            </a>

            {popupInfoRiskScores.properties.normScore && (
              <span className="ms-3">
                {popupInfoRiskScores.properties.normScore}%
              </span>
            )}
          </p>
          {popupInfoRiskScores.properties.risk ? (
            <div className="popup-table even-rows-white">
              {popupInfoRiskScores.properties.sections.map((section) => {
                return (
                  <Row className="py-1 flex-nowrap" key={section.name}>
                    <Col sm={7} className="d-flex align-items-center">
                      <div
                        className={`me-3 flex-grow-0 flex-shrink-0 rounded-point risk-${getRiskLevelClassName(
                          section.risk
                        )}`}
                      ></div>
                      <div className="font-italic">{section.name}</div>
                    </Col>
                    <Col sm={5} className="text-end fw-light">
                      {section.normScore}%
                    </Col>
                  </Row>
                );
              })}
            </div>
          ) : (
            <p>Audit is not scored.</p>
          )}
        </Popup>
      );
    }
  }, [popupInfoRiskScores]);

  const popupAllFarms = useMemo(() => {
    if (popupInfoAllFarms) {
      const { longitude, latitude } = getPinCoordinates([
        popupInfoAllFarms.longitude,
        popupInfoAllFarms.latitude,
      ]);

      return (
        <Popup
          anchor="top"
          longitude={longitude}
          latitude={latitude}
          onClose={() => setPopupInfoAllFarms(null)}
          closeButton={false}
          className="popup"
        >
          <p className="mb-2 popup-title d-flex justify-content-between align-items-start">
            <span>{popupInfoAllFarms.name}</span>
          </p>
        </Popup>
      );
    }
  }, [popupInfoAllFarms]);

  const bubbles = useMemo(() => {
    let map = {
      dataField: "",
      dataTextField: "",
      bubbleColor: "",
      labelColor: "",
    };

    switch (currentMapType) {
      case chartsTypes.ANIMAL_COUNT: {
        map = {
          dataField: "animalCount",
          dataTextField: "animalCountText",
          bubbleColor: HEX_COLORS.ORANGE,
          labelColor: HEX_COLORS.BLUE,
        };
        break;
      }
      case chartsTypes.AVG_VALUE: {
        map = {
          dataField: "avgValue",
          dataTextField: "avgValueText",
          bubbleColor: HEX_COLORS.YELLOW,
          labelColor: HEX_COLORS.BLUE,
        };
        break;
      }
      case chartsTypes.MAX_VALUE: {
        map = {
          dataField: "maxValue",
          dataTextField: "maxValueText",
          bubbleColor: HEX_COLORS.RED,
          labelColor: HEX_COLORS.WHITE,
        };
        break;
      }
      case chartsTypes.TONNAGE: {
        map = {
          dataField: "tonnage",
          dataTextField: "tonnageText",
          bubbleColor: HEX_COLORS.GREEN,
          labelColor: HEX_COLORS.WHITE,
        };
        break;
      }
      default:
        break;
    }

    return (
      <Source type="geojson" data={data}>
        <Layer {...circles(map.dataField, map.bubbleColor)} />
        <Layer {...labels(map.dataTextField, map.labelColor)} />
      </Source>
    );
  }, [data, currentMapType]);

  const addCustomControls = (event) => {
    const map = event?.target;
    if (map) {
      map.addControl(
        new StyleSwitcherControl(SATELLITE_MAP_STYLE, DARK_MAP_STYLE),
        "bottom-left"
      );

      map.addControl(
        new ZoomExtendControl(initialZoom, mapPosition.center),
        "bottom-left"
      );

      flyToMarkers();
    }
  };

  const handleTiltOnZoom = (e) => {
    const map = e.target;
    const state = e.viewState;

    if (state.zoom >= 5) {
      const zoomFrom = INITIAL_MAP_ZOOM.max - 10;
      const zoomTo = INITIAL_MAP_ZOOM.max;
      const pitchTo = 75;

      const smoothStep = (min, max, value) => {
        const x = Math.max(0, Math.min(1, (value - min) / (max - min)));
        return x * x * (3 - 2 * x);
      };

      const calcPitch = (initialPitch, pitchTo, zoom) => {
        return initialPitch * (1 - zoom) + pitchTo * zoom;
      };

      const newPitch = calcPitch(
        initialPitch,
        pitchTo,
        smoothStep(zoomFrom, zoomTo, state.zoom)
      );

      setCurrentPitch(newPitch);
      map.transform.pitch = newPitch; // instead of `map.setPitch`

      mapRef.current?.once("moveend", () => {
        mapRef.current?.setPitch(newPitch);
      });
    }
  };

  return loading ? (
    <div className="loader-container">
      <Loader status="Loading maps" />
    </div>
  ) : (
    <div className="intelligence">
      <MapGL
        // All camera option are set via transitions (ease, flyto, fitbounds)
        ref={mapRef}
        minZoom={INITIAL_MAP_ZOOM.min}
        maxZoom={INITIAL_MAP_ZOOM.max}
        maxPitch={85}
        mapStyle={SATELLITE_MAP_STYLE}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        onLoad={addCustomControls}
        onZoom={(e) =>
          currentMapType === chartsTypes.ALL_FARMS ||
          currentMapType === chartsTypes.RISK_SCORE
            ? handleTiltOnZoom(e)
            : null
        }
        onStyleData={(event) => {
          const map = event?.target;
          if (map) {
            const availableLayers = event.style.stylesheet.layers;
            availableLayers.forEach((layer) => {
              if (layersForDisable.includes(layer.id)) {
                map.setLayoutProperty(layer.id, "visibility", "none");
              }
            });
          }
        }}
        onClick={() => {
          if (surveyInfoInTotalBlock) {
            setSurveyInfoInTotalBlock(null);
          }
          setClickIsActive(false);
        }}
      >
        {currentMapType === chartsTypes.ALL_FARMS && allFarms}
        {currentMapType === chartsTypes.ALL_FARMS &&
          popupInfoAllFarms &&
          popupAllFarms}

        {currentMapType === chartsTypes.RISK_SCORE && pins}
        {currentMapType === chartsTypes.RISK_SCORE &&
          popupInfoRiskScores &&
          popupRiskScores}

        {currentMapType !== chartsTypes.ALL_FARMS &&
          currentMapType !== chartsTypes.RISK_SCORE &&
          bubbles}

        <NavigationControl position="bottom-left" />

        <GeocoderControl
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
          placeholder=" "
          marker={false}
          position="top-right"
        />
      </MapGL>

      <div className="intelligence-menu d-flex flex-column">
        <AccordionCustomComponent
          list={CHARTS}
          toggleTitle={currentMapType}
          onAccordionItemClick={handleMapDataTypeBtnClick}
          className="intelligence-accordion"
          variant="secondary"
        />

        <AccordionCustomComponent
          list={CATEGORIES_FILTERS}
          toggleTitle={currentFilter}
          onAccordionItemClick={setCurrentFilter}
          className="intelligence-accordion filter mt-3"
          // TODO: style component if disabled. This accordion does not have disabled option
          disabled={filtersBtnDisabled}
          variant="light"
        />
      </div>

      <div className={`intelligence-totals ${toggle ? "show" : "hide"}`}>
        <div className="intelligence-totals--wrapper">
          <div className="intelligence-totals--inner d-flex flex-column justify-content-between">
            <div className="text-water line-height-1 mt-1 mb-2">
              {surveyInfoInTotalBlock?.properties?.surveyName ||
                currentUser.currentCompany.name}
            </div>

            {currentMapType === chartsTypes.ALL_FARMS && (
              <AmountsTotalBlock
                currentMapType={currentMapType}
                countriesData={farmCountByCountryData}
              />
            )}
            {currentMapType === chartsTypes.RISK_SCORE && (
              <RiskScoreTotalBlock
                stepsData={stepsData}
                currentSurveyInfo={surveyInfoInTotalBlock}
              />
            )}
            {currentMapType !== chartsTypes.RISK_SCORE &&
              currentMapType !== chartsTypes.ALL_FARMS && (
                <AmountsTotalBlock
                  currentMapType={currentMapType}
                  countriesData={countriesData}
                />
              )}
          </div>
        </div>

        <Button
          className="intelligence-totals--toggle"
          variant="link"
          size="sm"
          onClick={() => setToggle(!toggle)}
        >
          <Icon iconString={`${toggle ? "times" : "poll-h"}`} />
        </Button>
      </div>
    </div>
  );
};

export default IntelligenceContainer;
