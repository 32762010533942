import { AxiosError } from "axios";
import { ALERT_ACTION_KIND, ERROR_POPUP_ACTION_KIND } from "../constants/enums";
import {
  AlertReducerAction,
  ErrorPopupReducerAction,
  ErrorPopupState,
} from "../models";
import { errorHandler } from "../utils/errorHandler";

type ShowFetchErrorProps = {
  /* Error got from catch block */
  error: AxiosError | Error;
  /* Text message with be displayed in the alert */
  customMsg: string;
  /* Which object is fetching? Farm|Audit|User|Company... */
  object: string;
  /** Name of fetching object. Bali Barramundi | Lake Cajon
   * errorHandler manages such cases:
   * string - name of object.
   * "unknown" - name of object is unknown,
   * "plural" - we have plural form of object. Name does not exists.  */
  objectName: string | "unknown" | "plural";
  /* Which operation is performed on the object? Get | Save | Delete | Edit  */
  operation: string;
  /* dispatch of AlertsContext  */
  dispatchAlert: React.Dispatch<AlertReducerAction>;
  /* dispatch of ErrorPopupContext  */
  dispatchPopup: React.Dispatch<ErrorPopupReducerAction>;
  /* Fired when Retry button clicked on Popup  */
  onRetry: () => void;
};

const SHOW_TIME_MS = 5000;

export const showTransientAlert = (
  dispatch: React.Dispatch<AlertReducerAction>,
  alert: AlertReducerAction
): void => {
  dispatch(alert);

  setTimeout(() => {
    dispatch({ type: ALERT_ACTION_KIND.CLEAR_ALERTS });
  }, SHOW_TIME_MS);
};

export const showErrorAlert = (
  dispatch: React.Dispatch<AlertReducerAction>,
  message: string
): void => {
  showTransientAlert(dispatch, {
    type: ALERT_ACTION_KIND.SHOW_ERROR_ALERT,
    text: message,
  });
};

export const showErrorPopup = (
  dispatch: React.Dispatch<ErrorPopupReducerAction>,
  popup: ErrorPopupState
): void => {
  dispatch({
    type: ERROR_POPUP_ACTION_KIND.SHOW_ERROR_POPUP,
    popup: popup,
  });
};

export const showFetchError = ({
  error,
  customMsg,
  object,
  objectName,
  operation,
  dispatchAlert,
  dispatchPopup,
  onRetry,
}: ShowFetchErrorProps): void => {
  const {
    type,
    message,
    showRetryBtn = false,
  } = errorHandler(error, customMsg, {
    object,
    objectName,
    operation,
  });

  if (type === "alert") {
    showTransientAlert(dispatchAlert, {
      type: ALERT_ACTION_KIND.SHOW_ERROR_ALERT,
      text: message,
    });
  } else {
    showErrorPopup(dispatchPopup, {
      onRetry,
      message,
      showRetryBtn,
    });
  }
};
