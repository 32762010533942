import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import HeaderUser from "./common/HeaderUser";
import ForgotPasswordContainer from "./auth/ForgotPasswordContainer";
import ResetPasswordContainer from "./auth/ResetPasswordContainer";
import LandingContainer from "./landing/LandingContainer";
import LoginContainer from "./auth/LoginContainer";
import SurveysContainer from "./surveys/SurveysContainer";
import SelectedSurveyContainer from "./surveys/SelectedSurveyContainer";
import AccountContainer from "./AccountContainer";
import ActionsContainer from "./actions/ActionsContainer";
import IntelligenceContainer from "./intelligence/IntelligenceContainer";
import TemplatesContainer from "./templates/TemplatesContainer";
import CompanyContainer from "./CompanyContainer";
import FarmsContainer from "./farms/FarmsContainer";
import UserContainer from "./users/UserContainer";
import UsersContainer from "./users/UsersContainer";
import ActivityLogContainer from "./ActivityLogContainer";
import SurveyPdf from "./report/SurveyPdf";
import ActionsPdf from "./report/ActionsPdf";
import FarmContainer from "./farms/FarmContainer";
import FarmUsersContainer from "./farms/FarmUsersContainer";
import SurveyLaunchFormContainer from "./surveys/SurveyLaunchFormContainer";

const Main = () => {
  const { isAuthenticated } = useContext(AuthContext);

  if (isAuthenticated) {
    return (
      <BrowserRouter>
        <HeaderUser />
        <div className="page-container">
          <Switch>
            <Route exact path="/" component={SurveysContainer} />
            <Route path="/account" component={AccountContainer} />
            <Route
              exact
              path="/activity-log"
              component={ActivityLogContainer}
            />
            <Route path="/forgotpassword" component={ForgotPasswordContainer} />
            <Route path="/reset/:guid" component={ResetPasswordContainer} />
            <Route exact path="/audits" component={SurveysContainer} />
            <Route
              exact
              path="/audits/new"
              component={SurveyLaunchFormContainer}
            />
            <Route
              exact
              path="/audit/:surveyType/:surveyExtId"
              component={SelectedSurveyContainer}
            />
            {/* <Route exact path="/audits/scores" component={SurveysContainer} /> */}
            <Route path="/actions" component={ActionsContainer} />
            <Route
              exact
              path="/intelligence"
              component={IntelligenceContainer}
            />
            <Route path="/templates" component={TemplatesContainer} />
            <Route
              exact
              path="/companies/:companyId/farms/:farmId/users"
              component={FarmUsersContainer}
            />
            <Route
              exact
              path="/companies/:companyId/farms/:farmId"
              component={FarmContainer}
            />
            <Route
              exact
              path="/companies/:companyId/farms"
              component={FarmsContainer}
            />
            <Route
              path="/companies/:companyId/users/:userId"
              component={UserContainer}
            />
            <Route
              path="/companies/:companyId/users"
              component={UsersContainer}
            />
            <Route path="/companies/:companyId" component={CompanyContainer} />
            <Route path="/report/:surveyId" component={SurveyPdf} />
            <Route
              path="/summary/:surveyId"
              render={(routeProps) => (
                <SurveyPdf {...routeProps} summary={true} />
              )}
            />
            <Route path="/actions-report/:surveyExtId" component={ActionsPdf} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  } else {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/forgotpassword" component={ForgotPasswordContainer} />
          <Route path="/reset/:guid" component={ResetPasswordContainer} />
          <Route path="/login" component={LoginContainer} />
          <Route component={LandingContainer} />
        </Switch>
      </BrowserRouter>
    );
  }
};

export default Main;
