import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

const ShortTextForm = ({ onSave, answer }) => {
  const [sandbox, setSandbox] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setSandbox(answer.answerStr);
  }, [answer]);

  const handleChange = (e) => {
    setSandbox(e.target.value);
  };

  const isValid = () => {
    setError("");

    if (sandbox && sandbox.length > 0 && sandbox.length <= 255) return true;
    setError("Answer must be between 1 and 255 characters");
    return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isValid()) return;
    onSave(sandbox);
  };

  return (
    <Form
      id="question-form"
      className="survey-answer-form"
      onSubmit={handleSubmit}
    >
      <Form.Group controlId="formGroupAnswer" className="form-group px-0">
        <Form.Control
          type="text"
          placeholder="Max 255 characters"
          value={sandbox}
          onChange={handleChange}
        />
      </Form.Group>

      {error.length ? <div className="error">{error}</div> : null}
    </Form>
  );
};

export default ShortTextForm;
