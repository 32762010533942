import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";

import { riskLevels } from "../../constants/landing";

type RiskLevelsProps = {
  /* Is this component is in the viewport? Helps to add 'in-view' class name. */
  isInViewport: boolean;
};

const RiskLevels: React.FC<RiskLevelsProps> = ({ isInViewport }) => {
  const riskLevelsLength = riskLevels.length;

  const [activeLevel, setActiveLevel] = useState<string>(
    riskLevels[riskLevelsLength - 1].level
  );

  return (
    <Row className="layout-container risk-levels flex-column">
      <Col className="flex-grow-0 position-relative">
        <Row className="layout-header flex-column">
          <Col className="flex-grow-0">
            <Row>
              <h2
                className={`layout-header-title layout-wrapper ${
                  isInViewport ? "in-view" : ""
                }`}
              >
                RISK SCORES
              </h2>
            </Row>
          </Col>

          <Col>
            <Row className="layout-header-content layout-wrapper justify-content-center align-items-center">
              <Col xs={12} xl={8} className="py-4 layout-inner">
                <div>
                  AquaRisk ranks risk exposures using state-of-the-art
                  algorithms that quantify the risk according to a risk
                  colouring system.
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Col>
        <Row className="layout-content layout-wrapper justify-content-center align-items-center py-4 flex-column">
          <Col xs={10} className="risks layout-outer">
            {riskLevels.map((riskLevel, index) => {
              const animationClassName = isInViewport
                ? "risk-item-animation"
                : "";
              const styleBars = { animationDelay: 0.2 * index + 0.5 + "s" };
              const styleNames = {
                animationDelay:
                  0.2 * riskLevelsLength + 0.5 + 0.2 * index + "s",
              };
              return (
                <Row
                  className={`risk ${
                    index !== riskLevelsLength - 1 ? "mb-4" : ""
                  } align-items-center ${animationClassName}`}
                  key={riskLevel.level}
                  active={`${activeLevel === riskLevel.level}`}
                  onClick={() => {
                    setActiveLevel(riskLevel.level);
                  }}
                >
                  <Col xs={12} lg={6}>
                    <div
                      className={`ms-auto risk-bar ${riskLevel.barClass}`}
                      style={styleBars}
                    >
                      <span className="risk-level d-lg-none text-end pe-3">
                        {riskLevel.level}
                      </span>
                    </div>
                  </Col>
                  <Col
                    xs={12}
                    lg={6}
                    style={styleNames}
                    className={`risk-text text-end text-lg-left`}
                  >
                    <Row className="risk-line-container d-flex">
                      <Col className="d-none d-lg-block">
                        <div className="align-items-center d-flex">
                          <div className="risk-level pe-3">
                            {riskLevel.level}
                          </div>
                          <div className="risk-line flex-grow-1 ms-3"></div>
                        </div>
                      </Col>
                      <Col>
                        <div className="risk-level-explanation text-end mt-3 mt-lg-0">
                          {riskLevel.explanation}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default RiskLevels;
