import axios, { AxiosResponse } from "axios";
import { API, getUserHeaders } from "../constants/requestConstants";
import * as Model from "../models/be_models";

export const getActiveSurveysForFarm = (
  companyExtId: string,
  farmExtId: string
): Promise<AxiosResponse<Model.ActiveSurveysResponse>> => {
  const url = `${API}/company/${companyExtId}/farm/${farmExtId}/survey`;

  return axios.get<Model.ActiveSurveysResponse>(url, {
    headers: getUserHeaders(),
  });
};

// GET /api/v1/company/{companyExtId}/survey
// getActiveSurveysForCompany is not used

export const getSurveys = (
  params: Model.GetSurveysParams
): Promise<AxiosResponse<Model.ActiveSurveysResponse>> => {
  const url = `${API}/survey`;

  return axios.get<Model.ActiveSurveysResponse>(url, {
    headers: getUserHeaders(),
    params,
  });
};

export const getSurvey = (
  // getActiveSurveyForUser
  surveyExtId: string
): Promise<AxiosResponse<Model.SurveyInfoResponse>> => {
  const url = `${API}/survey/${surveyExtId}`;

  return axios.get<Model.SurveyInfoResponse>(url, {
    headers: getUserHeaders(),
  });
};

// getCurrentActionItems  is not used

export const postCreateAction = (
  //setCurrentActionItem
  surveyExtId: string,
  body: Model.CreateActionItemRequest
): Promise<AxiosResponse> => {
  const url = `${API}/survey/${surveyExtId}/action`;

  return axios.post(url, body, {
    headers: getUserHeaders(),
  });
};

// GET /api/v1/survey/{surveyExtId}/back
// surveyMoveToBookmarkPrevious is not used

// POST /api/v1/survey/{surveyExtId}/back
// surveyAnswerAndPrevious is not used

// GET /api/v1/survey/{surveyExtId}/close
// surveyClose is not used

export const getCurrentQuestion = (
  //surveyMoveToBookmark
  surveyExtId: string
): Promise<AxiosResponse<Model.SurveyQuestionResponse>> => {
  const url = `${API}/survey/${surveyExtId}/current`;

  return axios.get<Model.SurveyQuestionResponse>(url, {
    headers: getUserHeaders(),
  });
};

export const getSurveyFarm = (
  //farmForSurvey
  surveyExtId: string,
  showMediaCats?: boolean
): Promise<AxiosResponse<Model.FarmResponse>> => {
  const url = `${API}/survey/${surveyExtId}/farm`;

  return axios.get<Model.FarmResponse>(url, {
    headers: getUserHeaders(),
    params: { showMediaCats },
  });
};

// surveyMoveToBookmarkNext is not used

export const postSaveAnswer = (
  // surveyAnswerAndNext
  surveyExtId: string,
  body: Model.SurveyQuestionAnswerRequest
): Promise<AxiosResponse<Model.SurveyQuestionResponse>> => {
  const url = `${API}/survey/${surveyExtId}/next`;

  return axios.post<Model.SurveyQuestionResponse>(url, body, {
    headers: getUserHeaders(),
  });
};

export const getSurveyScore = (
  surveyExtId: string,
  risk?: string
): Promise<AxiosResponse<Model.ScoreSurveyResultResponse>> => {
  const url = `${API}/survey/${surveyExtId}/score`;

  return axios.get<Model.ScoreSurveyResultResponse>(url, {
    headers: getUserHeaders(),
    params: { risk },
  });
};

export const postScoreSurvey = (
  //performSurveyScoring
  surveyExtId: string
): Promise<AxiosResponse<Model.SurveyScoreResponse>> => {
  const url = `${API}/survey/${surveyExtId}/score`;

  return axios.post<Model.SurveyScoreResponse>(url, new FormData(), {
    headers: getUserHeaders(),
  });
};

// GET /api/v1/survey/{surveyExtId}/score/section/{sectionId}
// getSurveySectionScore is not used

export const getSurveySectionStepScore = (
  surveyExtId: string,
  sectionId: string,
  stepId: string,
  details = true,
  risk?: string
): Promise<AxiosResponse<Model.ScoreSurveyResultResponse>> => {
  const url = `${API}/survey/${surveyExtId}/score/section/${sectionId}/step/${stepId}`;

  return axios.get<Model.ScoreSurveyResultResponse>(url, {
    headers: getUserHeaders(),
    params: { details, risk },
  });
};

// GET /api/v1/survey/{surveyExtId}/score/section/{sectionId}/step/{stepId}/question/{questionId}
// getSurveySectionStepQuestionScore is not used

export const getSurveySections = (
  //surveySections
  surveyExtId: string
): Promise<AxiosResponse<Model.SurveyTocSectionResponse[]>> => {
  const url = `${API}/survey/${surveyExtId}/sections`;

  return axios.get<Model.SurveyTocSectionResponse[]>(url, {
    headers: getUserHeaders(),
  });
};

// GET /api/v1/survey/{surveyExtId}/unanswered
// surveyMoveToFirstUnansweredQuestion is not used

export const getUsersForSurvey = (
  surveyExtId: string
): Promise<AxiosResponse<Model.UserInfoResponse[]>> => {
  const url = `${API}/survey/${surveyExtId}/user`;

  return axios.get<Model.UserInfoResponse[]>(url, {
    headers: getUserHeaders(),
  });
};

export const postCreateSurveyUser = (
  //createSurveyUser
  surveyExtId: string,
  body: Model.CreateSurveyUser
): Promise<AxiosResponse<Model.UserInfoAndRoles>> => {
  const url = `${API}/survey/${surveyExtId}/user`;

  return axios.post<Model.UserInfoAndRoles>(url, body, {
    headers: getUserHeaders(),
  });
};

// POST /api/v1/survey/{surveyExtId}/user/{userExtId}
// addUserToSurvey is not used

export const deleteUserFromSurvey = (
  //removeUserFromSurvey
  surveyExtId: string,
  userExtId: string
): Promise<AxiosResponse> => {
  const url = `${API}/survey/${surveyExtId}/user/${userExtId}`;

  return axios.delete(url, {
    headers: getUserHeaders(),
  });
};

// POST /api/v1/survey/{surveyExtId}/users
// addUsersToSurvey is not used

// GET /api/v1/survey/score
// getAllSurveyScore is not used

export const getSurveyCompletedYears = (): Promise<
  AxiosResponse<Model.UserSurveyYearsResponse>
> => {
  const url = `${API}/survey/completed/year`;

  return axios.get<Model.UserSurveyYearsResponse>(url, {
    headers: getUserHeaders(),
  });
};

export const getSurveyCreatedYears = (): Promise<
  AxiosResponse<Model.UserSurveyYearsResponse>
> => {
  const url = `${API}/survey/created/year`;

  return axios.get<Model.UserSurveyYearsResponse>(url, {
    headers: getUserHeaders(),
  });
};

// GET /api/v1/survey/year
// getSurveyYears is not used
