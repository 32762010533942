import axios, { AxiosResponse, GenericAbortSignal } from "axios";
import { API, getAuthHeaders } from "../constants/requestConstants";
import * as Model from "../models/be_models";

export const postUploadEnvironmentFile = (
  //uploadSamples
  body: FormData,
  signal?: GenericAbortSignal
): Promise<AxiosResponse<Model.EnvironmentalDataInfo>> => {
  const url = `${API}/data`;

  return axios.post<Model.EnvironmentalDataInfo>(url, body, {
    headers: getAuthHeaders(),
    signal,
  });
};
