import React from "react";

const shadowStyle = (showShadow, isCircle) => {
  if (!showShadow) return {};
  if (isCircle) return {};
  return {
    WebkitBoxShadow:
      "0 8px 17px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)",
    boxShadow:
      "0px 8px 17px 0px rgba(0,0,0,0.2),0px 6px 20px 0px rgba(0,0,0,0.19)",
  };
};

const PageElement = ({ onClick, href, page, style, className, children }) => {
  return onClick ? (
    <button
      className={["btn", className].join(" ")}
      onClick={() => onClick(page)}
      style={style}
    >
      {children}
    </button>
  ) : (
    <a href={href} className={className} style={style}>
      {children}
    </a>
  );
};

const PageItem = ({
  text,
  page,
  className,
  onClick,
  href,
  activeBgColor,
  activeBorderColor,
  disabledBgColor,
  disabledBorderColor,
  bgColor,
  borderColor,
  activeColor,
  disabledColor,
  color,
  circle,
  shadow,
  size,
}) => {
  const style = {
    ...getStyles(
      {
        activeBgColor,
        activeBorderColor,
        disabledBgColor,
        disabledBorderColor,
        bgColor,
        borderColor,
        activeColor,
        color,
        disabledColor,
      },
      className
    ),
    ...circleStyle(circle, size),
    ...shadowStyle(shadow, circle),
  };

  return (
    <li
      className={`page-item ${
        className !== undefined && className !== false ? className : ""
      }`}
    >
      <PageElement
        style={style}
        className={"page-link"}
        onClick={onClick}
        href={href}
        page={page}
      >
        {text}
      </PageElement>
    </li>
  );
};

const circleStyle = (isCircle, size) => {
  if (!isCircle) return {};
  if (size === "lg" || size === "sm") {
    if (size === "lg") {
      return {
        borderRadius: "30px",
        marginLeft: "6px",
        marginRight: "6px",
        width: "57px",
        height: "57px",
        padding: ".75rem 17px",
      };
    }
    if (size === "sm") {
      return {
        borderRadius: "30px",
        marginLeft: "4px",
        marginRight: "4px",
        width: "36px",
        height: "36px",
        padding: "7px",
      };
    }
  } else {
    return {
      borderRadius: "30px",
      marginLeft: "6px",
      marginRight: "6px",
      width: "45px",
      height: "45px",
      padding: "11px",
    };
  }
};

const getStyles = (styles, className) => {
  switch (className) {
    case undefined || false:
      return {
        color: styles.color && styles.color,
        backgroundColor: styles.bgColor,
        borderColor: styles.borderColor,
      };
    case "disabled":
      return {
        color: styles.disabledColor && styles.disabledColor,
        backgroundColor: styles.disabledBgColor
          ? styles.disabledBgColor
          : styles.bgColor,
        borderColor: styles.disabledBorderColor
          ? styles.disabledBorderColor
          : styles.borderColor,
      };
    case "active":
      return {
        color: styles.activeColor && styles.activeColor,
        backgroundColor: styles.activeBgColor,
        borderColor: styles.activeBorderColor,
      };
    default:
      break;
  }
};

const getPagination = (props) => {
  let arr = [];
  let startAt = props.currentPage - Math.floor(props.showMax / 2);
  let isPositive = () => Math.sign(startAt);
  if (isPositive() === -1 || isPositive() === 0 || Object.is(isPositive(), -0))
    startAt = 1;
  let max = props.showMax > props.totalPages ? props.totalPages : props.showMax;

  for (let i = 0; i < max; i++) {
    if (startAt + i > props.totalPages) continue;
    arr.push({
      page: startAt + i,
      text: startAt + i,
      isCurrent: isCurrent(startAt + i, props.currentPage),
      class: isCurrent(startAt + i, props.currentPage)
        ? props.activeClass
        : props.defaultClass,
      href:
        startAt + i === 1
          ? props.href && props.pageOneHref
          : props.href && props.href.replace("*", startAt + i),
    });
  }

  if (props.threeDots) {
    arr = addThreeDots(arr, props);
  }

  if (props.prevNext) {
    arr = addNext(arr, props);
    arr = addPrev(arr, props);
  }

  if (props.firstLast) {
    arr = addFirst(arr, props);
    arr = addLast(arr, props);
  }

  return arr;
};

const isCurrent = (page, currentPage) => currentPage === page;

const addThreeDots = (arr, props) => {
  let threeDotsObj = {
    page: false,
    text: "...",
    isCurrent: false,
    class: props.disabledClass,
  };
  arr[0].page !== 1 &&
    arr.unshift(threeDotsObj) &&
    arr.unshift({
      page: 1,
      text: 1,
      isCurrent: false,
      class: props.defaultClass,
      href: props.pageOneHref
        ? props.pageOneHref
        : props.href && props.href.replace("*", 1),
    });
  arr[arr.length - 1].page !== props.totalPages &&
    arr.push(threeDotsObj) &&
    arr.push({
      page: props.totalPages,
      text: props.totalPages,
      isCurrent: false,
      class: props.defaultClass,
      href: props.href && props.href.replace("*", props.totalPages),
    });
  return arr;
};

const addNext = (arr, props) => {
  let nextObj = {
    page: props.currentPage + 1,
    text: props.nextText,
    isCurrent: false,
    class: props.currentPage + 1 > props.totalPages && props.disabledClass,
    href: props.href && props.href.replace("*", props.currentPage + 1),
  };
  arr.push(nextObj);
  return arr;
};

const addPrev = (arr, props) => {
  let prevObj = {
    page: props.currentPage - 1,
    text: props.prevText,
    isCurrent: false,
    class: props.currentPage - 1 < 1 && props.disabledClass,
    href: props.href && props.href.replace("*", props.currentPage - 1),
  };
  arr.unshift(prevObj);
  return arr;
};

const addLast = (arr, props) => {
  let nextObj = {
    page: props.totalPages,
    text: props.lastText,
    isCurrent: false,
    class: props.currentPage + 1 > props.totalPages && props.disabledClass,
    href: props.href && props.href.replace("*", props.totalPages),
  };
  arr.push(nextObj);
  return arr;
};

const addFirst = (arr, props) => {
  let firstObj = {
    page: 1,
    text: props.firstText,
    isCurrent: false,
    class: props.currentPage - 1 < 1 && props.disabledClass,
    href: props.href && props.href.replace("*", 1),
  };
  arr.unshift(firstObj);
  return arr;
};

export default class Pagination extends React.Component {
  render() {
    const {
      onClick,
      size,
      ariaLabel,
      activeBgColor,
      activeBorderColor,
      disabledBgColor,
      disabledBorderColor,
      bgColor,
      borderColor,
      activeColor,
      color,
      disabledColor,
      circle,
      shadow,
      center,
      className,
    } = this.props;
    const pagination = getPagination(this.props);
    return (
      <nav
        aria-label={ariaLabel}
        className={`row ${center && "justify-content-center"} ${
          className && className
        }`}
      >
        <ul
          style={shadowStyle(shadow, circle)}
          className={`pagination ${
            (size === "sm" || size === "lg") && "pagination-" + size
          }`}
        >
          {pagination.map((page, i) => (
            <PageItem
              key={`${page}-${i}`}
              text={page.text}
              page={page.page}
              className={page.class}
              href={page.href}
              onClick={onClick}
              activeBgColor={activeBgColor}
              activeBorderColor={activeBorderColor}
              disabledBgColor={disabledBgColor}
              disabledBorderColor={disabledBorderColor}
              bgColor={bgColor}
              borderColor={borderColor}
              activeColor={activeColor}
              color={color}
              disabledColor={disabledColor}
              circle={circle}
              shadow={shadow}
              size={size}
            />
          ))}
        </ul>
      </nav>
    );
  }
}

// Pagination.propTypes = {
//   totalPages: React.PropTypes.number.isRequired,
//   currentPage: React.PropTypes.number.isRequired,
//   ariaLabel: React.PropTypes.string,
//   size: React.PropTypes.string,
//   showMax: React.PropTypes.number,
//   activeClass: React.PropTypes.string,
//   defaultClass: React.PropTypes.string,
//   disabledClass: React.PropTypes.string,
//   threeDots: React.PropTypes.bool,
//   href: React.PropTypes.string,
//   pageOneHref: React.PropTypes.string,
//   prevNext: React.PropTypes.bool,
//   prevText: React.PropTypes.string,
//   nextText: React.PropTypes.string,
//   firstLast: React.PropTypes.bool,
//   firstText: React.PropTypes.string,
//   lastText: React.PropTypes.string,
//   center: React.PropTypes.bool,
//   onClick: React.PropTypes.func,
//   activeBgColor: React.PropTypes.string,
//   activeBorderColor: React.PropTypes.string,
//   disabledBgColor: React.PropTypes.string,
//   disabledBorderColor: React.PropTypes.string,
//   bgColor: React.PropTypes.string,
//   borderColor: React.PropTypes.string,
//   activeColor: React.PropTypes.string,
//   disabledColor: React.PropTypes.string,
//   color: React.PropTypes.string,
//   circle: React.PropTypes.bool,
//   shadow: React.PropTypes.bool,
//   className: React.PropTypes.string
// };

Pagination.defaultProps = {
  currentPage: 1,
  ariaLabel: "Page navigator",
  activeClass: "active",
  disabledClass: "disabled",
  showMax: 5,
  center: false,
  size: "md", // sm md lg
  prevNext: true,
  prevText: "⟨",
  nextText: "⟩",
  firstLast: false,
  firstText: "⟨⟨",
  lastText: "⟩⟩",
  circle: false,
  shadow: false,
  // center: true
};
