import React from "react";
import { useHistory } from "react-router-dom";
import { Navbar, Button, Col } from "react-bootstrap";

import { AQUARISK_LOGO } from "../../constants/imagesConstants";

type HeaderGuestProps = {
  /* Specifies whether to show the 'Sign in' button */
  showSignInButton: boolean;
};

const HeaderGuest: React.FC<HeaderGuestProps> = ({ showSignInButton }) => {
  const history = useHistory();

  const goHome = () => {
    history.push("/");
  };

  const goLogin = () => {
    history.push("/login");
  };

  return (
    <header className="guest-header">
      <Navbar className="layout-wrapper row py-2 justify-content-between">
        <Col xs={6} lg={3} className="text-start ps-0">
          <Navbar.Brand href="#">
            <img src={AQUARISK_LOGO} alt="AquaRisk Logo" onClick={goHome} />
          </Navbar.Brand>
        </Col>
        {!showSignInButton ? null : (
          <Col
            xs={6}
            lg={3}
            className="sign-in text-end pe-0 align-self-center"
          >
            <Button
              variant="primary"
              size="sm"
              className="btn-link px-3"
              onClick={goLogin}
              data-cy="sign-in"
            >
              Sign in
            </Button>
          </Col>
        )}
      </Navbar>
    </header>
  );
};

export default HeaderGuest;
