import React, { useContext, useState } from "react";
import { ButtonToolbar, Button, Form, Row, Col } from "react-bootstrap";
import RichTextEditor, { EditorValue } from "react-rte";
import { AxiosError } from "axios";

import { AlertsContext } from "../../contexts/AlertsContext";
import { ErrorPopupContext } from "../../contexts/ErrorPopupContext";
import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import { postCloseActionItem } from "../../network/request";
import { ALERT_ACTION_KIND } from "../../constants/enums";
import { REACH_TEXT_EDITOR_TOOLBAR_CONFIG } from "../../constants/constants";
import { showTransientAlert, showFetchError } from "../../utils/alerts";
import { isAdmin, isCompany, isFarmManager } from "../../utils/userUtils";
import { formatDate } from "../../utils/date";
import { getRiskLevelClassName, getRiskLevelName } from "../../utils/string";
import {
  ActionItemWithDetailResponse,
  CloseActionItemRequest,
} from "../../models/be_models";

import Loader from "../common/Loader";
import Icon from "../common/Icon";
import ProfileImage from "../general/ProfileImage";

type ActionProps = {
  action: ActionItemWithDetailResponse;
  resolvable: boolean;
  onResolve: () => void;
};

const Action: React.FC<ActionProps> = ({
  action,
  resolvable = false,
  onResolve,
}) => {
  const [showResolveForm, setShowResolveForm] = useState(false);
  const [saving, setSaving] = useState(false);
  const [form, setForm] = useState<CloseActionItemRequest>({
    description: "",
  });
  const [error, setError] = useState("");
  const [editorState, setEditorState] = useState<EditorValue>(
    RichTextEditor.createEmptyValue()
  );

  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);
  const { currentUser } = useContext(CurrentUserContext);

  const userCanAcceptAction = (action: ActionItemWithDetailResponse) => {
    if (["LOW", "MODERATE_LOW", "MODERATE"].includes(action.riskLevel)) {
      return (
        isAdmin(currentUser) ||
        isCompany(currentUser) ||
        isFarmManager(currentUser)
      );
    } else {
      return isAdmin(currentUser) || isCompany(currentUser);
    }
  };

  const tooltip = (action: ActionItemWithDetailResponse) => {
    if (userCanAcceptAction(action)) return "";

    if (["LOW", "MODERATE_LOW", "MODERATE"].includes(action.riskLevel)) {
      return "Only Managers, Company and Admin can accept this risk.";
    } else {
      return "Only Company and Admin can accept this risk.";
    }
  };

  const handleChange = (value: EditorValue) => {
    setEditorState(value);
    setForm({ ...form, description: value.toString("html") });
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    if (!isValid()) return;
    setSaving(true);

    try {
      await postCloseActionItem(action.externalId, form);
      setSaving(false);
      setShowResolveForm(false);
      showTransientAlert(dispatchAlert, {
        type: ALERT_ACTION_KIND.SHOW_SUCCESS_ALERT,
        text: "Action resolved!",
      });
      onResolve();
    } catch (error) {
      setSaving(false);
      showFetchError({
        error: error as Error | AxiosError,
        customMsg: "API error while resolving the action.",
        object: "action",
        objectName: action.title,
        operation: "resolved",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => handleSubmit(e),
      });
    }
  };

  const isValid = () => {
    setError("");

    if (
      !editorState.toString("html").length ||
      editorState.toString("html") === "<p><br></p>"
    ) {
      setError("Description is required");
      return false;
    }

    return true;
  };

  return (
    <div className="action box-shadow mb-4 p-3">
      <Row className="justify-content-between align-items-start py-3">
        <Col lg={8} className="order-0">
          <Row className="justify-content-between align-items-center flex-wrap">
            <Col xs={12} md={5} className="ps-3 d-flex flex-nowrap">
              <span className="card-name me-3">{action.title}</span>
              {action.closedAt && (
                <Icon
                  iconString="check"
                  className={`checkmark-${getRiskLevelClassName(
                    action.riskLevel
                  )} me-2`}
                />
              )}
            </Col>
            <Col className="action-created smaller font-italic px-3 d-flex flex-wrap">
              <span className="text-nowrap pe-2">Created by: </span>
              <span className="text-nowrap pe-2 ">{action.createdByName}</span>
              <span className="text-nowrap ">
                {formatDate(action.createdAt)}
              </span>
            </Col>
            <Col xs={12} className="mt-2 mb-3">
              <div
                className={`bar bar-md risk-${getRiskLevelClassName(
                  action.riskLevel
                )}`}
              ></div>
              <div className={`text-end`}>
                {getRiskLevelName(action.riskLevel)}
              </div>
            </Col>
          </Row>
        </Col>
        <Col lg={2} className="smaller mb-lg-3 order-2 order-lg-1">
          {action.closedAt ? null : resolvable ? (
            <ButtonToolbar className="form-group px-0 mb-0 justify-content-end">
              {!showResolveForm ? (
                <Button
                  variant="info"
                  size="sm"
                  onClick={() => setShowResolveForm(true)}
                  disabled={!userCanAcceptAction(action)}
                  title={tooltip(action)}
                >
                  Review
                </Button>
              ) : (
                <>
                  {saving ? (
                    <Loader status="Resolving action" inlineStatus />
                  ) : (
                    <Button
                      variant="secondary"
                      size="sm"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  )}
                  <Button
                    variant="info"
                    size="sm"
                    className="text-uppercase btn-140 mt-3"
                    onClick={() => setShowResolveForm(false)}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </ButtonToolbar>
          ) : null}
        </Col>
        <Col xs={12} className="smaller mb-3 order-1 order-lg-2">
          <div dangerouslySetInnerHTML={{ __html: action.description }}></div>
        </Col>
        {action.closedAt ? (
          <Col xs={12} className="smaller mb-3 order-1 order-lg-2">
            <Col className="my-2">
              <div className="align-items-center d-flex">
                <ProfileImage name={action.closedByName} />
                <p className="my-0 mx-3">{action.closedByName}</p>
                <p className="font-italic fw-light my-0 mx-auto mx-md-2">
                  {formatDate(action.closedAt)}
                </p>
              </div>
            </Col>
            <div
              className={`bg-white rounded orange-border ms-5 p-2 comment-risk-${getRiskLevelClassName(
                action.riskLevel
              )}`}
            >
              <p
                className="ma-2"
                dangerouslySetInnerHTML={{ __html: action.closedDescription }}
              ></p>
            </div>
          </Col>
        ) : null}

        <Col xs={12} className="order-3">
          {showResolveForm ? (
            <Form className="resolve-action-form" onSubmit={handleSubmit}>
              <Form.Group
                controlId="formGroupDescription"
                className="form-group px-0 mt-4"
              >
                <Col className="my-2">
                  <div className="align-items-center d-flex">
                    <ProfileImage name={currentUser.fullName} />
                    <p className="my-0 mx-3">{currentUser.fullName}</p>
                    <p className="font-italic fw-light my-0 mx-2">
                      {formatDate()}
                    </p>
                  </div>
                </Col>
                <RichTextEditor
                  className={`rounded orange-border ms-5 comment-risk-${getRiskLevelClassName(
                    action.riskLevel
                  )}`}
                  toolbarConfig={REACH_TEXT_EDITOR_TOOLBAR_CONFIG}
                  value={editorState}
                  onChange={(value) => handleChange(value)}
                />
                {error ? (
                  <div className="error ms-5 mt-2 mb-3">{error}</div>
                ) : null}
              </Form.Group>

              {saving ? (
                <Loader status="Resolving action" inlineStatus />
              ) : (
                <ButtonToolbar className="form-group px-0 mb-0">
                  <Button variant="secondary" size="sm" type="submit">
                    Save
                  </Button>
                </ButtonToolbar>
              )}
            </Form>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default Action;
