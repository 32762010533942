import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  DropdownButton,
  Dropdown,
  ButtonToolbar,
  Button,
  Container,
  Nav,
  Row,
  Col,
} from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { isEqual } from "lodash";
import { AxiosError } from "axios";

import Loader from "../common/Loader";
import Icon from "../common/Icon";
import FarmsWithSurveysList from "./FarmsWithSurveysList";
import SurveyDetail from "./SurveyDetail";

import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import { AlertsContext } from "../../contexts/AlertsContext";
import { ErrorPopupContext } from "../../contexts/ErrorPopupContext";
import {
  getSurveys,
  getMyCompanyFarmLocations,
  getCompanyFarmsAutoCorrect,
} from "../../network/request";
import { isAdmin } from "../../utils/userUtils";
import { showFetchError } from "../../utils/alerts";
import {
  ActiveSurveyFarmResponse,
  GetSurveysParams,
  NameSuggesterResponse,
  StateProvinceDto,
  SurveyInfoResponse,
} from "../../models/be_models";

type Country = {
  key: number | null;
  name: string | "All";
  provinces: StateProvinceDto[];
};

type Province = {
  key: number | null;
  name: string | "All";
};

const surveyStates = [
  { key: null, display: "All" },
  { key: "NOT_STARTED", display: "Not Started" },
  { key: "IN_PROGRESS", display: "In Progress" },
  { key: "SCORED", display: "Scored" },
];

const itemsPerPage = 25;
const startYear = 2018;
const endYear = new Date().getFullYear();
const years: { key: number | null; display: number | "All" }[] = [
  { key: null, display: "All" },
];
for (let y = startYear; y <= endYear; y++) {
  years.push({ key: y, display: y });
}

const initialFilter: GetSurveysParams = {
  filterCompanyExtId: "",
};

const initialSurvey = {
  closed: false,
  createdAt: new Date(),
  createdBy: "",
  externalId: "",
  isSurveyComplete: false,
  name: "",
  updatedAt: new Date(),
  updatedBy: "",
};

const SurveysContainer: React.FC = () => {
  const history = useHistory();

  const { currentUser } = useContext(CurrentUserContext);
  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);

  const [loading, setLoading] = useState(false);
  const [loadingDataForFilters, setLoadingDataForFilters] = useState(false);

  const [farms, setFarms] = useState<ActiveSurveyFarmResponse[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  // surveyWithCompany - we need one var for selectedSurvey and for companyId for passing them all in one props in SurveyDetail component
  const [surveyWithCompany, setSurveyWithCompany] = useState<{
    survey: SurveyInfoResponse;
    companyId: string;
  }>({
    survey: initialSurvey,
    companyId: "",
  });
  const [filters, setFilters] = useState<GetSurveysParams>({
    ...initialFilter,
  });

  // For farms typeahead search:
  const [searching, setSearching] = useState(false);
  const [searchedFarms, setSearchedFarms] = useState<NameSuggesterResponse[]>(
    []
  );
  const [searchedFarm, setSearchedFarm] = useState<NameSuggesterResponse[]>();

  const [countries, setCountries] = useState<Country[]>([
    { key: null, name: "All", provinces: [] },
  ]);
  const [provinces, setProvinces] = useState<Province[]>([
    { key: null, name: "All" },
  ]);

  useEffect(() => {
    setSurveyWithCompany({
      survey: initialSurvey,
      companyId: currentUser.currentCompany.externalId,
    });

    if (!isEqual(filters, initialFilter)) {
      setFilters({ ...initialFilter });
    }

    setCurrentPage(1);
  }, [currentUser.currentCompany.externalId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!currentUser.currentCompany.externalId?.length) return;
        setLoading(true);

        // Query params
        const params: GetSurveysParams = {
          ...filters,
          details: true,
          filterCompanyExtId: currentUser.currentCompany.externalId,
          page: currentPage,
          size: itemsPerPage,
        };

        // Include non null filter properties with query params
        Object.keys(params).forEach((key) => {
          const typedKey = key as keyof GetSurveysParams;
          return params[typedKey] == null && delete params[typedKey];
        });

        const { data: result } = await getSurveys(params);
        if (result.companies.length) {
          setFarms(result.companies[0].farms);

          const firstFarm = result.companies[0].farms[0];
          setSurveyWithCompany({
            survey: firstFarm.surveys[0],
            companyId: currentUser.currentCompany.externalId,
          });
        } else {
          setFarms([]);
          setSurveyWithCompany({
            survey: initialSurvey,
            companyId: currentUser.currentCompany.externalId,
          });
        }
      } catch (error) {
        setFarms([]);
        showFetchError({
          error: error as Error | AxiosError,
          customMsg: "API error while loading the audits.",
          object: "audits",
          objectName: "plural",
          operation: "loaded",
          dispatchAlert,
          dispatchPopup,
          onRetry: () => fetchData(),
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [filters, currentPage, currentUser.currentCompany.externalId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingDataForFilters(true);

        const { data: result } = await getMyCompanyFarmLocations(
          currentUser.currentCompany.externalId
        );

        const countriesResults: Country[] = [];
        result.locations.forEach((item, index) => {
          if (item.country.name) {
            countriesResults.push({
              key: index + 1,
              name: item.country.name,
              provinces: item.stateProvinces,
            });
          }
        });
        countriesResults.sort((a, b) => (a.name > b.name ? 1 : -1));

        setCountries([
          { key: null, name: "All", provinces: [] },
          ...countriesResults,
        ]);
      } catch (error) {
        showFetchError({
          error: error as Error | AxiosError,
          customMsg: "API error while loading the farms locations.",
          object: "farms locations",
          objectName: "plural",
          operation: "loaded",
          dispatchAlert,
          dispatchPopup,
          onRetry: () => fetchData(),
        });
      } finally {
        setLoadingDataForFilters(false);
      }
    };

    if (currentUser.currentCompany.externalId) fetchData();
  }, [currentUser.currentCompany.externalId]);

  const handleSurveyStateClick = (surveyKey: string | null) => {
    const filterState = surveyKey ? surveyKey : undefined;
    setFilters({ ...filters, filterState });
  };

  const handleYearClick = (yearKey: number | null) => {
    const startYear = yearKey ? parseInt(yearKey.toString()) : undefined;
    setFilters({ ...filters, filterStartYear: startYear });
  };

  const handleCountryClick = (country: Country) => {
    setFilters({
      ...filters,
      filterCountry: country.key ? country.name : undefined,
      filterStateProvince: undefined,
    });

    const provincesResults: Province[] = [];
    country.provinces.forEach((province, index) => {
      if (province.name) {
        provincesResults.push({ key: index + 1, name: province.name });
      }
    });
    provincesResults.sort((a, b) => (a.name > b.name ? 1 : -1));

    setProvinces([{ key: null, name: "All" }, ...provincesResults]);
  };

  const handleProvinceClick = (province: Province) => {
    setFilters({
      ...filters,
      filterStateProvince: province.key ? province.name : undefined,
    });
  };

  const handleViewSurveyClick = (
    e: React.MouseEvent,
    survey: SurveyInfoResponse
  ) => {
    e.preventDefault();
    e.stopPropagation();
    +history.push(`/audit/view-audit/${survey?.externalId}`);
  };

  const handleResultsClick = (
    e: React.MouseEvent,
    survey: SurveyInfoResponse
  ) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(`/audit/audit-result/${survey?.externalId}`);
  };

  const handleSurveyUsersClick = (
    e: React.MouseEvent,
    survey: SurveyInfoResponse
  ) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(`/audit/audit-users/${survey?.externalId}`);
  };

  const goToAddSurvey = () => {
    history.push("/audits/new");
  };

  const handleSearchSelection = async (e: NameSuggesterResponse[]) => {
    setLoading(true);

    if (!e.length) {
      setSearchedFarm([]);
      setFilters({ ...filters, filterFarmExtId: undefined });
    } else {
      setSearchedFarm([{ name: e[0].name, externalId: e[0].externalId }]);
      setFilters({ ...filters, filterFarmExtId: e[0].externalId });
    }
  };

  const handleOnFarmsSearch = async (query: string) => {
    try {
      setSearching(true);
      const params = { name: query };
      const { data: searchedFarmsResult } = await getCompanyFarmsAutoCorrect(
        currentUser.currentCompany.externalId,
        params
      );
      setSearchedFarms(searchedFarmsResult);
    } catch (error) {
      showFetchError({
        error: error as Error | AxiosError,
        customMsg: "API error while loading the farms.",
        object: "farms",
        objectName: "plural",
        operation: "loaded",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => handleOnFarmsSearch(query),
      });
    } finally {
      setSearching(false);
    }
  };

  return loading || loadingDataForFilters ? (
    <div className="loader-container">
      <Loader status="Loading surveys" />
    </div>
  ) : (
    <Container
      className="surveys-container container-with-survey-detail aqua-container"
      data-cy="surveys-container"
      fluid
    >
      <Row>
        <div className="col main">
          <div className="px-2">
            <Nav className="filter-toolbar surveys-list mb-2 w-100">
              <Col
                md={12}
                className="d-flex justify-content-between justify-content-md-center mb-3 flex-wrap filter-toolbar-chips"
              >
                {surveyStates.map((surveyState) => {
                  const isActive = surveyState.key === filters.filterState;
                  return (
                    <Nav.Item
                      key={surveyState.key}
                      className={`${isActive && "active"} mb-2 mx-md-2`}
                      onClick={() => handleSurveyStateClick(surveyState.key)}
                    >
                      {isActive && <Icon iconString="check" className="me-3" />}
                      {surveyState.display}
                    </Nav.Item>
                  );
                })}
              </Col>

              <Col xs={12} md={8} lg={9} className="flex-grow-1">
                <DropdownButton
                  title={filters.filterStartYear || "All Years"}
                  variant="link"
                  size="sm"
                  className="d-none"
                >
                  {years.map((year) => {
                    return (
                      <Dropdown.Item
                        key={year.key}
                        value={year.display}
                        onClick={() => handleYearClick(year.key)}
                      >
                        {year.display}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
              </Col>

              <Col
                xs={12}
                md={4}
                lg={3}
                className="d-flex justify-content-between flex-wrap"
              >
                <DropdownButton
                  title={filters.filterCountry || "Country"}
                  variant="link"
                  size="sm"
                >
                  {countries.map((country) => {
                    return (
                      <Dropdown.Item
                        key={country.name}
                        value={country.name}
                        onClick={() => handleCountryClick(country)}
                      >
                        {country.name}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>

                <DropdownButton
                  title={filters.filterStateProvince || "Region"}
                  variant="link"
                  size="sm"
                  disabled={!filters.filterCountry}
                >
                  {provinces.map((province) => {
                    return (
                      <Dropdown.Item
                        key={province.name}
                        value={province.name}
                        onClick={() => handleProvinceClick(province)}
                      >
                        {province.name}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
              </Col>
            </Nav>
          </div>

          <div className="search-container px-2">
            <Row className="justify-content-between">
              <Col
                xs={12}
                md={6}
                lg={4}
                xl={3}
                className="mt-2 mb-2 order-1 order-md-0"
              >
                <AsyncTypeahead
                  id="farm-typeahead"
                  clearButton
                  isLoading={searching}
                  labelKey="name"
                  options={searchedFarms}
                  placeholder="Search"
                  minLength={1}
                  onChange={handleSearchSelection}
                  selected={searchedFarm}
                  onSearch={handleOnFarmsSearch}
                />
              </Col>
              <Col xs={12} md={4} className="mt-2 mb-2 order-0 order-md-1">
                {isAdmin(currentUser) ? (
                  <Button
                    variant="link"
                    className="ms-md-auto d-flex align-items-center btn-white btn-add"
                    onClick={() => goToAddSurvey()}
                    data-cy="add-survey-btn"
                  >
                    <span className="small">
                      <Icon iconString="plus" className="me-1" />
                    </span>
                    New Audit
                  </Button>
                ) : null}
              </Col>
            </Row>
          </div>

          <div className="aqua-container-content mt-3">
            <FarmsWithSurveysList
              farms={farms}
              selectedSurvey={surveyWithCompany.survey}
              handleSurveyClick={(survey: SurveyInfoResponse) =>
                setSurveyWithCompany({ ...surveyWithCompany, survey })
              }
              handleViewSurveyClick={handleViewSurveyClick}
              handleResultsClick={handleResultsClick}
              handleSurveyUsersClick={handleSurveyUsersClick}
            />

            {farms.length &&
            !(currentPage === 1 && farms.length < itemsPerPage) ? (
              <ButtonToolbar className="pagination">
                <Button
                  variant="primary"
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <Icon iconString="arrow-left" className="me-1" />
                  Prev
                </Button>
                <Button
                  variant="primary"
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={farms.length < itemsPerPage}
                >
                  Next
                  <Icon iconString="arrow-right" className="ms-1" />
                </Button>
              </ButtonToolbar>
            ) : null}
          </div>
        </div>
        <SurveyDetail surveyWithCompany={surveyWithCompany} />
      </Row>
    </Container>
  );
};

export default SurveysContainer;
