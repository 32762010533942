import { MapPosition, Marker } from "../models";
import {
  FarmResponse,
  SurveyWithActionItemResponse,
  UserInfo,
  UserInfoAndRoles,
} from "../models/be_models";

export const INITIAL_FARM: FarmResponse = {
  animalCount: 0,
  availableMediaCategories: [],
  country: "",
  description: "",
  duration: 0,
  farmArea: 0,
  growingArea: 0,
  harvestWeight: 0,
  latitude: 0,
  longitude: 0,
  name: "",
  siteId: "",
  siteType: "",
  species: "",
  startYear: 0,
  stateProvince: "",
  stockingDensity: 0,
  stockingWeight: 0,
  structureCount: 0,
  // surveyYear: 0, // is not used. instead use surveyDate
  surveyDate: new Date().toISOString(),
  tonnage: 0,
  currencyCode: "",
  actualValue: 0,
  avgValue: 0,
  maxValue: 0,
  externalId: "",
  structures: [],
};

export const INITIAL_SELECTED_SURVEY: SurveyWithActionItemResponse = {
  externalId: "",
  farmExternalId: "",
  farmName: "",
  itemCount: 0,
  latitude: 0,
  longitude: 0,
  surveyCompletedAt: "",
  surveyExtId: "",
  surveyName: "",
  surveyYear: 0,
};

export const INITIAL_USER: UserInfo = {
  externalId: "",
  email: "",
  fullName: "",
  phone: "",
  username: "",
};

export const INITIAL_USER_AND_ROLES: UserInfoAndRoles = {
  roles: [],
  user: INITIAL_USER,
};

export const INITIAL_MARKER: Marker = {
  id: "",
  lat: 0,
  lng: 0,
};

export const INITIAL_MAP_POSITION: MapPosition = {
  lat: 35.9574,
  lng: -75.6241,
  zoom: 1,
  pitch: 50,
};

export const INITIAL_MAP_ZOOM = {
  min: 2,
  max: 16,
};
