import React, { useContext, useState } from "react";
import {
  Row,
  Col,
  ButtonGroup,
  Button,
  OverlayTrigger,
  Tooltip,
  Dropdown,
} from "react-bootstrap";
import { AxiosError } from "axios";

import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import { ErrorPopupContext } from "../../contexts/ErrorPopupContext";
import { AlertsContext } from "../../contexts/AlertsContext";
import { getSurveyScore, postScoreSurvey } from "../../network/request";
import { ALERT_ACTION_KIND } from "../../constants/enums";
import { showTransientAlert, showFetchError } from "../../utils/alerts";
import { getRiskLevelClassName } from "../../utils/string";
import {
  isAdmin,
  isSurveyor,
  isCompany,
  isFarmManager,
} from "../../utils/userUtils";
import { SurveyInfoResponse } from "../../models/be_models";

import Icon from "../common/Icon";
import Loader from "../common/Loader";
import CustomDropdown from "../../uiComponents/CustomDropdown";

type SurveysListProps = {
  farmSurveys: SurveyInfoResponse[];
  selectedSurvey: SurveyInfoResponse;
  handleSurveyClick: (survey: SurveyInfoResponse) => void;
  handleViewSurveyClick: (
    e: React.MouseEvent,
    survey: SurveyInfoResponse
  ) => void;
  handleResultsClick: (e: React.MouseEvent, survey: SurveyInfoResponse) => void;
  handleSurveyUsersClick: (
    e: React.MouseEvent,
    survey: SurveyInfoResponse
  ) => void;
};

const SurveysList: React.FC<SurveysListProps> = ({
  farmSurveys,
  selectedSurvey,
  handleSurveyClick,
  handleViewSurveyClick,
  handleResultsClick,
  handleSurveyUsersClick,
}) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);

  const [surveys, setSurveys] = useState(farmSurveys);
  const [scoring, setScoring] = useState(false);
  const [hoveredSurvey, setHoveredSurvey] = useState("");

  const placeholders = [
    "Fish Health",
    "Environment",
    "Engineering",
    "Governance",
    "Husbandry",
  ];

  const isScored = (survey: SurveyInfoResponse) => {
    return (
      Object.prototype.hasOwnProperty.call(survey, "scoring") &&
      Object.prototype.hasOwnProperty.call(survey.scoring, "sections")
    );
  };

  const fetchSurveyScore = async (survey: SurveyInfoResponse) => {
    try {
      setScoring(true);
      const { data: newScores } = await getSurveyScore(survey.externalId);
      const newSurveys = surveys.map((s) => {
        if (s.externalId === survey.externalId) {
          s.scoring = { ...newScores, sections: newScores.children };
        }
        return s;
      });
      setSurveys(newSurveys);
    } catch (error) {
      showFetchError({
        error: error as Error | AxiosError,
        customMsg: "API error while loading the scored audit.",
        object: "audit",
        objectName: survey.name,
        operation: "loaded",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => fetchSurveyScore(survey),
      });
    } finally {
      setScoring(false);
    }
  };

  const handleScoreSurveyClick = async (
    e: React.MouseEvent,
    survey: SurveyInfoResponse
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setScoring(true);

    try {
      await postScoreSurvey(survey.externalId);
      // Refetch scores for this surveys
      await fetchSurveyScore(survey);
      showTransientAlert(dispatchAlert, {
        type: ALERT_ACTION_KIND.SHOW_SUCCESS_ALERT,
        text: "Audit scored!",
      });
    } catch (error) {
      showFetchError({
        error: error as Error | AxiosError,
        customMsg: "API error while scoring the audit.",
        object: "audit",
        objectName: survey.name,
        operation: "scored",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => handleScoreSurveyClick(e, survey),
      });
    } finally {
      setScoring(false);
    }
  };

  const generateReport = (survey: SurveyInfoResponse) => {
    window.open(`/report/${survey.externalId}`, "_blank");
  };

  const generateSummary = (survey: SurveyInfoResponse) => {
    window.open(`/summary/${survey.externalId}`, "_blank");
  };

  return (
    <Row>
      {surveys.map((survey) => {
        return (
          <Col key={survey.externalId} md={12} className="mb-3">
            <div
              className={
                survey.externalId === selectedSurvey.externalId
                  ? "selected farm-survey"
                  : "farm-survey"
              }
              onMouseEnter={() => setHoveredSurvey(survey.externalId)}
              onMouseLeave={() => setHoveredSurvey("")}
              onClick={() => handleSurveyClick(survey)}
            >
              <Row
                className="scores pseudo-link justify-content-between align-items-end py-3"
                onClick={(e) =>
                  isScored(survey)
                    ? handleResultsClick(e, survey)
                    : handleViewSurveyClick(e, survey)
                }
              >
                <Col
                  sm={6}
                  className="d-flex justify-content-between flex-wrap align-items-center order-1 order-sm-0"
                >
                  <div>
                    <span className="card-name me-3">{survey.name}</span>
                    <ButtonGroup className="card-actions me-3">
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        overlay={<Tooltip>Audit</Tooltip>}
                      >
                        <Button
                          variant="link"
                          size="sm"
                          onClick={(e) => handleViewSurveyClick(e, survey)}
                          data-cy="view-survey-btn"
                        >
                          <Icon iconString="clipboard-list" />
                        </Button>
                      </OverlayTrigger>

                      {isAdmin(currentUser) || isSurveyor(currentUser) ? (
                        <OverlayTrigger
                          trigger={["hover", "focus"]}
                          overlay={<Tooltip>Score</Tooltip>}
                        >
                          <Button
                            variant="link"
                            size="sm"
                            onClick={(e) => handleScoreSurveyClick(e, survey)}
                            data-cy="score-survey-btn"
                          >
                            <Icon iconString="calculator" />
                          </Button>
                        </OverlayTrigger>
                      ) : null}

                      {isScored(survey) && survey.scoring?.normScore ? (
                        <OverlayTrigger
                          trigger={["hover", "focus"]}
                          overlay={<Tooltip>Results</Tooltip>}
                        >
                          <Button
                            variant="link"
                            size="sm"
                            onClick={(e) => handleResultsClick(e, survey)}
                            data-cy="survey-results-btn"
                          >
                            <Icon iconString="eye" />
                          </Button>
                        </OverlayTrigger>
                      ) : null}

                      {isAdmin(currentUser) ||
                      isCompany(currentUser) ||
                      isFarmManager(currentUser) ||
                      isScored(survey) ? (
                        <CustomDropdown buttonIcon="ellipsis-v">
                          {isAdmin(currentUser) ||
                          isCompany(currentUser) ||
                          isFarmManager(currentUser) ? (
                            <Dropdown.Item
                              onClick={(e) => handleSurveyUsersClick(e, survey)}
                              data-cy="survey-users-btn"
                            >
                              <Icon iconString="users" className="me-1" /> Users
                            </Dropdown.Item>
                          ) : null}

                          {isScored(survey) && (
                            <Dropdown.Item
                              onClick={() => generateSummary(survey)}
                              data-cy="survey-summary-btn"
                            >
                              <Icon
                                iconString="file-pdf"
                                className="ms-1 me-2"
                              />{" "}
                              Summary
                            </Dropdown.Item>
                          )}

                          {isScored(survey) && (
                            <Dropdown.Item
                              onClick={() => generateReport(survey)}
                              data-cy="survey-report-btn"
                            >
                              <Icon
                                iconString="file-pdf"
                                className="ms-1 me-2"
                              />{" "}
                              Report
                            </Dropdown.Item>
                          )}
                        </CustomDropdown>
                      ) : null}
                    </ButtonGroup>
                  </div>

                  {scoring && hoveredSurvey === survey.externalId ? (
                    <Loader status="Scoring" inlineStatus />
                  ) : (
                    <div>
                      <span>Score: </span>
                      <span>
                        {isScored(survey) && survey.scoring?.normScore
                          ? survey.scoring.normScore
                            ? survey.scoring.normScore.toFixed(1) + "%"
                            : "0.0"
                          : "N/A"}
                      </span>
                    </div>
                  )}

                  <Col xs={12} className="px-0 mt-2">
                    <div
                      className={`bar bar-md risk-${
                        isScored(survey) && survey.scoring?.risk
                          ? getRiskLevelClassName(survey.scoring.risk)
                          : "undefined"
                      }`}
                    ></div>
                  </Col>
                </Col>

                <Col sm={6} md={5} className="order-0 order-sm-1 mb-3 mb-sm-0">
                  <Row className="justify-content-around">
                    {isScored(survey) && survey.scoring?.sections
                      ? survey.scoring.sections.map((section) => {
                          return (
                            <OverlayTrigger
                              key={section.name}
                              trigger={["hover", "focus"]}
                              placement="bottom"
                              overlay={<Tooltip>{section.name}</Tooltip>}
                            >
                              <Col
                                xs={2}
                                className="px-1 px-lg-2 text-nowrap text-center smaller fw-semi"
                                onClick={(e) => handleResultsClick(e, survey)}
                              >
                                <div className="mb-2">
                                  {section.normScore
                                    ? section.normScore.toFixed(1) + "%"
                                    : "0.0"}
                                </div>
                                <div
                                  className={`bar bar-md risk-${getRiskLevelClassName(
                                    section.risk
                                  )}`}
                                ></div>
                              </Col>
                            </OverlayTrigger>
                          );
                        })
                      : placeholders.map((placeholder) => {
                          return (
                            <OverlayTrigger
                              key={placeholder}
                              trigger={["hover", "focus"]}
                              placement="bottom"
                              overlay={<Tooltip>{placeholder}</Tooltip>}
                            >
                              <Col
                                xs={2}
                                className="px-1 px-lg-2 text-nowrap text-center smaller fw-semi"
                              >
                                <div className="mb-2">N/A</div>
                                <div className="bar bar-md risk-undefined"></div>
                              </Col>
                            </OverlayTrigger>
                          );
                        })}
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        );
      })}
    </Row>
  );
};

export default SurveysList;
