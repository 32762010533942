import React from "react";
import { Row, Col } from "react-bootstrap";

import { species } from "../../constants/landing";

type SpeciesProps = {
  /* Is this component is in the viewport? Helps to add 'in-view' class name. */
  isInViewport: boolean;
};

const Species: React.FC<SpeciesProps> = ({ isInViewport }) => {
  return (
    <Row className="layout-container species flex-column">
      <Col className="flex-grow-0">
        <Row className="layout-header flex-column">
          <Col className="flex-grow-0">
            <Row>
              <h2
                className={`layout-header-title layout-wrapper ${
                  isInViewport ? "in-view" : ""
                }`}
              >
                Species
              </h2>
            </Row>
          </Col>
          <Col>
            <Row className="layout-header-content layout-wrapper justify-content-center align-items-center">
              <Col xs={12} xl={8} className="py-4 layout-inner">
                <div>
                  AquaRisk standards are tailored for the main aquaculture
                  species and rearing systems from salmon to shrimp delivering
                  progressive risk management and actionable intelligence.
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Col>
        <Row className="layout-content layout-wrapper justify-content-center align-items-center py-4">
          <Col xs={10} className="h-100 layout-outer">
            <Row className="h-100 species-list align-items-center">
              {species.map((speciesItem, index) => {
                const animationClassName = isInViewport
                  ? "species-item-animation"
                  : "";
                const style = { animationDelay: 0.3 * index + 0.5 + "s" };
                return (
                  <Col
                    xs={3}
                    lg={2}
                    key={speciesItem.name}
                    className="py-3 py-lg-0"
                  >
                    <div
                      className={`${animationClassName} species-item d-flex flex-column align-items-center justify-content-center`}
                      style={style}
                    >
                      <img
                        src={speciesItem.img}
                        className="species-img"
                        alt={`${speciesItem.name} icon`}
                      />
                      <span className="species-name pt-2 text-center">
                        {speciesItem.name}
                      </span>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Species;
