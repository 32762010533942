import { React, useState, useRef } from "react";
import { Button, Accordion } from "react-bootstrap";

const AccordionCustomComponent = ({
  list,
  toggleTitle,
  onAccordionItemClick,
  className = "",
  variant = "secondary",
  disabled = false,
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const accordionToggleRef = useRef(null);

  return (
    <Accordion
      defaultActiveKey="0"
      className={`${className} ${disabled ? "disabled" : ""}`}
    >
      <Accordion.Item eventKey="accordionKey">
        <Accordion.Header
          className={`rounded-2 toggle ${isAccordionOpen ? "show" : ""}`}
          onClick={() => setIsAccordionOpen((prev) => !prev)}
          ref={accordionToggleRef}
        >
          <span>{toggleTitle}</span>
        </Accordion.Header>
        <Accordion.Body>
          <div>
            {list.map((item) => {
              return (
                <Button
                  key={item.type}
                  variant={variant}
                  className="d-block"
                  onClick={() => {
                    onAccordionItemClick(item.type);
                    accordionToggleRef.current.children[0].click();
                  }}
                >
                  {item.title}
                </Button>
              );
            })}
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AccordionCustomComponent;
