/* eslint-disable @typescript-eslint/no-this-alias */

import { Map } from "mapbox-gl";

import { Marker } from "../models";

export const getPinCoordinates = (
  coordinates: [number, number]
): { longitude: number; latitude: number } => {
  let longitude = Number(coordinates[0]);
  let latitude = Number(coordinates[1]);
  if (latitude < -90 || latitude > 90) {
    longitude = 0;
    latitude = 0;
  }

  return { longitude, latitude };
};

export const calculateMapCenter = (markers: Marker[]): [number, number] => {
  if (markers.length === 0) return [0, 0];
  if (markers.length === 1) return [markers[0].lng, markers[0].lat];

  let north = markers[0].lng,
    east = markers[0].lat,
    south = markers[0].lng,
    west = markers[0].lat;

  markers.forEach((spot) => {
    // location in [long,lat] format
    if (spot.lng > north) north = spot.lng;
    if (spot.lat > east) east = spot.lat;
    if (spot.lng < south) south = spot.lng;
    if (spot.lat < west) west = spot.lat;
  });

  return [(north + south) / 2, (east + west) / 2];
};

export const circles = (field: string, circleColor: string): unknown => {
  return {
    type: "circle",
    paint: {
      "circle-color": [
        "interpolate",
        ["linear"],
        ["get", field],
        0,
        circleColor
      ],
      "circle-opacity": 0.75,
      "circle-radius": {
        property: field,
        stops: [
          [{ zoom: 0, value: 0 }, 5],
          [{ zoom: 0, value: 10 }, 10],
          [{ zoom: 0, value: 1000 }, 25],
          [{ zoom: 0, value: 10000 }, 15],
          [{ zoom: 0, value: 100000 }, 25],
          [{ zoom: 0, value: 1000000 }, 35],
          [{ zoom: 0, value: 10000000 }, 45]
        ],
        base: 2
      }
    }
  };
};

export const labels = (field: string, textColor: string): unknown => {
  return {
    type: "symbol",
    filter: [
      "all",
      ["!", ["==", ["get", field], "0k"]],
      ["!", ["==", ["get", field], "0"]],
      ["!", ["==", ["get", field], 0]]
    ],
    layout: {
      "text-field": ["get", field],
      "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
      "text-size": 16
    },
    paint: {
      "text-color": textColor
    }
  };
};

export class StyleSwitcher {
  // This is custom control element.
  // It is based on https://docs.mapbox.com/mapbox-gl-js/api/markers/#icontrol
  firstStyle: string;
  secondStyle: string;
  _map: Map | undefined;
  _btn?: HTMLButtonElement;
  _container?: HTMLDivElement;

  constructor(firstStyle = "", secondStyle = "") {
    this.firstStyle = firstStyle;
    this.secondStyle = secondStyle;
  }

  onAdd(map: Map): HTMLDivElement {
    this._map = map;
    const _this = this;

    this._btn = document.createElement("button");
    this._btn.className = "mapboxgl-ctrl-icon mapboxgl-ctrl-styletoggle";
    this._btn.type = "button";
    this._btn["ariaLabel"] = "Toggle Style";

    const currentStyle = map.getStyle();

    this._btn.onclick = function () {
      if (map.getStyle().sprite === currentStyle.sprite) {
        map.setStyle(_this.secondStyle);
      } else {
        map.setStyle(_this.firstStyle);
      }
    };

    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group mb-3";
    this._container.appendChild(this._btn);

    return this._container;
  }

  onRemove(): void {
    this._container?.parentNode?.removeChild(this._container);
    this._map = undefined;
  }
}

export class ZoomExtend {
  // This is custom control element.
  // It is based on https://docs.mapbox.com/mapbox-gl-js/api/markers/#icontrol
  initialZoom: number;
  initialCenter: [number, number];
  _map: Map | undefined;
  _btn?: HTMLButtonElement;
  _container?: HTMLDivElement;

  constructor(initialZoom = 3, initialCenter: [number, number] = [0, 0]) {
    this.initialZoom = initialZoom;
    this.initialCenter = initialCenter;
  }

  onAdd(map: Map): HTMLDivElement {
    this._map = map;
    const _this = this;

    this._btn = document.createElement("button");
    this._btn.className = "mapboxgl-ctrl-icon mapboxgl-ctrl-zoom";
    this._btn.type = "button";
    this._btn["ariaLabel"] = "Zoom Extend";

    this._btn.onclick = function () {
      map.flyTo({
        zoom: _this.initialZoom,
        center: _this.initialCenter,
        speed: 0.2,
        duration: 2000
      });
    };

    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group mb-3";
    this._container.appendChild(this._btn);

    return this._container;
  }

  onRemove(): void {
    this._container?.parentNode?.removeChild(this._container);
    this._map = undefined;
  }
}
