import React, { useContext, useState, useEffect } from "react";
import { useHistory, RouteComponentProps } from "react-router-dom";
import {
  Row,
  Container,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import { AxiosError } from "axios";

import { getSurveyFarm } from "../../network/request";
import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import { ErrorPopupContext } from "../../contexts/ErrorPopupContext";
import { AlertsContext } from "../../contexts/AlertsContext";
import { showFetchError } from "../../utils/alerts";
import { FarmResponse } from "../../models/be_models";
import { MEDIA_CATEGORY_ENUM } from "../../constants/enums";

import Survey from "./Survey";
import SurveyResults from "./SurveyResults";
import SurveyUsers from "./SurveyUsers";
import Loader from "../common/Loader";
import SelectedSurveyDetail from "./SelectedSurveyDetail";
import PlayVideoModal from "../general/PlayVideoModal";

import ROVIconWhite from "../../images/ROVIconWhite.svg";
import DroneIconWhite from "../../images/DroneIconWhite.svg";
import ROVIcon_dis from "../../images/ROVIcon_dis.svg";
import DroneIcon_dis from "../../images/DroneIcon_dis.svg";

type MediaCategory = {
  name: "Drone" | "ROV" | "Video";
  value: MEDIA_CATEGORY_ENUM;
  icon?: string;
  disIcon?: string;
  disabledName?: "No drone" | "No ROV";
};

type SelectedSurveyContainerProps = {
  surveyExtId: string;
  surveyType: "view-audit" | "audit-result" | "audit-users";
};

const mediaCategories: MediaCategory[] = [
  // { name: "Map", value: "map", icon: MapIcon },
  {
    name: "Drone",
    value: MEDIA_CATEGORY_ENUM.DRONE,
    icon: DroneIconWhite,
    disIcon: DroneIcon_dis,
    disabledName: "No drone",
  },
  { name: "Video", value: MEDIA_CATEGORY_ENUM.VIDEO },
  {
    name: "ROV",
    value: MEDIA_CATEGORY_ENUM.SUBMARINE,
    icon: ROVIconWhite,
    disIcon: ROVIcon_dis,
    disabledName: "No ROV",
  },
];

const SelectedSurveyContainer: React.FC<
  RouteComponentProps<SelectedSurveyContainerProps>
> = (props) => {
  const history = useHistory();

  const { currentUser } = useContext(CurrentUserContext);
  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);

  const [loading, setLoading] = useState(false);
  const [selectedFarm, setSelectedFarm] = useState<FarmResponse>();
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [selectedMediaCategory, setSelectedMediaCategory] = useState<
    MEDIA_CATEGORY_ENUM | ""
  >("");
  const [availableCategories, setAvailableCategories] = useState<
    ("DRONE" | "SUBMARINE" | "VIDEO")[]
  >([]);

  const surveyExtId = props.match.params.surveyExtId;
  const showSurvey = props.match.params.surveyType === "view-audit";
  const showResults = props.match.params.surveyType === "audit-result";
  const showSurveyUsers = props.match.params.surveyType === "audit-users";

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data: farmResult } = await getSurveyFarm(surveyExtId);
        setAvailableCategories(farmResult?.availableMediaCategories);
        setSelectedFarm(farmResult);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        showFetchError({
          error: error as Error | AxiosError,
          customMsg: "API error while loading the audit farm.",
          object: "audit farm",
          objectName: "unknown",
          operation: "loaded",
          dispatchAlert,
          dispatchPopup,
          onRetry: () => fetchData(),
        });
      }
    };

    fetchData();
  }, [surveyExtId]);

  const goToSurveys = () => {
    return history.push("/audits");
  };

  const setMediaShow = (category: MEDIA_CATEGORY_ENUM) => {
    setShowVideoModal(true);
    setSelectedMediaCategory(category);
  };

  const mediaButton = (
    category: MediaCategory,
    isDisabledButton?: boolean,
    isDisabledGroup?: boolean
  ) => {
    const tooltipMessage = `${
      isDisabledButton ? category.disabledName : category.name
    } video`;

    return (
      <OverlayTrigger
        key={category.value}
        trigger={["hover", "focus"]}
        overlay={<Tooltip>{tooltipMessage}</Tooltip>}
      >
        {!isDisabledButton ? (
          <Button
            key={category.value}
            variant="link"
            size="sm"
            className="mx-2 rounded-0"
            onClick={() => {
              setMediaShow(category.value);
            }}
          >
            <img alt={category.icon} src={category.icon} width="25" />
          </Button>
        ) : (
          <Container key={category.value} className="rounded-0 py-1">
            <img
              alt={category.disIcon}
              src={isDisabledGroup ? category.icon : category.disIcon}
              width="25"
            />
          </Container>
        )}
      </OverlayTrigger>
    );
  };

  const renderCategoryButtons = () => {
    const disabled = !availableCategories.length;
    return (
      <Container
        className={`media-buttons px-0 py-4 ${
          disabled && "media-buttons--disabled"
        }`}
      >
        <InputGroup className="d-flex flex-column flex-nowrap align-items-around px-1">
          {mediaCategories.map((category, i) => {
            return category.value === MEDIA_CATEGORY_ENUM.VIDEO ? (
              <p
                key={category.name + category.value + i}
                className={`m-auto py-3  px-0 ${
                  disabled ? "media-heading--disabled" : "text-white"
                }`}
              >{`${disabled ? "No " : ""}${category.name}`}</p>
            ) : availableCategories.find(
                (el) => el === category.value.toUpperCase()
              ) ? (
              mediaButton(category)
            ) : (
              mediaButton(category, true, disabled)
            );
          })}
        </InputGroup>
      </Container>
    );
  };

  return loading ? (
    <div className="loader-container">
      <Loader status="Loading surveys" />
    </div>
  ) : (
    <Container
      className="surveys-container container-with-survey-detail aqua-container"
      fluid
    >
      <Row>
        {showSurvey ? (
          <Survey selectedSurveyExtId={surveyExtId} onClose={goToSurveys} />
        ) : showResults ? (
          <SurveyResults
            selectedSurveyExtId={surveyExtId}
            onClose={goToSurveys}
          />
        ) : showSurveyUsers ? (
          <SurveyUsers
            companyId={currentUser.currentCompany.externalId}
            selectedSurveyExtId={surveyExtId}
            onClose={goToSurveys}
          />
        ) : null}

        {selectedFarm ? (
          <SelectedSurveyDetail
            selectedSurveyExtId={surveyExtId}
            companyId={currentUser.currentCompany.externalId}
          />
        ) : null}
      </Row>
      {showVideoModal && selectedMediaCategory && (
        <PlayVideoModal
          show={showVideoModal}
          onHide={() => setShowVideoModal(false)}
          selectedSurveyExtId={surveyExtId}
          selectedMediaCategory={selectedMediaCategory}
        />
      )}
      {renderCategoryButtons()}
    </Container>
  );
};

export default SelectedSurveyContainer;
