import axios, { AxiosResponse } from "axios";
import {
  BASE_URL,
  getGuestHeadersWithTypes,
} from "../constants/requestConstants";
import * as Model from "../models/be_models";

export const postPasswordChange = (
  //doPasswordChange
  body: Model.PasswordChangeRequest
): Promise<AxiosResponse<boolean>> => {
  const url = `${BASE_URL}/password/change`;

  return axios.post(url, body, {
    headers: getGuestHeadersWithTypes(),
  });
};

export const postPasswordReset = (
  //doPasswordReset
  body: Model.PasswordResetRequest
): Promise<AxiosResponse> => {
  const url = `${BASE_URL}/password/reset`;

  return axios.post(url, body, {
    headers: getGuestHeadersWithTypes(),
  });
};
