import React, { useState, useContext, useRef } from "react";
import {
  Container,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Button,
  Row,
  Col,
} from "react-bootstrap";

import { postPasswordReset } from "../../network/request";
import { ALERT_ACTION_KIND } from "../../constants/enums";
import { AlertsContext } from "../../contexts/AlertsContext";
import { ErrorPopupContext } from "../../contexts/ErrorPopupContext";
import { showFetchError } from "../../utils/alerts";

import Header from "../common/HeaderGuest";
import Loader from "../common/Loader";

const ForgotPasswordContainer = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [showError, setShowError] = useState(false);

  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);

  const landingContainer = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setShowError(true);
      return;
    }

    setLoading(true);
    const body = { email: email };

    try {
      await postPasswordReset(body);
      dispatchAlert({
        type: ALERT_ACTION_KIND.SHOW_SUCCESS_ALERT,
        text: `Reset Password instructions have been sent to ${email}.`,
      });
    } catch (error) {
      showFetchError({
        error,
        customMsg: "API error while changing the password.",
        object: "user",
        objectName: email,
        operation: "reached",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => handleSubmit(e),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      className="auth-container reset-bootstrap-form-group"
      ref={landingContainer}
      fluid
      data-cy="forgot-password-container"
    >
      <Header landingContainer={landingContainer} showSignInButton={true} />

      <main className="auth-container-inner">
        <div className="form-container-wrapper">
          <div className="form-container px-md-5">
            <Row className="justify-content-center align-content-center flex-column">
              <Col>
                <h1 className="headline">Recover password</h1>
              </Col>
              <Col className="login-form pt-3">
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="formEmail" className="form-group">
                    <FormLabel>E-mail address</FormLabel>
                    <FormControl
                      type="email"
                      placeholder="Enter e-mail address"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      data-cy="email"
                    />
                  </FormGroup>

                  <p className="explanation-text mt-5">
                    You will receive an email with instructions for how to
                    create a new password to access your account.
                  </p>
                  <p className="explanation-text mb-5">
                    If you don’t receive an email from Aquarisk within 24 hours,
                    please get in touch with us at accounts@aquarisk.tech.
                  </p>

                  <div className="submit text-center">
                    <div className="error-message pb-2" data-cy="error">
                      {showError ? "Incorrect e-mail" : ""}
                    </div>
                    {loading ? (
                      <Loader status="Sending reset email" inlineStatus />
                    ) : (
                      <Button
                        variant="primary"
                        type="submit"
                        className="button-submit py-1 px-5"
                        data-cy="submit-btn"
                      >
                        Recover password
                      </Button>
                    )}
                  </div>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </main>
    </Container>
  );
};

export default ForgotPasswordContainer;
