import React, { useState, useEffect, useContext } from "react";
import { Toast, Container, Button } from "react-bootstrap";
import { AspectRatio } from "react-aspect-ratio";

import { getListMediaForSurveyByCategory } from "../../network/request";
import { BASE_URL } from "../../constants/requestConstants";
import { MEDIA_CATEGORY_ENUM } from "../../constants/enums";
import { ErrorPopupContext } from "../../contexts/ErrorPopupContext";
import { AlertsContext } from "../../contexts/AlertsContext";
import { showFetchError } from "../../utils/alerts";

import Loader from "../common/Loader";
import Icon from "../common/Icon";
import { AxiosError } from "axios";

type PlayVideoModalProps = {
  // should the modal be shown?
  show: boolean;
  // triggered when close button is clicked
  onHide: () => void;
  selectedSurveyExtId: string;
  // type of media category
  selectedMediaCategory: MEDIA_CATEGORY_ENUM;
};

const PlayVideoModal: React.FC<PlayVideoModalProps> = ({
  show,
  onHide,
  selectedSurveyExtId,
  selectedMediaCategory,
}) => {
  const [mediaSrc, setMediaSrc] = useState<string | null>(null);

  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);

  useEffect(() => {
    const fetchData = async () => {
      setMediaSrc(null);
      try {
        if (
          !selectedSurveyExtId ||
          !selectedSurveyExtId.length ||
          selectedMediaCategory === MEDIA_CATEGORY_ENUM.MAP
        )
          return;
        const { data: media } = await getListMediaForSurveyByCategory(
          selectedSurveyExtId,
          selectedMediaCategory
        );
        const downloadUri = media[0]?.downloadUri;
        if (downloadUri) {
          setMediaSrc(`${BASE_URL}${downloadUri}`);
        }
      } catch (error) {
        showFetchError({
          error: error as Error | AxiosError,
          customMsg: "API error while loading the media details.",
          object: "media details",
          objectName: "plural",
          operation: "loaded",
          dispatchAlert,
          dispatchPopup,
          onRetry: () => fetchData(),
        });
      }
    };

    fetchData();
  }, [selectedSurveyExtId, selectedMediaCategory]);

  return (
    <Container className="col-12 video-modal">
      <Toast
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        className="video-toast"
      >
        <Button
          variant="link"
          size="sm"
          className={"ms-1 rounded-0 pb-0 text-white"}
          onClick={onHide}
        >
          <Icon iconString="times" className="ms-1" />
        </Button>
        <Toast.Body className="pb-0 py-0 px-1 align-items-center">
          <div className="d-flex video-wrapper">
            {mediaSrc ? (
              <AspectRatio ratio="16/9">
                <video
                  id="vidObj"
                  width="100%"
                  controls
                  crossOrigin="anonymous"
                  autoPlay
                  preload="true"
                >
                  <source src={mediaSrc} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </AspectRatio>
            ) : (
              <div className="d-flex align-items-center m-auto video-loader text-white mt-5">
                <Loader status="Loading video" light={true} />
              </div>
            )}
          </div>
        </Toast.Body>
      </Toast>
    </Container>
  );
};

export default PlayVideoModal;
