import { initialCurrentUser } from "../contexts/CurrentUserContext";
import { CurrentUser, CurrentUserReducerAction } from "../models";
import { CURRENT_USER_ACTION_KIND } from "../constants/enums";

export const currentUserReducer = (
  state: CurrentUser,
  action: CurrentUserReducerAction
): CurrentUser => {
  let user;

  switch (action.type) {
    case CURRENT_USER_ACTION_KIND.SET_USER:
      user = { ...initialCurrentUser };

      if (action.user) {
        user = {
          ...state,
          authorities: action.user.authorities,
          email: action.user.email,
          externalId: action.user.externalId,
          fullName: action.user.fullName,
          username: action.user.username,
        };

        localStorage.currentUser = JSON.stringify(user);
      }
      return user;

    case CURRENT_USER_ACTION_KIND.SET_CURRENT_COMPANY:
      user = { ...localStorage.currentUser };

      if (action.company) {
        user = {
          ...state,
          currentCompany: action.company,
        };

        localStorage.currentUser = JSON.stringify(user);
      }
      return user;

    case CURRENT_USER_ACTION_KIND.CLEAR_USER:
      user = { ...state, ...initialCurrentUser };
      localStorage.removeItem("currentUser");
      return user;

    default:
      return state;
  }
};
