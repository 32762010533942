import React from "react";
import AquaRiskLoaderLogo from "../../images/AquaRiskLoaderLogo.svg";

type LoaderProps = {
  /* Text message showing while loading */
  status: string;
  /* Is it inline version of the Loader? */
  inlineStatus?: boolean;
  /* Is it a light (white) version of the Loader? */
  light?: boolean;
};

const Loader: React.FC<LoaderProps> = ({
  status,
  inlineStatus = false,
  light = false,
}) => {
  const inlineLoader = () => {
    return (
      <div className="inline-loader whiten d-flex justify-content-center">
        <div className="loader center">
          <div className={`lds-ripple ${light ? "lds-ripple__light" : ""}`}>
            <div></div>
            <div></div>
          </div>
        </div>

        <div className="status">{status}</div>
      </div>
    );
  };

  const blockLoader = () => {
    return (
      <div className="center logo-loader-wrapper">
        <img
          src={AquaRiskLoaderLogo}
          alt="AquaRisk Logo"
          className="img-logo"
        />
        <div className="progress-loader">
          <div className="loaderBar"></div>
        </div>
      </div>
    );
  };

  return inlineStatus ? inlineLoader() : blockLoader();
};

export default Loader;
