import React from "react";
import { Row, Col } from "react-bootstrap";
import { CATEGORY_ICONS } from "../../constants/imagesConstants";

const ScoreTitles: React.FC = () => {
  const placeholders = [
    "Fish Health",
    "Environment",
    "Engineering",
    "Governance",
    "Husbandry",
  ];

  return (
    <Row className="justify-content-between pt-3 score-titles">
      <Col md={4} sm={6}></Col>

      <Col md={5} sm={6}>
        <Row className="justify-content-around">
          {placeholders.map((placeholder) => {
            return (
              <Col
                xs={2}
                key={placeholder}
                className="text-info lead text-center"
                title={placeholder}
              >
                <img
                  src={CATEGORY_ICONS[placeholder.toLowerCase()]}
                  className="img-icon-md"
                  alt={placeholder}
                />
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
};

export default ScoreTitles;
