import React, { useContext, useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";

import { AlertsContext } from "../contexts/AlertsContext";
import { ErrorPopupContext } from "../contexts/ErrorPopupContext";
import { CurrentUserContext } from "../contexts/CurrentUserContext";
import { getMe } from "../network/request";
import { USER_ROLES } from "../constants/enums";
import { isAdmin, isCompany } from "../utils/userUtils";
import { showFetchError } from "../utils/alerts";

import Loader from "./common/Loader";
import Icon from "./common/Icon";

const AccountContainer = () => {
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [account, setAccount] = useState({
    externalId: "",
    username: "",
    email: "",
    fullName: "",
    authorities: "",
    phone: "",
  });

  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);

  const { currentUser } = useContext(CurrentUserContext);
  const history = useHistory();

  const getUserRolesString = useCallback((availableRolesString) => {
    const availableRoles = availableRolesString.split(",");
    let rolesString = "";
    availableRoles.map((availableRole) => {
      const roleName = USER_ROLES[availableRole];
      rolesString += `${roleName}, `;
      return rolesString;
    });
    rolesString = rolesString.substring(0, rolesString.length - 2);
    setRoles(rolesString);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data: result } = await getMe();
        setAccount(result);
        setLoading(false);
        getUserRolesString(result.authorities);
      } catch (error) {
        setLoading(false);
        showFetchError({
          error,
          customMsg: "API error while loading your account information.",
          object: "account information",
          objectName: "unknown",
          operation: "loaded",
          dispatchAlert,
          dispatchPopup,
          onRetry: () => fetchData(),
        });
      }
    };

    fetchData();
  }, [getUserRolesString]);

  const goToEditUser = () => {
    history.push(
      `/companies/${currentUser.currentCompany.externalId}/users/${account.externalId}`
    );
  };

  return loading ? (
    <div className="loader-container">
      <Loader status="Loading account" />
    </div>
  ) : (
    <Container className="aqua-container pt-5" data-cy="account-container">
      <Row className="justify-content-center flex-wrap">
        <Col xs={12} lg={8}>
          <div className="mb-4">
            <div className="filter-toolbar d-flex align-items-center mb-2">
              <h4 className="header-title mb-1 me-4">Account</h4>
              {isAdmin(currentUser) || isCompany(currentUser) ? (
                <Button variant="link" size="sm" onClick={() => goToEditUser()}>
                  <Icon iconString="edit" />
                </Button>
              ) : null}
            </div>
          </div>
        </Col>
        <Col xs={12} lg={8} className="odd-rows-white">
          <Row>
            <Col md={3} className="fw-semi py-2 px-4">
              Full name:
            </Col>
            <Col className="fw-light py-2 px-4">{account.fullName}</Col>
          </Row>
          <Row>
            <Col md={3} className="fw-semi py-2 px-4">
              Email:
            </Col>
            <Col className="fw-light py-2 px-4">{account.email}</Col>
          </Row>
          {account.phone ? (
            <Row>
              <Col md={3} className="fw-semi py-2 px-4">
                Phone:
              </Col>
              <Col className="fw-light py-2 px-4 overflow-y-auto">
                {account.phone}
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col md={3} className="fw-semi py-2 px-4">
              Role:
            </Col>
            <Col className="fw-light py-2 px-4 overflow-y-auto">{roles}</Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default AccountContainer;
