import React, { useEffect, useState } from "react";
import { Form, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Icon from "../common/Icon";

const SingleSelectForm = ({ onSave, answer, answerOptions }) => {
  const [sandbox, setSandbox] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (!answer || !answer.answerStr) return;
    const ordinal = answer.answerStr;
    setSandbox(ordinal);
  }, [answer]);

  const handleChange = (e) => {
    setSandbox(e.target.value);
  };

  const isValid = () => {
    setError("");

    if (sandbox && sandbox.length) return true;
    setError("An option must be selected");
    return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isValid()) return;
    onSave(sandbox);
  };

  return (
    <Form
      id="question-form"
      className="survey-answer-form"
      onSubmit={handleSubmit}
    >
      {answerOptions.map((answerOption) => {
        return (
          <Form.Check
            key={answerOption.ordinal}
            custom
            type="radio"
            className="mb-1"
          >
            <Form.Check.Input
              label={answerOption.text}
              value={answerOption.ordinal}
              id={answerOption.text}
              checked={sandbox === answerOption.ordinal.toString()}
              onChange={handleChange}
            />
            <Form.Check.Label htmlFor={answerOption.text}>
              {answerOption.text}
              {answerOption.hint ? (
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="bottom"
                  overlay={<Tooltip>{answerOption.hint}</Tooltip>}
                >
                  <Button variant="link" onClick={null} className="px-1 py-0">
                    <Icon iconString="info-circle" />
                  </Button>
                </OverlayTrigger>
              ) : null}
            </Form.Check.Label>
          </Form.Check>
        );
      })}

      {error.length ? <div className="error">{error}</div> : null}
    </Form>
  );
};

export default SingleSelectForm;
