import axios, { AxiosResponse } from "axios";
import { API, getHeaders } from "../constants/requestConstants";

export const postContactMe = (
  body: FormData
): Promise<AxiosResponse | Error> => {
  const url = `${API}/contact`;
  return axios.post(url, body, {
    headers: getHeaders(),
  });
};
