export enum ANSWER_TYPES {
  SHORT_TEXT = "SHORT_TEXT", // single line form 255 max
  LONG_TEXT = "LONG_TEXT", // multi line form 654345 (log),
  YES_NO = "YES_NO", // string YES or NO,
  SINGLE_SELECT = "SINGLE_SELECT", // integer (ordinal position),
  MULTI_SELECT = "MULTI_SELECT", // comma separate integer (ordinal position),
  FLOAT = "FLOAT", // ex "0.01",
  FLOAT_RANGE = "FLOAT_RANGE", // ex "0.01-0.02",
  INTEGER = "INTEGER", // ex: "3",
  INTEGER_RANGE = "INTEGER_RANGE", // ex: "3-4",
  DATE = "DATE", // ISO8661 y/m/d,
  DATE_TIME = "DATE_TIME", //ISO8661 y/m/d h:m:s,
  URL = "URL", // string,
  USER = "USER", // string userExtId
}

export enum ANSWER_UNITS {
  MG_L = "MG_L",
  PERCENT = "PERCENT",
  PPM = "PPM",
  PPT = "PPT",
  FEET_INCHES = "FEET_INCHES",
  METERS = "METERS",
  FAHRENHEIT = "FAHRENHEIT",
  CELSIUS = "CELSIUS",
  YEAR = "YEAR",
  KILOGRAMS = "KILOGRAMS",
  GRAMS = "GRAMS",
  LITRES = "LITRES",
  MILLILITERS = "MILLILITERS",
  NONE = "NONE",
}

export enum ANSWER_INCLUDE_IF {
  EQ = "EQ",
  NE = "NE",
  GTE = "GTE",
  LTE = "LTE",
  GT = "GT",
  LT = "LT",
  BETWEEN = "BETWEEN",
  CONTAINS = "CONTAINS",
}
