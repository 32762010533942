import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

const IntegerForm = ({ onSave, answer }) => {
  const [sandbox, setSandbox] = useState(0);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!answer || !answer.answerStr) return;
    const value = parseInt(answer.answerStr);
    setSandbox(value);
  }, [answer]);

  const handleChange = (e) => {
    const value = parseInt(e.target.value);
    setSandbox(value);
  };

  const isValid = () => {
    setError("");

    if (sandbox != null && sandbox > -1 && sandbox <= 2147483647) return true;
    setError("Answer must between 0 and 2,147,483,647");
    return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isValid()) return;
    onSave(sandbox);
  };

  return (
    <Form
      id="question-form"
      className="survey-answer-form"
      onSubmit={handleSubmit}
    >
      <Form.Group
        controlId="formGroupAnswer"
        className="form-group px-0 col-12 col-sm-6 col-xl-4"
      >
        <Form.Control
          type="number"
          step="1"
          placeholder="0"
          value={sandbox}
          onChange={handleChange}
        />
      </Form.Group>

      {error.length ? <div className="error">{error}</div> : null}
    </Form>
  );
};

export default IntegerForm;
