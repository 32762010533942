import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useInView } from "react-intersection-observer";

import HeaderGuestLanding from "../common/HeaderGuestLanding";
import Footer from "../common/Footer";
import Promo from "./Promo";
import ContactUs from "./ContactUs";
import RiskLevels from "./RiskLevels";
import RiskCategories from "./RiskCategories";
import Species from "./Species";

const LandingContainer: React.FC = () => {
  const [imgSection, inViewImgSection] = useInView();
  const [imgSection1, inViewImgSection1] = useInView();
  const [imgSection2, inViewImgSection2] = useInView();
  const [imgSection3, inViewImgSection3] = useInView();
  const [infoSection1, inViewInfoSection1] = useInView();
  const [infoSection2, inViewInfoSection2] = useInView();
  const [infoSection3, inViewInfoSection3] = useInView();
  const [infoSection4, inViewInfoSection4] = useInView();

  const [layoutStickyBgId, setLayoutStickyBgId] = useState<string>("slide1");
  const [layoutStickyTitleInView, setLayoutStickyTitleInView] =
    useState<string>("");

  const landingContainer = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (inViewImgSection) {
      setLayoutStickyTitleInView("in-view");
    } else {
      setLayoutStickyTitleInView("");
    }

    if (inViewImgSection3) {
      setLayoutStickyBgId("slide3");
    } else if (inViewImgSection2) {
      setLayoutStickyBgId("slide2");
    } else if (inViewImgSection1) {
      setLayoutStickyBgId("slide1");
    }
  }, [
    inViewImgSection3,
    inViewImgSection2,
    inViewImgSection1,
    inViewImgSection,
  ]);

  const isInView = (inViewSection: boolean): string => {
    return inViewSection ? "in-view" : "";
  };

  return (
    <Container
      fluid
      id="landing-container"
      ref={landingContainer}
      className="reset-bootstrap-form-group"
      data-cy="landing-page"
    >
      <HeaderGuestLanding landingContainer={landingContainer} />

      <main>
        <section>
          <Promo />
        </section>

        <section className="layout-sticky">
          <Row
            className={`layout-sticky-title z-index-1`}
            style={{
              display: inViewInfoSection1 || inViewInfoSection3 ? "none" : "",
            }}
          >
            <h2
              className={`layout-header-title layout-wrapper ${layoutStickyTitleInView}`}
            >
              Aquaculture risk
            </h2>
          </Row>

          <div className="slide" ref={imgSection}>
            <Row className="layout-sticky-bg" id={layoutStickyBgId}></Row>
          </div>

          <Row
            className="layout-sticky-inner justify-content-center"
            ref={imgSection1}
          >
            <Col xs={10} lg={8} className="layout-inner">
              <Row className="flex-column">
                <Col xs={12} lg={10}>
                  <p
                    className={`layout-sticky-inner-text ${isInView(
                      inViewImgSection1
                    )}`}
                  >
                    Aerial assessment of the risk factors at farming sites and
                    streamed in the cloud.
                  </p>
                </Col>
                <Col xs={12} lg={10}>
                  <p
                    className={`layout-sticky-inner-headline my-5 ${isInView(
                      inViewImgSection1
                    )}`}
                  >
                    Aerial assessment
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            className="layout-sticky-inner justify-content-center"
            ref={imgSection2}
          >
            <Col xs={10} lg={8} className="layout-inner">
              <Row className="flex-column">
                <Col xs={12} lg={10}>
                  <p
                    className={`layout-sticky-inner-text ${isInView(
                      inViewImgSection2
                    )}`}
                  >
                    ROV deployments to inspect animal health, engineering
                    factors and maintenance protocols.
                  </p>
                </Col>
                <Col xs={12} lg={10}>
                  <p
                    className={`layout-sticky-inner-headline my-5 ${isInView(
                      inViewImgSection2
                    )}`}
                  >
                    Sub-sea assessment
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            className="layout-sticky-inner justify-content-center"
            ref={imgSection3}
          >
            <Col xs={10} lg={8} className="layout-inner">
              <Row className="flex-column">
                <Col xs={12} lg={10}>
                  <p
                    className={`layout-sticky-inner-text ${isInView(
                      inViewImgSection3
                    )}`}
                  >
                    Receive feedback from farming managers on risk exposures to
                    manage production, insurance and financial risk.
                  </p>
                </Col>
                <Col xs={12} lg={10}>
                  <p
                    className={`layout-sticky-inner-headline my-5 ${isInView(
                      inViewImgSection3
                    )}`}
                  >
                    Action Center
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>

        <div ref={infoSection1}>
          <RiskLevels isInViewport={inViewInfoSection1} />
        </div>

        <div ref={infoSection2}>
          <RiskCategories isInViewport={inViewInfoSection2} />
        </div>

        <div ref={infoSection3}>
          <Species isInViewport={inViewInfoSection3} />
        </div>

        <div
          id="contact"
          className="layout-container d-flex flex-column justify-content-between"
          ref={infoSection4}
          data-cy="contact"
        >
          <ContactUs />
          <Footer />
        </div>

        {inViewInfoSection4 ? null : (
          <a href="#contact" className={"get-demo-fixed"}>
            <Button
              variant="primary"
              size="sm"
              className="d-none d-lg-inline-block get-demo-btn px-2"
            >
              Book a Demo
            </Button>
          </a>
        )}
      </main>
    </Container>
  );
};

export default LandingContainer;
