import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  ButtonToolbar,
  Button,
  Form,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";

import { AlertsContext } from "../contexts/AlertsContext";
import { CurrentUserContext } from "../contexts/CurrentUserContext";
import { ErrorPopupContext } from "../contexts/ErrorPopupContext";
import {
  getMyCompanyFarms,
  postCreateCompany,
  putUpdateCompany,
  deleteCompany,
  getCompanyUsers,
  getMyCompanies,
  getMyCompany,
} from "../network/request";
import {
  ALERT_ACTION_KIND,
  CURRENT_USER_ACTION_KIND,
} from "../constants/enums";
import { showTransientAlert, showFetchError } from "../utils/alerts";
import { isAdmin, isCompany } from "../utils/userUtils";

import Loader from "./common/Loader";
import DeleteConfirmationModal from "./general/DeleteConfirmationModal";

const initialForm = {
  name: "",
  address1: "",
  address2: "",
  city: "",
  province: "",
  postCode: "",
  phone: "",
  email: "",
  webAddress: "",
};

const CompanyContainer = (props) => {
  const [fetching, setFetching] = useState(false);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);

  const [form, setForm] = useState({ ...initialForm });
  const [farms, setFarms] = useState([]);
  const [users, setUsers] = useState([]);
  const { currentUser, dispatch } = useContext(CurrentUserContext);
  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);

  const companyId = props.match.params.companyId;
  const isNewCompany = companyId === "new" || !companyId.length;
  const history = useHistory();

  const fetchMyCompany = async () => {
    try {
      const { data: result } = await getMyCompany(companyId);
      setForm(result);
    } catch (error) {
      showFetchError({
        error,
        customMsg: "API error while loading the company.",
        object: "company",
        objectName: "unknown",
        operation: "loaded",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => fetchMyCompany(),
      });
    }
  };

  const fetchMyCompanyFarms = async () => {
    try {
      const { data: farmsResult } = await getMyCompanyFarms(companyId, true);
      setFarms(farmsResult);
    } catch (error) {
      showFetchError({
        error,
        customMsg: "API error while loading the company farms.",
        object: "company farms",
        objectName: "plural",
        operation: "loaded",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => fetchMyCompanyFarms(),
      });
    }
  };

  const fetchCompanyUsers = async () => {
    try {
      const { data: usersResult } = await getCompanyUsers(companyId);
      setUsers(usersResult);
    } catch (error) {
      showFetchError({
        error,
        customMsg: "API error while loading the company users.",
        object: "company users",
        objectName: "plural",
        operation: "loaded",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => fetchCompanyUsers(),
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setFetching(true);
      await fetchMyCompany();
      await fetchMyCompanyFarms();
      await fetchCompanyUsers();
      setFetching(false);
    };

    if (isNewCompany) {
      setForm({ ...initialForm });
    } else {
      fetchData();
    }
  }, [companyId, isNewCompany]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const fetchCreateCompany = async () => {
    try {
      setSaving(true);
      const { data: result } = await postCreateCompany(form);
      dispatch({
        type: CURRENT_USER_ACTION_KIND.SET_CURRENT_COMPANY,
        company: result,
      });
      history.push("/");
      window.location.reload("/");
      showTransientAlert(dispatchAlert, {
        type: ALERT_ACTION_KIND.SHOW_SUCCESS_ALERT,
        text: "Company saved!",
      });
    } catch (error) {
      showFetchError({
        error,
        customMsg: "API error while creating the company.",
        object: "company",
        objectName: form.name,
        operation: "created",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => fetchCreateCompany(),
      });
    } finally {
      setSaving(false);
    }
  };

  const fetchUpdateCompany = async () => {
    try {
      setSaving(true);
      await putUpdateCompany(companyId, form);
      showTransientAlert(dispatchAlert, {
        type: ALERT_ACTION_KIND.SHOW_SUCCESS_ALERT,
        text: "Company saved!",
      });
    } catch (error) {
      showFetchError({
        error,
        customMsg: "API error while updating the company.",
        object: "company",
        objectName: form.name,
        operation: "updating",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => fetchUpdateCompany(),
      });
    } finally {
      setSaving(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isNewCompany) {
      await fetchCreateCompany();
    } else {
      await fetchUpdateCompany();
    }
  };

  const handleFarmsClick = () => {
    history.push(`/companies/${form.externalId}/farms`);
  };

  const handleUsersClick = () => {
    history.push(`/companies/${form.externalId}/users`);
  };

  const renderDeleteModal = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteModalShow(true);
  };

  const fetchCompanies = async () => {
    try {
      setDeleting(true);
      const { data: companiesResult } = await getMyCompanies();

      dispatch({
        type: CURRENT_USER_ACTION_KIND.SET_CURRENT_COMPANY,
        company: companiesResult[0],
      });

      history.push("/");
      window.location.reload("/");
    } catch (error) {
      setDeleting(false);
      showFetchError({
        error,
        customMsg: "API error while loading the companies.",
        object: "companies",
        objectName: "plural",
        operation: "loaded",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => fetchCompanies(),
      });
    }
  };

  const fetchDeleteCompany = async () => {
    try {
      setDeleting(true);
      await deleteCompany(companyId);
      showTransientAlert(dispatch, {
        type: ALERT_ACTION_KIND.SHOW_SUCCESS_ALERT,
        text: "Company deleted!",
      });
    } catch (error) {
      showFetchError({
        error,
        customMsg: "API error while deleting the companies.",
        object: "companies",
        objectName: "plural",
        operation: "deleted",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => fetchDeleteCompany(),
      });
    } finally {
      setDeleting(false);
    }
  };

  const handleDelete = async () => {
    await fetchDeleteCompany();
    await fetchCompanies();

    setDeleteModalShow(false);
  };

  return (
    <Container className="aqua-container">
      {fetching ? (
        <Row className="loader-container">
          <Loader status="Loading company" />
        </Row>
      ) : (
        <Row>
          <Col xs={12} className="main">
            <div className="header mb-5">
              <div className="w-100 d-flex align-items-start">
                {/* <Button
                variant="link"
                className="btn-back">
                <i className="arrow arrow-lg arrow-gray arrow-left"></i>
              </Button> */}
                <div className="filter-toolbar ps-4 mb-2">
                  {isNewCompany ? (
                    <h4 className="header-title">New Company</h4>
                  ) : (
                    <h4 className="header-title">Edit Company</h4>
                  )}

                  {(isAdmin(currentUser) || isCompany(currentUser)) &&
                  !isNewCompany ? (
                    <ButtonToolbar>
                      <div className="w-100">
                        <Button
                          variant="link"
                          className="ps-0 pb-0 underline"
                          onClick={handleFarmsClick}
                        >
                          <span>{farms.length}</span>
                          <span>&nbsp;Farms</span>
                        </Button>
                      </div>
                      <div className="w-100">
                        <Button
                          variant="link"
                          className="ps-0 pb-0 underline"
                          onClick={handleUsersClick}
                        >
                          <span>{users.length}</span>
                          <span>&nbsp;Users</span>
                        </Button>
                      </div>
                    </ButtonToolbar>
                  ) : null}
                </div>
              </div>
            </div>

            <Form className="row mt-5 company-form" onSubmit={handleSubmit}>
              <Form.Group
                controlId="formGroupName"
                className="form-group col-12 col-sm-6"
              >
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="name"
                  value={form.name}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group
                controlId="formGroupAddressOne"
                className="form-group col-12"
              >
                <Form.Label>Address 1</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="address1"
                  value={form.address1}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group
                controlId="formGroupAddressTwo"
                className="form-group col-12"
              >
                <Form.Label>Address 2</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="address2"
                  value={form.address2}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group
                controlId="formGroupCity"
                className="form-group col-12 col-sm-6"
              >
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="city"
                  value={form.city}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group
                controlId="formGroupProvince"
                className="form-group col-12 col-sm-6"
              >
                <Form.Label>Province</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="province"
                  value={form.province}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group
                controlId="formGroupPostCode"
                className="form-group col-12 col-sm-6"
              >
                <Form.Label>Post Code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="postCode"
                  value={form.postCode}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group
                controlId="formGroupPhone"
                className="form-group col-12 col-sm-6"
              >
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="phone"
                  value={form.phone}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group
                controlId="formGroupEmail"
                className="form-group col-12 col-sm-6"
              >
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group
                controlId="formGroupWebAddress"
                className="form-group col-12 col-sm-6"
              >
                <Form.Label>Web Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="webAddress"
                  value={form.webAddress}
                  onChange={handleChange}
                />
              </Form.Group>

              <ButtonToolbar className="col-12 form-group justify-content-between mt-5">
                {isAdmin(currentUser) && !isNewCompany ? (
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={renderDeleteModal}
                  >
                    Delete
                  </Button>
                ) : null}

                {saving ? (
                  <Loader status="Saving" />
                ) : (
                  <Button variant="secondary" size="sm" type="submit">
                    Save
                  </Button>
                )}
              </ButtonToolbar>
            </Form>
          </Col>
        </Row>
      )}
      <DeleteConfirmationModal
        show={deleteModalShow}
        onClose={() => setDeleteModalShow(false)}
        onConfirm={handleDelete}
        loading={deleting}
        title="company"
      >
        <>
          <p>
            {`${form.name} has ${users.length ? users.length : 0} users and ${
              farms.length ? farms.length : 0
            } farms.`}
          </p>
          <p className="mb-0"> Delete {form.name}?</p>
        </>
      </DeleteConfirmationModal>
    </Container>
  );
};

export default CompanyContainer;
