import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";

import Icon from "../components/common/Icon";

const CustomDropdown = ({ children, buttonIcon }) => {
  const [showMenu, setShowMenu] = useState(false);
  const wrapperRef = useRef(null);

  /**
   * Hook that handle clicks outside of the passed ref
   */
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div onClick={(e) => e.stopPropagation()} className="custom-dropdown">
      <Button
        title="menu"
        variant="link"
        size="sm"
        onClick={() => setShowMenu((prev) => !prev)}
      >
        <Icon iconString={buttonIcon} />
      </Button>
      <div
        className={`dropdown-menu ${showMenu ? "show" : ""}`}
        ref={wrapperRef}
      >
        {children}
      </div>
    </div>
  );
};

export default CustomDropdown;
