import React, { useContext } from "react";
import { Modal, Button } from "react-bootstrap";

import { ErrorPopupContext } from "../../contexts/ErrorPopupContext";
import { ERROR_POPUP_ACTION_KIND } from "../../constants/enums";

const ErrorPopup: React.FC = () => {
  const { popup, dispatch } = useContext(ErrorPopupContext);
  const { show, onClose, onRetry, message, showRetryBtn } = popup;

  const closePopup = (): void => {
    dispatch({ type: ERROR_POPUP_ACTION_KIND.CLOSE_POPUP });
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      data-cy="error-popup"
      backdrop
    >
      <Modal.Header className="">
        <Modal.Title className="fw-normal fs-20">
          We’ve encountered an error...
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="py-10">
        <div className="py-3 border-top border-bottom border-primary border-opacity-25 fs-14">
          <div dangerouslySetInnerHTML={{ __html: message }} />
          <p className="mb-0 fw-light">Our apologies for the inconvenience.</p>
        </div>
      </Modal.Body>

      {showRetryBtn ? (
        <Modal.Footer className="justify-content-between">
          <Button
            variant="link"
            onClick={closePopup}
            className="text-decoration-underline fs-6"
            data-cy="exit-btn"
          >
            Cancel
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              closePopup();
              onRetry();
            }}
            data-cy="delete-btn"
            className="fs-6"
          >
            Retry
          </Button>
        </Modal.Footer>
      ) : (
        <Modal.Footer className="justify-content-end">
          <Button
            variant="secondary"
            onClick={closePopup}
            data-cy="exit-btn"
            className="px-5 fs-6"
          >
            OK
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ErrorPopup;
