import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";

const DateTimeForm = ({ onSave, answer }) => {
  const [sandbox, setSandbox] = useState(new Date());
  const [error, setError] = useState("");

  useEffect(() => {
    if (!answer || !answer.answerStr) return;
    const value = new Date(answer.answerStr);
    setSandbox(value);
  }, [answer]);

  const handleChange = (date) => {
    setSandbox(date);
  };

  const isValid = () => {
    setError("");

    if (sandbox !== null) return true;
    setError("Date is required");
    return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isValid()) return;
    onSave(sandbox);
  };

  return (
    <Form
      id="question-form"
      className="survey-answer-form"
      onSubmit={handleSubmit}
    >
      <Form.Group
        controlId="formGroupAnswer"
        className="form-group px-0 col-12 col-sm-6 date-picker-ann"
      >
        <DatePicker
          selected={sandbox}
          onChange={handleChange}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          dateFormat="MMMM d, yyyy h:mm aa"
          timeCaption="time"
        />
      </Form.Group>

      {error.length ? <div className="error">{error}</div> : null}
    </Form>
  );
};

export default DateTimeForm;
