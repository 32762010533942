import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Navbar, NavDropdown, Nav, Col } from "react-bootstrap";
import { AxiosError } from "axios";

import { getMe, getMyCompanies } from "../../network/request";
import {
  AUTH_ACTION_KIND,
  CURRENT_USER_ACTION_KIND,
} from "../../constants/enums";
import { AuthContext } from "../../contexts/AuthContext";
import {
  CurrentUserContext,
  initialCurrentUser,
} from "../../contexts/CurrentUserContext";
import { AlertsContext } from "../../contexts/AlertsContext";
import { ErrorPopupContext } from "../../contexts/ErrorPopupContext";
import { isAdmin } from "../../utils/userUtils";
import { CompanyResponse } from "../../models/be_models";
import { showFetchError } from "../../utils/alerts";

import Loader from "./Loader";
import ProfileImage from "../general/ProfileImage";
import HeaderCompanies from "./HeaderCompanies";
import { AQUARISK_LOGO } from "../../constants/imagesConstants";

const HeaderUser: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [companies, setCompanies] = useState<CompanyResponse[]>([]);

  const location = useLocation();
  const history = useHistory();
  const authContext = useContext(AuthContext);

  const { currentUser, dispatch } = useContext(CurrentUserContext);
  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const { data: userResult } = await getMe();

        const user = {
          authorities: userResult.authorities,
          email: userResult.email,
          externalId: userResult.externalId,
          fullName: userResult.fullName,
          username: userResult.username,
          currentCompany: initialCurrentUser.currentCompany,
        };

        dispatch({
          type: CURRENT_USER_ACTION_KIND.SET_USER,
          user,
        });
      } catch (error) {
        showFetchError({
          error: error as AxiosError | Error,
          customMsg: "API error while loading the current user.",
          object: "current user",
          objectName: "unknown",
          operation: "loaded",
          dispatchAlert,
          dispatchPopup,
          onRetry: () => fetchData(),
        });
      } finally {
        setLoading(false);
      }
    };

    if (!currentUser.externalId) fetchData();
  }, [currentUser.externalId, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const { data: companiesResult } = await getMyCompanies();
        setCompanies(companiesResult);

        const isCurrentCompanyExistsInMyCompanies = !!companiesResult.filter(
          (item) => item.externalId === currentUser.currentCompany?.externalId
        ).length;

        if (companiesResult.length && !isCurrentCompanyExistsInMyCompanies) {
          const [firstMyCompany] = companiesResult;
          const company = {
            address1: firstMyCompany.address1,
            city: firstMyCompany.city,
            externalId: firstMyCompany.externalId,
            name: firstMyCompany.name,
            postCode: firstMyCompany.postCode,
            province: firstMyCompany.province,
          };

          dispatch({
            type: CURRENT_USER_ACTION_KIND.SET_CURRENT_COMPANY,
            company,
          });
        }
      } catch (error) {
        showFetchError({
          error: error as AxiosError | Error,
          customMsg: "API error while loading the current user companies.",
          object: "user companies",
          objectName: "plural",
          operation: "loaded",
          dispatchAlert,
          dispatchPopup,
          onRetry: () => fetchData(),
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  const goHome = () => {
    history.push("/");
  };

  const signOut = () => {
    authContext.dispatch({ type: AUTH_ACTION_KIND.LOGOUT });
    dispatch({
      type: CURRENT_USER_ACTION_KIND.CLEAR_USER,
    });
    window.location.reload();
  };

  return (
    <Navbar fixed="top" expand="lg" className="justify-content-between">
      <Navbar.Brand className="me-0" href="#">
        <img
          src={AQUARISK_LOGO}
          height="60"
          alt="AquaRisk Logo"
          onClick={goHome}
          className="p-2"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />

      {loading ? (
        <div className="ms-auto pe-3">
          <Loader status="Loading profile" inlineStatus />
        </div>
      ) : (
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto col-lg-6 justify-content-lg-around nav-main align-items-center">
            <Nav.Link
              href="/audits"
              active={["/audits", "/"].includes(location.pathname)}
            >
              <span className="link">Audits</span>
            </Nav.Link>
            <Nav.Link
              href="/actions"
              active={["/actions"].includes(location.pathname)}
            >
              <span className="link">Actions</span>
            </Nav.Link>
            <Nav.Link
              href="/intelligence"
              active={["/intelligence"].includes(location.pathname)}
            >
              <span className="link">Intelligence</span>
            </Nav.Link>
            {isAdmin(currentUser) ? (
              <Nav.Link
                href="/templates"
                active={["/templates"].includes(location.pathname)}
              >
                <span className="link">Templates</span>
              </Nav.Link>
            ) : null}
          </Nav>

          <Nav>
            <HeaderCompanies companies={companies} />

            <Col className="d-lg-none ">
              <NavDropdown.Divider />

              <ProfileImage
                classNameProp="mx-auto"
                name={currentUser.fullName || currentUser.email}
              />
              <p className="user-name d-lg-none my-2">
                {currentUser.fullName || currentUser.email}
              </p>
              <NavDropdown.Item className="d-lg-none" href="/account">
                Account
              </NavDropdown.Item>
              {isAdmin(currentUser) && (
                <NavDropdown.Item className="d-lg-none" href="/activity-log">
                  <span className="link text-nowrap">Activity Log</span>
                </NavDropdown.Item>
              )}
              <NavDropdown.Item className="d-lg-none" onClick={signOut}>
                Sign Out
              </NavDropdown.Item>
            </Col>

            <NavDropdown
              title={
                <ProfileImage
                  name={currentUser.fullName || currentUser.email}
                />
              }
              className="d-none d-lg-flex flex-column align-items-center nav-dropdown ms-4 dropdown-user"
              data-cy="current-user-btn"
            >
              <NavDropdown.Item className="dropdown-item-header">
                {currentUser.fullName || currentUser.email}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                onClick={() => history.push("/account")}
                data-cy="account-btn"
              >
                Account
              </NavDropdown.Item>
              {isAdmin(currentUser) && (
                <NavDropdown.Item onClick={() => history.push("/activity-log")}>
                  <span className="link text-nowrap">Activity Log</span>
                </NavDropdown.Item>
              )}
              <NavDropdown.Item onClick={signOut} data-cy="sign-out">
                Sign Out
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      )}
    </Navbar>
  );
};

export default HeaderUser;
