import axios, { AxiosResponse } from "axios";
import { ADMIN_API, getUserHeaders } from "../constants/requestConstants";
import * as Model from "../models/be_models";

export const postGetLogs = (
  //getLogs
  page: number,
  size: number,
  body: Model.AuditLogsRequest
): Promise<AxiosResponse<string[]>> => {
  const url = `${ADMIN_API}/audit/log`;

  return axios.post(url, body, {
    headers: getUserHeaders(),
    params: {
      page,
      size,
    },
  });
};
