import React, { useRef, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Form,
  FormGroup,
  FormControl,
  FormLabel,
  Button,
  Row,
  Col,
} from "react-bootstrap";

import { postLogIn } from "../../network/request";
import { AUTH_ACTION_KIND } from "../../constants/enums";
import { AuthContext } from "../../contexts/AuthContext";
import { AlertsContext } from "../../contexts/AlertsContext";
import { ErrorPopupContext } from "../../contexts/ErrorPopupContext";
import { showFetchError } from "../../utils/alerts";

import Loader from "../common/Loader";
import Header from "../common/HeaderGuest";
import Icon from "../common/Icon";

const LoginContainer = () => {
  const landingContainer = useRef(null);
  const history = useHistory();
  const { dispatch } = useContext(AuthContext);
  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await postLogIn(email, password);

      dispatch({
        type: AUTH_ACTION_KIND.LOGIN,
        token: result.access_token,
        refreshToken: result.refresh_token,
        tokenType: result.token_type,
        tokenExpiresIn: result.expires_in,
      });
      history.push("/");
    } catch (error) {
      //TODO: implement email validation
      setShowError(true);
      showFetchError({
        error,
        customMsg: "API error while sign in.",
        object: "user",
        objectName: "unknown",
        operation: "signed in",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => handleSubmit(e),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();

    history.push("/forgotpassword");
  };

  return (
    <Container
      fluid
      ref={landingContainer}
      data-cy="login-container"
      className="auth-container reset-bootstrap-form-group"
    >
      <Header landingContainer={landingContainer} showSignInButton={false} />

      <main className="auth-container-inner">
        <div className="form-container-wrapper">
          <div className="form-container px-md-5">
            <Row className="justify-content-center align-content-center flex-column">
              <Col>
                <h1 className="headline">Sign in to your account</h1>
                <p className="request-demo-text text-center">
                  <span>Don’t have an account? Request a demo </span>
                  <a
                    href="/#contact"
                    title="Request a demo"
                    className="request-demo-link text-water"
                    data-cy="signup-btn"
                  >
                    here
                  </a>
                  .
                </p>
              </Col>
              <Col className="login-form pt-3">
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="formEmail" className="form-group">
                    <FormLabel>E-mail address</FormLabel>
                    <FormControl
                      type="email"
                      placeholder="Enter e-mail address"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      data-cy="email"
                    />
                  </FormGroup>

                  <FormGroup controlId="formPassword" className="form-group">
                    <div className="d-flex justify-content-between">
                      <FormLabel>Password</FormLabel>
                    </div>

                    <div className="password-wrapper">
                      <FormControl
                        type={passwordShown ? "text" : "password"}
                        placeholder="Enter password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        data-cy="password"
                      />
                      <Button
                        variant="link"
                        className="show-password-button p-0 border-0"
                        onClick={() =>
                          setPasswordShown(passwordShown ? false : true)
                        }
                        data-cy="hide-show-psw"
                      >
                        <Icon
                          iconString={passwordShown ? "eye-slash" : "eye"}
                        />
                      </Button>
                    </div>
                  </FormGroup>

                  <FormGroup
                    controlId="forgotPassword"
                    className="text-end mb-4 form-group"
                  >
                    <Button
                      variant="link"
                      className="forgot-password-link text-water p-0 border-0"
                      onClick={handleForgotPassword}
                      data-cy="forgot-password-btn"
                    >
                      Forgot your password?
                    </Button>
                  </FormGroup>

                  <div className="submit text-center">
                    <div className="error-message pb-2" data-cy="error">
                      {showError ? "Incorrect e-mail or password" : ""}
                    </div>
                    {loading ? (
                      <Loader status="Authenticating" inlineStatus />
                    ) : (
                      <Button
                        variant="primary"
                        type="submit"
                        className="button-submit py-1 px-5"
                        data-cy="submit-btn"
                      >
                        Sign in
                      </Button>
                    )}
                  </div>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </main>
    </Container>
  );
};

export default LoginContainer;
