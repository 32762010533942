import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  ButtonGroup,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useHistory, RouteComponentProps } from "react-router-dom";
import { AxiosError } from "axios";

import { AlertsContext } from "../../contexts/AlertsContext";
import { ErrorPopupContext } from "../../contexts/ErrorPopupContext";
import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import {
  getMyCompany,
  getMyCompanyFarms,
  getCompanyFarmsAutoCorrect,
  deleteFarm,
  getActiveSurveysForFarm,
} from "../../network/request";
import { ALERT_ACTION_KIND } from "../../constants/enums";
import {
  INITIAL_FARM,
  INITIAL_MAP_POSITION,
} from "../../constants/initialData";
import { showTransientAlert, showFetchError } from "../../utils/alerts";
import { getOneLevelSmallerUrl } from "../../utils/string";
import { FarmResponse, NameSuggesterResponse } from "../../models/be_models";
import { Marker } from "../../models/";

import Loader from "../common/Loader";
import Icon from "../common/Icon";
import AquaMapBoxGl from "../general/AquaMapBoxGl";
import DeleteConfirmationModal from "../general/DeleteConfirmationModal";

type FarmsContainerProps = {
  companyId: string;
};

const FarmsContainer: React.FC<RouteComponentProps<FarmsContainerProps>> = (
  props
) => {
  const history = useHistory();

  // For farms typeahead search:
  const [searching, setSearching] = useState(false);
  const [searchedFarms, setSearchedFarms] = useState<NameSuggesterResponse[]>(
    []
  );
  const [searchedFarmName, setSearchedFarmName] = useState<
    NameSuggesterResponse[]
  >([]);

  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(true);
  const [farmForDelete, setFarmForDelete] = useState<
    FarmResponse & { numberOfSurveys: number }
  >();
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [company, setCompany] = useState({
    externalId: "",
    name: "",
  });

  const [farms, setFarms] = useState<FarmResponse[]>([]);
  const [fetchedFarms, setFetchedFarms] = useState<FarmResponse[]>([]);
  const [selectedFarm, setSelectedFarm] = useState<FarmResponse>(INITIAL_FARM);
  const [mapPosition, setMapPosition] = useState(INITIAL_MAP_POSITION);
  const [markers, setMarkers] = useState<Marker[]>([]);

  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);
  const { currentUser } = useContext(CurrentUserContext);

  const companyId = props.match.params.companyId;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data: companyResult } = await getMyCompany(companyId);
        setCompany(companyResult);
      } catch (error) {
        showFetchError({
          error: error as AxiosError | Error,
          customMsg: "API error while loading the company.",
          object: "company",
          objectName: "unknown",
          operation: "loaded",
          dispatchAlert,
          dispatchPopup,
          onRetry: () => fetchData(),
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [companyId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data: farmsResult } = await getMyCompanyFarms(companyId, true);
        setFetchedFarms(farmsResult);
        setFarms(farmsResult);
        const newMarkers = farmsResult.map((farm) => {
          return {
            id: farm.externalId,
            lat: farm.latitude,
            lng: farm.longitude,
          };
        });
        setMarkers(newMarkers);
        if (farmsResult.length) {
          setSelectedFarm(farmsResult[0]);
          setMapPosition((mp) => {
            return {
              ...mp,
              lat: farmsResult[0].latitude,
              lng: farmsResult[0].longitude,
            };
          });
        }
      } catch (error) {
        showFetchError({
          error: error as AxiosError | Error,
          customMsg: "API error while loading the farms.",
          object: "farms",
          objectName: "plural",
          operation: "loaded",
          dispatchAlert,
          dispatchPopup,
          onRetry: () => fetchData(),
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [companyId]);

  const handleFarmClick = (farm: FarmResponse) => {
    if (farm === selectedFarm) {
      setSelectedFarm(INITIAL_FARM);
    } else {
      setSelectedFarm(farm);
      setMapPosition((prev) => ({
        ...prev,
        lat: farm.latitude,
        lng: farm.longitude,
        zoom: 5,
      }));
      const main = document.getElementsByClassName("main")[0];

      const scrollTop =
        (main?.scrollHeight / farms.length) * farms.indexOf(farm);

      main.scrollTo({
        top: scrollTop,
        behavior: "smooth",
      });
    }
  };

  const handleFarmMarkerClick = (farmId: string) => {
    const farm = farms.filter((item) => item.externalId === farmId)[0];
    if (farm) {
      handleFarmClick(farm);
    }
  };

  const goToEditFarm = (farmId: string) => {
    history.push(`/companies/${companyId}/farms/${farmId}`);
  };

  const goToCreateFarm = () => {
    return history.push(`/companies/${companyId}/farms/new`);
  };

  const goToFarmUsers = (farmId: string) => {
    return history.push(`/companies/${companyId}/farms/${farmId}/users`);
  };

  const renderDeleteModal = async (e: React.MouseEvent, farm: FarmResponse) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleting(true);
    setDeleteModalShow(true);

    const farmWithSurveysNumber = { ...farm, numberOfSurveys: 0 };
    setFarmForDelete(farmWithSurveysNumber);

    try {
      const { data: surveysResult } = await getActiveSurveysForFarm(
        companyId,
        farmWithSurveysNumber.externalId
      );

      const numberOfSurveys =
        surveysResult.companies[0]?.farms[0]?.surveys?.length;

      setFarmForDelete({ ...farmWithSurveysNumber, numberOfSurveys });
    } catch (error) {
      showFetchError({
        error: error as AxiosError | Error,
        customMsg: "API error while loading the audits.",
        object: "audits",
        objectName: "plural",
        operation: "loaded",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => renderDeleteModal(e, farm),
      });
    } finally {
      setDeleting(false);
    }
  };

  const handleDeleteFarm = async () => {
    setDeleting(true);
    if (farmForDelete?.externalId) {
      try {
        await deleteFarm(companyId, farmForDelete.externalId);
        showTransientAlert(dispatchAlert, {
          type: ALERT_ACTION_KIND.SHOW_SUCCESS_ALERT,
          text: `Farm ${farmForDelete.name} is deleted!`,
        });

        const newFarms = farms.filter(
          (f) => f.externalId !== farmForDelete.externalId
        );
        setFarms(newFarms);
        const newMarkers = markers.filter(
          (m) => m.id !== farmForDelete.externalId
        );
        setMarkers(newMarkers);
        if (newFarms.length) handleFarmClick(newFarms[0]);
      } catch (error) {
        showFetchError({
          error: error as AxiosError | Error,
          customMsg: "API error while deleting the farm.",
          object: "farm",
          objectName: farmForDelete.name,
          operation: "deleted",
          dispatchAlert,
          dispatchPopup,
          onRetry: () => handleDeleteFarm(),
        });
      } finally {
        setDeleting(false);
        setDeleteModalShow(false);
      }
    }
  };

  const handleSearchSelection = async (e: NameSuggesterResponse[]) => {
    if (!e.length) {
      setSearchedFarmName([]);
      setSearchedFarms([]);
      setFarms(fetchedFarms);
    } else {
      setSearchedFarmName([e[0]]);
      const searchedFarmsList = farms.filter((farm) => farm.name === e[0].name);
      setFarms(searchedFarmsList);
    }
  };

  const onClose = () => {
    window.location.pathname = getOneLevelSmallerUrl();
  };

  const handleOnFarmsSearch = async (query: string) => {
    try {
      setSearching(true);
      const params = { name: query };
      const { data: searchedFarmsResult } = await getCompanyFarmsAutoCorrect(
        currentUser.currentCompany.externalId,
        params
      );
      setSearchedFarms(searchedFarmsResult);
    } catch (error) {
      showFetchError({
        error: error as AxiosError | Error,
        customMsg: "API error while loading the farms.",
        object: "farms",
        objectName: "plural",
        operation: "loaded",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => handleDeleteFarm(),
      });
    } finally {
      setSearching(false);
    }
  };

  return (
    <Container className="farms-container" fluid data-cy="farms-container">
      <Row>
        <Col className="main pb-4 px-4">
          {loading ? (
            <div className="loader-container">
              <Loader status="Loading farms" />
            </div>
          ) : (
            <Row>
              <Col xs={12}>
                <Row>
                  <div className="header mb-5">
                    <div className="w-100 d-flex align-items-start">
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        placement="bottom"
                        overlay={<Tooltip>Back</Tooltip>}
                      >
                        <Button
                          variant="link"
                          className="btn-back"
                          onClick={onClose}
                        >
                          <i className="arrow arrow-lg arrow-gray arrow-left"></i>
                        </Button>
                      </OverlayTrigger>
                      <div className="filter-toolbar">
                        <h4 className="header-title">{company.name}</h4>
                        <p className="smaller mb-0">
                          Total no of farms: {farms.length}
                        </p>
                      </div>
                    </div>
                  </div>
                </Row>
                <Row className="justify-content-between search-container">
                  <Col
                    xs={12}
                    md={6}
                    lg={4}
                    xl={3}
                    className="mb-4 order-1 order-md-0"
                  >
                    <AsyncTypeahead
                      id="farm-typeahead"
                      clearButton
                      isLoading={searching}
                      labelKey="name"
                      options={searchedFarms}
                      placeholder="Search farms"
                      minLength={1}
                      onChange={handleSearchSelection}
                      selected={searchedFarmName}
                      onSearch={handleOnFarmsSearch}
                    />
                  </Col>
                  <Col xs={12} md={4} className="mb-4 order-0 order-md-1">
                    <Button
                      variant="link"
                      className="ms-md-auto d-flex align-items-center btn-white btn-add"
                      onClick={() => goToCreateFarm()}
                      data-cy="add-farm-btn"
                    >
                      <span className="small">
                        <Icon iconString="plus" className="me-1" />
                      </span>
                      Farm
                    </Button>
                  </Col>
                </Row>
              </Col>

              <Col xs={12}>
                {farms.map((farm) => {
                  return (
                    <div
                      className={
                        farm.externalId === selectedFarm.externalId
                          ? "selected surveys-toggle btn btn-link mb-4 py-0"
                          : "surveys-toggle btn btn-link mb-4 py-0"
                      }
                      key={farm.externalId}
                      onClick={() => handleFarmClick(farm)}
                    >
                      <div className="farm">
                        <span className="card-name">{farm.name}</span>
                        <ButtonGroup className="card-actions">
                          <OverlayTrigger
                            trigger={["hover", "focus"]}
                            placement="bottom"
                            overlay={<Tooltip>Edit</Tooltip>}
                          >
                            <Button
                              variant="link"
                              size="sm"
                              onClick={() => goToEditFarm(farm.externalId)}
                              data-cy="edit-farm-btn"
                            >
                              <Icon iconString="edit" />
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            trigger={["hover", "focus"]}
                            placement="bottom"
                            overlay={<Tooltip>Users</Tooltip>}
                          >
                            <Button
                              variant="link"
                              size="sm"
                              onClick={() => goToFarmUsers(farm.externalId)}
                            >
                              <Icon iconString="users" />
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            trigger={["hover", "focus"]}
                            placement="bottom"
                            overlay={<Tooltip>Delete</Tooltip>}
                          >
                            <Button
                              variant="link"
                              size="sm"
                              onClick={(e) => renderDeleteModal(e, farm)}
                              data-cy="delete-farm-btn"
                            >
                              <Icon iconString="trash" />
                            </Button>
                          </OverlayTrigger>
                        </ButtonGroup>
                      </div>
                    </div>
                  );
                })}
              </Col>
            </Row>
          )}
        </Col>

        <Col xs={5} lg={4} className="map px-0 border-left d-none d-md-block">
          <AquaMapBoxGl
            position={mapPosition}
            markers={markers}
            selectedMarkerId={selectedFarm.externalId}
            onMarkerClick={(farmId) => handleFarmMarkerClick(farmId)}
          />
        </Col>
      </Row>
      {farmForDelete && (
        <DeleteConfirmationModal
          show={deleteModalShow}
          onClose={() => setDeleteModalShow(false)}
          onConfirm={handleDeleteFarm}
          loading={deleting}
          title="farm"
        >
          <>
            <p>
              {`${farmForDelete.name} contains ${
                farmForDelete.numberOfSurveys
                  ? farmForDelete.numberOfSurveys
                  : 0
              } audits.`}
            </p>
            <p className="mb-0"> Delete {farmForDelete.name}?</p>
          </>
        </DeleteConfirmationModal>
      )}
    </Container>
  );
};

export default FarmsContainer;
