import React, { useState, useContext } from "react";
import { Row, Col, Button, Form, Image } from "react-bootstrap";
import { AxiosError } from "axios";

import { postContactMe } from "../../network/request";
import { ALERT_ACTION_KIND } from "../../constants/enums";
import { AlertsContext } from "../../contexts/AlertsContext";
import { ErrorPopupContext } from "../../contexts/ErrorPopupContext";
import { ContactUsForm } from "../../models";
import { showFetchError } from "../../utils/alerts";

import longlineLogo from "../../images/landing/longline-logo.svg";
import externalLinkIcon from "../../images/landing/external-link-icon.svg";

const ContactUs: React.FC = () => {
  const calendlyLink = "https://calendly.com/longline/office-hours";

  const [form, setForm] = useState<ContactUsForm>({
    name: "",
    email: "",
    comment: "",
  });
  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    try {
      const formData = new FormData();
      for (const key in form) {
        formData.append(key, form[key as keyof ContactUsForm]);
      }

      await postContactMe(formData);

      dispatchAlert({
        type: ALERT_ACTION_KIND.SHOW_SUCCESS_ALERT,
        text: "Thank you. Your information request has been submitted successfully.",
      });
    } catch (error) {
      showFetchError({
        error: error as AxiosError | Error,
        customMsg:
          "An error occurred submitting the form. Please try again later.",
        object: "form data",
        objectName: "unknown",
        operation: "submitted",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => handleSubmit(event),
      });
    }
  };

  return (
    <Row className="contact-us h-100 justify-content-center align-items-center">
      <Col xs={12} lg={9} className="layout-outer">
        <Row className="justify-content-between">
          <Col xs={12} lg={6} className="mt-5 mt-lg-0">
            <Row className="h-100 flex-column justify-content-between">
              <Col className="contact-us-title flex-grow-0 mb-4 mb-lg-0">
                Interested?
              </Col>
              <Col className="flex-grow-0 mb-5 mb-lg-0">
                Leave us a message using the form provided or book a one-on-one
                demonstration of Aquarisk’s groundbreaking features using the
                button below.
              </Col>
              <Col className="flex-grow-0">
                <a
                  href={calendlyLink}
                  className="btn-lg demo-button me-4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Book a Demo
                </a>
                <Image src={externalLinkIcon}></Image>
              </Col>
              <Col className="d-none d-lg-block flex-grow-0">
                <Row className="justify-content-between">
                  <Col xs={9} lg={8} className="address">
                    <Row className="flex-column justify-content-between h-100">
                      <Col>
                        <div className="address-divider"></div>
                      </Col>
                      <Col>
                        <div className="d-flex justify-content-between flex-nowrap">
                          <div>
                            <h5 className="address-title mb-1">
                              United Kingdom
                            </h5>
                            <p className="mb-0">
                              63 St Mary Axe
                              <br />
                              London, EC3A 8AA
                            </p>
                          </div>
                          <div>
                            <h5 className="address-title mb-1">
                              Republic of Ireland
                            </h5>
                            <p className="mb-0">
                              6-9 Trinity Street
                              <br />
                              Dublin, D02 EY47
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={3} className="text-end ps-0">
                    <Image
                      src={longlineLogo}
                      height="98"
                      className="longline-logo"
                      alt="Longline Environment Logo"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={5} className="my-5 my-lg-0">
            <Form className="form" onSubmit={handleSubmit}>
              <Form.Group controlId="formGroupUsername" className="form-group">
                <Form.Control
                  type="text"
                  name="name"
                  required
                  placeholder="Full Name"
                  value={form.name}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="formGroupEmail" className="form-group">
                <Form.Control
                  type="email"
                  name="email"
                  required
                  placeholder="E-mail"
                  value={form.email}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="formGroupEmail" className="form-group">
                <Form.Control
                  as="textarea"
                  rows={4}
                  name="comment"
                  placeholder="Message"
                  value={form.comment}
                  onChange={handleChange}
                />
              </Form.Group>
              <Button variant="success" type="submit">
                Submit
              </Button>
            </Form>
          </Col>
          <Col xs={12} className="d-block d-lg-none flex-grow-0 mb-5">
            <Row className="justify-content-between">
              <Col xs={9} lg={8} className="address">
                <Row className="flex-column justify-content-between h-100">
                  <Col>
                    <div className="address-divider"></div>
                  </Col>
                  <Col>
                    <div className="d-flex justify-content-between flex-nowrap">
                      <div>
                        <h5 className="address-title mb-1">United Kingdom</h5>
                        <p className="mb-0">
                          63 St Mary Axe
                          <br />
                          London, EC3A 8AA
                        </p>
                      </div>
                      <div>
                        <h5 className="address-title mb-1">
                          Republic of Ireland
                        </h5>
                        <p className="mb-0">
                          6-9 Trinity Street
                          <br />
                          Dublin, D02 EY47
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={3} className="text-end ps-0">
                <Image
                  src={longlineLogo}
                  height="98"
                  className="longline-logo"
                  alt="Longline Environment Logo"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ContactUs;
