import React, { useEffect, useContext, useState } from "react";
import {
  Container,
  Row,
  Col,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Image,
} from "react-bootstrap";
import fileDownload from "js-file-download";

import { AlertsContext } from "../../contexts/AlertsContext";
import { ErrorPopupContext } from "../../contexts/ErrorPopupContext";
import { getTemplates, getTemplate } from "../../network/request";
import pdf_icon_multiple_farms from "../../images/MultiplePDF_icon.svg";
import { showFetchError } from "../../utils/alerts";

import Loader from "../common/Loader";
import Icon from "../common/Icon";
import TemplateUpload from "./TemplateUpload";
import TemplateEdit from "./TemplateEdit";
import TemplatePdf from "../report/TemplatePdf";
import TemplatePdfForMultipleFarms from "../report/TemplatePdfForMultipleFarms";

const TemplatesContainer = () => {
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [hoveredTemplate, setHoveredTemplate] = useState("");
  const [showUpload, setShowUpload] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showTemplatePdf, setShowTemplatePdf] = useState(false);
  const [showTemplatePdfForMultipleFarms, setShowTemplatePdfForMultipleFarms] =
    useState(false);
  const [templateSelected, setTemplateSelected] = useState({});
  const [templateName, setTemplateName] = useState("");

  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data: result } = await getTemplates();
        setTemplates(result);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        showFetchError({
          error,
          customMsg: "API error while loading templates.",
          object: "templates",
          objectName: "plural",
          operation: "loaded",
          dispatchAlert,
          dispatchPopup,
          onRetry: () => fetchData(),
        });
      }
    };

    fetchData();
  }, []);

  const handleAddTemplateClick = () => {
    setTemplateSelected(null);
    setTemplateName("");
    setShowEdit(true);
  };

  const fetchTemplate = async (template) => {
    try {
      const { data: result } = await getTemplate(template.names);
      return result;
    } catch (error) {
      showFetchError({
        error,
        customMsg: "API error while loading the template.",
        object: "template",
        objectName: template.names,
        operation: "loaded",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => handleEditTemplateClick(),
      });
    }
  };

  const handleEditTemplateClick = async (e, template) => {
    e.preventDefault();
    const result = await fetchTemplate(template);
    setTemplateSelected(result);
    setTemplateName(template.names);
    setShowEdit(true);
  };

  const handleTemplatePdfClick = async (e, template) => {
    e.preventDefault();
    const result = await fetchTemplate(template);
    setTemplateSelected(result);
    setTemplateName(template.names);
    setShowTemplatePdf(true);
  };

  const handleMultipleFarmsTemplatePdfClick = async (e, template) => {
    e.preventDefault();
    const result = await fetchTemplate(template);
    setTemplateSelected(result);
    setTemplateName(template.names);
    setShowTemplatePdfForMultipleFarms(true);
  };

  const handleDownloadTemplateClick = async (e, template) => {
    e.preventDefault();
    const result = await fetchTemplate(template);
    fileDownload(JSON.stringify(result), template.names + ".json");
  };

  // const handleDeleteTemplateClick = (e, template) => {
  //   e.preventDefault();
  //   console.log(`delete template ${template.names}`);
  // }

  return loading ? (
    <div className="loader-container">
      <Loader status="Loading templates" />
    </div>
  ) : showUpload ? (
    <TemplateUpload onClose={() => setShowUpload(false)} />
  ) : showEdit ? (
    <TemplateEdit
      templateNameDefault={templateName}
      templateDefault={templateSelected}
      onClose={() => setShowEdit(false)}
    />
  ) : showTemplatePdf ? (
    <TemplatePdf
      template={templateSelected}
      onClose={() => setShowTemplatePdf(false)}
    />
  ) : showTemplatePdfForMultipleFarms ? (
    <TemplatePdfForMultipleFarms
      template={templateSelected}
      onClose={() => setShowTemplatePdf(false)}
    />
  ) : (
    <Container className="templates-container aqua-container" fluid>
      <div className={showUpload ? "hidden" : ""}>
        <ButtonToolbar className=" mb-2">
          <Button
            variant="link"
            className="btn-355 mt-4 mb-3 me-4 d-flex align-items-center btn-white"
            onClick={handleAddTemplateClick}
          >
            <span className="small">
              <Icon iconString="plus" className="me-1" />
            </span>
            Template
          </Button>
          <Button
            variant="link"
            className="btn-355 mt-4 mb-3 d-flex align-items-center btn-white"
            onClick={() => setShowUpload(true)}
          >
            <span className="small">
              <Icon iconString="file-upload" className="me-2" />
            </span>
            Upload Template
          </Button>
        </ButtonToolbar>

        <div className="aqua-container-content cards">
          <Row>
            {templates.map((template) => {
              return (
                <Col key={template.names} md={6} xs={12}>
                  <div
                    className="surveys-toggle d-flex justify-content-between rounded bg-white btn btn-link mb-4 py-0 align-items-center"
                    onMouseEnter={() => setHoveredTemplate(template.names)}
                    onMouseLeave={() => setHoveredTemplate("")}
                  >
                    <div className="card-name">{template.names}</div>

                    <ButtonGroup
                      className={
                        hoveredTemplate === template.names
                          ? "card-actions"
                          : "card-actions"
                      }
                    >
                      <Button
                        variant="link"
                        size="sm"
                        onClick={(e) => handleEditTemplateClick(e, template)}
                        title="Edit"
                      >
                        <Icon iconString="edit" />
                      </Button>
                      <Button
                        variant="link"
                        size="sm"
                        onClick={(e) =>
                          handleDownloadTemplateClick(e, template)
                        }
                        title="Download"
                      >
                        <Icon iconString="file-download" />
                      </Button>

                      <Button
                        variant="link"
                        size="sm"
                        onClick={(e) => handleTemplatePdfClick(e, template)}
                        title="PDF"
                      >
                        <Icon iconString="file-pdf" />
                      </Button>

                      <Button
                        variant="link"
                        size="sm"
                        onClick={(e) =>
                          handleMultipleFarmsTemplatePdfClick(e, template)
                        }
                        title="PDF for multiple farms"
                        className="d-flex align-items-center"
                      >
                        <Image
                          src={pdf_icon_multiple_farms}
                          alt="PDF for multiple farms"
                          height="14px"
                        />
                      </Button>
                      {/* <Button
                            variant="info"
                            size="sm"
                            onClick={(e) => handleDeleteTemplateClick(e, template)}
                            title="Delete">
                            <Icon iconString="trash" />
                          </Button> */}
                    </ButtonGroup>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default TemplatesContainer;
