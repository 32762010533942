export const QUESTION_STATUS = {
  FINISH: "FINISH",
};

export const COMMON = {
  NONE: "NONE",
};

export enum USER_ROLES_ENUM {
  SURVEYOR = "ROLE_SURVEYOR",
  COMPANY = "ROLE_COMPANY",
  ADMIN = "ROLE_ADMIN",
  MANAGER = "ROLE_MANAGER",
  USER = "ROLE_USER",
  ADJUSTER = "ROLE_ADJUSTER",
  FARMER = "ROLE_FARMER",
  UNDERWRITER = "ROLE_UNDERWRITER",
}

export enum USER_ROLES {
  ROLE_SURVEYOR = "Auditor",
  ROLE_COMPANY = "Company",
  ROLE_ADMIN = "Admin",
  ROLE_MANAGER = "Manager",
  ROLE_USER = "User",
  ROLE_ADJUSTER = "Adjuster",
  ROLE_FARMER = "Farmer",
  ROLE_UNDERWRITER = "Underwriter",
}

export const MEDIA_CATEGORY = Object.freeze({
  DRONE: "drone",
  SUBMARINE: "submarine",
});

export enum RISK_LEVELS_ENUM {
  HIGH = "HIGH",
  MODERATE_HIGH = "MODERATE_HIGH",
  MODERATE = "MODERATE",
  MODERATE_LOW = "MODERATE_LOW",
  LOW = "LOW",
  UNKNOWN = "UNKNOWN",
}

export const CATEGORIES = Object.freeze({
  ALL: "All categories",
  FISH_HEALTH: "Fish Health",
  ENVIRONMENT: "Environment",
  ENGINEERING: "Engineering",
  GOVERNANCE: "Governance",
  HUSBANDRY: "Husbandry",
});

// it should match with the colors in the bs_variables.scss file
export const HEX_COLORS = Object.freeze({
  RED: "#E94A52",
  ORANGE: "#F6A44E",
  YELLOW: "#E9DB64",
  CYAN: "#35ABE2",
  GREEN: "#76B72D",
  BLUE: "#3C698A",
  GRAY: "#aaacae",
  LIGHTGRAY: "#eaeff3",
  WHITE: "#ffffff",
});

export const CHARTS_ENUM = Object.freeze({
  ALL_FARMS: "All Farms",
  RISK_SCORE: "Risk Score",
  ANIMAL_COUNT: "Animal Count",
  TONNAGE: "Tonnage",
  AVG_VALUE: "Average Value",
  MAX_VALUE: "Max Value",
});

// Lists

export const RISK_LEVELS = [
  { key: RISK_LEVELS_ENUM.HIGH, display: "High" },
  { key: RISK_LEVELS_ENUM.MODERATE_HIGH, display: "Moderate High" },
  { key: RISK_LEVELS_ENUM.MODERATE, display: "Moderate" },
  { key: RISK_LEVELS_ENUM.MODERATE_LOW, display: "Moderate Low" },
  { key: RISK_LEVELS_ENUM.LOW, display: "Low" },
];

export const RISK_LEVELS_WITH_ALL = [
  { key: null, display: "All" },
  ...RISK_LEVELS,
];

export const CHARTS = [
  { type: CHARTS_ENUM.ALL_FARMS, title: CHARTS_ENUM.ALL_FARMS },
  { type: CHARTS_ENUM.RISK_SCORE, title: CHARTS_ENUM.RISK_SCORE },
  { type: CHARTS_ENUM.ANIMAL_COUNT, title: CHARTS_ENUM.ANIMAL_COUNT },
  { type: CHARTS_ENUM.TONNAGE, title: CHARTS_ENUM.TONNAGE },
  { type: CHARTS_ENUM.AVG_VALUE, title: CHARTS_ENUM.AVG_VALUE + " ($)" },
  { type: CHARTS_ENUM.MAX_VALUE, title: CHARTS_ENUM.MAX_VALUE + " ($)" },
];

export const CATEGORIES_FILTERS = [
  { type: CATEGORIES.ALL, title: CATEGORIES.ALL },
  { type: CATEGORIES.FISH_HEALTH, title: CATEGORIES.FISH_HEALTH },
  { type: CATEGORIES.ENVIRONMENT, title: CATEGORIES.ENVIRONMENT },
  { type: CATEGORIES.ENGINEERING, title: CATEGORIES.ENGINEERING },
  { type: CATEGORIES.GOVERNANCE, title: CATEGORIES.GOVERNANCE },
  { type: CATEGORIES.HUSBANDRY, title: CATEGORIES.HUSBANDRY },
];

export enum ALERT_ACTION_KIND {
  SHOW_SUCCESS_ALERT = "SHOW_SUCCESS_ALERT",
  SHOW_ERROR_ALERT = "SHOW_ERROR_ALERT",
  CLEAR_ALERTS = "CLEAR_ALERTS",
}

export enum ERROR_POPUP_ACTION_KIND {
  SHOW_ERROR_POPUP = "SHOW_ERROR_POPUP",
  CLOSE_POPUP = "CLOSE_POPUP",
}

export enum AUTH_ACTION_KIND {
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
}

export enum CURRENT_USER_ACTION_KIND {
  SET_USER = "SET_USER",
  SET_CURRENT_COMPANY = "SET_CURRENT_COMPANY",
  CLEAR_USER = "CLEAR_USER",
}

export enum ACTIVITY_METHODS {
  ADD = "ADD",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  LAUNCH = "LAUNCH",
  UPLOAD = "UPLOAD",
  DOWNLOAD = "DOWNLOAD",
  ADD_USER = "ADD_USER",
  UPDATE_USER = "UPDATE_USER",
  DELETE_USER = "DELETE_USER",
}

export enum ACTIVITY_TARGETS {
  SURVEY = "SURVEY",
  TEMPLATE = "TEMPLATE",
  FARM = "FARM",
  COMPANY = "COMPANY",
  USER = "USER",
  ACTION_ITEM = "ACTION_ITEM",
  ACTION_ITEM_DETAIL = "ACTION_ITEM_DETAIL",
  COMPANY_USER = "COMPANY_USER",
  COMPANY_FARM = "COMPANY_FARM",
  SURVEY_USER = "SURVEY_USER",
}

export enum MEDIA_CATEGORY_ENUM {
  DRONE = "drone",
  SUBMARINE = "submarine",
  VIDEO = "video",
  MAP = "map",
}
