import React, { useEffect, useState } from "react";
import { Form, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Icon from "../common/Icon";

const MultiSelectForm = ({ onSave, answer, answerOptions }) => {
  const [sandbox, setSandbox] = useState([]);
  const [error, setError] = useState("");
  const [selectedAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (!answer || !answer.answerStr) return;
    const selected = answer.answerStr
      .replace(/\[/, "")
      .replace(/\]/, "")
      .replace(/\s+/, "")
      .split(",")
      .filter((item) => item.length > 0)
      .map((item) => item.replace(/\s+/, ""));
    const selectedUniq = [...new Set(selected)];
    setSandbox(selectedUniq);
    const emptySandbox = [];
    answerOptions.forEach((el, i) => {
      emptySandbox.push(i.toString());
    });
    const uniqueAnswerOptions = [...new Set(emptySandbox)];
    if (JSON.stringify(selectedUniq) === JSON.stringify(uniqueAnswerOptions)) {
      setSelectAll(true);
    }
  }, [answer, answerOptions]);

  const handleChange = (e) => {
    if (e.target.checked) {
      const newSandbox = [...sandbox, e.target.value];
      const newSandboxUniq = [...new Set(newSandbox)];
      setSandbox(newSandboxUniq);
    } else {
      setSandbox(sandbox.filter((item) => item !== e.target.value));
    }
  };

  const handleSelectAllClick = (e) => {
    if (e.target.checked) {
      setSelectAll(true);
      const newSandbox = [];
      answerOptions.forEach((el, i) => {
        newSandbox.push(i.toString());
      });
      setSandbox(newSandbox);
    } else {
      setSelectAll(false);
      setSandbox([]);
    }
  };

  const isValid = () => {
    setError("");

    if (sandbox.length) return true;
    setError("An option must be selected");
    return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isValid()) return;
    onSave(sandbox);
  };

  return (
    <Form
      id="question-form"
      className="survey-answer-form d-flex flex-wrap"
      onSubmit={handleSubmit}
    >
      {answerOptions.map((answerOption) => {
        return (
          <Form.Check
            key={answerOption.ordinal}
            type="checkbox"
            custom
            className="col-12 col-sm-6 col-xl-4 mb-1"
          >
            <Form.Check.Input
              value={answerOption.ordinal}
              id={answerOption.text}
              checked={sandbox.includes(answerOption.ordinal.toString())}
              onChange={handleChange}
            />
            <Form.Check.Label htmlFor={answerOption.text}>
              {answerOption.text}
              {answerOption.hint ? (
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="bottom"
                  overlay={<Tooltip>{answerOption.hint}</Tooltip>}
                >
                  <Button variant="link" onClick={null} className="px-1 py-0">
                    <Icon iconString="info-circle" />
                  </Button>
                </OverlayTrigger>
              ) : null}
            </Form.Check.Label>
          </Form.Check>
        );
      })}

      <Form.Check
        type="checkbox"
        custom
        className="d-block col-12 col-xl-4 my-3"
      >
        <Form.Check.Input
          value={selectedAll}
          id="select-all"
          checked={selectedAll}
          onChange={handleSelectAllClick}
        />
        <Form.Check.Label htmlFor={"select-all"}>Select All</Form.Check.Label>
      </Form.Check>

      {error.length ? <div className="error">{error}</div> : null}
    </Form>
  );
};

export default MultiSelectForm;
