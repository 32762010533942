import { AlertState, AlertReducerAction } from "../models";
import { ALERT_ACTION_KIND } from "../constants/enums";

export const alertsReducer = (
  state: AlertState[],
  action: AlertReducerAction
): AlertState[] => {
  switch (action.type) {
    case ALERT_ACTION_KIND.SHOW_SUCCESS_ALERT:
      return [
        ...state,
        {
          text: action.text,
          variant: "success",
        },
      ];

    case ALERT_ACTION_KIND.SHOW_ERROR_ALERT:
      return [
        ...state,
        {
          text: action.text,
          variant: "danger",
        },
      ];

    case ALERT_ACTION_KIND.CLEAR_ALERTS:
      return [];

    default:
      return state;
  }
};
