import axios, { AxiosResponse } from "axios";
import { API, getUserHeaders } from "../constants/requestConstants";
import * as ResponseModel from "../models/be_models";

export const getMe = (): Promise<
  AxiosResponse<ResponseModel.UserAccountSummaryResponse>
> => {
  const url = `${API}/user/me`;

  return axios.get<ResponseModel.UserAccountSummaryResponse>(url, {
    headers: getUserHeaders(),
  });
};

export const getUserFarms = (
  //API_GET_USER_FARMS
  extId: string
): Promise<AxiosResponse<ResponseModel.UserFarmResponse[]>> => {
  // getUserFarmsByExtId
  const url = `${API}/user/${extId}/farm`;

  return axios.get<ResponseModel.UserFarmResponse[]>(url, {
    headers: getUserHeaders(),
  });
};

// /api/v1/user/company getMyCompanies //is not used

// /api/v1/user/company/{companyExtId/farm} getMyFarmsForCompany //is not used

// /api/v1/user/email/{userEmail} getUserByEmail //is not used

// /api/v1/user/email/{userEmail}/farm getUserFarmsByEmail //is not used

// /api/v1/user/farm getMyFarms //is not used

// /api/v1/user/me/authorization getMyAuthorizations //is not used

// /api/v1/user/me/landing getMyLandingPage //is not used
