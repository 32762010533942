import React, { useContext, useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  ButtonGroup,
  OverlayTrigger,
  Tooltip,
  Container,
} from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { AxiosError } from "axios";

import { AlertsContext } from "../../contexts/AlertsContext";
import { ErrorPopupContext } from "../../contexts/ErrorPopupContext";
import {
  getUsersForSurvey,
  getSurvey,
  postCreateSurveyUser,
  deleteUserFromSurvey,
  getCompanyUsersAutoCorrect,
} from "../../network/request";
import { showTransientAlert, showFetchError } from "../../utils/alerts";
import { ALERT_ACTION_KIND } from "../../constants/enums";
import {
  NameSuggesterResponse,
  SurveyInfoResponse,
  UserInfoResponse,
} from "../../models/be_models";

import Loader from "../common/Loader";
import Icon from "../common/Icon";

type SurveyUsersProps = {
  companyId: string;
  selectedSurveyExtId: string;
  onClose: () => void;
};

const SurveyUsers: React.FC<SurveyUsersProps> = ({
  companyId,
  selectedSurveyExtId,
  onClose,
}) => {
  const [loading, setLoading] = useState(true);
  const [searching, setSearching] = useState(false);
  const [surveyUsers, setSurveyUsers] = useState<UserInfoResponse[]>([]);
  const [currentSurvey, setCurrentSurvey] = useState<SurveyInfoResponse>();
  const [searchedUsers, setSearchedUsers] = useState<NameSuggesterResponse[]>(
    []
  );
  const [reloadTrigger, setReloadTrigger] = useState(0);

  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      try {
        setLoading(true);
        const { data: surveyUsersResult } = await getUsersForSurvey(
          selectedSurveyExtId
        );

        if (isSubscribed) {
          setSurveyUsers(surveyUsersResult);
          setLoading(false);
        }
      } catch (error) {
        if (isSubscribed) {
          setLoading(false);
          showFetchError({
            error: error as Error | AxiosError,
            customMsg: "API error while loading the users.",
            object: "users",
            objectName: "plural",
            operation: "loaded",
            dispatchAlert,
            dispatchPopup,
            onRetry: () => fetchData(),
          });
        }
      }
    };

    fetchData();

    return () => {
      isSubscribed = false;
    };
  }, [selectedSurveyExtId, reloadTrigger]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      try {
        setLoading(true);
        const { data: result } = await getSurvey(selectedSurveyExtId);
        if (isSubscribed) {
          setCurrentSurvey(result);
          setLoading(false);
        }
      } catch (error) {
        if (isSubscribed) {
          setLoading(false);
          showFetchError({
            error: error as Error | AxiosError,
            customMsg: "API error while loading the audit.",
            object: "audit",
            objectName: "unknown",
            operation: "loaded",
            dispatchAlert,
            dispatchPopup,
            onRetry: () => fetchData(),
          });
        }
      }
    };

    fetchData();

    return () => {
      isSubscribed = false;
    };
  }, [selectedSurveyExtId]);

  const handleSearchSelection = async (e: NameSuggesterResponse[]) => {
    try {
      setLoading(true);
      const params = { username: e[0].name, email: e[0].name };
      await postCreateSurveyUser(selectedSurveyExtId, params);
      setReloadTrigger(reloadTrigger + 1);
      showTransientAlert(dispatchAlert, {
        type: ALERT_ACTION_KIND.SHOW_SUCCESS_ALERT,
        text: `${e[0].name} added to audit.`,
      });
    } catch (error) {
      showFetchError({
        error: error as Error | AxiosError,
        customMsg: "API error while adding the user to the audit.",
        object: "user",
        objectName: "unknown",
        operation: "added",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => handleSearchSelection(e),
      });
    } finally {
      setLoading(false);
    }
  };

  const removeUserFromSurvey = async (user: UserInfoResponse) => {
    try {
      setLoading(true);
      await deleteUserFromSurvey(selectedSurveyExtId, user.externalId);
      setReloadTrigger(reloadTrigger + 1);
      setLoading(false);
      showTransientAlert(dispatchAlert, {
        type: ALERT_ACTION_KIND.SHOW_SUCCESS_ALERT,
        text: `${user.email} removed from audit.`,
      });
    } catch (error) {
      showFetchError({
        error: error as Error | AxiosError,
        customMsg: "API error while deleting the user from the audit.",
        object: "user",
        objectName: user.email,
        operation: "deleted",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => removeUserFromSurvey(user),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleOnUserSearch = async (query: string) => {
    try {
      setSearching(true);
      const params = { name: query };
      const { data: searchedUsersResult } = await getCompanyUsersAutoCorrect(
        companyId,
        params
      );
      setSearchedUsers(searchedUsersResult);
    } catch (error) {
      showFetchError({
        error: error as Error | AxiosError,
        customMsg: "API error while loading the users.",
        object: "users",
        objectName: "plural",
        operation: "loaded",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => handleOnUserSearch(query),
      });
    } finally {
      setSearching(false);
    }
  };

  return (
    <Col className="farms-container main pb-5">
      {loading ? (
        <Row className="loader-container">
          <Loader status="Loading users" />
        </Row>
      ) : (
        <Row>
          <Col xs={12}>
            <div className="header  mb-5">
              <div className="w-100 d-flex align-items-start">
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="bottom"
                  overlay={<Tooltip>Back</Tooltip>}
                >
                  <Button variant="link" className="btn-back" onClick={onClose}>
                    <i className="arrow arrow-lg arrow-gray arrow-left"></i>
                  </Button>
                </OverlayTrigger>
                <div className="filter-toolbar">
                  <h4 className="header-title">{currentSurvey?.name}</h4>
                  <p className="smaller mb-0">
                    Total no of users: {surveyUsers.length}
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Container className="ps-md-5">
            <Col xs={12} className="ps-md-5">
              {surveyUsers.map((surveyUser) => {
                return (
                  <div
                    className="surveys-toggle btn btn-link mb-4 py-0"
                    key={surveyUser.email}
                  >
                    <div className="farm">
                      <span className="card-name d-flex flex-wrap">
                        <span className="text-nowrap me-3">
                          {surveyUser.fullName}
                        </span>
                        <span className="fw-light me-3">/</span>
                        <span className="fw-light me-3">
                          {surveyUser.email}
                        </span>
                      </span>
                      <ButtonGroup className="card-actions text-nowrap">
                        <OverlayTrigger
                          trigger={["hover", "focus"]}
                          placement="bottom"
                          overlay={<Tooltip>Remove user from audit</Tooltip>}
                        >
                          <Button
                            variant="link"
                            size="sm"
                            onClick={() => removeUserFromSurvey(surveyUser)}
                          >
                            <Icon iconString="trash" />
                          </Button>
                        </OverlayTrigger>
                      </ButtonGroup>
                    </div>
                  </div>
                );
              })}

              <AsyncTypeahead
                id="user-typeahead"
                isLoading={searching}
                labelKey="name"
                options={searchedUsers}
                placeholder="Add user to audit"
                minLength={1}
                onChange={handleSearchSelection}
                selected={[]}
                onSearch={handleOnUserSearch}
              />
            </Col>
          </Container>
        </Row>
      )}
    </Col>
  );
};

export default SurveyUsers;
