import axios, { AxiosError } from "axios";
import * as Sentry from "@sentry/react";

type ErrorHandlerResponse = {
  message: string;
  type: "popup" | "alert";
  showRetryBtn?: boolean;
};

/** Which object is fetching? Farm|Audit|User|Company...
 * object: string;
 *
 * Name of fetching object. Bali Barramundi | Lake Cajon
 * errorHandler manages such cases:
 * string - name of object.
 * "unknown" - name of object is unknown,
 * "plural" - we have plural form of object. Name does not exists.
 * objectName: string | "unknown" | "plural";
 *
 * Which operation is performed on the object? Get | Save | Delete | Edit
 * operation?: string; */

const popupMessage404 = (
  object: string,
  objectName: string | "unknown" | "plural"
) => {
  let mainMessage = "";

  switch (objectName) {
    case "unknown":
      mainMessage = `The ${object} was not found on the server.`;
      break;
    case "plural":
      mainMessage = `The ${object} were not found on the server.`;
      break;
    default:
      mainMessage = `The ${object} ${objectName} was not found on the server.`;
  }

  return `<div>
    <p class="text-water fw-light">Not found</p>
    <p>${mainMessage}</p>
    <p>Possibly it was removed by another user or process.</p>
  </div>`;
};

const popupMessage451 = (
  object: string,
  objectName: string | "unknown" | "plural"
) => {
  let mainMessage = "";

  switch (objectName) {
    case "unknown":
    case "plural":
      mainMessage = `Access to the ${object} is denied.`;
      break;
    default:
      mainMessage = `Access to the ${object} ${objectName} is denied.`;
  }

  return `<div>
    <p class="text-water fw-light">Unavailable for legal reasons</p>
    <p>${mainMessage}</p>
    <p>For more information, please contact the system administrator:</p>
    <p><a class="text-water" href="mailto:webmaster@aquarisk.com">webmaster@aquarisk.com</a></p>
  </div>`;
};

const popupMessage500 = () => {
  return `<div>
    <p class="text-water fw-light">Internal server error</p>
    <p>If this problem persists, please contact the system administrator</p>
    <p><a class="text-water" href="mailto:webmaster@aquarisk.com">webmaster@aquarisk.com</a></p>
  </div>`;
};

const popupMessage503 = (
  object: string,
  objectName: string | "unknown" | "plural",
  operation: string
) => {
  let mainMessage = "";

  switch (objectName) {
    case "unknown":
    case "plural":
      mainMessage = `The ${object} could not be ${operation}.`;
      break;
    default:
      mainMessage = `The ${object} ${objectName} could not be ${operation}.`;
  }

  return `<div>
    <p class="text-water fw-light">Service unavailable</p>
    <p>${mainMessage}</p>
    <p>Please cancel the request or try again.</p>
  </div>`;
};

export const errorHandler = (
  error: AxiosError | Error,
  customMsg: string,
  responseObject: {
    object: string;
    objectName: string;
    operation: string;
  }
): ErrorHandlerResponse => {
  if (localStorage.currentUser) {
    const currentUser = JSON.parse(localStorage.currentUser);
    Sentry.configureScope((scope) => {
      scope.setUser(currentUser);
    });
  }
  if (error.message) {
    Sentry.captureMessage(error.message);
  }
  Sentry.captureException(error);

  if (axios.isAxiosError(error)) {
    if (error.code === "ERR_NETWORK") {
      return {
        message: "Internet connection lost",
        type: "alert",
      };
    }

    if (error.code === "ERR_CANCELED") {
      return {
        message: "Canceled by user",
        type: "alert",
      };
    }

    if (error.response) {
      switch (error.response.status) {
        case 500: {
          return {
            message: popupMessage500(),
            type: "popup",
            showRetryBtn: false,
          };
        }
        case 503: {
          return {
            message: popupMessage503(
              responseObject.object,
              responseObject.objectName,
              responseObject.operation
            ),
            type: "popup",
            showRetryBtn: true,
          };
        }
        case 404: {
          return {
            message: popupMessage404(
              responseObject.object,
              responseObject.objectName
            ),
            type: "popup",
            showRetryBtn: false,
          };
        }
        case 451: {
          return {
            message: popupMessage451(
              responseObject.object,
              responseObject.objectName
            ),
            type: "popup",
            showRetryBtn: false,
          };
        }
        default:
          console.error(error);
          return {
            message: customMsg || "Unexpected API error",
            type: "alert",
          };
      }
    }

    console.error(error);
    return {
      message: customMsg || "Unexpected error while sending the request.",
      type: "alert",
    };
  }

  console.error(error);
  return {
    message: "Unexpected error",
    type: "alert",
  };
};
