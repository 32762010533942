import React, { useContext, useState } from "react";
import {
  Container,
  Row,
  Col,
  ButtonToolbar,
  Button,
  Form,
} from "react-bootstrap";

import { AlertsContext } from "../../contexts/AlertsContext";
import { ErrorPopupContext } from "../../contexts/ErrorPopupContext";
import { postUploadTemplateFile } from "../../network/request";
import { ALERT_ACTION_KIND } from "../../constants/enums";
import { showTransientAlert, showFetchError } from "../../utils/alerts";

import Loader from "../common/Loader";

const TemplateUpload = ({ onClose }) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    name: "",
    file: null,
  });

  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);

  const handleChange = (e) => {
    if (e.target.name === "file") {
      setForm({ ...form, [e.target.name]: e.target.files[0] });
    } else {
      setForm({ ...form, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("name", form.name.replace(/\s+/g, "-")); //.toLowerCase());
      formData.append("file", form.file);

      await postUploadTemplateFile(formData);
      setLoading(false);
      showTransientAlert(dispatchAlert, {
        type: ALERT_ACTION_KIND.SHOW_SUCCESS_ALERT,
        text: "Template created!",
      });
      onClose();
    } catch (error) {
      setLoading(false);
      showFetchError({
        error,
        customMsg: "API error while uploading the template file.",
        object: "template",
        objectName: "unknown",
        operation: "uploaded",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => handleSubmit(e),
      });
    }
  };

  return (
    <Container className="aqua-container template-upload-container">
      <Row className="pt-4">
        <Col xs={12}>
          <div className="header">
            <Button variant="link" className="btn-back" onClick={onClose}>
              <i className="arrow arrow-lg arrow-gray arrow-left"></i>
            </Button>
            <div className="w-100 d-flex align-items-start">
              <div className="filter-toolbar mb-2">
                <h4 className="header-title">Create Template</h4>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Form className="row mt-5 template-form" onSubmit={handleSubmit}>
        <Form.Group
          controlId="formGroupName"
          className="form-group col-12 col-md-6"
        >
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" name="name" onChange={handleChange} />
        </Form.Group>

        <Form.Group controlId="formGroupFile" className="form-group col-12">
          <Form.Label>File</Form.Label>
          <Form.Control
            type="file"
            accept=".json,.csv"
            name="file"
            onChange={handleChange}
          />
        </Form.Group>

        {loading ? (
          <Loader status="Saving" />
        ) : (
          <ButtonToolbar className="col-12 form-group justify-content-between mt-5">
            <Button variant="secondary" size="sm" type="submit">
              Save
            </Button>
          </ButtonToolbar>
        )}
      </Form>
    </Container>
  );
};

export default TemplateUpload;
