import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type IconProps = {
  /** String name of Font Awesome Icon
   * Can be seen here: /node_modules/@fortawesome/fontawesome-common-types/index.d.ts */
  iconString: IconProp;
  /* Custom css classes for the Icon */
  className?: string;
};

const Icon: React.FC<IconProps> = ({ iconString, className = "" }) => {
  return <FontAwesomeIcon className={className} icon={iconString} />;
};

export default Icon;
