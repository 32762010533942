import React, { useState, useContext, useRef } from "react";
import { Modal, Button, Row, Col, Spinner } from "react-bootstrap";
import { AxiosError } from "axios";

import { postUploadEnvironmentFile } from "../../network/request";
import { ALERT_ACTION_KIND } from "../../constants/enums";
import { AlertsContext } from "../../contexts/AlertsContext";
import { ErrorPopupContext } from "../../contexts/ErrorPopupContext";
import { showTransientAlert, showFetchError } from "../../utils/alerts";
import { byteToMb } from "../../utils/math";

import Icon from "../common/Icon";

type UploadEnvironmentFileModalProps = {
  selectedSurveyExtId: string;
  // should the modal be shown?
  show: boolean;
  // triggered when close button is clicked
  onHide: () => void;
};

const UploadEnvironmentFileModal: React.FC<UploadEnvironmentFileModalProps> = ({
  selectedSurveyExtId,
  show,
  onHide,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);

  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);
  const controller = useRef<AbortController | null>(null);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setFile(file);
    }
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
    file: File
  ) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("surveyExtId", selectedSurveyExtId);
      formData.append("file", file);

      controller.current = new AbortController();

      await postUploadEnvironmentFile(formData, controller.current.signal);

      showTransientAlert(dispatchAlert, {
        type: ALERT_ACTION_KIND.SHOW_SUCCESS_ALERT,
        text: "Farm environment uploaded!",
      });
    } catch (error) {
      showFetchError({
        error: error as Error | AxiosError,
        customMsg: "API error while uploading the file.",
        object: "file",
        objectName: "unknown",
        operation: "uploaded",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => handleSubmit(e, file),
      });
    } finally {
      setLoading(false);
      setFile(null);
      onHide();
    }
  };

  const cancelUpload = (
    setFile: React.Dispatch<React.SetStateAction<File | null>>
  ) => {
    controller.current && controller.current.abort();
    setFile(null);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="justify-content-center">
        <Modal.Title className="fw-semi">Upload Environmental Data</Modal.Title>
      </Modal.Header>

      <Modal.Body className="py-10">
        <div className="input-wrapper">
          <form
            onSubmit={(e) => {
              if (file) handleSubmit(e, file);
            }}
            className="form"
          >
            <Row className="justify-content-between flex-nowrap">
              <Col xs={4} md={5} className="align-self-center py-2">
                <div className="d-flex align-items-center">
                  <div className="file-name">{file ? file.name : ""}</div>
                </div>
              </Col>
              {loading ? (
                <Col className="px-0 d-flex justify-content-center">
                  <Spinner animation="border" className="wide-border" />
                </Col>
              ) : file ? (
                <Col className="px-0 d-flex justify-content-center">
                  <Button className="file-upload-btn modal-btn" type="submit">
                    <span>Upload</span>
                  </Button>
                </Col>
              ) : (
                <Col className="px-0 d-flex justify-content-center file-input ">
                  <input
                    type="file"
                    name="environmentInput"
                    accept=".csv"
                    id="env-file-input"
                    className="file-input__input"
                    onChange={onChangeHandler}
                  />
                  <label
                    className="file-input__label modal-btn"
                    htmlFor="env-file-input"
                  >
                    <span>Browse</span>
                  </label>
                </Col>
              )}
              <Col xs={4} md={5} className="align-self-center">
                {file && (
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="pe-4 font-italic fw-light">
                      {file ? byteToMb(file.size) : ""}
                    </div>
                    <Button
                      variant="link"
                      className="cancel-button pe-0 me-0"
                      size="sm"
                      onClick={() => cancelUpload(setFile)}
                    >
                      <Icon iconString="times-circle" className="icon" />
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
          </form>
        </div>
      </Modal.Body>

      <Modal.Footer className="justify-content-end">
        <Button variant="link" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadEnvironmentFileModal;
