import React, { createContext, useReducer } from "react";
import { authReducer } from "../reducers/authReducer";
import { AuthContextType } from "../models";

const initialValues = {
  isAuthenticated: false,
  dispatch: (): void => undefined,
};

export const AuthContext = createContext<AuthContextType>(initialValues);

const AuthContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [isAuthenticated, dispatch] = useReducer(
    authReducer,
    !!localStorage.accessToken
  );

  return (
    <AuthContext.Provider value={{ isAuthenticated, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
