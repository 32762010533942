import axios, { AxiosResponse, GenericAbortSignal } from "axios";
import {
  API,
  getUserHeaders,
  getAuthHeaders,
} from "../constants/requestConstants";
import * as Model from "../models/be_models";

export const getListMediaForSurvey = (
  //listMediaForSurvey
  surveyExtId: string,
  latestOnly?: boolean
): Promise<AxiosResponse<Model.MediaInfoResponse[]>> => {
  const url = `${API}/survey/${surveyExtId}/media`;

  return axios.get<Model.MediaInfoResponse[]>(url, {
    headers: getUserHeaders(),
    params: { latestOnly },
    data: {}, //empty data is needed for preventing 415 error.
  });
};

// GET /api/v1/survey/{surveyExtId}/media/{extId}
// downloadMedia

// PUT /api/v1/survey/{surveyExtId}/media/{mediaExtId}
// updateMediaForSurvey

export const deleteMediaForSurvey = (
  surveyExtId: string,
  mediaExtId: string
): Promise<AxiosResponse> => {
  const url = `${API}/survey/${surveyExtId}/media/${mediaExtId}`;

  return axios.delete(url, {
    headers: getUserHeaders(),
    data: {}, //empty data is needed for preventing 415 error.
  });
};

export const getListMediaForSurveyByCategory = (
  //listMediaForSurveyByCategory
  surveyExtId: string,
  category: string,
  latestOnly = true
): Promise<AxiosResponse<Model.MediaInfoResponse[]>> => {
  const url = `${API}/survey/${surveyExtId}/media/category/${category}`;

  return axios.get<Model.MediaInfoResponse[]>(url, {
    headers: getUserHeaders(),
    params: { latestOnly },
    data: {}, //empty data is needed for preventing 415 error.
  });
};

export const postUploadMediaToSurvey = (
  //uploadMediaToSurvey
  surveyExtId: string,
  category: string,
  body: FormData,
  signal?: GenericAbortSignal
): Promise<AxiosResponse<Model.MediaInfoResponse>> => {
  const url = `${API}/survey/${surveyExtId}/media/category/${category}`;

  return axios.post<Model.MediaInfoResponse>(url, body, {
    headers: getAuthHeaders(),
    signal,
  });
};

// GET /api/v1/video/{ticketId}
// downloadVideo
