import aquariskReportLogo from "../images/AquariskReportLogo.jpg";
import aquariskLogo from "../images/AquariskLogo.svg";
import fishhealth from "../images/Fish Health.svg";
import environment from "../images/Environment.svg";
import equipment from "../images/Equipment.svg";
import engineering from "../images/Engineering.svg";
import governance from "../images/Governance.svg";
import husbandry from "../images/Husbandry.svg";

export const AQUARISK_REPORT_LOGO = aquariskReportLogo;

export const AQUARISK_LOGO = aquariskLogo;

export const CATEGORY_ICONS: Record<string, string> = {
  "fish health": fishhealth,
  "animal health": fishhealth,
  environment: environment,
  equipment: equipment, // there is some "Equipment" categories in the old templates. We can remove this line as soon everything will be renamed to "Engineering"
  governance: governance,
  husbandry: husbandry,
  engineering: engineering,
};

export const MAP_MARKER_SVG_PATH = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

export const GEOCODER_SEARCH_ICON_SVG_PATH = `M7.4 2.5c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.9 4.9 4.9c1 0 1.8-.2 2.5-.8l3.7 3.7c.2.2.4.3.8.3.7 0 1.1-.4 1.1-1.1 0-.3-.1-.5-.3-.8L11.4 10c.4-.8.8-1.6.8-2.5.1-2.8-2.1-5-4.8-5zm0 1.6c1.8 0 3.2 1.4 3.2 3.2s-1.4 3.2-3.2 3.2-3.3-1.3-3.3-3.1 1.4-3.3 3.3-3.3z`;
