import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
  ButtonGroup,
} from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useHistory, RouteComponentProps } from "react-router-dom";
import { AxiosError } from "axios";

import { AlertsContext } from "../../contexts/AlertsContext";
import { ErrorPopupContext } from "../../contexts/ErrorPopupContext";
import {
  getFarmUsers,
  postCreateFarmUser,
  deleteFarmUser,
  getMyCompanyFarm,
  getCompanyUsersAutoCorrect,
} from "../../network/request";
import { ALERT_ACTION_KIND } from "../../constants/enums";
import { INITIAL_FARM } from "../../constants/initialData";
import { showTransientAlert, showFetchError } from "../../utils/alerts";
import {
  FarmResponse,
  UserInfo,
  NameSuggesterResponse,
} from "../../models/be_responses_models";

import Loader from "../common/Loader";
import Icon from "../common/Icon";

type FarmUsersContainerProps = {
  farmId: string;
  companyId: string;
};

const FarmUsersContainer: React.FC<
  RouteComponentProps<FarmUsersContainerProps>
> = (props) => {
  const history = useHistory();

  const farmId = props.match.params.farmId;
  const companyId = props.match.params.companyId;

  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);

  const [farm, setFarm] = useState<FarmResponse>(INITIAL_FARM);
  const [farmUsers, setFarmUsers] = useState<UserInfo[]>([]);
  const [searchedUsers, setSearchedUsers] = useState<NameSuggesterResponse[]>(
    []
  );
  const [reloadTrigger, setReloadTrigger] = useState(0);

  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data: farmUsersResult } = await getFarmUsers(companyId, farmId);
        setFarmUsers(farmUsersResult.map((farmUser) => farmUser.user));
        setLoading(false);
      } catch (error) {
        setLoading(false);
        showFetchError({
          error: error as AxiosError | Error,
          customMsg: "API error while loading users.",
          object: "users",
          objectName: "plural",
          operation: "loaded",
          dispatchAlert,
          dispatchPopup,
          onRetry: () => fetchData(),
        });
      }
    };

    fetchData();
  }, [companyId, farmId, reloadTrigger]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data: result } = await getMyCompanyFarm(companyId, farmId);
        setFarm({ ...result });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        showFetchError({
          error: error as AxiosError | Error,
          customMsg: "API error while loading farm details.",
          object: "farm",
          objectName: "unknown",
          operation: "loaded",
          dispatchAlert,
          dispatchPopup,
          onRetry: () => fetchData(),
        });
      }
    };

    if (farmId) fetchData();
  }, [farmId, companyId]);

  const goBack = () => {
    return history.goBack();
  };

  const handleSearchSelection = async (e: NameSuggesterResponse[]) => {
    try {
      setLoading(true);
      const params = { username: e[0].name, email: e[0].name };
      await postCreateFarmUser(companyId, farmId, params);
      setReloadTrigger(reloadTrigger + 1);
      setLoading(false);
      showTransientAlert(dispatchAlert, {
        type: ALERT_ACTION_KIND.SHOW_SUCCESS_ALERT,
        text: `${e[0].name} added to farm.`,
      });
    } catch (error) {
      setLoading(false);
      showFetchError({
        error: error as AxiosError | Error,
        customMsg: "API error while adding the user to the farm.",
        object: "farm user",
        objectName: "unknown",
        operation: "added",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => handleSearchSelection(e),
      });
    }
  };

  const removeUserFromFarm = async (user: UserInfo) => {
    try {
      setLoading(true);
      await deleteFarmUser(companyId, farmId, user.externalId);
      setReloadTrigger(reloadTrigger + 1);
      setLoading(false);
      showTransientAlert(dispatchAlert, {
        type: ALERT_ACTION_KIND.SHOW_SUCCESS_ALERT,
        text: `${user.email} removed from farm.`,
      });
    } catch (error) {
      setLoading(false);
      showFetchError({
        error: error as AxiosError | Error,
        customMsg: "API error while deleting the user from the farm.",
        object: "farm user",
        objectName: "unknown",
        operation: "deleted",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => removeUserFromFarm(user),
      });
    }
  };

  const handleOnUserSearch = async (query: string) => {
    try {
      setSearching(true);
      const params = { name: query };
      const { data: searchedUsersResult } = await getCompanyUsersAutoCorrect(
        companyId,
        params
      );
      setSearchedUsers(searchedUsersResult);
      setSearching(false);
    } catch (error) {
      setSearching(false);
      showFetchError({
        error: error as AxiosError | Error,
        customMsg: "API error while loading the users.",
        object: "users",
        objectName: "plural",
        operation: "loaded",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => handleOnUserSearch(query),
      });
    }
  };

  return (
    <Container className="farms-container main pb-4" fluid>
      {loading ? (
        <div className="loader-container">
          <Loader status="Loading users" />
        </div>
      ) : (
        <Row>
          <Col xs={12}>
            <div className="header mb-5">
              <div className="w-100 d-flex align-items-start">
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="bottom"
                  overlay={<Tooltip>Back</Tooltip>}
                >
                  <Button variant="link" className="btn-back" onClick={goBack}>
                    <i className="arrow arrow-lg arrow-gray arrow-left"></i>
                  </Button>
                </OverlayTrigger>
                <div className="filter-toolbar">
                  <h4 className="header-title">{farm.name}</h4>
                  <p className="smaller mb-0">
                    Total no of users: {farmUsers.length}
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12}>
            {farmUsers.map((farmUser) => {
              return (
                <div
                  className="surveys-toggle btn btn-link mb-4 py-0"
                  key={farmUser.email}
                >
                  <div className="farm">
                    <span className="card-name d-flex flex-wrap">
                      <span className="text-nowrap me-3">
                        {farmUser.fullName}
                      </span>
                      <span className="fw-light me-3">/</span>
                      <span className="fw-light me-3">{farmUser.email}</span>
                    </span>
                    <ButtonGroup className="card-actions text-nowrap">
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        placement="bottom"
                        overlay={<Tooltip>Remove user from farm</Tooltip>}
                      >
                        <Button
                          variant="link"
                          size="sm"
                          onClick={() => removeUserFromFarm(farmUser)}
                        >
                          <Icon iconString="trash" />
                        </Button>
                      </OverlayTrigger>
                    </ButtonGroup>
                  </div>
                </div>
              );
            })}

            <AsyncTypeahead
              id="user-typeahead"
              isLoading={searching}
              labelKey="name"
              options={searchedUsers}
              placeholder="Add user to farm"
              minLength={1}
              onChange={handleSearchSelection}
              selected={[]}
              onSearch={handleOnUserSearch}
            />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default FarmUsersContainer;
