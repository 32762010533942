import React, { useContext, useState } from "react";
import {
  ButtonToolbar,
  Button,
  Row,
  Col,
  Container,
  Spinner,
} from "react-bootstrap";
import { PDFExport } from "@progress/kendo-react-pdf";

import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import { ANSWER_TYPES } from "../../constants/answerTypes";
import { COMMON } from "../../constants/enums";
import {
  CATEGORY_ICONS,
  AQUARISK_REPORT_LOGO,
} from "../../constants/imagesConstants";

import Icon from "../common/Icon";
import PdfFooter from "./PdfFooter";

const pdfRef = React.createRef();

const TemplatePdfForMultipleFarms = ({ template }) => {
  const { currentUser } = useContext(CurrentUserContext);
  const [fileSaving, setFileSaving] = useState(false);

  let report;

  const exportPDF = () => {
    setFileSaving(true);

    report.save(() => setFileSaving(false));
  };

  const pageTemplate = (props) => {
    return <PdfFooter footerName={template.name} pdfProps={props} />;
  };

  const getQuestion = (question) => {
    switch (question.answerType) {
      case ANSWER_TYPES.YES_NO:
        return (
          <Row className="single-select-field yes-no py-3 keep-together">
            <Col xs={4} className="single-select-item">
              <span className="icon"></span>
              <span className="icon"></span>
              <span className="icon"></span>
              <span className="icon"></span>
              <span className="text">YES</span>
            </Col>
            <Col xs={4} className="single-select-item">
              <span className="icon"></span>
              <span className="icon"></span>
              <span className="icon"></span>
              <span className="icon"></span>
              <span className="text">NO</span>
            </Col>
          </Row>
        );
      case ANSWER_TYPES.SINGLE_SELECT:
        return (
          <Row className="single-select-field py-3">
            {question.answerOptions.map((item) => (
              <Col
                xs={6}
                className="single-select-item mb-2 keep-together"
                key={item.id}
              >
                <span className="icon"></span>
                <span className="icon"></span>
                <span className="icon"></span>
                <span className="icon"></span>
                <span className="text">{item.text}</span>
              </Col>
            ))}
          </Row>
        );
      case ANSWER_TYPES.MULTI_SELECT:
        return (
          <div className="multi-select-field py-3">
            <Row>
              {question.answerOptions.map((item) => (
                <Col
                  xs={6}
                  className="multi-select-item mb-2 keep-together"
                  key={item.id}
                >
                  <span className="icon"></span>
                  <span className="icon"></span>
                  <span className="icon"></span>
                  <span className="icon"></span>
                  <span className="text">{item.text}</span>
                </Col>
              ))}
            </Row>
            <div className="multi-select-item mb-2 mt-3">
              <span className="icon"></span>
              <span className="icon"></span>
              <span className="icon"></span>
              <span className="icon"></span>
              <span className="text">Select All</span>
            </div>
          </div>
        );
      case ANSWER_TYPES.SHORT_TEXT:
        return (
          <Row className="text-field py-3 keep-together">
            <Col>
              <div className="field pt-3 text-water">A</div>
              <div className="field pt-1 text-water">B</div>
              <div className="field pt-1 text-water">C</div>
              <div className="field pt-1 text-water">D</div>
            </Col>
          </Row>
        );
      case ANSWER_TYPES.LONG_TEXT:
        return (
          <Row className="long-text-field py-3 keep-together">
            <Col>
              <div className="field">
                <div>A</div>
                <div>B</div>
                <div>C</div>
                <div>D</div>
              </div>
            </Col>
          </Row>
        );
      case ANSWER_TYPES.INTEGER:
      case ANSWER_TYPES.FLOAT:
        return (
          <Row className="number-field py-3 keep-together">
            <Col xs={6}>
              <div className="field text-water">A</div>
              <div className="field pt-1 text-water">B</div>
              <div className="field pt-1 text-water">C</div>
              <div className="field pt-1 text-water">D</div>
            </Col>
            <Col xs={6}>
              <div className="units">
                {question.answerUnits !== COMMON.NONE
                  ? question.answerUnits
                  : ""}
              </div>
            </Col>
          </Row>
        );
      default:
        <div>don&quot;t know</div>;
    }
  };

  return (
    <Container className="aqua-container report-container field-template-pdf field-template-pdf-multiple-farms pt-4">
      <div className="header">
        <ButtonToolbar className="form-group ms-auto">
          <Button
            variant="primary"
            size="sm"
            onClick={() => exportPDF()}
            className="save-pdf-btn"
          >
            {fileSaving ? (
              <>
                <Spinner animation="border" variant="light" size="sm" />
                Downloading
              </>
            ) : (
              <>
                <Icon className="me-1" iconString="download" />
                Download
              </>
            )}
          </Button>
        </ButtonToolbar>
      </div>

      <div className="pdf-export-wrapper mb-5">
        <PDFExport
          paperSize={"A4"}
          fileName={`${template.name}.pdf`}
          title=""
          subject=""
          keywords=""
          ref={(r) => (report = r)}
          pageTemplate={pageTemplate}
          keepTogether={".keep-together"}
          margin={{ bottom: 70, top: 20, left: 0, right: 0 }}
        >
          <div className="report" ref={pdfRef}>
            <Row className="align-items-center pdf-header">
              <Col>
                <h2 className="pdf-title mb-0">{template.name}</h2>
                <div className="pdf-subtitle">
                  {currentUser.currentCompany.name}
                </div>
              </Col>
              <Col className="text-end">
                <img
                  src={AQUARISK_REPORT_LOGO}
                  height="40"
                  alt="AquaRisk Logo"
                  className="img-logo"
                />
              </Col>
            </Row>

            <div className="d-flex justify-content-end mt-3">
              <div className="text-field text-water col-6 p-0 text-uppercase">
                Date
              </div>
            </div>

            <div>
              <div>Farms:</div>
              <div className="text-field text-water">A</div>
              <div className="text-field text-water">B</div>
              <div className="text-field text-water">C</div>
              <div className="text-field text-water">D</div>

              <div className="legend circles d-flex justify-content-center mt-3 mb-2">
                <div className="text-water">A</div>
                <div className="text-water">B</div>
                <div className="text-water">C</div>
                <div className="text-water">D</div>
              </div>

              <div className="legend squares d-flex justify-content-center">
                <div className="text-water">A</div>
                <div className="text-water">B</div>
                <div className="text-water">C</div>
                <div className="text-water">D</div>
              </div>
            </div>

            {template.sections.map((category) =>
              category.steps.map((step) => (
                <div key={step.id}>
                  <Row className="my-4">
                    <Col xs={6}>
                      <div className="step-name pb-2">{step.title}</div>
                    </Col>
                    <Col xs={6}>
                      <Row className="justify-content-around">
                        <Col xs={6} className="text-center">
                          <img
                            src={CATEGORY_ICONS[category?.name.toLowerCase()]}
                            className="img-icon img-icon-md"
                            alt={category.name}
                          />
                        </Col>
                        <Col xs={6} className="category-name">
                          {category.name}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {step.questions.map((question, index) => (
                    <div
                      className="question-section keep-together"
                      key={question.id}
                    >
                      <div className="question-section-title">
                        {index + 1}. {question.text}
                      </div>
                      <div className="question-section-field">
                        {getQuestion(question)}
                      </div>
                    </div>
                  ))}
                </div>
              ))
            )}

            <div className="pb-4"></div>
          </div>
        </PDFExport>
      </div>
    </Container>
  );
};

export default TemplatePdfForMultipleFarms;
