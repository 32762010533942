import { RISK_LEVELS } from "../constants/enums";

export const getRiskLevelClassName = (
  riskLevel: string | null
): string | null => {
  if (!riskLevel) return null;

  return riskLevel.toLowerCase().replace(/_/g, "-");
};

export const getRiskLevelName = (key: string): string => {
  return RISK_LEVELS.find((riskLevel) => riskLevel.key === key)?.display || "";
};

export const changePdfHtmlTags = (htmlString: string): string => {
  htmlString = htmlString.replaceAll("<em>", '<span class="pdf-font-italic">');
  htmlString = htmlString.replaceAll("</em>", "</span>");
  htmlString = htmlString.replaceAll(
    "<strong>",
    '<span class="pdf-font-bold">'
  );
  htmlString = htmlString.replaceAll("</strong>", "</span>");

  return htmlString;
};

export const getOneLevelSmallerUrl = (): string => {
  return window.location.pathname.slice(
    0,
    window.location.pathname.lastIndexOf("/")
  );
};

export const getBaseUrl = (): string => {
  return window.location.pathname.slice(
    0,
    window.location.pathname.indexOf("/")
  );
};
