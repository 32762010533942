import React, { useEffect, useContext, useState } from "react";
import {
  ButtonToolbar,
  Button,
  Col,
  Row,
  OverlayTrigger,
  Tooltip,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { AxiosError } from "axios";

import {
  postSaveAnswer,
  getCurrentQuestion,
  getSurveyToc,
  getJumpToQuestion,
} from "../../network/request";
import { AlertsContext } from "../../contexts/AlertsContext";
import { ErrorPopupContext } from "../../contexts/ErrorPopupContext";
import { showFetchError } from "../../utils/alerts";
import {
  SurveyAnswer,
  SurveyQuestionResponse,
  SurveyTocQuestionResponse,
  SurveyTocSectionResponse,
  SurveyTocStepResponse,
} from "../../models/be_models";

import Loader from "../common/Loader";
import Icon from "../common/Icon";
import SurveyQuestion from "./SurveyQuestion";
import UploadVideoModal from "../general/UploadVideoModal";
import AddNewActionModal from "../general/AddNewActionModal";
import UploadEnvironmentFileModal from "../general/UploadEnvironmentFileModal";

type SurveyProps = {
  selectedSurveyExtId: string;
  onClose: () => void;
};

const Survey: React.FC<SurveyProps> = ({ selectedSurveyExtId, onClose }) => {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [tableOfContents, setTableOfContents] = useState<
    SurveyTocSectionResponse[]
  >([]);
  const [question, setQuestion] = useState<SurveyQuestionResponse>();
  const [actionsCount, setActionsCount] = useState(0);
  const [footageModalShow, setFootageModalShow] = useState(false);
  const [addActionModalShow, setAddActionModalShow] = useState(false);
  const [environmentModalShow, setEnvironmentModalShow] = useState(false);
  const [currentSteps, setCurrentSteps] = useState<SurveyTocStepResponse[]>([]);
  const [currentQuestions, setCurrentQuestions] = useState<
    SurveyTocQuestionResponse[]
  >([]);
  const [lastQuestionId, setLastQuestionId] = useState("");

  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      try {
        setLoading(true);
        const { data: result } = await getCurrentQuestion(selectedSurveyExtId);
        if (isSubscribed) {
          setQuestion(result);
          setActionsCount(result.actionItems.length);
          setLoading(false);
        }
      } catch (error) {
        if (isSubscribed) {
          setLoading(false);
          showFetchError({
            error: error as Error | AxiosError,
            customMsg: "API error while loading the audit question.",
            object: "question",
            objectName: "unknown",
            operation: "loaded",
            dispatchAlert,
            dispatchPopup,
            onRetry: () => fetchData(),
          });
          onClose();
        }
      }
    };

    fetchData();

    return () => {
      isSubscribed = false;
    };
  }, [selectedSurveyExtId]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      try {
        setLoading(true);
        const { data: result } = await getSurveyToc(selectedSurveyExtId);
        if (isSubscribed) {
          setTableOfContents(result.sections);
        }
      } catch (error) {
        if (isSubscribed) {
          setLoading(false);
          showFetchError({
            error: error as Error | AxiosError,
            customMsg: "API error while loading the list of questions.",
            object: "list of questions",
            objectName: "unknown",
            operation: "loaded",
            dispatchAlert,
            dispatchPopup,
            onRetry: () => fetchData(),
          });
        }
      }
    };

    fetchData();

    return () => {
      isSubscribed = false;
    };
  }, [selectedSurveyExtId]);

  useEffect(() => {
    // set currentSteps, currentQuestions for dropdowns
    if (tableOfContents.length && question?.questionContext.sectionName) {
      const steps = tableOfContents.filter((item) => {
        return item.name === question.questionContext.sectionName;
      })[0].steps;
      setCurrentSteps(steps);

      const questions = steps.filter((item) => {
        return item.title === question.questionContext.stepTitle;
      })[0].questions;
      setCurrentQuestions(questions);

      // find lastQuestionId for each step. It is needed for prefilling LongTextForm
      const lastQuestionNumber = questions.length - 1;
      setLastQuestionId(questions[lastQuestionNumber].externalId);
    }
  }, [question, tableOfContents]);

  const handleNavigate = async (
    sectionId: string,
    stepId: string,
    questionId: string
  ) => {
    try {
      setLoading(true);
      const { data: result } = await getJumpToQuestion(
        selectedSurveyExtId,
        sectionId,
        stepId,
        questionId
      );
      setQuestion(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showFetchError({
        error: error as Error | AxiosError,
        customMsg: "API error while moving to the question.",
        object: "question",
        objectName: "unknown",
        operation: "changed",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => handleNavigate(sectionId, stepId, questionId),
      });
    }
  };

  const handleSubmitAnswer = async (
    questionId: string,
    answer: SurveyAnswer
  ) => {
    try {
      setSaving(true);
      const body = {
        questionId: questionId,
        answerValue: answer,
      };
      const { data: result } = await postSaveAnswer(selectedSurveyExtId, body);
      if (result.questionContext) {
        setQuestion(result);
        setSaving(false);
      } else {
        onClose();
        window.location.reload();
      }
    } catch (error) {
      setSaving(false);
      showFetchError({
        error: error as Error | AxiosError,
        customMsg: "API error while saving the answer.",
        object: "answer",
        objectName: "unknown",
        operation: "saved",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => handleSubmitAnswer(questionId, answer),
      });
    }
  };

  const handleUpdateActionsCount = () => {
    setActionsCount((prevState) => prevState + 1);
  };

  return (
    <div
      className="survey main aqua-container survey-results survey-question"
      data-cy="survey-view-container"
    >
      {loading ? (
        <div className="w-100 loader-container">
          <Loader status="Loading question" />
        </div>
      ) : saving ? (
        <div className="w-100 loader-container">
          <Loader status="Saving answer" />
        </div>
      ) : question ? (
        <div className="col-12">
          <div className="header">
            <div className="w-100 d-flex align-items-start">
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="bottom"
                overlay={<Tooltip>Back</Tooltip>}
              >
                <Button variant="link" className="btn-back" onClick={onClose}>
                  <i className="arrow arrow-lg arrow-gray arrow-left"></i>
                </Button>
              </OverlayTrigger>

              <Row className="filter-toolbar justify-content-between w-100 px-3 ps-md-4">
                <Col xs={12} lg={8}>
                  <DropdownButton
                    title={question.questionContext.stepTitle}
                    variant="link"
                    size="sm"
                    className="survey-dropdown indicator-title"
                    data-cy="step-dropdown"
                  >
                    {currentSteps.map(({ externalId, title, questions }) => {
                      return (
                        <Dropdown.Item
                          key={externalId}
                          value={title}
                          active={
                            externalId === question.questionContext.stepId
                          }
                          onClick={() => {
                            handleNavigate(
                              question.questionContext.sectionId,
                              externalId,
                              questions[0].externalId
                            );
                          }}
                        >
                          {title}
                        </Dropdown.Item>
                      );
                    })}
                  </DropdownButton>
                  <DropdownButton
                    title={question.questionContext.sectionName}
                    variant="link"
                    size="sm"
                    className="survey-dropdown category-title"
                    data-cy="section-dropdown"
                  >
                    {tableOfContents.map((item) => {
                      return (
                        <Dropdown.Item
                          key={item.externalId}
                          value={item.name}
                          active={
                            item.externalId ===
                            question.questionContext.sectionId
                          }
                          onClick={() => {
                            handleNavigate(
                              item.externalId,
                              item.steps[0].externalId,
                              item.steps[0].questions[0].externalId
                            );
                          }}
                        >
                          {item.name}
                        </Dropdown.Item>
                      );
                    })}
                  </DropdownButton>
                </Col>

                <Col xs={12} lg={4} className="px-0">
                  <ButtonToolbar className="justify-content-lg-end">
                    <OverlayTrigger
                      trigger={["hover", "focus"]}
                      placement="bottom"
                      overlay={<Tooltip>Upload Environment Data</Tooltip>}
                    >
                      <Button
                        variant="link"
                        size="lg"
                        className="me-0 p-1"
                        onClick={() => setEnvironmentModalShow(true)}
                      >
                        <Icon
                          iconString="cloud-upload-alt"
                          className="h3 mb-0"
                        />
                      </Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                      trigger={["hover", "focus"]}
                      placement="bottom"
                      overlay={<Tooltip>Upload Footage</Tooltip>}
                    >
                      <Button
                        variant="link"
                        size="lg"
                        className="ms-1 pe-0"
                        onClick={() => setFootageModalShow(true)}
                      >
                        <Icon iconString="video" />
                      </Button>
                    </OverlayTrigger>
                  </ButtonToolbar>
                </Col>
              </Row>
            </div>
          </div>

          <div className="survey-body aqua-container-content ps-md-5 d-flex flex-column pb-4">
            <Row className="px-3 mt-5 align-items-center">
              <Col xs={12} lg={8}>
                <DropdownButton
                  title={`${question.questionContext.question.ordinal / 10}. ${
                    question.questionContext.question.name
                  }`}
                  variant="link"
                  size="sm"
                  className="survey-dropdown question-title"
                  data-cy="question-dropdown"
                >
                  {currentQuestions.map((item) => {
                    return (
                      <Dropdown.Item
                        key={item.externalId}
                        value={item.name}
                        active={
                          item.externalId ===
                          question.questionContext.question.id
                        }
                        onClick={() => {
                          handleNavigate(
                            question.questionContext.sectionId,
                            question.questionContext.stepId,
                            item.externalId
                          );
                        }}
                      >
                        {`${item.ordinal / 10}. ${item.name}`}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
              </Col>
              <Col xs={12} lg={4}>
                <Button
                  variant="link"
                  size="sm"
                  className="text-danger d-block ms-lg-auto"
                  onClick={() => setAddActionModalShow(true)}
                >
                  {actionsCount} Actions
                </Button>
              </Col>
            </Row>

            <SurveyQuestion
              question={question}
              lastQuestionId={lastQuestionId}
              onSubmitAnswer={handleSubmitAnswer}
            />

            <ButtonToolbar className="form-group px-0 mt-auto align-self-end mb-0">
              <Button
                form="question-form"
                variant="secondary"
                size="sm"
                type="submit"
                data-cy="submit-btn"
              >
                Save
              </Button>
            </ButtonToolbar>

            <UploadEnvironmentFileModal
              selectedSurveyExtId={selectedSurveyExtId}
              show={environmentModalShow}
              onHide={() => setEnvironmentModalShow(false)}
            />
            <UploadVideoModal
              selectedSurveyExtId={selectedSurveyExtId}
              show={footageModalShow}
              onHide={() => setFootageModalShow(false)}
            />
            <AddNewActionModal
              selectedSurveyExtId={selectedSurveyExtId}
              show={addActionModalShow}
              onHide={() => setAddActionModalShow(false)}
              onUpdateActionCount={handleUpdateActionsCount}
            />
          </div>
        </div>
      ) : (
        <div className="col-12">No question</div>
      )}
    </div>
  );
};

export default Survey;
