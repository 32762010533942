import React, { useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import ReactPlayer, { VimeoConfig } from "react-player/vimeo";
import Icon from "../common/Icon";
import video from "../../images/landing/landing-video.mp4";

const Promo: React.FC = () => {
  const [headline, inViewHeadline] = useInView();
  const [targetContainer, inViewTargetContainer] = useInView();

  const [runFullVideo, setRunFullVideo] = useState<boolean>(false);
  const ref = useRef<ReactPlayer>(null);

  const videoUrl = "https://vimeo.com/668656258";

  const setInView = (inViewSection: boolean) => {
    return inViewSection ? "in-view" : "";
  };

  return (
    <Row className="promo justify-content-center">
      <Col xs={12} className="layout-wrapper">
        <Row className="align-items-center justify-content-center">
          <Col
            xs={12}
            className={`${
              runFullVideo ? "d-none" : ""
            } video-container gx-0 gx-lg-4`}
          >
            <video loop autoPlay={true} muted className="video">
              <source src={video} type="video/mp4" />
            </video>
            <div className="full-video-button">
              <div
                className="full-video-button-inner text-center"
                onClick={() => setRunFullVideo(true)}
              >
                <Icon className="play-icon" iconString="play-circle" />
                <div className="play-text text-uppercase">Watch full video</div>
              </div>
            </div>
          </Col>
          <Col
            xs={12}
            className={`${
              !runFullVideo ? "d-none" : ""
            } video-container gx-0 gx-lg-4`}
          >
            <ReactPlayer
              url={videoUrl}
              className="video"
              width="100%"
              height="100%"
              playing={runFullVideo ? true : false}
              onEnded={() => {
                if (ref.current) ref.current.showPreview();
              }}
              ref={ref}
              config={
                {
                  vimeo: {
                    playerOptions: {
                      allowfullscreen: 1,
                      controls: true,
                    },
                  },
                } as VimeoConfig
              }
            />
          </Col>
        </Row>
        <Row className="flex-column align-items-center justify-content-center">
          <Col xs={12} lg={8} ref={headline} className="layout-inner">
            <h1 className={`headline ${setInView(inViewHeadline)}`}>
              Revolutionizing risk analytics for farming, insurance, and finance
            </h1>
          </Col>
          <Col xs={12} lg={8} className="layout-inner">
            <Row
              className="target-container align-items-center"
              ref={targetContainer}
            >
              <Col
                xs={12}
                md={6}
                className={`target d-none d-md-block ${setInView(
                  inViewTargetContainer
                )}`}
              >
                Risk analytics reinvented
              </Col>
              <Col
                xs={12}
                md={6}
                className={`target-text ${setInView(inViewTargetContainer)}`}
              >
                AquaRisk detects risks before they occur assessing multi-factor
                risk for animal health, environment, engineering, governance and
                husbandry. Our scoring algorithms benchmark risk exposures
                across farming sites for the main aquaculture species and
                rearing systems.
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Promo;
