import React, { useContext, useEffect, useState } from "react";
import { Col, Button, Row, Badge } from "react-bootstrap";
import { AxiosError } from "axios";

import { AlertsContext } from "../../contexts/AlertsContext";
import { ErrorPopupContext } from "../../contexts/ErrorPopupContext";
import { getSurveyScore } from "../../network/request";
import { showFetchError } from "../../utils/alerts";
import { getRiskLevelClassName } from "../../utils/string";
import { CATEGORY_ICONS } from "../../constants/imagesConstants";
import { RISK_LEVELS_ENUM } from "../../constants/enums";
import {
  CategoryResponse,
  ScoreSurveyResultResponse,
  StepResponse,
} from "../../models/be_models";

import Loader from "../common/Loader";
import Tile from "../general/Tile";
import SurveyResult from "./SurveyResult";
import FilterByRiskLevel from "../general/FilterByRiskLevel";
import DownloadWhite from "../../images/DownloadWhite.svg";

type SurveyResultsProps = {
  selectedSurveyExtId: string;
  onClose: () => void;
};

const initialScore = {
  children: [],
  description: "",
  externalId: "",
  guidance: "",
  name: "",
  normScore: 0,
  questions: [],
  risk: "",
  score: 0.0,
  surveyExtId: "",
  title: "",
};

const SurveyResults: React.FC<SurveyResultsProps> = ({
  selectedSurveyExtId,
  onClose,
}) => {
  const [loading, setLoading] = useState(false);
  const [score, setScore] = useState<ScoreSurveyResultResponse>(initialScore);
  const [sectionScores, setSectionScores] = useState<CategoryResponse[]>([]);
  const [sectionId, setSectionId] = useState("");
  const [selectedStep, setSelectedStep] = useState<StepResponse | null>(null);
  const [riskFilter, setRiskFilter] = useState<RISK_LEVELS_ENUM | null>(null);

  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      try {
        setLoading(true);
        const { data: result } = await getSurveyScore(
          selectedSurveyExtId,
          riskFilter ? riskFilter : undefined
        );

        if (isSubscribed) {
          setScore(result);
          if (result.children) setSectionScores(result.children);
          setLoading(false);
        }
      } catch (error) {
        if (isSubscribed) {
          setLoading(false);
          showFetchError({
            error: error as Error | AxiosError,
            customMsg: "API error while loading the audit results.",
            object: "audit results",
            objectName: "plural",
            operation: "loaded",
            dispatchAlert,
            dispatchPopup,
            onRetry: () => fetchData(),
          });
        }
      }
    };

    fetchData();

    return () => {
      isSubscribed = false;
    };
  }, [selectedSurveyExtId, riskFilter]);

  const handleStepClick = (sectionId: string, step: StepResponse) => {
    setSectionId(sectionId);
    setSelectedStep(step);
  };

  const generateReport = () => {
    window.open(`/report/${selectedSurveyExtId}`, "_blank");
  };

  const generateSummary = () => {
    window.open(`/summary/${selectedSurveyExtId}`, "_blank");
  };

  return (
    <Col className="survey-results main aqua-container ">
      {loading ? (
        <div className="w-100 loader-container">
          <Loader status="Loading results" />
        </div>
      ) : selectedStep ? (
        <div className="ps-md-3">
          <SurveyResult
            surveyId={selectedSurveyExtId}
            sectionId={sectionId}
            step={selectedStep}
            onClose={() => setSelectedStep(null)}
          />
        </div>
      ) : (
        <div>
          <div className="header">
            <Button variant="link" className="btn-back" onClick={onClose}>
              <i className="arrow arrow-lg arrow-gray arrow-left"></i>
            </Button>

            <Row className="riskFilter-toolbar">
              <Col xs={12} lg={8}>
                <h4 className="header-title">{score.title}</h4>
              </Col>

              <Col
                xs={12}
                lg={4}
                className="d-flex align-items-center justify-content-lg-end mb-2 mb-lg-0"
              >
                <div
                  className={`radio risk-${getRiskLevelClassName(score.risk)}`}
                ></div>
                <h4 className="header-title mb-0">
                  Score: {score.normScore ? score.normScore.toFixed(1) : "0.0"}%
                </h4>
              </Col>

              <Col
                xs={12}
                className="ps-0 pe-3 justify-content-center justify-content-lg-between"
              >
                <div className="ps-1 pt-3 d-flex flex-column-reverse flex-xl-row flex-nowrap justify-content-center justify-content-lg-between align-items-center">
                  <FilterByRiskLevel
                    filterActionPriority={riskFilter}
                    handleActionPriorityClick={setRiskFilter}
                  />

                  <div className="d-flex mb-0 mb-md-3 mb-xl-0">
                    <Badge
                      bg="link"
                      className="report-download-btn d-flex justify-content-center align-items-center text-white py-2 mt-lg-0 rounded-pill me-2"
                      onClick={() => generateReport()}
                    >
                      <div className="small pe-2 d-flex text-white align-items-center">
                        <img alt="Download" src={DownloadWhite} height="16" />
                      </div>
                      <span className="fw-light">Report</span>
                    </Badge>

                    <Badge
                      bg="link"
                      className="report-download-btn d-flex justify-content-center align-items-center text-white py-2 mt-lg-0 rounded-pill"
                      onClick={() => generateSummary()}
                    >
                      <div className="small pe-2 d-flex text-white align-items-center">
                        <img alt="Download" src={DownloadWhite} height="16" />
                      </div>
                      <span className="fw-light">Summary</span>
                    </Badge>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className="results">
            {sectionScores.map((sectionScore, $index) => {
              const categoryIcon =
                CATEGORY_ICONS[sectionScore.name.toLowerCase()];
              if (sectionScore.children.length) {
                return (
                  <div key={$index}>
                    <div className="section-header">
                      {categoryIcon && (
                        <img
                          src={categoryIcon}
                          className="img-icon-lg mb-3"
                          alt={sectionScore.name}
                        />
                      )}
                      <div>{sectionScore.name}</div>
                    </div>
                    <div className="results-tiles">
                      {sectionScore.children.map((step, $index) => {
                        return (
                          <Col
                            xs={2}
                            className="tile-wrapper-results"
                            key={$index}
                          >
                            <Tile
                              text={step.title}
                              riskLevel={getRiskLevelClassName(step.risk)}
                              score={step.risk.replace(/_/g, " ")}
                              onClick={() =>
                                handleStepClick(sectionScore.externalId, step)
                              }
                            />
                          </Col>
                        );
                      })}
                    </div>
                  </div>
                );
              }
              return <div key={$index}></div>;
            })}
          </div>
        </div>
      )}
    </Col>
  );
};

export default SurveyResults;
