import React from "react";
import { Button, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";

import { PRETTY_ANSWER_UNITS } from "../../constants/answerUnits";
import { ANSWER_TYPES } from "../../constants/answerTypes";
import { QUESTION_STATUS, COMMON } from "../../constants/enums";
import { SurveyAnswer, SurveyQuestionResponse } from "../../models/be_models";

import Icon from "../common/Icon";
import ShortTextForm from "../surveyAnswerForms/ShortTextForm";
import LongTextForm from "../surveyAnswerForms/LongTextForm";
import YesNoForm from "../surveyAnswerForms/YesNoForm";
import SingleSelectForm from "../surveyAnswerForms/SingleSelectForm";
import MultiSelectForm from "../surveyAnswerForms/MultiSelectForm";
import FloatForm from "../surveyAnswerForms/FloatForm";
import FloatRangeForm from "../surveyAnswerForms/FloatRangeForm";
import IntegerForm from "../surveyAnswerForms/IntegerForm";
import IntegerRangeForm from "../surveyAnswerForms/IntegerRangeForm";
import DateForm from "../surveyAnswerForms/DateForm";
import DateTimeForm from "../surveyAnswerForms/DateTimeForm";
import UrlForm from "../surveyAnswerForms/UrlForm";

type SurveyQuestionProps = {
  question: SurveyQuestionResponse;
  lastQuestionId: string;
  onSubmitAnswer: (questionId: string, answer: SurveyAnswer) => void;
};

const SurveyQuestion: React.FC<SurveyQuestionProps> = ({
  question,
  lastQuestionId,
  onSubmitAnswer,
}) => {
  const questionContext = question.questionContext;
  const guidance = questionContext.question.guidance;
  const hasGuidance = guidance != null && guidance.length > 0;
  const answer = questionContext.question.answer;
  const answerOptions = questionContext.question.answerOptions;
  const answerType = questionContext.question.answerType;
  const isLastQuestion = lastQuestionId === questionContext.question.id;

  const handleSubmitAnswer = (answer: SurveyAnswer) => {
    onSubmitAnswer(questionContext.question.id, answer);
  };

  return (
    <div className="survey-question px-3 mt-5" data-cy="survey-question">
      {question.status === QUESTION_STATUS.FINISH ? (
        <h2>The audit is complete.</h2>
      ) : (
        <Row>
          <Col xs={12} className="mb-3">
            <p className="lead">
              <span className="me-2">{questionContext.question.text}</span>
              {questionContext.question.answerUnits.length &&
              questionContext.question.answerUnits !== COMMON.NONE ? (
                <strong>
                  &nbsp;(
                  {PRETTY_ANSWER_UNITS[questionContext.question.answerUnits]})
                </strong>
              ) : null}
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="bottom"
                overlay={<Tooltip>{guidance}</Tooltip>}
              >
                <Button
                  variant="link"
                  disabled={!hasGuidance}
                  title={guidance}
                  className="px-1"
                >
                  <Icon iconString="info-circle" />
                </Button>
              </OverlayTrigger>
            </p>
          </Col>

          <Col xs={12}>
            {answerType === ANSWER_TYPES.SHORT_TEXT ? (
              <ShortTextForm onSave={handleSubmitAnswer} answer={answer} />
            ) : answerType === ANSWER_TYPES.LONG_TEXT ? (
              <LongTextForm
                onSave={handleSubmitAnswer}
                answer={answer}
                isLastQuestion={isLastQuestion}
              />
            ) : answerType === ANSWER_TYPES.YES_NO ? (
              <YesNoForm onSave={handleSubmitAnswer} answer={answer} />
            ) : answerType === ANSWER_TYPES.SINGLE_SELECT ? (
              <SingleSelectForm
                onSave={handleSubmitAnswer}
                answer={answer}
                answerOptions={answerOptions}
              />
            ) : answerType === ANSWER_TYPES.MULTI_SELECT ? (
              <MultiSelectForm
                onSave={handleSubmitAnswer}
                answer={answer}
                answerOptions={answerOptions}
              />
            ) : answerType === ANSWER_TYPES.FLOAT ? (
              <FloatForm onSave={handleSubmitAnswer} answer={answer} />
            ) : answerType === ANSWER_TYPES.FLOAT_RANGE ? (
              <FloatRangeForm onSave={handleSubmitAnswer} answer={answer} />
            ) : answerType === ANSWER_TYPES.INTEGER ? (
              <IntegerForm onSave={handleSubmitAnswer} answer={answer} />
            ) : answerType === ANSWER_TYPES.INTEGER_RANGE ? (
              <IntegerRangeForm onSave={handleSubmitAnswer} answer={answer} />
            ) : answerType === ANSWER_TYPES.DATE ? (
              <DateForm onSave={handleSubmitAnswer} answer={answer} />
            ) : answerType === ANSWER_TYPES.DATE_TIME ? (
              <DateTimeForm onSave={handleSubmitAnswer} answer={answer} />
            ) : answerType === ANSWER_TYPES.URL ? (
              <UrlForm onSave={handleSubmitAnswer} answer={answer} />
            ) : (
              <div>Unknown answer type</div>
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};

export default SurveyQuestion;
