import React, { useContext, useEffect, useState, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  ButtonToolbar,
  Button,
} from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useHistory } from "react-router-dom";
import { AxiosError } from "axios";

import { AlertsContext } from "../../contexts/AlertsContext";
import { ErrorPopupContext } from "../../contexts/ErrorPopupContext";
import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import {
  getTemplates,
  postLaunchSurvey,
  getCompanyUsersAutoCorrect,
  getCompanyFarmsAutoCorrect,
} from "../../network/request";
import { showTransientAlert, showFetchError } from "../../utils/alerts";
import { ALERT_ACTION_KIND } from "../../constants/enums";
import {
  NameSuggesterResponse,
  SurveyTemplatesResponse,
} from "../../models/be_models";

import Loader from "../common/Loader";

const SurveyLaunchFormContainer: React.FC = () => {
  const history = useHistory();
  const { currentUser } = useContext(CurrentUserContext);

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [loadingFarms, setLoadingFarms] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [templates, setTemplates] = useState<SurveyTemplatesResponse[]>([]);
  const [farms, setFarms] = useState<NameSuggesterResponse[]>([]);
  const [users, setUsers] = useState<NameSuggesterResponse[]>([]);
  const [templateName, setTemplateName] = useState("");
  const [assignedUsers, setAssignedUsers] = useState([
    { externalId: currentUser.externalId, name: currentUser.email },
  ]);
  const [form, setForm] = useState({
    name: "",
    surveyYear: new Date().getFullYear(),
    description: "",
    companyExtId: currentUser.currentCompany.externalId,
    farmExtId: "",
    userExtIds: [currentUser.externalId],
  });

  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);

  const goBack = () => {
    return history.goBack();
  };

  const clearAlert = useCallback(() => {
    dispatchAlert({ type: ALERT_ACTION_KIND.CLEAR_ALERTS });
  }, [dispatchAlert]);

  useEffect(() => {
    clearAlert();

    const fetchData = async () => {
      setLoading(true);
      try {
        const { data: templatesResult } = await getTemplates();
        setTemplates(templatesResult);
        if (templatesResult.length) setTemplateName(templatesResult[0].names);
      } catch (error) {
        showFetchError({
          error: error as Error | AxiosError,
          customMsg: "API error while loading templates.",
          object: "templates",
          objectName: "plural",
          operation: "loaded",
          dispatchAlert,
          dispatchPopup,
          onRetry: () => fetchData(),
        });
      }

      setLoading(false);
    };

    fetchData();
  }, [clearAlert]);

  const handleTemplateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTemplateName(e.target.value);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleAssignedFarmChange = (e: NameSuggesterResponse[]) => {
    if (e.length) {
      setForm({ ...form, farmExtId: e[0].externalId });
    } else {
      setForm({ ...form, farmExtId: "" });
    }
  };

  const handleAssignedUsersChange = (e: NameSuggesterResponse[]) => {
    setAssignedUsers(e);
    setForm({
      ...form,
      userExtIds: e.map((user: NameSuggesterResponse) => user.externalId),
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);

    try {
      const { data: result } = await postLaunchSurvey(templateName, form);
      setSubmitting(false);
      if (result.accepted) {
        showTransientAlert(dispatchAlert, {
          type: ALERT_ACTION_KIND.SHOW_SUCCESS_ALERT,
          text: "Audit launched!",
        });
        goBack();
      } else {
        showTransientAlert(dispatchAlert, {
          type: ALERT_ACTION_KIND.SHOW_ERROR_ALERT,
          text: result.messages.join(" "),
        });
      }
    } catch (error) {
      setSubmitting(false);
      showFetchError({
        error: error as Error | AxiosError,
        customMsg: "API error while launching the audit.",
        object: "audit",
        objectName: form.name,
        operation: "launched",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => handleSubmit(e),
      });
    }
  };

  const handleOnFarmSearch = async (query: string) => {
    try {
      setLoadingFarms(true);
      const params = { name: query };
      const { data: farmsResult } = await getCompanyFarmsAutoCorrect(
        currentUser.currentCompany.externalId,
        params
      );
      setFarms(farmsResult);
      setLoadingFarms(false);
    } catch (error) {
      setLoadingFarms(false);
      showFetchError({
        error: error as Error | AxiosError,
        customMsg: "API error while loading the farms.",
        object: "farms",
        objectName: "plural",
        operation: "loaded",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => handleOnFarmSearch(query),
      });
    }
  };

  const handleOnUserSearch = async (query: string) => {
    try {
      setLoadingUsers(true);
      const params = { name: query };
      const { data: usersResult } = await getCompanyUsersAutoCorrect(
        currentUser.currentCompany.externalId,
        params
      );
      setUsers(usersResult);
      setLoadingUsers(false);
    } catch (error) {
      setLoadingUsers(false);
      showFetchError({
        error: error as Error | AxiosError,
        customMsg: "API error while loading the users.",
        object: "users",
        objectName: "plural",
        operation: "loaded",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => handleOnUserSearch(query),
      });
    }
  };

  const isSurveyYearValid = () => {
    return !String(form.surveyYear).match(/^\d\d\d\d$/i);
  };

  return (
    <Container
      className="aqua-container survey-launch-form main"
      data-cy="launch-survey-container"
    >
      <Row>
        <Col>
          <div className="header mb-5">
            <Button variant="link" className="btn-back" onClick={goBack}>
              <i className="arrow arrow-lg arrow-gray arrow-left"></i>
            </Button>
            <div className="w-100 d-flex align-items-start">
              <div className="filter-toolbar mb-2">
                <h4 className="header-title">Launch Audit</h4>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {loading ? (
        <Row>
          <Col xs={12}>
            <Loader status="Loading templates" />
          </Col>
        </Row>
      ) : (
        <Form className="row mt-5 launch-form" onSubmit={handleSubmit}>
          <Form.Group
            controlId="formTemplateGroupSelect"
            className="form-group col-12 col-md-6"
          >
            <Form.Label>Template</Form.Label>
            <Form.Select
              onChange={handleTemplateChange}
              className="form-control"
            >
              {templates.map((template, index) => {
                return (
                  <option key={index} value={template.names}>
                    {template.names}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
          <Form.Group
            controlId="formGroupName"
            className="form-group col-12 col-md-6"
          >
            <Form.Label>Audit Name</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              name="name"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group
            controlId="formGroupDescription"
            className="form-group col-12"
          >
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              name="description"
              className="text-start"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group
            controlId="formFarmSelect"
            className="form-group col-12 col-md-6"
            data-cy="farm-autocomplete"
          >
            <Form.Label>Farm</Form.Label>
            <AsyncTypeahead
              id="farm-typeahead"
              isLoading={loadingFarms}
              labelKey="name"
              options={farms}
              minLength={1}
              onChange={handleAssignedFarmChange}
              onSearch={handleOnFarmSearch}
            />
          </Form.Group>

          <Form.Group
            controlId="formGroupName"
            className="form-group col-12 col-md-6"
          >
            <Form.Label>Audit Year</Form.Label>
            <Form.Control
              type="number"
              placeholder=""
              name="surveyYear"
              value={String(form.surveyYear)}
              onChange={handleChange}
              //TODO: implement such validation for Form.Control in other places where possible
              isInvalid={isSurveyYearValid()}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid year.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            controlId="formUsersSelect"
            className="form-group col-12 col-md-12"
            data-cy="users-autocomplete"
          >
            <Form.Label>Assigned Users</Form.Label>
            <AsyncTypeahead
              id="user-typeahead"
              isLoading={loadingUsers}
              labelKey="name"
              options={users}
              multiple={true}
              minLength={1}
              onChange={handleAssignedUsersChange}
              selected={assignedUsers}
              onSearch={handleOnUserSearch}
            />
          </Form.Group>

          {submitting ? (
            <Loader status="Launching" />
          ) : (
            <ButtonToolbar className="col-12 form-group justify-content-between mt-5">
              <Button
                variant="secondary"
                type="submit"
                size="sm"
                data-cy="submit-btn"
              >
                Save
              </Button>
            </ButtonToolbar>
          )}
        </Form>
      )}
    </Container>
  );
};

export default SurveyLaunchFormContainer;
