import React, { useState, useContext, useRef } from "react";
import {
  Container,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";

import { postPasswordChange } from "../../network/request";
import { ALERT_ACTION_KIND } from "../../constants/enums";
import { AlertsContext } from "../../contexts/AlertsContext";
import { ErrorPopupContext } from "../../contexts/ErrorPopupContext";
import { showFetchError } from "../../utils/alerts";

import Header from "../common/HeaderGuest";
import Loader from "../common/Loader";

const ResetPasswordContainer = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [form, setForm] = useState({
    password: "",
    passwordConfirmation: "",
  });

  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);

  const landingContainer = useRef(null);
  const guid = props.match.params.guid;
  const history = useHistory();

  const isValid = () => {
    setError("");

    if (form.password !== form.passwordConfirmation) {
      setError("Password confirmation does not match");
      return false;
    }

    if (!form.password.length) {
      setError("Password must be longer");
      return false;
    }

    return true;
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValid()) return;
    setLoading(true);
    const body = {
      clid: guid,
      password: form.password,
    };

    try {
      await postPasswordChange(body);
      dispatchAlert({
        type: ALERT_ACTION_KIND.SHOW_SUCCESS_ALERT,
        text: "Password reset!",
      });
      history.push(`/`);
    } catch (error) {
      showFetchError({
        error,
        customMsg: "API error while changing the password.",
        object: "user",
        objectName: "unknown",
        operation: "reached",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => handleSubmit(e),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      className="auth-container reset-bootstrap-form-group"
      fluid
      ref={landingContainer}
      data-cy="reset-password-container"
    >
      <Header landingContainer={landingContainer} showSignInButton={true} />

      <main className="auth-container-inner">
        <div className="form-container-wrapper">
          <div className="form-container px-md-5">
            <Row className="justify-content-center align-content-center flex-column">
              <Col>
                <h1 className="headline">Reset password</h1>
              </Col>
              <Col className="login-form pt-3">
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="formPassword" className="form-group">
                    <FormLabel>New password</FormLabel>
                    <FormControl
                      type="password"
                      placeholder="Password"
                      name="password"
                      value={form.password}
                      onChange={handleChange}
                      data-cy="password"
                    />
                  </FormGroup>

                  <FormGroup
                    controlId="formPasswordConfirmation"
                    className="form-group"
                  >
                    <FormLabel>New password confirmation</FormLabel>
                    <FormControl
                      type="password"
                      placeholder="Password Confirmation"
                      name="passwordConfirmation"
                      value={form.passwordConfirmation}
                      onChange={handleChange}
                      data-cy="password-repeat"
                    />
                  </FormGroup>

                  <div className="submit text-center mt-5">
                    <div className="error-message pb-2" data-cy="error">
                      {error}
                    </div>
                    {loading ? (
                      <Loader status="Setting new password" inlineStatus />
                    ) : (
                      <Button
                        variant="primary"
                        type="submit"
                        className="button-submit py-1 px-5"
                        data-cy="submit-btn"
                      >
                        Reset password
                      </Button>
                    )}
                  </div>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </main>
    </Container>
  );
};

export default ResetPasswordContainer;
