import axios, { AxiosResponse } from "axios";
import {
  ADMIN_API,
  getUserHeaders,
  getAuthHeaders,
} from "../constants/requestConstants";
import * as Model from "../models/be_models";

export const getTemplates = (): Promise<
  AxiosResponse<Model.SurveyTemplatesResponse[]>
> => {
  const url = `${ADMIN_API}/template`;

  return axios.get<Model.SurveyTemplatesResponse[]>(url, {
    headers: getUserHeaders(),
  });
};

export const postUploadTemplateFile = (
  //uploadTemplateFile
  body: FormData
): Promise<AxiosResponse<string>> => {
  const url = `${ADMIN_API}/template`;

  return axios.post(url, body, {
    headers: getAuthHeaders(),
  });
};

export const getTemplate = (name: string): Promise<AxiosResponse<string>> => {
  const url = `${ADMIN_API}/template/${name}`;

  return axios.get(url, {
    headers: getUserHeaders(),
  });
};

export const postUploadTemplateJson = (
  //uploadTemplateJson
  name: string,
  body: Model.SurveyTemplate
): Promise<AxiosResponse<Model.SurveyFormInfo>> => {
  const url = `${ADMIN_API}/template/${name}`;

  return axios.post<Model.SurveyFormInfo>(url, body, {
    headers: getAuthHeaders(),
  });
};

export const postLaunchSurvey = (
  //launchSurvey
  name: string,
  body: Model.LaunchSurveyRequest
): Promise<AxiosResponse<Model.LaunchSurveyResponse>> => {
  const url = `${ADMIN_API}/template/${name}/launch`;

  return axios.post<Model.LaunchSurveyResponse>(url, body, {
    headers: getAuthHeaders(),
  });
};
