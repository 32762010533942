import { ToolbarConfig } from "react-rte";

export const DATE_ISO_DEFAULT = "1970-01-01T00:00:00";

export const SATELLITE_MAP_STYLE =
  "mapbox://styles/mapbox/satellite-streets-v11";

export const DARK_MAP_STYLE = "mapbox://styles/mapbox/dark-v9";

// array for disabling some default layers with labels on satellite-streets-v11 (we need only City names, Street names, Country names, Airports)
export const SATELLITE_MAP_LAYERS_FOR_DISABLE = [
  "tunnel-primary-secondary-tertiary-case",
  "tunnel-major-link-case",
  "tunnel-motorway-trunk-case",
  "tunnel-path",
  "tunnel-steps",
  "tunnel-major-link",
  "tunnel-pedestrian",
  "tunnel-primary-secondary-tertiary",
  "tunnel-oneway-arrow-blue",
  "tunnel-motorway-trunk",
  "tunnel-oneway-arrow-white",
  "ferry",
  "ferry-auto",
  "road-pedestrian-case",
  "road-secondary-tertiary-case",
  "road-primary-case",
  "road-major-link-case",
  "road-motorway-trunk-case",
  "road-path",
  "road-steps",
  "road-major-link",
  "road-pedestrian",
  "road-secondary-tertiary",
  "road-primary",
  "road-oneway-arrow-blue",
  "road-motorway-trunk",
  "road-oneway-arrow-white",
  "bridge-pedestrian-case",
  "bridge-primary-secondary-tertiary-case",
  "bridge-major-link-case",
  "bridge-motorway-trunk-case",
  "bridge-path",
  "bridge-steps",
  "bridge-major-link",
  "bridge-pedestrian",
  "bridge-primary-secondary-tertiary",
  "bridge-oneway-arrow-blue",
  "bridge-motorway-trunk",
  "bridge-major-link-2-case",
  "bridge-motorway-trunk-2-case",
  "bridge-major-link-2",
  "bridge-motorway-trunk-2",
  "bridge-oneway-arrow-white",
  "aerialway",
  "road-label",
  "road-number-shield",
  "road-exit-shield",
  "waterway-label",
  "natural-line-label",
  "natural-point-label",
  "water-line-label",
  "water-point-label",
  "poi-label",
  "transit-label",
  // === these layers are visible ===
  // "background",
  // "satellite",
  // "road-street-low",
  // "road-street-case",
  // "road-street",
  // "airport-label",
  // "settlement-subdivision-label",
  // "settlement-label",
  // "state-label",
  // "country-label",
  // "admin-1-boundary-bg",
  // "admin-0-boundary-bg",
  // "admin-1-boundary",
  // "admin-0-boundary",
  // "admin-0-boundary-disputed",
];

export const CURRENCY_CODE_LIST = ["EUR", "USD", "GBP", "CAD", "CHF", "AUD"];

export const SPECIES_LIST = [
  "Atlantic salmon",
  "Gilthead sea bream",
  "European seabass",
  "Turbot",
  "Sea trout",
  "Atlantic bluefin tuna",
  "Meagre",
  "Shi drum",
  "Grey mullet",
  "Senegalese sole",
  "Red porgy",
  "Atlantic halibut",
  "Common sole",
  "Rainbow trout",
  "Common carp",
  "North African catfish",
  "Bighead carp",
  "European eel",
  "Silver carp",
  "Grass carp",
  "Roach",
  "European catfish",
  "European whitefish",
  "Tench",
  "Brook trout",
  "Pike perch",
  "Northern pike",
  "Nile tilapia",
  "Artic char",
  "Danube sturgeon",
  "Siberian sturgeon",
  "European perch",
  "Mediterranean mussel",
  "Blue mussel",
  "Pacific oyster",
  "Manila clam",
  "Good clam",
  "European flat oyster",
  "Pullet carpet shell",
  "Queen scallop",
  "Great Atlantic scallop",
  "Common cockle",
  "Winged kelp",
  "Sea belt",
  "Sea lettuce",
  "Barramundi",
  "Pangasius",
  "Seriola",
  "Red snapper",
  "Rose snapper",
  "Seriola",
  "Yellowtail tuna",
  "Orange spotted grouper",
  "Giant grouper",
  "Highfin grouper",
  "Whiteleg shrimp",
  "Giant tiger prawn",
  "Kuruma prawn",
  "Striped bass",
];

export const REACH_TEXT_EDITOR_TOOLBAR_CONFIG: ToolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
  BLOCK_ALIGNMENT_BUTTONS: [],
};
