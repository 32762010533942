import { React, useState } from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";

import { CHARTS_ENUM } from "../../constants/enums";

const AmountsTotalBlock = ({ currentMapType, countriesData }) => {
  const [selectedCountry, setSelectedCountry] = useState("All");

  let dataField = selectedCountry.animalCount;
  let titleField = currentMapType;

  switch (currentMapType) {
    case CHARTS_ENUM.ANIMAL_COUNT: {
      dataField =
        selectedCountry !== "All"
          ? selectedCountry.animalCount
          : countriesData.reduce(
              (prev, current) => prev + current.animalCount,
              0
            );
      dataField = Math.round(dataField).toLocaleString();
      titleField = "Animal Count";
      break;
    }
    case CHARTS_ENUM.AVG_VALUE: {
      dataField =
        selectedCountry !== "All"
          ? selectedCountry.avgValue
          : countriesData.reduce((prev, current) => prev + current.avgValue, 0);
      dataField = "$" + Math.round(dataField).toLocaleString();
      titleField = "Total Average Value";
      break;
    }
    case CHARTS_ENUM.MAX_VALUE: {
      dataField =
        selectedCountry !== "All"
          ? selectedCountry.maxValue
          : countriesData.reduce((prev, current) => prev + current.maxValue, 0);
      dataField = "$" + Math.round(dataField).toLocaleString();
      titleField = "Maximum Fish Value";
      break;
    }
    case CHARTS_ENUM.TONNAGE: {
      dataField =
        selectedCountry !== "All"
          ? selectedCountry.tonnage
          : countriesData.reduce((prev, current) => prev + current.tonnage, 0);
      dataField = Math.round(dataField).toLocaleString();
      titleField = "Total Tonnage";
      break;
    }
    case CHARTS_ENUM.ALL_FARMS: {
      dataField =
        selectedCountry !== "All"
          ? selectedCountry.farmCount
          : countriesData.reduce(
              (prev, current) => prev + current.farmCount,
              0
            );
      dataField = Math.round(dataField).toLocaleString();
      titleField = "All Farms";
      break;
    }
    default:
      break;
  }

  return (
    <>
      {selectedCountry && (
        <div className="text-center">
          <p className="number my-4">{dataField}</p>
          <p className="map-type-name">{titleField}</p>
        </div>
      )}
      <DropdownButton
        title={selectedCountry.country || "All countries"}
        variant="link"
        drop="up"
        size="sm"
      >
        <Dropdown.Item
          key="company"
          value="All"
          onClick={() => setSelectedCountry("All")}
        >
          All
        </Dropdown.Item>
        {countriesData.map((country) => {
          return (
            <Dropdown.Item
              key={country.country}
              value={country.country}
              onClick={() => setSelectedCountry(country)}
            >
              {country.country}
            </Dropdown.Item>
          );
        })}
      </DropdownButton>
    </>
  );
};

export default AmountsTotalBlock;
