import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import RichTextEditor from "react-rte";
import { REACH_TEXT_EDITOR_TOOLBAR_CONFIG } from "../../constants/constants";

const LongTextForm = ({ onSave, answer, isLastQuestion }) => {
  const [error, setError] = useState("");
  const [editorState, setEditorState] = useState(
    RichTextEditor.createEmptyValue()
  );

  useEffect(() => {
    const prefilledRichTextEditorString =
      isLastQuestion &&
      (!answer.answerStr || answer.answerStr === "<p>undefined</p>")
        ? "<p><b>Findings:</b></br></p><p><b>Recommendations:</b></br></p>"
        : answer.answerStr;
    setEditorState(
      RichTextEditor.createValueFromString(
        prefilledRichTextEditorString,
        "html"
      )
    );
  }, [answer, isLastQuestion]);

  const isValid = () => {
    setError("");
    if (
      editorState &&
      editorState.toString("html") &&
      editorState.toString("html").length > 0 &&
      editorState.toString("html").length <= 65535
    )
      return true;
    setError("Answer must be between 1 and 65,535 characters");
    return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isValid()) return;
    onSave(editorState.toString("html"));
  };

  return (
    <Form
      id="question-form"
      className="survey-answer-form long-text-form"
      onSubmit={handleSubmit}
    >
      <Form.Group controlId="formGroupAnswer" className="form-group px-0">
        <RichTextEditor
          className="rounded border-0"
          toolbarConfig={REACH_TEXT_EDITOR_TOOLBAR_CONFIG}
          value={editorState}
          onChange={(value) => setEditorState(value)}
        />
      </Form.Group>

      {error.length ? <div className="error">{error}</div> : null}
    </Form>
  );
};

export default LongTextForm;
