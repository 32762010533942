import React from "react";
import { Row, Col, Image } from "react-bootstrap";

import emailIcon from "../../images/landing/Email.svg";
import facebookIcon from "../../images/landing/Facebook.svg";
import twitterIcon from "../../images/landing/Twitter.svg";
import linkedinIcon from "../../images/landing/Linkedin.svg";
import instagramIcon from "../../images/landing/Instagram.svg";

const Footer: React.FC = () => {
  return (
    <footer>
      <Row className="footer justify-content-center">
        <Col xs={12} lg={9} className="layout-outer">
          <Row>
            <Col xs={12} lg={6} className="align-self-center">
              <div className="d-flex py-2 align-items-center justify-content-around justify-content-lg-start">
                <a
                  href="https://www.longline.co.uk/flow/longline/who/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link my-0 me-lg-5"
                >
                  Who we are
                </a>
                <a
                  href="https://www.longline.co.uk/flow/privacy/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link me-0 me-lg-5"
                >
                  Privacy policy
                </a>
                <a
                  href="https://www.longline.co.uk/flow/contacts/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link me-0 me-lg-5"
                >
                  Contacts
                </a>
              </div>
            </Col>
            <Col xs={12} lg={6}>
              <div className="d-flex py-2 justify-content-center justify-content-lg-end">
                <a href="mailto:info@longline.co.uk" className="m-2">
                  <Image
                    src={emailIcon}
                    title="Email"
                    alt="Email"
                    height="30"
                  />
                </a>
                <a
                  href="https://www.facebook.com/LonglineEnvironment/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="m-2"
                >
                  <Image
                    src={facebookIcon}
                    title="Facebook"
                    alt="Facebook"
                    height="30"
                  />
                </a>
                <a
                  href="https://twitter.com/longlineenv"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="m-2"
                >
                  <Image
                    src={twitterIcon}
                    title="Twitter"
                    alt="Twitter"
                    height="30"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/longline-environment"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="m-2"
                >
                  <Image
                    src={linkedinIcon}
                    title="Linkedin"
                    alt="Linkedin"
                    height="30"
                  />
                </a>
                <a
                  href="https://www.instagram.com/longlineenvironment/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="m-2"
                >
                  <Image
                    src={instagramIcon}
                    title="Instagram"
                    alt="Instagram"
                    height="30"
                  />
                </a>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
