import React, { useState, useContext } from "react";
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  Spinner,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import RichTextEditor, { EditorValue } from "react-rte";
import { AxiosError } from "axios";

import { postCreateAction } from "../../network/request";
import { AlertsContext } from "../../contexts/AlertsContext";
import { ErrorPopupContext } from "../../contexts/ErrorPopupContext";
import {
  RISK_LEVELS as riskLevels,
  RISK_LEVELS_ENUM,
  ALERT_ACTION_KIND,
} from "../../constants/enums";
import { REACH_TEXT_EDITOR_TOOLBAR_CONFIG } from "../../constants/constants";
import { showTransientAlert, showFetchError } from "../../utils/alerts";
import { getRiskLevelClassName } from "../../utils/string";

type AddNewActionModalProps = {
  selectedSurveyExtId: string;
  // should the modal be shown?
  show: boolean;
  // triggered when close button is clicked
  onHide: () => void;
  // triggered when action was created
  onUpdateActionCount: () => void;
};

const AddNewActionModal: React.FC<AddNewActionModalProps> = ({
  selectedSurveyExtId,
  show,
  onHide,
  onUpdateActionCount,
}) => {
  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);

  const [saving, setSaving] = useState(false);
  const [form, setForm] = useState({
    title: "",
    description: "",
    riskLevel: riskLevels[0].key,
  });
  const [error, setError] = useState("");
  const [selectedRiskLevel, setSelectedRiskLevel] = useState(riskLevels[0]);

  const prefilledRichTextEditorString =
    "<p><b>Findings:</b></br></p><p><b>Recommendations:</b></br></p>";
  const [editorState, setEditorState] = useState<EditorValue>(
    RichTextEditor.createValueFromString(prefilledRichTextEditorString, "html")
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleTextEditorChange = (e: EditorValue) => {
    setEditorState(e);
    setForm({ ...form, description: e.toString("html") });
  };

  const handleDropdownChange = (riskLevel: {
    key: RISK_LEVELS_ENUM;
    display: string;
  }) => {
    setSelectedRiskLevel(riskLevel);
    setForm({ ...form, riskLevel: riskLevel.key });
  };

  const handleSubmit = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (!isValid()) return;
    setSaving(true);

    try {
      await postCreateAction(selectedSurveyExtId, form);
      showTransientAlert(dispatchAlert, {
        type: ALERT_ACTION_KIND.SHOW_SUCCESS_ALERT,
        text: "Action saved!",
      });
    } catch (error) {
      showFetchError({
        error: error as Error | AxiosError,
        customMsg: "API error while saving the action.",
        object: "action",
        objectName: form.title,
        operation: "saved",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => handleSubmit(e),
      });
    } finally {
      setSaving(false);
      onUpdateActionCount();
      onHide();
    }
  };

  const isValid = () => {
    setError("");

    if (!form.title.length) {
      setError("Title is required");
      return false;
    }

    if (
      !editorState.toString("html").length ||
      editorState.toString("html") === prefilledRichTextEditorString
    ) {
      setError("Description is required");
      return false;
    }

    if (!form.riskLevel.length) {
      setError("Risk level is required");
      return false;
    }

    return true;
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add-action-modal"
    >
      <Modal.Header className="justify-content-center">
        <Modal.Title className="fw-semi">New Action</Modal.Title>
      </Modal.Header>

      <Modal.Body className="pb-0">
        <Form className="action-form">
          <Form.Group controlId="formGroupTitle" className="form-group px-0">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              name="title"
              value={form.title}
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group
            controlId="formGroupDescription"
            className="form-group px-0"
          >
            <RichTextEditor
              className="rich-text-editor"
              toolbarConfig={REACH_TEXT_EDITOR_TOOLBAR_CONFIG}
              value={editorState}
              onChange={(value) => handleTextEditorChange(value)}
            />
          </Form.Group>

          <Form.Group
            controlId="formGroupRiskLevel"
            className="form-group px-0 mb-0"
          >
            <Row className="justify-content-between align-items-center">
              <Col xs={6}>
                <Row className="align-items-center flex-wrap">
                  <Col className="flex-grow-0">
                    <div className="ms-2 text-nowrap">Risk Level:</div>
                  </Col>
                  <Col className="flex-grow-0">
                    <DropdownButton
                      title={selectedRiskLevel.display}
                      variant="link"
                      size="sm"
                      className={`checkmark-${getRiskLevelClassName(
                        selectedRiskLevel.key
                      )} risk-levels-dropdown`}
                    >
                      {riskLevels.map((riskLevel) => {
                        return (
                          <Dropdown.Item
                            key={riskLevel.key}
                            value={riskLevel.key}
                            active={form.riskLevel === riskLevel.key}
                            onClick={() => handleDropdownChange(riskLevel)}
                            className={`checkmark-${getRiskLevelClassName(
                              riskLevel.key
                            )}`}
                          >
                            {riskLevel.display}
                          </Dropdown.Item>
                        );
                      })}
                    </DropdownButton>
                  </Col>
                </Row>
              </Col>
              <Col xs={6}>
                {error ? <div className="error text-end">{error}</div> : null}
              </Col>
            </Row>
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer className="justify-content-end">
        {saving ? (
          <Spinner animation="border" />
        ) : (
          <>
            <Button variant="link" onClick={onHide}>
              Close
            </Button>
            <Button variant="link" onClick={handleSubmit}>
              Save
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewActionModal;
