import React, { createContext, useReducer } from "react";
import { alertsReducer } from "../reducers/alertsReducer";
import { AlertContextType } from "../models";

const initialValues = {
  alerts: [],
  dispatch: (): void => undefined,
};

export const AlertsContext = createContext<AlertContextType>(initialValues);

const AlertsContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [alerts, dispatch] = useReducer(alertsReducer, initialValues.alerts);

  return (
    <AlertsContext.Provider value={{ alerts, dispatch }}>
      {children}
    </AlertsContext.Provider>
  );
};

export default AlertsContextProvider;
