import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  ButtonToolbar,
  Button,
  Form,
} from "react-bootstrap";
import { isEqual, sortBy } from "lodash";
import { useHistory, RouteComponentProps } from "react-router-dom";
import { AxiosError } from "axios";

import { AlertsContext } from "../../contexts/AlertsContext";
import { ErrorPopupContext } from "../../contexts/ErrorPopupContext";
import {
  postSetCurrentRolesForUser,
  getUserFarms,
  getCompanyUser,
  getMyCompanyFarms,
  putSetUserFarms,
  postCreateCompanyUser,
  putUpdateCompanyUser,
  deleteCompanyUser,
} from "../../network/request";
import { showTransientAlert, showFetchError } from "../../utils/alerts";
import {
  USER_ROLES_ENUM,
  USER_ROLES,
  ALERT_ACTION_KIND,
} from "../../constants/enums";
import {
  INITIAL_USER,
  INITIAL_USER_AND_ROLES,
} from "../../constants/initialData";
import {
  UserInfoAndRoles,
  UserInfo,
  FarmResponse,
  UserFarmResponse,
} from "../../models/be_models";
import { FormattedMultiSelectItem } from "../../models";

import Loader from "../common/Loader";
import FarmsMultiSelect from "../farms/FarmsMultiSelect";
import DeleteConfirmationModal from "../general/DeleteConfirmationModal";

type UserContainerProps = {
  // type for `match.params`
  userId: string; // must be type `string` since value comes from the URL
  companyId: string; // must be type `string` since value comes from the URL
};

const availableRoles = Object.values(USER_ROLES_ENUM);

const innerRolesArr = [USER_ROLES_ENUM.SURVEYOR, USER_ROLES_ENUM.ADMIN];

const companyRolesArr = [
  USER_ROLES_ENUM.FARMER,
  USER_ROLES_ENUM.MANAGER,
  USER_ROLES_ENUM.COMPANY,
];

const otherRolesArr = [
  USER_ROLES_ENUM.USER,
  USER_ROLES_ENUM.ADJUSTER,
  USER_ROLES_ENUM.UNDERWRITER,
];

const allRolesArr = [...innerRolesArr, ...companyRolesArr, ...otherRolesArr];

const UserContainer: React.FC<RouteComponentProps<UserContainerProps>> = ({
  match: { params },
}) => {
  const history = useHistory();
  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);

  const userId = params.userId === "new" ? "" : params.userId;
  const companyId = params.companyId;

  const [loading, setLoading] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [deleteModalShow, setDeleteModalShow] = useState<boolean>(false);

  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [user, setUser] = useState<UserInfoAndRoles>(INITIAL_USER_AND_ROLES);
  const [form, setForm] = useState<UserInfo>(INITIAL_USER);
  const [farms, setFarms] = useState<FarmResponse[]>([]);
  const [userFarms, setUserFarms] = useState<UserFarmResponse[]>([]);
  const [multiSelectFarmsList, setMultiSelectFarmsList] = useState<
    FormattedMultiSelectItem[]
  >([]);
  const [emailValidationMsg, setEmailValidationMsg] = useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const { data: result } = await getCompanyUser(companyId, userId);

        setUser({ ...result });
        setForm({ ...result.user });
        setUserRoles([...result.roles]);
      } catch (error) {
        showFetchError({
          error: error as AxiosError | Error,
          customMsg: "API error while loading the user.",
          object: "user",
          objectName: "unknown",
          operation: "loaded",
          dispatchAlert,
          dispatchPopup,
          onRetry: () => fetchData(),
        });
      } finally {
        setLoading(false);
      }
    };
    if (userId) fetchData();
  }, [userId, companyId]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchMyCompanyFarms();
      await fetchUserFarms();
      setLoading(false);
    };

    fetchData();
  }, [companyId, userId]);

  const fetchMyCompanyFarms = async () => {
    try {
      const { data: farmsResult } = await getMyCompanyFarms(companyId, true);
      setFarms(farmsResult);
    } catch (error) {
      showFetchError({
        error: error as AxiosError | Error,
        customMsg: "API error while loading the farms.",
        object: "farms",
        objectName: "plural",
        operation: "loaded",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => fetchMyCompanyFarms(),
      });
    }
  };

  const fetchUserFarms = async () => {
    try {
      const { data: userFarmsResult } = await getUserFarms(userId);
      setUserFarms(userFarmsResult);
    } catch (error) {
      showFetchError({
        error: error as AxiosError | Error,
        customMsg: "API error while loading user farms.",
        object: "farms",
        objectName: "plural",
        operation: "loaded",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => fetchUserFarms(),
      });
    }
  };

  const goBack = () => {
    return history.goBack();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const fetchUpdateCompanyUser = async (finalForm: UserInfo) => {
    try {
      await putUpdateCompanyUser(companyId, userId, finalForm);
      showTransientAlert(dispatchAlert, {
        type: ALERT_ACTION_KIND.SHOW_SUCCESS_ALERT,
        text: "User updated!",
      });
    } catch (error) {
      showFetchError({
        error: error as AxiosError | Error,
        customMsg: "API error while updating the user.",
        object: "user",
        objectName: "unknown",
        operation: "updated",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => fetchUpdateCompanyUser(finalForm),
      });
    }
  };

  const fetchCreateCompanyUser = async (finalForm: UserInfo) => {
    try {
      await postCreateCompanyUser(companyId, finalForm);
      showTransientAlert(dispatchAlert, {
        type: ALERT_ACTION_KIND.SHOW_SUCCESS_ALERT,
        text: "User saved!",
      });
    } catch (error) {
      showFetchError({
        error: error as AxiosError | Error,
        customMsg: "API error while saving the user.",
        object: "user",
        objectName: "unknown",
        operation: "saved",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => fetchCreateCompanyUser(finalForm),
      });
    }
  };

  const handleSaveMainData = async (e: React.MouseEvent) => {
    e.preventDefault();
    const finalForm = { ...form, username: form.email }; // username is not used on UI, but maybe it is used on backend.

    if (user.user.externalId && userId.length) {
      await fetchUpdateCompanyUser(finalForm);
    } else {
      await fetchCreateCompanyUser(finalForm);
    }
  };

  const handleRoleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const newRoles = [...userRoles, e.target.value];
      setUserRoles(newRoles);
    } else {
      setUserRoles(userRoles.filter((item) => item !== e.target.value));
    }
  };

  const handleSaveRoles = async (e: React.MouseEvent) => {
    e.preventDefault();
    try {
      await postSetCurrentRolesForUser(userId, {
        roles: userRoles,
      });
      showTransientAlert(dispatchAlert, {
        type: ALERT_ACTION_KIND.SHOW_SUCCESS_ALERT,
        text: "User's roles are saved!",
      });
    } catch (error) {
      showFetchError({
        error: error as AxiosError | Error,
        customMsg: "API error while updating roles.",
        object: "roles",
        objectName: "plural",
        operation: "updated",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => handleSaveRoles(e),
      });
    }
  };

  const handleSaveFarms = async () => {
    const farmListIds = multiSelectFarmsList.map(
      (farm: FormattedMultiSelectItem) => farm.id
    );
    const payload = {
      farmExtIds: farmListIds,
    };

    try {
      await putSetUserFarms(companyId, userId, payload);
      showTransientAlert(dispatchAlert, {
        type: ALERT_ACTION_KIND.SHOW_SUCCESS_ALERT,
        text: "Farm saved!",
      });
    } catch (error) {
      showFetchError({
        error: error as AxiosError | Error,
        customMsg: "API error while saving the farm.",
        object: "farm",
        objectName: "unknown",
        operation: "saved",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => handleSaveFarms(),
      });
    }
  };

  const checkFormValidation = () => {
    const isEmailValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
      form.email
    );

    return isEmailValid;
  };

  const handleSaveEditUser = (e: React.MouseEvent) => {
    if (!isEqual(form, { ...user.user })) handleSaveMainData(e);

    if (!isEqual(userRoles, [...user.roles])) handleSaveRoles(e);

    const fetchedUserFarmsIds = userFarms.map((farm) => farm.externalId);
    const changedFarmsIds = multiSelectFarmsList.map((farm) => farm.id);
    if (!isEqual(sortBy(fetchedUserFarmsIds), sortBy(changedFarmsIds)))
      handleSaveFarms();
  };

  const handleSaveNewUser = (e: React.MouseEvent) => {
    handleSaveMainData(e);
  };

  const handleSaveUser = (e: React.MouseEvent) => {
    if (form.email) {
      if (checkFormValidation()) {
        setSaving(true);

        userId ? handleSaveEditUser(e) : handleSaveNewUser(e);

        setEmailValidationMsg("");

        setSaving(false);
        goBack();
      } else {
        setEmailValidationMsg("Email is not valid");
      }
    } else {
      setEmailValidationMsg("Email is required");
    }
  };

  const renderDeleteModal = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteModalShow(true);
  };

  const handleDeleteUser = async () => {
    setDeleting(true);

    try {
      await deleteCompanyUser(companyId, user.user.externalId);
      showTransientAlert(dispatchAlert, {
        type: ALERT_ACTION_KIND.SHOW_SUCCESS_ALERT,
        text: "User deleted!",
      });
      goBack();
    } catch (error) {
      showFetchError({
        error: error as AxiosError | Error,
        customMsg: "API error while deleting the user.",
        object: "user",
        objectName: user.user.email,
        operation: "deleted",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => handleDeleteUser(),
      });
    } finally {
      setDeleting(false);
      setDeleteModalShow(false);
    }
  };

  const checkbox = (roleKey: string) => {
    return (
      <Form.Group className="form-group col-12" key={roleKey}>
        <Form.Check type="checkbox">
          <Form.Check.Input
            type="checkbox"
            value={roleKey}
            id={roleKey}
            checked={userRoles.includes(roleKey)}
            onChange={handleRoleChange}
            data-cy="role-form-check"
          />
          <Form.Check.Label className="smaller" htmlFor={roleKey}>
            {USER_ROLES[roleKey as keyof typeof USER_ROLES]}
          </Form.Check.Label>
        </Form.Check>
      </Form.Group>
    );
  };

  const renderEmailValidationError = (msg: string) => {
    return <span className="error ms-auto text-lowercase">{msg}</span>;
  };

  return (
    <div
      className="aqua-container main user-container"
      data-cy="user-container"
    >
      {loading ? (
        <div className="loader-container">
          <Loader status="Loading user" />
        </div>
      ) : saving ? (
        <div className="loader-container">
          <Loader status="Saving user" />
        </div>
      ) : (
        <Container className="pb-4">
          <div className="header mb-5">
            <div className="w-100 d-flex align-items-start">
              <Button variant="link" className="btn-back" onClick={goBack}>
                <i className="arrow arrow-lg arrow-gray arrow-left"></i>
              </Button>
              <div className="filter-toolbar mb-2">
                {userId ? (
                  <h4 className="header-title">Edit User</h4>
                ) : (
                  <h4 className="header-title">New User</h4>
                )}
              </div>
            </div>
          </div>

          <Form className="row user-form">
            <Form.Group
              controlId="formGroupFullName"
              className="form-group col-12"
            >
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="fullName"
                value={form.fullName}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group
              controlId="formGroupEmail"
              className="form-group col-12 col-sm-6"
            >
              <Form.Label className="d-flex" data-cy="email-validation-error">
                Email{" "}
                {emailValidationMsg &&
                  renderEmailValidationError(emailValidationMsg)}
              </Form.Label>
              <Form.Control
                type="email"
                placeholder=""
                name="email"
                value={form.email}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group
              controlId="formGroupPhone"
              className="form-group col-12 col-sm-6"
            >
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="phone"
                value={form.phone || ""}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>

          {userId ? (
            <>
              {userRoles.length ? (
                <Row className="mt-3 mb-4" data-cy="roles-section">
                  <Form.Group className="form-group col-12 mb-4">
                    <h4 className="header-title text-uppercase">Roles</h4>
                  </Form.Group>
                  <Col xs={4} className="vertical-divider-right">
                    {availableRoles.includes(USER_ROLES_ENUM.SURVEYOR) ||
                    availableRoles.includes(USER_ROLES_ENUM.ADMIN) ? (
                      <Row className="user-role-column">
                        {innerRolesArr
                          .filter((e) => availableRoles.includes(e))
                          .map((item) => checkbox(item))}
                      </Row>
                    ) : null}
                  </Col>
                  <Col xs={4} className="vertical-divider-right">
                    <Row className="user-role-column">
                      {companyRolesArr
                        .filter((e) => availableRoles.includes(e))
                        .map((item) => checkbox(item))}
                    </Row>
                  </Col>
                  <Col xs={4}>
                    <Row className="user-role-column">
                      {otherRolesArr
                        .filter((e: USER_ROLES_ENUM) =>
                          availableRoles.includes(e)
                        )
                        .map((item) => checkbox(item))}
                      {availableRoles
                        .filter(
                          (e: USER_ROLES_ENUM) => !allRolesArr.includes(e)
                        )
                        .map((item) => checkbox(item))}
                    </Row>
                  </Col>
                </Row>
              ) : (
                <Loader status="Loading roles" />
              )}

              {farms.length ? (
                <Row>
                  <Form.Group className="form-group col-12 mt-3 mb-0">
                    <h4 className="header-title text-uppercase">
                      Assigned farms
                    </h4>
                  </Form.Group>
                  {user.user.externalId ? (
                    <Form.Group
                      controlId="formGroupAssignedUsers"
                      className="form-group col-12"
                    >
                      <FarmsMultiSelect
                        itemsProp={farms}
                        selectedItemsProp={userFarms}
                        callback={setMultiSelectFarmsList}
                        data-cy="farms-multiselect"
                      />
                    </Form.Group>
                  ) : (
                    <Loader status="Loading farms" />
                  )}
                </Row>
              ) : null}
            </>
          ) : null}

          <Row>
            <ButtonToolbar className="col-12 form-group justify-content-start mt-3">
              {user.user.externalId && userId ? (
                <Button
                  variant="danger"
                  size="sm"
                  onClick={renderDeleteModal}
                  className="me-5"
                  data-cy="delete-user-btn"
                >
                  Delete
                </Button>
              ) : null}
              <Button
                variant="secondary"
                size="sm"
                onClick={handleSaveUser}
                data-cy="submit-btn"
              >
                Save
              </Button>
            </ButtonToolbar>
          </Row>
          {user?.user.externalId && userId && (
            <DeleteConfirmationModal
              show={deleteModalShow}
              onClose={() => setDeleteModalShow(false)}
              onConfirm={handleDeleteUser}
              loading={deleting}
              title="user"
            >
              <>
                <p>
                  {`${user.user.email} (${user.user.fullName}) has ${
                    userFarms.length ? userFarms.length : 0
                  } farms.`}
                </p>
                <p className="mb-0"> Delete {user.user.email}?</p>
              </>
            </DeleteConfirmationModal>
          )}
        </Container>
      )}
    </div>
  );
};

export default UserContainer;
