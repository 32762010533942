import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

const IntegerRangeForm = ({ onSave, answer }) => {
  const [sandbox, setSandbox] = useState(0.0);
  const [sandboxTwo, setSandboxTwo] = useState(0.0);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!answer || !answer.answerStr) return;
    const split = answer.answerStr.split("-");
    const value1 = parseInt(split[0]);
    const value2 = parseInt(split[1]);
    setSandbox(value1);
    setSandboxTwo(value2);
  }, [answer]);

  const handleChange = (e) => {
    const value = parseInt(e.target.value);

    if (e.target.name === "sandbox") {
      setSandbox(value);
    } else {
      setSandboxTwo(value);
    }
  };

  const isValid = () => {
    setError("");

    if (
      sandbox > 0 &&
      sandboxTwo <= 2147483647 &&
      sandboxTwo.length > 0 &&
      sandboxTwo <= 2147483647
    ) {
      return true;
    } else if (sandbox > sandboxTwo) {
      setError("The second input must be greater than the first input");
      return false;
    } else {
      setError("Range inputs must be between 0 and 2,147,483,647");
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isValid()) return;
    onSave(`${sandbox}-${sandboxTwo}`);
  };

  return (
    <Form
      id="question-form"
      className="survey-answer-form"
      onSubmit={handleSubmit}
    >
      <Form.Group
        controlId="formGroupAnswer"
        className="form-group px-0 col-12 col-sm-6 col-xl-4"
      >
        <Form.Control
          type="number"
          step="1"
          name="sandbox"
          placeholder="0"
          value={sandbox}
          onChange={handleChange}
        />

        <Form.Control
          type="number"
          step="1"
          name="sandboxTwo"
          placeholder="5"
          value={sandboxTwo}
          onChange={handleChange}
        />

        {error.length ? <div className="error">{error}</div> : null}
      </Form.Group>
    </Form>
  );
};

export default IntegerRangeForm;
