import { Row, Col } from "react-bootstrap";

import { AQUARISK_REPORT_LOGO } from "../../constants/imagesConstants";

const PdfFooter = ({ footerName, pdfProps }) => {
  return (
    <div className="pdf-footer">
      <Row className="align-items-center">
        <Col className="col-4">
          <p className="mb-0">{footerName}</p>
        </Col>
        <Col className="col-4">
          <p className="mb-0 text-center">
            Page {pdfProps.pageNum} of {pdfProps.totalPages}
          </p>
        </Col>
        <Col className="col-4 text-end">
          {pdfProps.pageNum > 1 && (
            <img
              src={AQUARISK_REPORT_LOGO}
              height="25"
              alt="AquaRisk Logo"
              className="ms-5"
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PdfFooter;
