import axios, { AxiosResponse } from "axios";
import { API, getUserHeaders } from "../constants/requestConstants";
import * as BdModel from "../models/be_models";

export const getMyCompanyFarms = (
  companyExtId: string,
  showDetails = false,
): Promise<AxiosResponse<BdModel.FarmResponse[]>> => {
  const url = `${API}/company/${companyExtId}/farm/?details=${showDetails}`;

  return axios.get<BdModel.FarmResponse[]>(url, {
    headers: getUserHeaders(),
  });
};

export const getCompanyFarmsAutoCorrect = (
  companyExtId: string,
  farmSearchBody: BdModel.CompanyFarmsAutoCorrectRequest,
): Promise<AxiosResponse<BdModel.NameSuggesterResponse[]>> => {
  const url = `${API}/company/${companyExtId}/farm-ac`;

  return axios.post<BdModel.NameSuggesterResponse[]>(url, farmSearchBody, {
    headers: getUserHeaders(),
  });
};

export const getMyCompanyFarm = (
  companyExtId: string,
  farmExtId: string,
): Promise<AxiosResponse<BdModel.FarmResponse>> => {
  const url = `${API}/company/${companyExtId}/farm/${farmExtId}`;

  return axios.get<BdModel.FarmResponse>(url, {
    headers: getUserHeaders(),
  });
};

export const postCreateFarm = (
  //createFarm
  companyExtId: string,
  payload: BdModel.CreateFarmRequest,
): Promise<AxiosResponse<BdModel.FarmResponse>> => {
  const url = `${API}/company/${companyExtId}/farm`;

  return axios.post<BdModel.FarmResponse>(url, payload, {
    headers: getUserHeaders(),
  });
};

export const putUpdateFarm = (
  //updateFarm
  companyExtId: string,
  farmExtId: string,
  payload: BdModel.UpdateFarmRequest,
): Promise<AxiosResponse<BdModel.FarmResponse>> => {
  const url = `${API}/company/${companyExtId}/farm/${farmExtId}`;

  return axios.put<BdModel.FarmResponse>(url, payload, {
    headers: getUserHeaders(),
  });
};

export const deleteFarm = (
  companyExtId: string,
  farmExtId: string,
): Promise<AxiosResponse<BdModel.FarmResponse>> => {
  const url = `${API}/company/${companyExtId}/farm/${farmExtId}`;

  return axios.delete<BdModel.FarmResponse>(url, {
    headers: getUserHeaders(),
  });
};

// getFarmSurveys always gives an empty object {}. This API doesn't work.
// `${API}/company/${companyExtId}/farm/${farmExtId}/surveys`;

// getFarmSurveysAutoCorrect is not used

export const getFarmUsers = (
  companyExtId: string,
  farmExtId: string,
): Promise<AxiosResponse<BdModel.UserInfoAndRoles[]>> => {
  const url = `${API}/company/${companyExtId}/farm/${farmExtId}/user`;

  return axios.get<BdModel.UserInfoAndRoles[]>(url, {
    headers: getUserHeaders(),
  });
};

export const postCreateFarmUser = (
  //createFarmUser
  companyExtId: string,
  farmExtId: string,
  userBody: BdModel.CreateFarmUser,
): Promise<AxiosResponse<BdModel.UserInfoAndRoles>> => {
  const url = `${API}/company/${companyExtId}/farm/${farmExtId}/user`;

  return axios.post<BdModel.UserInfoAndRoles>(url, userBody, {
    headers: getUserHeaders(),
  });
};

// getFarmUsersAutoCorrect is not used

// updateFarmUser is not used

export const deleteFarmUser = (
  companyExtId: string,
  farmExtId: string,
  userExtId: string,
): Promise<AxiosResponse> => {
  const url = `${API}/company/${companyExtId}/farm/${farmExtId}/user/${userExtId}`;

  return axios.delete(url, {
    headers: getUserHeaders(),
    data: {}, //empty data is needed for preventing 415 error
  });
};

// addUsersToFarm is not used

// setFarmUsers is not used

export const getMyCompanyFarmLocations = (
  companyExtId: string,
): Promise<AxiosResponse<BdModel.CompanyLocationsResponse>> => {
  const url = `${API}/company/${companyExtId}/farm/locations`;

  return axios.get<BdModel.CompanyLocationsResponse>(url, {
    headers: getUserHeaders(),
  });
};

// getMyCompanyFarmYears is not used

// addFarmsToUser is not used

export const putSetUserFarms = (
  //setUserFarms
  companyExtId: string,
  userExtId: string,
  farmsBody: BdModel.SetUserFarmsRequest,
): Promise<AxiosResponse> => {
  const url = `${API}/company/${companyExtId}/user/${userExtId}/farms`;

  return axios.put(url, farmsBody, {
    headers: getUserHeaders(),
  });
};
