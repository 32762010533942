import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  ButtonToolbar,
  Button,
  DropdownButton,
  Dropdown,
  FormCheck,
  Accordion,
} from "react-bootstrap";
import { AxiosError } from "axios";

import { AlertsContext } from "../../contexts/AlertsContext";
import { ErrorPopupContext } from "../../contexts/ErrorPopupContext";
import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import {
  getOpenActionItemsBySurvey,
  getSurveysWithOpenActionItems,
  getSurveyCreatedYears,
} from "../../network/request";
import {
  INITIAL_MAP_POSITION,
  INITIAL_SELECTED_SURVEY,
} from "../../constants/initialData";
import { RISK_LEVELS_ENUM } from "../../constants/enums";
import { showFetchError } from "../../utils/alerts";
import { calculateMapCenter } from "../../utils/map";
import { formatDate } from "../../utils/date";
import {
  ActionItemWithDetailResponse,
  GetOpenActionItemsBySurveyParams,
  GetSurveysWithOpenActionItemsParams,
  SurveyWithActionItemResponse,
} from "../../models/be_models";
import { MapPosition, Marker } from "../../models";

import Loader from "../common/Loader";
import Icon from "../common/Icon";
import AquaMapBoxGl from "../general/AquaMapBoxGl";
import Action from "./Action";
import FilterByRiskLevel from "../general/FilterByRiskLevel";
import Download from "../../images/Download.svg";

const itemsPerPageOptions = [10, 25, 50, 100, 250];

const ActionsContainer: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [loadingActions, setLoadingActions] = useState(false);
  const [showHistory, setShowHistory] = useState(false);

  const [surveys, setSurveys] = useState<SurveyWithActionItemResponse[]>([]);
  const [actions, setActions] = useState<ActionItemWithDetailResponse[]>([]);
  const [selectedSurvey, setSelectedSurvey] =
    useState<SurveyWithActionItemResponse>(INITIAL_SELECTED_SURVEY);

  const [mapPosition, setMapPosition] =
    useState<MapPosition>(INITIAL_MAP_POSITION);
  const [markers, setMarkers] = useState<Marker[]>([]);
  const [reloadTrigger, setReloadTrigger] = useState(0);
  const [filters, setFilters] = useState<{
    filterActionPriority: RISK_LEVELS_ENUM | null;
    createdAtYearFilter: number | null;
  }>({
    filterActionPriority: null,
    createdAtYearFilter: null,
  });

  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [surveysYearsList, setSurveysYearsList] = useState<("All" | number)[]>([
    "All",
  ]);
  const [currentYear, setCurrentYear] = useState<number | "All">();

  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);
  const { currentUser } = useContext(CurrentUserContext);

  const companyId = currentUser.currentCompany.externalId;

  useEffect(() => {
    const fetchData = async (survey: SurveyWithActionItemResponse) => {
      try {
        setLoadingActions(true);

        // Query params
        let params: GetOpenActionItemsBySurveyParams = {
          closed: true,
          detail: true,
        };
        if (filters.filterActionPriority !== null) {
          params = { ...params, riskLevelFilter: filters.filterActionPriority };
        }

        const { data: actionsResults } = await getOpenActionItemsBySurvey(
          survey.surveyExtId,
          params
        );
        setActions(actionsResults.items);
        setLoadingActions(false);
      } catch (error) {
        setLoadingActions(false);
        showFetchError({
          error: error as Error | AxiosError,
          customMsg: "API error while loading the actions.",
          object: "actions",
          objectName: "plural",
          operation: "loaded",
          dispatchAlert,
          dispatchPopup,
          onRetry: () => fetchData(survey),
        });
      }
    };

    if (selectedSurvey.externalId) {
      fetchData(selectedSurvey);
    }
  }, [filters, companyId, selectedSurvey, showHistory, reloadTrigger]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Query params
        let params: GetSurveysWithOpenActionItemsParams = {
          page: currentPage,
          size: itemsPerPage,
          closed: showHistory,
        };
        if (filters.createdAtYearFilter !== null)
          params = {
            ...params,
            createdAtYearFilter: filters.createdAtYearFilter, //TODO: change to closedAtYearFilter after "Task #4630 Last Survey Page: FE" finished
          };
        if (filters.filterActionPriority !== null)
          params = { ...params, riskLevelFilter: filters.filterActionPriority };

        const { data: surveysWithActionsResult } =
          await getSurveysWithOpenActionItems(companyId, params);
        setSurveys(surveysWithActionsResult.items);

        let uniqueFarms: SurveyWithActionItemResponse[] = [];

        surveysWithActionsResult.items.forEach((item) => {
          if (
            !uniqueFarms.some(
              (farm) => farm.farmExternalId === item.farmExternalId
            )
          ) {
            uniqueFarms = [...uniqueFarms, item];
          }
        });

        const newMarkers = uniqueFarms.map((farm) => {
          return {
            id: farm.farmExternalId,
            lat: farm.latitude || 0,
            lng: farm.longitude || 0,
          };
        });

        setMarkers(newMarkers);

        if (surveysWithActionsResult.items.length) {
          const centerCoord = calculateMapCenter(newMarkers);
          setMapPosition((prev) => ({
            ...prev,
            lng: centerCoord[0],
            lat: centerCoord[1],
          }));
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        showFetchError({
          error: error as Error | AxiosError,
          customMsg: "API error while loading the audits.",
          object: "audits",
          objectName: "plural",
          operation: "loaded",
          dispatchAlert,
          dispatchPopup,
          onRetry: () => fetchData(),
        });
      }
    };

    fetchData();
  }, [companyId, currentPage, itemsPerPage, showHistory, filters]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: yearsOfSurveys } = await getSurveyCreatedYears(); //TODO: change to getSurveyCompletedYears after "Task #4630 Last Survey Page: FE" finished
        setSurveysYearsList(["All", ...yearsOfSurveys.surveyYears]);
      } catch (error) {
        showFetchError({
          error: error as Error | AxiosError,
          customMsg: "API error while loading the audit years.",
          object: "years",
          objectName: "plural",
          operation: "loaded",
          dispatchAlert,
          dispatchPopup,
          onRetry: () => fetchData(),
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleFarmClick = (survey: SurveyWithActionItemResponse) => {
    if (survey === selectedSurvey) {
      setSelectedSurvey(INITIAL_SELECTED_SURVEY);
    } else {
      setSelectedSurvey(survey);
    }
    setMapPosition((prev) => ({
      ...prev,
      lat: survey.latitude || 0,
      lng: survey.longitude || 0,
    }));
  };

  const handleFarmMarkerClick = (farmId: string) => {
    const survey = surveys.filter((item) => item.externalId === farmId)[0];
    if (survey) {
      handleFarmClick(survey);
    }
  };

  const handleActionPriorityClick = (priorityKey: RISK_LEVELS_ENUM | null) => {
    setFilters({ ...filters, filterActionPriority: priorityKey });
  };

  const actionsNumberString = (survey: SurveyWithActionItemResponse) => {
    const numberOfActions = survey?.itemCount || "";
    const actionString = numberOfActions === 1 ? "Action" : "Actions";
    return `${numberOfActions} ${actionString}`;
  };

  const generateReport = (survey: SurveyWithActionItemResponse) => {
    window.open(`/actions-report/${survey.surveyExtId}`, "_blank");
  };

  return (
    <Container
      className="actions-container surveys-container farm-with-survey"
      fluid
    >
      <Row>
        <Col className="main pb-4">
          {loading ? (
            <div className="loader-container">
              <Loader status="Loading farms" />
            </div>
          ) : (
            <div className="px-2">
              <Row>
                <Col xs={12} className="filter-toolbar">
                  <FilterByRiskLevel
                    filterActionPriority={filters.filterActionPriority}
                    handleActionPriorityClick={handleActionPriorityClick}
                  >
                    <ButtonToolbar className="mt-3 my-3 ps-md-2 justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <DropdownButton
                          title={`Audit year`}
                          variant="link"
                          size="sm"
                        >
                          {surveysYearsList.map((year) => {
                            return (
                              <Dropdown.Item
                                key={year}
                                value={year}
                                active={year === currentYear}
                                onClick={() => {
                                  setCurrentYear(year);
                                  setFilters((prev) => ({
                                    ...prev,
                                    createdAtYearFilter:
                                      year === "All" ? null : year,
                                  }));
                                }}
                              >
                                {year}
                              </Dropdown.Item>
                            );
                          })}
                        </DropdownButton>
                        <FormCheck
                          type="switch"
                          id="show-history-switch"
                          label="Show History"
                          reverse={true}
                          className="fs-14 fw-semi mb-0 ms-4"
                          onChange={() => setShowHistory(!showHistory)}
                          checked={showHistory}
                        />
                      </div>
                      <DropdownButton
                        title={`Farms Per Page: ${itemsPerPage}`}
                        variant="link"
                        size="sm"
                        align="end"
                      >
                        {itemsPerPageOptions.map((itemsPerPageOption) => {
                          return (
                            <Dropdown.Item
                              key={itemsPerPageOption}
                              value={itemsPerPageOption}
                              active={itemsPerPageOption === itemsPerPage}
                              onClick={() => {
                                setItemsPerPage(itemsPerPageOption);
                                setCurrentPage(1);
                              }}
                            >
                              {itemsPerPageOption}
                            </Dropdown.Item>
                          );
                        })}
                      </DropdownButton>
                    </ButtonToolbar>
                  </FilterByRiskLevel>
                </Col>
              </Row>
              {surveys.length ? (
                <Row className="mt-2 farms-with-actions ">
                  <Col xs={12}>
                    <Accordion
                      className="cards farm-with-survey"
                      defaultActiveKey={selectedSurvey.farmExternalId}
                    >
                      {surveys.map((survey) => {
                        return (
                          <Accordion.Item
                            key={survey.surveyExtId}
                            className={
                              survey.surveyExtId === selectedSurvey.surveyExtId
                                ? "selected farm mb-4"
                                : "farm mb-4"
                            }
                            eventKey={survey.surveyExtId}
                          >
                            <Accordion.Header
                              className="w-100 align-items-center action-header"
                              onClick={() => handleFarmClick(survey)}
                            >
                              <Row className="flex-grow-1 justify-content-md-between align-items-center">
                                <Col xs={12} md={8}>
                                  <span className="survey-name">
                                    {survey.surveyName} | {survey.farmName}
                                  </span>

                                  {survey.surveyCompletedAt && (
                                    <span className="fs-12 fst-italic ps-3">
                                      (&nbsp;Completed:
                                      <span className="ps-2">
                                        {formatDate(
                                          new Date(survey.surveyCompletedAt)
                                        )}
                                      </span>
                                      &nbsp;)
                                    </span>
                                  )}
                                </Col>

                                <Col xs={12} md={3}>
                                  <div className="d-flex align-items-center justify-content-start justify-content-md-end">
                                    <div className="d-inline-block">
                                      <span className="d-inline-block">
                                        {actionsNumberString(survey)}
                                      </span>
                                    </div>
                                    <div
                                      className="btn-generate"
                                      onClick={() => generateReport(survey)}
                                    >
                                      <div className="small pe-2 ms-4 d-flex align-items-center">
                                        <img
                                          alt="Download"
                                          src={Download}
                                          height="16"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Accordion.Header>

                            <Accordion.Body
                              className={
                                selectedSurvey.surveyExtId ===
                                survey.surveyExtId
                                  ? "show w-100"
                                  : "w-100"
                              }
                            >
                              {selectedSurvey.surveyExtId &&
                              selectedSurvey.surveyExtId ===
                                survey.surveyExtId ? (
                                loadingActions ? (
                                  <Loader
                                    status="Loading actions"
                                    inlineStatus
                                  />
                                ) : (
                                  <div className="action-wrapper mt-4">
                                    {actions.map((action) => {
                                      return (
                                        <Action
                                          key={action.externalId}
                                          action={action}
                                          onResolve={() =>
                                            setReloadTrigger(reloadTrigger + 1)
                                          }
                                          resolvable
                                        />
                                      );
                                    })}
                                  </div>
                                )
                              ) : null}
                            </Accordion.Body>
                          </Accordion.Item>
                        );
                      })}
                    </Accordion>
                  </Col>

                  {surveys.length &&
                  !(currentPage === 1 && surveys.length < itemsPerPage) ? (
                    <Col xs={12}>
                      <ButtonToolbar className="pagination">
                        <Button
                          variant="primary"
                          onClick={() => setCurrentPage(currentPage - 1)}
                          disabled={currentPage === 1}
                        >
                          <Icon iconString="arrow-left" className="mr-1" />
                          Prev
                        </Button>
                        <Button
                          variant="primary"
                          onClick={() => setCurrentPage(currentPage + 1)}
                          disabled={surveys.length < itemsPerPage}
                        >
                          Next
                          <Icon iconString="arrow-right" className="ms-1" />
                        </Button>
                      </ButtonToolbar>
                    </Col>
                  ) : null}
                </Row>
              ) : (
                <p className="center">No actions required.</p>
              )}
            </div>
          )}
        </Col>

        <Col xs={5} md={4} className="map px-0 border-left d-none d-md-block">
          <AquaMapBoxGl
            callingContainer="ActionsContainer"
            position={mapPosition}
            markers={markers}
            selectedMarkerId={selectedSurvey.farmExternalId}
            onMarkerClick={(farmId) => handleFarmMarkerClick(farmId)}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default ActionsContainer;
