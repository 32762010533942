/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Nav,
  FormGroup,
} from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { AlertsContext } from "../../contexts/AlertsContext";
import { ErrorPopupContext } from "../../contexts/ErrorPopupContext";
import { postUploadTemplateJson } from "../../network/request";
import { HEX_COLORS, COMMON, ALERT_ACTION_KIND } from "../../constants/enums";
import { ANSWER_TYPES } from "../../constants/answerTypes";
import { showTransientAlert, showFetchError } from "../../utils/alerts";

import Loader from "../common/Loader";
import Icon from "../common/Icon";

const TemplateEdit = ({ templateNameDefault, templateDefault, onClose }) => {
  let dateNow = new Date();

  const answerOptionEmpty = {
    id: "",
    name: "",
    text: "Place Text Here",
    hint: "Place Hint Here",
    scoring: { value: 0 },
    ordinal: 0,
  };
  const questionEmpty = {
    id: "",
    name: "",
    text: "Place Text Here",
    guidance: "Place Guidance Here",
    hint: "Place Hint Here",
    scoring: { minValue: 0, maxValue: 0, defaultValue: 0 },
    ordinal: 0,
    includeIf: null, //{answerId: null, condition: null, value: null},
    answer: {
      id: "",
      answerType: ANSWER_TYPES.SHORT_TEXT,
      questionId: "",
      scoring: {
        minValue: 0,
        maxValue: 0,
        defaultValue: 0,
        yesValue: 0,
        noValue: 0,
      },
    },
    answerType: ANSWER_TYPES.SHORT_TEXT,
    answerUnits: COMMON.NONE,
    answerOptions: [],
    languageText: {},
    title: "",
    maxScore: 0,
    minScore: 0,
    description: "",
  };
  const stepEmpty = {
    id: "",
    name: "",
    title: "Place Title Here",
    description: "Place Description Here",
    ordinal: 0,
    scoring: { minValue: 0, maxValue: 0, defaultValue: 0 },
    maxScore: 0,
    minScore: 0,
    questions: [],
  };
  const templateEmpty = {
    creationDate: dateNow.toJSON(),
    id: uuidv4(),
    maxScore: 0,
    minScore: 0,
    name: "",
    scoring: {
      maxScore: 0,
      minScore: 0,
    },
    sections: [
      {
        id: "section-animal-health",
        name: "Animal Health",
        title: "Animal health risk",
        description:
          "OIE diseases, antibiotic protocols, vaccination protocols, parasite protocols, distance from shipping lanes, distance from hydrocarbon industry, Distance",
        ordinal: 10,
        minScore: 0,
        maxScore: 0,
        scoring: {
          defaultValue: 0,
          maxValue: 0,
          minValue: 0,
        },
        steps: [{ ...stepEmpty, id: uuidv4(), name: "Step 1", ordinal: 10 }],
      },
      {
        id: "section-environment",
        name: "Environment",
        title: "Environment risk",
        description:
          "Site depth, wind exposure, significant wave height, current speed, water temperature, dissolved oxygen, oxygen saturation, salinity, water monitoring, tidal range",
        ordinal: 20,
        minScore: 0,
        maxScore: 0,
        scoring: {
          defaultValue: 0,
          maxValue: 0,
          minValue: 0,
        },
        steps: [{ ...stepEmpty, id: uuidv4(), name: "Step 1", ordinal: 10 }],
      },
      {
        id: "section-equipment",
        name: "Engineering",
        title: "Engineering risk",
        description:
          "Holding units, equipment condition, mooring design, inspection protocols, installation protocols, age of nets, net risk, net design and testing, technology",
        ordinal: 30,
        minScore: 0,
        maxScore: 0,
        scoring: {
          defaultValue: 0,
          maxValue: 0,
          minValue: 0,
        },
        steps: [{ ...stepEmpty, id: uuidv4(), name: "Step 1", ordinal: 10 }],
      },
      {
        id: "section-governance",
        name: "Governance",
        title: "Governance risk",
        description:
          "Regulatory oversight, certification status, area based management, management qualifications, operations qualifications, aquaculture experience, species experience, record keeping, batch traceability",
        ordinal: 40,
        minScore: 0,
        maxScore: 0,
        scoring: {
          defaultValue: 0,
          maxValue: 0,
          minValue: 0,
        },
        steps: [{ ...stepEmpty, id: uuidv4(), name: "Step 1", ordinal: 10 }],
      },
      {
        id: "section-husbandry",
        name: "Husbandry",
        title: "Husbandry risk",
        description:
          "Site history, historical biomass, carrying capacity, disease diagnostics, biomass risk, stocking density, generational mortality, monthly mortality",
        ordinal: 50,
        minScore: 0,
        maxScore: 0,
        scoring: {
          defaultValue: 0,
          maxValue: 0,
          minValue: 0,
        },
        steps: [{ ...stepEmpty, id: uuidv4(), name: "Step 1", ordinal: 10 }],
      },
    ],
  };

  const numberValues = [
    "minValue",
    "maxValue",
    "defaultValue",
    "yesValue",
    "noValue",
    "minScore",
    "maxScore",
  ];

  const templateName = templateNameDefault || "";

  const [template, setTemplate] = useState(templateDefault || templateEmpty);
  const [loading, setLoading] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState("");
  const [filters, setFilters] = useState({
    section: template.sections[0].name,
    step: template.sections[0].steps.length
      ? template.sections[0].steps[0].name
      : "",
  });

  const [sectionActive, setSectionActive] = useState(template.sections[0]);
  const [stepActive, setStepActive] = useState(
    template.sections[0].steps.length ? template.sections[0].steps[0] : null
  );
  const [questionsActive, setQuestionsActive] = useState(
    template.sections[0].steps.length
      ? template.sections[0].steps[0].questions
      : []
  );
  const [questionActiveIndex, setQuestionActiveIndex] = useState({});

  const [stateItems, setStateItems] = useState([]);
  const [stateItemsQuestions, setStateItemsQuestions] = useState(
    template.sections[0].steps.length
      ? template.sections[0].steps[0].questions
      : []
  );
  const [stateItemsSteps, setStateItemsSteps] = useState(
    template.sections[0].steps
  );

  const [showSection, setShowSection] = useState({
    stepScoring: false,
    sectionScoring: true,
  });

  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);

  useEffect(() => {
    setStateItemsQuestions(questionsActive);
    setStepActive({ ...stepActive, questions: questionsActive });
  }, [questionsActive]);

  useEffect(() => {
    let maxScore = 0;
    let minScore = 0;
    let sectionUpd = { ...sectionActive };
    sectionUpd.steps.forEach((item) => {
      if (item.id === stepActive.id) {
        Object.assign(item, stepActive);
      }
    });
    sectionUpd.steps.forEach((item) => {
      maxScore += Number(item.scoring.maxValue);
      minScore += Number(item.scoring.minValue);
    });
    sectionUpd.maxScore = maxScore;
    sectionUpd.minScore = minScore;
    sectionUpd.scoring = {
      ...sectionUpd.scoring,
      maxValue: maxScore,
      minValue: minScore,
    };
    setSectionActive(sectionUpd);
  }, [stepActive]);

  useEffect(() => {
    let maxScore = 0;
    let minScore = 0;
    let templateUpd = { ...template };
    templateUpd.sections.forEach((item) => {
      if (item.id === sectionActive.id) {
        Object.assign(item, sectionActive);
      }
    });
    templateUpd.sections.forEach((item) => {
      maxScore += Number(item.scoring.maxValue);
      minScore += Number(item.scoring.minValue);
    });
    templateUpd.maxScore = maxScore;
    templateUpd.minScore = minScore;
    templateUpd.scoring = { minScore, maxScore };
    setTemplate(templateUpd);
  }, [sectionActive]);

  const answerTypesList = [
    "SHORT_TEXT",
    "LONG_TEXT",
    "YES_NO",
    "SINGLE_SELECT",
    "MULTI_SELECT",
    "FLOAT",
    "FLOAT_RANGE",
    "INTEGER",
    "INTEGER_RANGE",
    "DATE",
    "DATE_TIME",
    "URL",
    "USER",
  ];
  const includeIfConditionsList = [
    {
      text: "Choose condition",
      value: null,
    },
    {
      text: "equals",
      value: "EQ",
    },
    {
      text: "not equals",
      value: "NE",
    },
    {
      text: " greater than or equals",
      value: "GTE",
    },
    {
      text: "less than or equals",
      value: "LTE",
    },
    {
      text: "greater than",
      value: "GT",
    },
    {
      text: "less than",
      value: "LT",
    },
    {
      text: "between",
      value: "BETWEEN",
    },
    {
      text: "contains",
      value: "CONTAINS",
    },
  ];

  const handleSectionClick = (section) => {
    setFilters({
      ...filters,
      section: section.name,
      step: section.steps[0].name,
    });
    setSectionActive(section);
    setStateItemsSteps(section.steps);
    setStepActive(section.steps[0]);
    setQuestionsActive(section.steps[0].questions);
    setStateItemsQuestions(section.steps[0].questions);
  };

  const handleStepClick = (step) => {
    setFilters({ ...filters, step: step.name });
    setStepActive(step);
    setQuestionsActive(step.questions);
    setStateItemsQuestions(step.questions);
  };

  const handleChangeTemplate = (e) => {
    setTemplate({ ...template, [e.target.name]: e.target.value });
  };

  const handleChangeSectionScoring = (e) => {
    const sName = e.target.name;
    let scoringUpd = sectionActive.scoring;
    scoringUpd[sName] = Number(e.target.value);
    setSectionActive({
      ...sectionActive,
      scoring: scoringUpd,
      minScore: scoringUpd.minValue,
      maxScore: scoringUpd.maxValue,
    });
  };

  const handleChangeStep = (e) => {
    let stepUpd = { ...stepActive };
    stepUpd[e.target.name] = e.target.value;
    setStepActive(stepUpd);
  };

  const handleChangeStepScoring = (e) => {
    const sName = e.target.name;
    let scoringUpd = stepActive.scoring;
    scoringUpd[sName] = Number(e.target.value);
    setStepActive({
      ...stepActive,
      scoring: scoringUpd,
      minScore: scoringUpd.minValue,
      maxScore: scoringUpd.maxValue,
    });
  };

  /**** FOR QUESTION: ****/
  const handleChange = (e) => {
    const qIndex = Number(e.target.name.replace(/[^0-9]/g, ""));
    const qName = e.target.name.slice(0, e.target.name.indexOf(qIndex));
    let questionsUpd = [...questionsActive];
    questionsUpd[qIndex][qName] = e.target.value;
    setQuestionsActive(questionsUpd);
  };

  const handleChangeQuestionScoring = (e) => {
    const qIndex = Number(e.target.name.replace(/[^0-9]/g, ""));
    const qName = e.target.name.slice(0, e.target.name.indexOf(qIndex));

    let scoringUpd = questionsActive[qIndex].scoring;
    scoringUpd[qName] = Number(e.target.value);

    let questionsUpd = [...questionsActive];
    questionsUpd[qIndex].scoring = scoringUpd;
    questionsUpd[qIndex].minScore = scoringUpd.minValue;
    questionsUpd[qIndex].maxScore = scoringUpd.maxValue;
    setQuestionsActive(questionsUpd);
  };

  const handleChangeQuestionIncludeIf = (e) => {
    console.log(e.target.name, e.target.value, e.target.checked);
    const qIndex = Number(e.target.name.replace(/[^0-9]/g, ""));
    const qName = e.target.name.slice(0, e.target.name.indexOf(qIndex));

    let includeIfUpd = questionsActive[qIndex].includeIf || {};
    if (qName === "includeIf") {
      includeIfUpd = e.target.checked ? null : {};
    } else {
      includeIfUpd[qName] = e.target.value;
    }

    let questionsUpd = [...questionsActive];
    questionsUpd[qIndex].includeIf = includeIfUpd;
    setQuestionsActive(questionsUpd);
  };

  const handleChangeQuestionAnswerOptions = (e) => {
    const oIndex = e.target.name.slice(
      e.target.name.indexOf("-") + 1,
      e.target.name.length
    );
    const qIndex = Number(
      e.target.name.slice(0, e.target.name.indexOf("-")).replace(/[^0-9]/g, "")
    );
    const oName = e.target.name.slice(0, e.target.name.indexOf(qIndex));

    let optionsUpd = questionsActive[qIndex].answerOptions;
    console.log(optionsUpd[oIndex]);
    if (oName === "value") {
      optionsUpd[oIndex].scoring[oName] = Number(e.target.value);
    } else {
      optionsUpd[oIndex][oName] = e.target.value;
    }

    let questionsUpd = [...questionsActive];
    questionsUpd[qIndex].answerOptions = optionsUpd;
    setQuestionsActive(questionsUpd);
  };

  const handleChangeQuestionAnswer = (e) => {
    const qIndex = Number(e.target.name.replace(/[^0-9]/g, ""));
    const aName = e.target.name.slice(0, e.target.name.indexOf(qIndex));

    let answerUpd = questionsActive[qIndex].answer || {};

    if (numberValues.includes(aName)) {
      answerUpd[aName] = Number(e.target.value);
    } else {
      answerUpd[aName] = e.target.value;
    }

    let questionsUpd = [...questionsActive];
    questionsUpd[qIndex].answer = answerUpd;
    questionsUpd[qIndex].answerType = answerUpd.answerType;
    if (aName === "answerType") {
      if (e.target.value !== "SINGLE_SELECT" && aName !== "MULTI_SELECT") {
        questionsUpd[qIndex].answerOptions = [];
      }
    }
    setQuestionsActive(questionsUpd);
  };

  const handleChangeAnswerScoring = (e) => {
    const qIndex = Number(e.target.name.replace(/[^0-9]/g, ""));
    const aName = e.target.name.slice(0, e.target.name.indexOf(qIndex));

    let answerScoringUpd = questionsActive[qIndex].answer.scoring || {};
    answerScoringUpd[aName] = Number(e.target.value);

    let questionsUpd = [...questionsActive];
    questionsUpd[qIndex].answer.scoring = answerScoringUpd;
    setQuestionsActive(questionsUpd);
  };

  const handleAddOption = (index) => {
    const oIndex = questionsActive[index].answerOptions.length;
    let optionsUpd = questionsActive[index].answerOptions;
    optionsUpd.push({
      ...answerOptionEmpty,
      id: uuidv4(),
      name: "option-" + (oIndex + 1),
      ordinal: oIndex,
    });

    let questionsUpd = [...questionsActive];
    questionsUpd[index].answerOptions = optionsUpd;
    setQuestionsActive(questionsUpd);
    setShowSection({
      ...showSection,
      ["qAnswerOption" + index + oIndex]: true,
    });
  };

  const handleAddQuestion = () => {
    const index = stepActive.questions.length;
    let questionsUpd = [...stepActive.questions];
    questionsUpd.push({
      ...questionEmpty,
      id: uuidv4(),
      name: "Question " + (index + 1),
      ordinal: (index + 1) * 10,
    });
    questionsUpd[index].answer.id = uuidv4();
    questionsUpd[index].answer.questionId = questionsUpd[index].id;

    setQuestionsActive(questionsUpd);
    setShowSection({ ...showSection, ["q" + index]: true });
  };

  const handleAddStep = () => {
    const index = sectionActive.steps.length;
    let stepsUpd = [...sectionActive.steps];
    stepsUpd.push({
      ...stepEmpty,
      id: uuidv4(),
      name: "Step " + (index + 1),
      ordinal: (index + 1) * 10,
    });
    setSectionActive({ ...sectionActive, steps: stepsUpd });
    setStateItemsSteps(stepsUpd);
    setStepActive(stepsUpd[index]);
    setFilters({ ...filters, step: stepsUpd[index].name });
    setShowSection({ ...showSection, ["step" + index]: true });
  };

  const handleRemoveOption = (index, oIndex) => {
    console.log(index, oIndex);
    let optionsUpd = questionsActive[index].answerOptions;
    optionsUpd.splice(oIndex, 1);
    console.log(optionsUpd);
    optionsUpd.forEach((item, indexNew) => {
      item.ordinal = indexNew;
    });
    let questionsUpd = [...questionsActive];
    questionsUpd[index].answerOptions = optionsUpd;
    setQuestionsActive(questionsUpd);
  };

  const handleRemoveQuestion = (index) => {
    console.log(index);
    let questionsUpd = [...questionsActive];
    questionsUpd.splice(index, 1);
    console.log(questionsUpd);
    questionsUpd.forEach((item, indexNew) => {
      item.ordinal = (indexNew + 1) * 10;
      item.name = "Question " + (indexNew + 1);
    });
    setQuestionsActive(questionsUpd);
  };

  const handleRemoveStep = (id) => {
    let stepsUpd = [...sectionActive.steps];
    const index = stepsUpd.findIndex((item) => item.id === id);
    stepsUpd.splice(index, 1);
    stepsUpd.forEach((item, indexNew) => {
      item.ordinal = (indexNew + 1) * 10;
      item.name = "Step " + (indexNew + 1);
    });
    const indexNew =
      index - 1 < stepsUpd.length && index - 1 >= 0
        ? index - 1
        : index >= 0
        ? index
        : 0;
    setSectionActive({ ...sectionActive, steps: stepsUpd });
    setStateItemsSteps(stepsUpd);
    setStepActive(stepsUpd[indexNew]);
    setFilters({ ...filters, step: stepsUpd[indexNew].name });
    setShowSection({ ...showSection, ["step" + indexNew]: true });
  };

  const handleChangeTemplateName = async (e) => {
    setNewTemplateName(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let formUpd = { ...template };
    console.log(formUpd);
    const templateNameUpd = newTemplateName || templateName;
    try {
      await postUploadTemplateJson(templateNameUpd, formUpd);
      setLoading(false);
      showTransientAlert(dispatchAlert, {
        type: ALERT_ACTION_KIND.SHOW_SUCCESS_ALERT,
        text: "Template saved!",
      });
    } catch (error) {
      setLoading(false);
      showFetchError({
        error,
        customMsg: "API error while saving the template.",
        object: "template",
        objectName: templateNameUpd,
        operation: "saved",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => handleSubmit(e),
      });
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: "4px",
    margin: `0 0 1px 0`,
    // change background colour if dragging
    background: isDragging ? HEX_COLORS.WHITE : HEX_COLORS.LIGHTGRAY,
    // styles we need to apply on draggables
    ...draggableStyle,
  });
  const getItemStyleHorizontal = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: "4px",
    margin: `0 1px 0 0`,
    // change background colour if dragging
    background: isDragging ? HEX_COLORS.WHITE : HEX_COLORS.LIGHTGRAY,
    // styles we need to apply on draggables
    ...draggableStyle,
  });
  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? HEX_COLORS.BLUE : HEX_COLORS.WHITE,
    width: "100%",
  });
  const getListStyleHorizontal = (isDraggingOver) => ({
    background: isDraggingOver ? HEX_COLORS.BLUE : HEX_COLORS.WHITE,
    display: "flex",
    overflowX: "auto",
  });
  const onDragEndAnswerOptions = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(
      stateItems,
      result.source.index,
      result.destination.index
    );
    items.forEach((item, index) => {
      item.ordinal = index;
    });
    // setStateItems(items);
    let questionsUpd = [...questionsActive];
    questionsUpd[questionActiveIndex].answerOptions = items;
    setQuestionsActive(questionsUpd);
  };
  const onDragEndQuestions = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    console.log(stateItemsQuestions);
    const items = reorder(
      stateItemsQuestions,
      result.source.index,
      result.destination.index
    );
    console.log(items);
    items.forEach((item, index) => {
      item.ordinal = (index + 1) * 10;
      item.name = "Question " + (index + 1);
    });
    setQuestionsActive(items);
  };

  const onDragEndSteps = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(
      stateItemsSteps,
      result.source.index,
      result.destination.index
    );
    items.forEach((item, index) => {
      item.ordinal = (index + 1) * 10;
      item.name = "Step " + (index + 1);
    });
    const index = items.findIndex((item) => item.id === stepActive.id);
    setSectionActive({ ...sectionActive, steps: items });
    setStepActive(items[index]);
    setStateItemsSteps(items);
    setFilters({ ...filters, step: items[index].name });
  };

  return (
    <div className="aqua-container template-upload-container">
      <Container>
        <Row className="pt-4">
          <Col xs={12}>
            <div className="header">
              <Button variant="link" className="btn-back" onClick={onClose}>
                <i className="arrow arrow-lg arrow-gray arrow-left"></i>
              </Button>
              <div className="w-100 d-flex align-items-start">
                <div className="filter-toolbar mb-2 w-100">
                  <h4 className="header-title mb-4">
                    {templateDefault ? "Edit" : "Add"} Template
                  </h4>
                  <Form.Group className="row form-group mb-2 px-0">
                    <Col md={6} className="d-flex mb-2">
                      <Form.Label column sm={3} xl={2} className="ps-0">
                        Name*:{" "}
                      </Form.Label>
                      {templateDefault ? (
                        template.name
                      ) : (
                        <div className="col-sm-9 col-xl-10 px-0">
                          <Form.Control
                            type="text"
                            placeholder="Place Template Name Here"
                            name="name"
                            value={template.name}
                            onChange={handleChangeTemplate}
                          />
                          <Form.Text>
                            Should be without spaces and specific symbols.
                          </Form.Text>
                        </div>
                      )}
                    </Col>
                    <Col md={6} className="d-flex mb-2">
                      <Form.Label column sm={3} xl={2} className="ps-0">
                        File Name*:{" "}
                      </Form.Label>
                      {templateNameDefault ? (
                        templateName
                      ) : (
                        <div className="col-sm-9 col-xl-10 px-0">
                          <Form.Control
                            type="text"
                            placeholder="Place Template File Name Here"
                            name="newTemplateName"
                            value={newTemplateName}
                            onChange={handleChangeTemplateName}
                          />
                          <Form.Text>
                            Should be without spaces and specific symbols.
                          </Form.Text>
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group className="row form-group px-0">
                    <Col md={6} className="d-flex  mb-2">
                      <Form.Label column sm={3} xl={2} className="ps-0">
                        min Score
                      </Form.Label>
                      <Form.Control
                        type="number"
                        step="0.1"
                        placeholder=""
                        readOnly
                        name="minScore"
                        value={template.minScore}
                        onChange={handleChangeTemplate}
                      />
                    </Col>
                    <Col md={6} className="d-flex  mb-2">
                      <Form.Label column sm={3} xl={2} className="ps-0">
                        max Score
                      </Form.Label>
                      <Form.Control
                        type="number"
                        step="0.1"
                        placeholder=""
                        readOnly
                        name="maxScore"
                        value={template.maxScore}
                        onChange={handleChangeTemplate}
                      />
                    </Col>
                  </Form.Group>
                  <Nav className="mb-3">
                    <Nav.Item className="pe-2 fw-semibold">
                      All Sections {">"}
                    </Nav.Item>
                    <div className="nav-flex-overflow">
                      {template.sections.map((section) => {
                        return (
                          <Nav.Item
                            key={section.name}
                            className={
                              section.name === filters.section
                                ? "fw-semi px-2 pseudo-link"
                                : "px-2 pseudo-link"
                            }
                            onClick={() => handleSectionClick(section)}
                          >
                            {section.name}
                          </Nav.Item>
                        );
                      })}
                    </div>
                    <Button
                      variant="link"
                      size="sm"
                      className="border-0 py-0 ps-3 pe-2 w-auto fw-semibold text-decoration-none text-nowrap"
                      onClick={() =>
                        setShowSection({
                          ...showSection,
                          section: !showSection.section,
                        })
                      }
                    >
                      <Icon iconString="edit" className="me-1" />
                      Section
                    </Button>
                  </Nav>

                  <Row
                    className={
                      showSection.section ? "d-flex  mb-4" : "collapsed"
                    }
                  >
                    <Form.Group className="form-group col-12 mb-0 px-3">
                      <Form.Label
                        as={Button}
                        variant="link"
                        className="border-0 p-0 w-auto"
                        onClick={() =>
                          setShowSection({
                            ...showSection,
                            sectionScoring: !showSection.sectionScoring,
                          })
                        }
                      >
                        section scoring
                        <Icon
                          className="ms-3"
                          iconString={
                            showSection.sectionScoring
                              ? "caret-down"
                              : "caret-right"
                          }
                        />
                      </Form.Label>
                    </Form.Group>
                    <div
                      className={
                        showSection.sectionScoring ? "d-flex" : "collapsed"
                      }
                    >
                      <Form.Group className="form-group col-12 col-md-3 d-flex ps-3 pe-0">
                        <Form.Label column sm={7}>
                          min value
                        </Form.Label>
                        <Form.Control
                          type="number"
                          step="0.1"
                          placeholder=""
                          readOnly
                          name="minValue"
                          value={sectionActive.scoring.minValue}
                          onChange={handleChangeSectionScoring}
                        />
                      </Form.Group>
                      <Form.Group className="form-group col-12 col-md-3 d-flex ps-3 pe-0">
                        <Form.Label column sm={7}>
                          max value
                        </Form.Label>
                        <Form.Control
                          type="number"
                          step="0.1"
                          placeholder=""
                          readOnly
                          name="maxValue"
                          value={sectionActive.scoring.maxValue}
                          onChange={handleChangeSectionScoring}
                        />
                      </Form.Group>
                      <Form.Group className="form-group col-12 col-md-3 d-flex ps-3 pe-0">
                        <Form.Label column sm={7}>
                          default value
                        </Form.Label>
                        <Form.Control
                          type="number"
                          step="0.1"
                          placeholder=""
                          name="defaultValue"
                          value={sectionActive.scoring.defaultValue}
                          onChange={handleChangeSectionScoring}
                        />
                      </Form.Group>
                    </div>
                  </Row>

                  {sectionActive && sectionActive.steps ? (
                    <Nav className="mb-2">
                      <Nav.Item className="pe-2 pt-1 fw-semibold">
                        All Steps {">"}
                      </Nav.Item>
                      <DragDropContext onDragEnd={onDragEndSteps}>
                        <Droppable
                          droppableId="droppable"
                          direction="horizontal"
                        >
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={getListStyleHorizontal(
                                snapshot.isDraggingOver
                              )}
                            >
                              {sectionActive.steps.map((step, $indexStep) => (
                                <Draggable
                                  key={step.id}
                                  draggableId={step.id}
                                  index={$indexStep}
                                  className="d-flex"
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyleHorizontal(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <Nav.Item
                                        key={step.name}
                                        className={
                                          step.name === filters.step
                                            ? "fw-semi px-2 pseudo-link"
                                            : "px-2 pseudo-link"
                                        }
                                        onClick={() => handleStepClick(step)}
                                      >
                                        {step.name}
                                      </Nav.Item>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                      <Button
                        variant="link"
                        size="sm"
                        onClick={() => handleAddStep()}
                        className="border-0 py-0 ps-3 pe-2 w-auto fw-semibold text-decoration-none text-nowrap"
                      >
                        <Icon iconString="plus" className="me-1" />
                        Step
                      </Button>
                    </Nav>
                  ) : null}
                </div>
              </div>
            </div>
          </Col>
        </Row>

        {stepActive && stepActive.questions ? (
          <Form className="row template-form" onSubmit={handleSubmit}>
            <Form.Group className="form-group col-12 col-md-6 mb-2">
              <Form.Label>step id</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="id"
                readOnly
                value={stepActive.id || ""}
                onChange={handleChangeStep}
              />
            </Form.Group>
            <Form.Group className="form-group col-12 col-md-6 mb-2 d-flex align-items-end justify-content-end">
              <Button
                variant="outline-info"
                size="sm"
                onClick={() => handleRemoveStep(stepActive.id)}
              >
                Remove step
              </Button>
            </Form.Group>
            <Form.Group className="form-group col-12 col-md-6 mb-2">
              <Form.Label>step name</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="name"
                readOnly
                value={stepActive.name || ""}
                onChange={handleChangeStep}
              />
            </Form.Group>
            <Form.Group className="form-group col-12 col-md-6 mb-2">
              <Form.Label>step ordinal</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="ordinal"
                readOnly
                value={stepActive.ordinal || ""}
                onChange={handleChangeStep}
              />
            </Form.Group>
            <Form.Group className="form-group col-12 col-md-6 mb-2">
              <Form.Label>step Title</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="title"
                value={stepActive.title || ""}
                onChange={handleChangeStep}
              />
            </Form.Group>
            <Form.Group className="form-group col-12 col-md-6 mb-2">
              <Form.Label>step Description</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="description"
                value={stepActive.description || ""}
                onChange={handleChangeStep}
              />
            </Form.Group>
            <Form.Group className="form-group col-12 mb-0">
              <Form.Label
                as={Button}
                variant="link"
                className="border-0 p-0 w-auto"
                onClick={() =>
                  setShowSection({
                    ...showSection,
                    stepScoring: !showSection.stepScoring,
                  })
                }
              >
                step scoring
                <Icon
                  className="ms-3"
                  iconString={
                    showSection.stepScoring ? "caret-down" : "caret-right"
                  }
                />
              </Form.Label>
            </Form.Group>
            <div className={showSection.stepScoring ? "d-flex " : "collapsed"}>
              <Form.Group className="form-group col-12 col-md-3 d-flex pe-0">
                <Form.Label column sm={8}>
                  min value
                </Form.Label>
                <Form.Control
                  type="number"
                  step="0.1"
                  placeholder=""
                  name="minValue"
                  value={stepActive.scoring.minValue}
                  onChange={handleChangeStepScoring}
                />
              </Form.Group>
              <Form.Group className="form-group col-12 col-md-3 d-flex pe-0">
                <Form.Label column sm={8}>
                  max value
                </Form.Label>
                <Form.Control
                  type="number"
                  step="0.1"
                  placeholder=""
                  name="maxValue"
                  value={stepActive.scoring.maxValue}
                  onChange={handleChangeStepScoring}
                />
              </Form.Group>
              <Form.Group className="form-group col-12 col-md-3 d-flex pe-0">
                <Form.Label column sm={8}>
                  default value
                </Form.Label>
                <Form.Control
                  type="number"
                  step="0.1"
                  placeholder=""
                  name="defaultValue"
                  value={stepActive.scoring.defaultValue}
                  onChange={handleChangeStepScoring}
                />
              </Form.Group>
            </div>

            <Form.Group className="form-group col-12 mb-2 mt-4">
              <h3 className="text-secondary fw-normal mb-0">Questions</h3>
            </Form.Group>
            <Form.Group className="form-group col-12">
              <DragDropContext onDragEnd={onDragEndQuestions}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {stepActive.questions.map((question, $index) => (
                        <Draggable
                          key={question.id}
                          draggableId={question.id}
                          index={$index}
                          className="d-flex"
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <div className="col-12 px-0 mb-2 d-flex justify-content-between align-items-end my-3">
                                <p
                                  className="fw-semi mb-0"
                                  onClick={() =>
                                    setShowSection({
                                      ...showSection,
                                      ["q" + $index]: showSection["q" + $index]
                                        ? false
                                        : true,
                                    })
                                  }
                                >
                                  Question {$index + 1}
                                  <Icon
                                    className="ms-3"
                                    iconString={
                                      showSection["q" + $index]
                                        ? "caret-down"
                                        : "caret-right"
                                    }
                                  />
                                </p>
                                <Button
                                  variant="link"
                                  size="sm"
                                  className="border-0 p-0 btn-40 text-danger"
                                  onClick={() => handleRemoveQuestion($index)}
                                >
                                  <Icon iconString="trash" />
                                </Button>
                              </div>
                              <div
                                className={
                                  showSection["q" + $index]
                                    ? "d-block mb-3"
                                    : "collapsed"
                                }
                              >
                                <Form.Group className="form-group d-flex mb-2">
                                  <Form.Label column sm={2} className="ps-0">
                                    id
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    name={"id" + $index}
                                    readOnly
                                    value={question.id || ""}
                                    onChange={handleChange}
                                  />
                                </Form.Group>
                                <Form.Group className="form-group d-flex mb-2">
                                  <Form.Label column sm={2} className="ps-0">
                                    name
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    name={"name" + $index}
                                    value={question.name || ""}
                                    onChange={handleChange}
                                  />
                                </Form.Group>
                                <Form.Group className="form-group d-flex mb-2">
                                  <Form.Label column sm={2} className="ps-0">
                                    ordinal
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    readOnly
                                    name={"ordinal" + $index}
                                    value={question.ordinal || ""}
                                    onChange={handleChange}
                                  />
                                </Form.Group>
                                <Form.Group className="form-group d-flex mb-2">
                                  <Form.Label column sm={2} className="ps-0">
                                    text
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    name={"text" + $index}
                                    value={question.text || ""}
                                    onChange={handleChange}
                                  />
                                </Form.Group>
                                <Form.Group className="form-group d-flex mb-2">
                                  <Form.Label column sm={2} className="ps-0">
                                    guidance
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    name={"guidance" + $index}
                                    value={question.guidance || ""}
                                    onChange={handleChange}
                                  />
                                </Form.Group>
                                <Form.Group className="form-group d-flex mb-2">
                                  <Form.Label column sm={2} className="ps-0">
                                    hint
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    name={"hint" + $index}
                                    value={question.hint || ""}
                                    onChange={handleChange}
                                  />
                                </Form.Group>
                                <Form.Group className="form-group col-12 mb-0 mt-3">
                                  <Form.Label
                                    as={Button}
                                    variant="link"
                                    className="border-0 p-0 w-auto"
                                    onClick={() =>
                                      setShowSection({
                                        ...showSection,
                                        ["qScoring" + $index]: showSection[
                                          "qScoring" + $index
                                        ]
                                          ? false
                                          : true,
                                      })
                                    }
                                  >
                                    scoring
                                    <Icon
                                      className="ms-3"
                                      iconString={
                                        showSection["qScoring" + $index]
                                          ? "caret-down"
                                          : "caret-right"
                                      }
                                    />
                                  </Form.Label>
                                </Form.Group>
                                <div
                                  className={
                                    showSection["qScoring" + $index]
                                      ? "d-flex flex-wrap"
                                      : "collapsed"
                                  }
                                >
                                  <Form.Group className="form-group col-12 col-md-3 d-flex pe-0 mt-3 mb-2">
                                    <Form.Label
                                      column
                                      sm={8}
                                      className="text-secondary"
                                    >
                                      min value
                                    </Form.Label>
                                    <Form.Control
                                      type="number"
                                      step="0.1"
                                      placeholder=""
                                      name={"minValue" + $index}
                                      value={question.scoring.minValue}
                                      onChange={handleChangeQuestionScoring}
                                    />
                                  </Form.Group>
                                  <Form.Group className="form-group col-12 col-md-3 d-flex pe-0 mt-3 mb-2">
                                    <Form.Label
                                      column
                                      sm={8}
                                      className="text-secondary"
                                    >
                                      max value
                                    </Form.Label>
                                    <Form.Control
                                      type="number"
                                      step="0.1"
                                      placeholder=""
                                      name={"maxValue" + $index}
                                      value={question.scoring.maxValue}
                                      onChange={handleChangeQuestionScoring}
                                    />
                                  </Form.Group>
                                  <Form.Group className="form-group col-12 col-md-3 d-flex pe-0 mt-3 mb-2">
                                    <Form.Label
                                      column
                                      sm={8}
                                      className="text-secondary"
                                    >
                                      default value
                                    </Form.Label>
                                    <Form.Control
                                      type="number"
                                      step="0.1"
                                      placeholder=""
                                      name={"defaultValue" + $index}
                                      value={question.scoring.defaultValue}
                                      onChange={handleChangeQuestionScoring}
                                    />
                                  </Form.Group>
                                </div>

                                <Form.Group className="form-group col-12 mb-0 mt-3">
                                  <Form.Label
                                    as={Button}
                                    variant="link"
                                    className="border-0 p-0 w-auto"
                                    onClick={() =>
                                      setShowSection({
                                        ...showSection,
                                        ["qIncludeIf" + $index]: showSection[
                                          "qIncludeIf" + $index
                                        ]
                                          ? false
                                          : true,
                                      })
                                    }
                                  >
                                    include If
                                    <Icon
                                      className="ms-3"
                                      iconString={
                                        showSection["qIncludeIf" + $index]
                                          ? "caret-down"
                                          : "caret-right"
                                      }
                                    />
                                  </Form.Label>
                                  <Form.Check
                                    custom
                                    type="checkbox"
                                    className="ms-3 mt-3"
                                  >
                                    <Form.Check.Input
                                      type="checkbox"
                                      variant="info"
                                      value="null"
                                      name={"includeIf" + $index}
                                      id={"includeIf" + $index}
                                      checked={question.includeIf == null}
                                      onChange={handleChangeQuestionIncludeIf}
                                    />
                                    <Form.Check.Label
                                      className="smaller"
                                      htmlFor={"includeIf" + $index}
                                    >
                                      Null
                                    </Form.Check.Label>
                                  </Form.Check>
                                </Form.Group>
                                <div
                                  className={
                                    showSection["qIncludeIf" + $index]
                                      ? ""
                                      : "collapsed"
                                  }
                                >
                                  <Form.Group className="form-group col-12 d-flex mb-2 mt-3">
                                    <Form.Label
                                      column
                                      sm={2}
                                      className="text-secondary"
                                    >
                                      answer Id
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder=""
                                      name={"answerId" + $index}
                                      value={
                                        question.includeIf &&
                                        question.includeIf.answerId
                                          ? question.includeIf.answerId
                                          : ""
                                      }
                                      onChange={handleChangeQuestionIncludeIf}
                                    />
                                  </Form.Group>
                                  <Form.Group className="form-group col-12 d-flex mb-2">
                                    <Form.Label
                                      column
                                      sm={2}
                                      className="text-secondary"
                                    >
                                      condition
                                    </Form.Label>
                                    <Form.Select
                                      name={"condition" + $index}
                                      value={
                                        question.includeIf &&
                                        question.includeIf.condition
                                          ? question.includeIf.condition
                                          : ""
                                      }
                                      onChange={handleChangeQuestionIncludeIf}
                                    >
                                      {includeIfConditionsList.map((item) => {
                                        return (
                                          <option
                                            key={item.value}
                                            value={item.value}
                                          >
                                            {item.text}
                                          </option>
                                        );
                                      })}
                                    </Form.Select>
                                  </Form.Group>

                                  <Form.Group className="form-group col-12 d-flex mb-3">
                                    <Form.Label
                                      column
                                      sm={2}
                                      className="text-secondary"
                                    >
                                      value
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder=""
                                      name={"value" + $index}
                                      value={
                                        question.includeIf &&
                                        question.includeIf.value
                                          ? question.includeIf.value
                                          : ""
                                      }
                                      onChange={handleChangeQuestionIncludeIf}
                                    />
                                  </Form.Group>
                                </div>

                                <Form.Group className="form-group col-12 mb-0 mt-3">
                                  <Form.Label
                                    as={Button}
                                    variant="link"
                                    className="border-0 p-0 w-auto"
                                    onClick={() =>
                                      setShowSection({
                                        ...showSection,
                                        ["qAnswer" + $index]: showSection[
                                          "qAnswer" + $index
                                        ]
                                          ? false
                                          : true,
                                      })
                                    }
                                  >
                                    answer
                                    <Icon
                                      className="ms-3"
                                      iconString={
                                        showSection["qAnswer" + $index]
                                          ? "caret-down"
                                          : "caret-right"
                                      }
                                    />
                                  </Form.Label>
                                </Form.Group>
                                <div
                                  className={
                                    showSection["qAnswer" + $index]
                                      ? ""
                                      : "collapsed"
                                  }
                                >
                                  <Form.Group className="form-group col-12 d-flex mb-2 mt-3">
                                    <Form.Label
                                      column
                                      sm={2}
                                      className="text-secondary"
                                    >
                                      answer Id
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder=""
                                      name={"id" + $index}
                                      readOnly
                                      value={question.answer.id || ""}
                                      onChange={handleChangeQuestionAnswer}
                                    />
                                  </Form.Group>
                                  <Form.Group className="form-group col-12 d-flex mb-2">
                                    <Form.Label
                                      column
                                      sm={2}
                                      className="text-secondary"
                                    >
                                      answer type
                                    </Form.Label>
                                    <Form.Select
                                      name={"answerType" + $index}
                                      value={question.answer.answerType}
                                      onChange={handleChangeQuestionAnswer}
                                    >
                                      {answerTypesList.map((item) => {
                                        return (
                                          <option key={item} value={item}>
                                            {item}
                                          </option>
                                        );
                                      })}
                                    </Form.Select>
                                  </Form.Group>
                                  <Form.Group className="form-group col-12 d-flex mb-3">
                                    <Form.Label
                                      column
                                      sm={2}
                                      className="text-secondary"
                                    >
                                      question id
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder=""
                                      name={"questionId" + $index}
                                      readOnly
                                      value={question.answer.questionId || ""}
                                      onChange={handleChangeQuestionAnswer}
                                    />
                                  </Form.Group>
                                  <Form.Group className="form-group col-12 d-flex mb-0 mt-3">
                                    <Form.Label
                                      as={Button}
                                      variant="link"
                                      column
                                      sm={3}
                                      className="border-0 py-0 pe-0 w-auto text-secondary"
                                      onClick={() =>
                                        setShowSection({
                                          ...showSection,
                                          ["qAnswerScoring" + $index]:
                                            showSection[
                                              "qAnswerScoring" + $index
                                            ]
                                              ? false
                                              : true,
                                        })
                                      }
                                    >
                                      scoring
                                      <Icon
                                        className="ms-3"
                                        iconString={
                                          showSection["qAnswerScoring" + $index]
                                            ? "caret-down"
                                            : "caret-right"
                                        }
                                      />
                                    </Form.Label>
                                  </Form.Group>
                                  <div
                                    className={
                                      showSection["qAnswerScoring" + $index]
                                        ? "d-flex flex-wrap mt-3"
                                        : "collapsed"
                                    }
                                  >
                                    <Form.Group className="form-group col-12 col-md-4 d-flex mb-2">
                                      <Form.Label
                                        column
                                        sm={6}
                                        className="ps-4 pe-1 text-secondary"
                                      >
                                        min value
                                      </Form.Label>
                                      <Form.Control
                                        type="number"
                                        step="0.1"
                                        placeholder=""
                                        name={"minValue" + $index}
                                        value={question.answer.scoring.minValue}
                                        onChange={handleChangeAnswerScoring}
                                      />
                                    </Form.Group>
                                    <Form.Group className="form-group col-12 col-md-4 d-flex mb-2">
                                      <Form.Label
                                        column
                                        sm={6}
                                        className="ps-4 pe-1 text-secondary"
                                      >
                                        max value
                                      </Form.Label>
                                      <Form.Control
                                        type="number"
                                        step="0.1"
                                        placeholder=""
                                        name={"maxValue" + $index}
                                        value={question.answer.scoring.maxValue}
                                        onChange={handleChangeAnswerScoring}
                                      />
                                    </Form.Group>
                                    <Form.Group className="form-group col-12 col-md-4 d-flex mb-2">
                                      <Form.Label
                                        column
                                        sm={6}
                                        className="ps-4 pe-1 text-secondary"
                                      >
                                        default value
                                      </Form.Label>
                                      <Form.Control
                                        type="number"
                                        step="0.1"
                                        placeholder=""
                                        name={"defaultValue" + $index}
                                        value={
                                          question.answer.scoring.defaultValue
                                        }
                                        onChange={handleChangeAnswerScoring}
                                      />
                                    </Form.Group>
                                    <Form.Group className="form-group col-12 col-md-4 d-flex  mb-2">
                                      <Form.Label
                                        column
                                        sm={6}
                                        className="ps-4 pe-1 text-secondary"
                                      >
                                        yes Value
                                      </Form.Label>
                                      <Form.Control
                                        type="number"
                                        step="0.1"
                                        placeholder=""
                                        name={"yesValue" + $index}
                                        value={question.answer.scoring.yesValue}
                                        onChange={handleChangeAnswerScoring}
                                      />
                                    </Form.Group>
                                    <Form.Group className="form-group col-12 col-md-4 d-flex  mb-3">
                                      <Form.Label
                                        column
                                        sm={6}
                                        className="ps-4 pe-1 text-secondary"
                                      >
                                        no Value
                                      </Form.Label>
                                      <Form.Control
                                        type="number"
                                        step="0.1"
                                        placeholder=""
                                        name={"noValue" + $index}
                                        value={question.answer.scoring.noValue}
                                        onChange={handleChangeAnswerScoring}
                                      />
                                    </Form.Group>
                                  </div>
                                </div>

                                <Form.Group className="form-group d-flex mb-2 mt-3">
                                  <Form.Label column sm={2} className="ps-0">
                                    answer Units
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    name={"answerUnits" + $index}
                                    value={question.answerUnits || ""}
                                    onChange={handleChange}
                                  />
                                </Form.Group>
                                {question.answerType === "SINGLE_SELECT" ||
                                question.answerType === "MULTI_SELECT" ? (
                                  <div>
                                    <Form.Group className="form-group col-12 mb-0 my-3">
                                      <Form.Label
                                        as={Button}
                                        variant="link"
                                        className="border-0 p-0 w-auto"
                                        onClick={() => {
                                          setShowSection({
                                            ...showSection,
                                            ["qAnswerOptions" + $index]:
                                              showSection[
                                                "qAnswerOptions" + $index
                                              ]
                                                ? false
                                                : true,
                                          });
                                          setQuestionActiveIndex($index);
                                          setStateItems(
                                            questionsActive[$index]
                                              .answerOptions
                                          );
                                        }}
                                      >
                                        answer options
                                        <Icon
                                          className="ms-3"
                                          iconString={
                                            showSection[
                                              "qAnswerOptions" + $index
                                            ]
                                              ? "caret-down"
                                              : "caret-right"
                                          }
                                        />
                                      </Form.Label>
                                    </Form.Group>

                                    <div
                                      className={
                                        showSection["qAnswerOptions" + $index]
                                          ? "form-group"
                                          : "collapsed"
                                      }
                                    >
                                      {loading ? (
                                        <Loader status="Saving" />
                                      ) : question.answerOptions.length ? (
                                        <DragDropContext
                                          onDragEnd={onDragEndAnswerOptions}
                                        >
                                          <Droppable droppableId="droppable">
                                            {(provided, snapshot) => (
                                              <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={getListStyle(
                                                  snapshot.isDraggingOver
                                                )}
                                              >
                                                {question.answerOptions.map(
                                                  (option, $indexO) => (
                                                    <Draggable
                                                      key={option.id}
                                                      draggableId={option.id}
                                                      index={$indexO}
                                                      className="d-flex flex-wrap"
                                                    >
                                                      {(provided, snapshot) => (
                                                        <div
                                                          ref={
                                                            provided.innerRef
                                                          }
                                                          {...provided.draggableProps}
                                                          {...provided.dragHandleProps}
                                                          style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided
                                                              .draggableProps
                                                              .style
                                                          )}
                                                        >
                                                          <div className="col-12 px-0 d-flex justify-content-between">
                                                            <Form.Label
                                                              as={Button}
                                                              variant="link"
                                                              column
                                                              sm={2}
                                                              className="w-auto border-0 ps-0 py-0 text-secondary"
                                                              onClick={() =>
                                                                setShowSection({
                                                                  ...showSection,
                                                                  ["qAnswerOption" +
                                                                  $index +
                                                                  $indexO]:
                                                                    showSection[
                                                                      "qAnswerOption" +
                                                                        $index +
                                                                        $indexO
                                                                    ]
                                                                      ? false
                                                                      : true,
                                                                })
                                                              }
                                                            >
                                                              option{" "}
                                                              {$indexO + 1}
                                                              <Icon
                                                                className="ms-3"
                                                                iconString={
                                                                  showSection[
                                                                    "qAnswerOption" +
                                                                      $index +
                                                                      $indexO
                                                                  ]
                                                                    ? "caret-down"
                                                                    : "caret-right"
                                                                }
                                                              />
                                                            </Form.Label>
                                                            <Button
                                                              variant="link"
                                                              size="sm"
                                                              className="border-0 p-0 btn-40 text-end text-danger"
                                                              onClick={() =>
                                                                handleRemoveOption(
                                                                  $index,
                                                                  $indexO
                                                                )
                                                              }
                                                            >
                                                              <Icon iconString="trash" />
                                                            </Button>
                                                          </div>
                                                          <div
                                                            className={
                                                              showSection[
                                                                "qAnswerOption" +
                                                                  $index +
                                                                  $indexO
                                                              ]
                                                                ? "w-100 d-flex flex-wrap"
                                                                : "collapsed"
                                                            }
                                                          >
                                                            <Form.Group className="form-group col-12 col-md-6 d-flex mb-2">
                                                              <Form.Label
                                                                column
                                                                sm={3}
                                                                className="ps-4 pe-1"
                                                              >
                                                                Id
                                                              </Form.Label>
                                                              <Form.Control
                                                                type="text"
                                                                placeholder=""
                                                                name={
                                                                  "id" +
                                                                  $index +
                                                                  "-" +
                                                                  $indexO
                                                                }
                                                                readOnly={
                                                                  option.id
                                                                    ? true
                                                                    : false
                                                                }
                                                                value={
                                                                  option.id ||
                                                                  ""
                                                                }
                                                                onChange={
                                                                  handleChangeQuestionAnswerOptions
                                                                }
                                                              />
                                                            </Form.Group>
                                                            <Form.Group className="form-group col-12 col-md-6 d-flex mb-2">
                                                              <Form.Label
                                                                column
                                                                sm={3}
                                                                className="ps-4 pe-1"
                                                              >
                                                                name
                                                              </Form.Label>
                                                              <Form.Control
                                                                type="text"
                                                                placeholder=""
                                                                name={
                                                                  "name" +
                                                                  $index +
                                                                  "-" +
                                                                  $indexO
                                                                }
                                                                value={
                                                                  option.name ||
                                                                  ""
                                                                }
                                                                onChange={
                                                                  handleChangeQuestionAnswerOptions
                                                                }
                                                              />
                                                            </Form.Group>
                                                            <Form.Group className="form-group col-12 col-md-6 d-flex mb-2">
                                                              <Form.Label
                                                                column
                                                                sm={3}
                                                                className="ps-4 pe-1"
                                                              >
                                                                ordinal
                                                              </Form.Label>
                                                              <Form.Control
                                                                type="text"
                                                                placeholder=""
                                                                name={
                                                                  "ordinal" +
                                                                  $index +
                                                                  "-" +
                                                                  $indexO
                                                                }
                                                                readOnly
                                                                value={
                                                                  option.ordinal ||
                                                                  ""
                                                                }
                                                                onChange={
                                                                  handleChangeQuestionAnswerOptions
                                                                }
                                                              />
                                                            </Form.Group>
                                                            <Form.Group className="form-group col-12 col-md-6 d-flex mb-2">
                                                              <Form.Label
                                                                column
                                                                sm={3}
                                                                className="ps-4 pe-1"
                                                              >
                                                                text
                                                              </Form.Label>
                                                              <Form.Control
                                                                type="text"
                                                                placeholder=""
                                                                name={
                                                                  "text" +
                                                                  $index +
                                                                  "-" +
                                                                  $indexO
                                                                }
                                                                value={
                                                                  option.text ||
                                                                  ""
                                                                }
                                                                onChange={
                                                                  handleChangeQuestionAnswerOptions
                                                                }
                                                              />
                                                            </Form.Group>
                                                            <Form.Group className="form-group col-12 col-md-6 d-flex mb-2">
                                                              <Form.Label
                                                                column
                                                                sm={3}
                                                                className="ps-4 pe-1"
                                                              >
                                                                hint
                                                              </Form.Label>
                                                              <Form.Control
                                                                type="text"
                                                                placeholder=""
                                                                name={
                                                                  "hint" +
                                                                  $index +
                                                                  "-" +
                                                                  $indexO
                                                                }
                                                                value={
                                                                  option.hint ||
                                                                  ""
                                                                }
                                                                onChange={
                                                                  handleChangeQuestionAnswerOptions
                                                                }
                                                              />
                                                            </Form.Group>
                                                            <Form.Group className="form-group col-12 col-md-6 d-flex mb-3">
                                                              <Form.Label
                                                                column
                                                                sm={3}
                                                                className="ps-4 pe-1"
                                                              >
                                                                scoring
                                                              </Form.Label>
                                                              <Form.Control
                                                                type="number"
                                                                step="0.1"
                                                                placeholder=""
                                                                name={
                                                                  "value" +
                                                                  $index +
                                                                  "-" +
                                                                  $indexO
                                                                }
                                                                value={
                                                                  option.scoring
                                                                    .value
                                                                }
                                                                onChange={
                                                                  handleChangeQuestionAnswerOptions
                                                                }
                                                              />
                                                            </Form.Group>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </Draggable>
                                                  )
                                                )}
                                                {provided.placeholder}
                                              </div>
                                            )}
                                          </Droppable>
                                        </DragDropContext>
                                      ) : null}

                                      <Form.Group className="form-group col-12 px-0 mb-3 mt-1">
                                        <Button
                                          variant="link"
                                          size="sm"
                                          onClick={() =>
                                            handleAddOption($index)
                                          }
                                          className="border-0 py-0 w-auto fw-semibold text-decoration-none"
                                        >
                                          <Icon
                                            iconString="plus"
                                            className="me-1"
                                          />
                                          Option
                                        </Button>
                                      </Form.Group>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Form.Group>
            <Form.Group className="form-group col-12 mb-3 mt-1">
              <Button
                variant="link"
                size="sm"
                onClick={() => handleAddQuestion()}
                className="border-0 py-0 w-auto fw-semibold text-decoration-none"
              >
                <Icon iconString="plus" className="me-1" />
                Question
              </Button>
            </Form.Group>

            {loading ? (
              <Loader status="Saving" />
            ) : (
              <FormGroup className="form-group col-12 mt-5">
                <Button
                  variant="secondary"
                  size="sm"
                  type="submit"
                  className="d-inline-block"
                >
                  Save
                </Button>
                {templateNameDefault ? (
                  <Col
                    xs="10"
                    className="d-inline-flex justify-content-between align-items-center "
                  >
                    <div className="col-md-3 text-center">OR</div>
                    <div className="col-md-9 pe-0 d-flex">
                      <Form.Control
                        type="text"
                        placeholder="New template file name"
                        name="newTemplateName"
                        value={newTemplateName}
                        className="me-3"
                        onChange={handleChangeTemplateName}
                      />
                      <Button
                        variant="secondary"
                        size="sm"
                        className="btn-min-140"
                        type="submit"
                      >
                        Save as new
                      </Button>
                    </div>
                  </Col>
                ) : null}
              </FormGroup>
            )}
          </Form>
        ) : null}
      </Container>
    </div>
  );
};

export default TemplateEdit;
