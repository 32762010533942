/* It is used for Editing and Creating Farms*/
import React, { useContext, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  ButtonToolbar,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useHistory, RouteComponentProps } from "react-router-dom";
import { isEqual } from "lodash";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import { AxiosError } from "axios";

import { AlertsContext } from "../../contexts/AlertsContext";
import { ErrorPopupContext } from "../../contexts/ErrorPopupContext";
import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import {
  deleteFarm,
  getActiveSurveysForFarm,
  getMyCompanyFarm,
  getCompanyUsers,
  getFarmUsers,
  putUpdateFarm,
  postCreateFarm,
} from "../../network/request";
import { ALERT_ACTION_KIND } from "../../constants/enums";
import { showTransientAlert, showFetchError } from "../../utils/alerts";
import { formatDateCutUTC } from "../../utils/date";
import { CURRENCY_CODE_LIST, SPECIES_LIST } from "../../constants/constants";
import { GEOCODER_SEARCH_ICON_SVG_PATH } from "../../constants/imagesConstants";
import {
  INITIAL_FARM,
  INITIAL_MARKER,
  INITIAL_MAP_POSITION,
} from "../../constants/initialData";
import {
  FarmResponse,
  UserInfoAndRoles,
  UpdateFarmRequest,
  CreateFarmRequest,
} from "../../models/be_models";
import { MapPosition, Marker, FormattedMultiSelectItem } from "../../models";

import Loader from "../common/Loader";
import AquaMapBoxGl from "../general/AquaMapBoxGl";
import UsersMultiSelect from "../users/UsersMultiSelect";
import DeleteConfirmationModal from "../general/DeleteConfirmationModal";
import GeocoderInput from "../../uiComponents/GeocoderInput";

type FarmContainerProps = {
  farmId: string;
  companyId: string;
};

const FarmContainer: React.FC<RouteComponentProps<FarmContainerProps>> = (
  props
) => {
  const history = useHistory();
  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);
  const { currentUser } = useContext(CurrentUserContext);

  const farmId =
    props.match.params.farmId === "new" ? "" : props.match.params.farmId;
  const companyId = props.match.params.companyId;

  const [farm, setFarm] = useState<FarmResponse & { numberOfSurveys?: number }>(
    INITIAL_FARM
  );
  const [form, setForm] = useState<FarmResponse>(INITIAL_FARM);

  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(true);
  const [deleteModalShow, setDeleteModalShow] = useState(false);

  const [farmUsers, setFarmUsers] = useState<UserInfoAndRoles[]>([]);
  const [companyUsers, setCompanyUsers] = useState<UserInfoAndRoles[]>([]);
  const [multiSelectUserList, setMultiSelectUserList] = useState<
    FormattedMultiSelectItem[]
  >([]);

  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [isCountryTyping, setIsCountryTyping] = useState(false);

  const [selectedProvince, setSelectedProvince] = useState<string>("");
  const [isProvinceTyping, setIsProvinceTyping] = useState(false);

  const [selectedSpecies, setSelectedSpecies] = useState<string[]>([]); //AsyncTypeahead works with arrays
  const [isSpeciesTyping, setIsSpeciesTyping] = useState(false);

  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState<string[]>(
    []
  ); //AsyncTypeahead works with arrays
  const [isCurrencyTyping, setIsCurrencyTyping] = useState(false);

  const [mapPosition, setMapPosition] =
    useState<MapPosition>(INITIAL_MAP_POSITION);
  const [marker, setMarker] = useState<Marker>(INITIAL_MARKER);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "latitude") {
      setMapPosition((prev) => ({ ...prev, lat: +e.target.value }));
      setMarker({ ...marker, lat: +e.target.value });
    }

    if (e.target.name === "longitude") {
      setMapPosition((prev) => ({ ...prev, lng: +e.target.value }));
      setMarker({ ...marker, lng: +e.target.value });
    }

    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data: result } = await getMyCompanyFarm(companyId, farmId);

        setFarm({
          ...result,
          surveyDate: formatDateCutUTC(result.surveyDate),
        });
        setForm({
          ...result,
          surveyDate: formatDateCutUTC(result.surveyDate),
        });

        setSelectedCountry({ ...result }.country || "");
        setSelectedProvince({ ...result }.stateProvince || "");

        setSelectedSpecies([{ ...result }.species || ""]);
        setSelectedCurrencyCode([{ ...result }.currencyCode || ""]);
      } catch (error) {
        showFetchError({
          error: error as AxiosError | Error,
          customMsg: "API error while loading the farm details.",
          object: "farm details",
          objectName: "plural",
          operation: "loaded",
          dispatchAlert,
          dispatchPopup,
          onRetry: () => fetchData(),
        });
      } finally {
        setLoading(false);
      }
    };

    if (farmId) fetchData();
  }, [farmId, companyId]);

  useEffect(() => {
    const fetchData = async () => {
      if (farm) {
        try {
          setLoading(true);
          const { data: farmsUsersResult } = await getFarmUsers(
            companyId,
            farmId
          );
          setFarmUsers(farmsUsersResult);
        } catch (error) {
          showFetchError({
            error: error as AxiosError | Error,
            customMsg: "API error while loading the farm users.",
            object: "farm users",
            objectName: "plural",
            operation: "loaded",
            dispatchAlert,
            dispatchPopup,
            onRetry: () => fetchData(),
          });
        } finally {
          setLoading(false);
        }
      }
    };

    setMapPosition((prev) => ({
      ...prev,
      lat: farm.latitude || 0,
      lng: farm.longitude || 0,
      zoom: 14,
    }));

    setMarker({
      id: farmId,
      lat: farm.latitude || 0,
      lng: farm.longitude || 0,
    });

    if (farmId) fetchData();
  }, [companyId, farm, farmId]);

  useEffect(() => {
    const fetchData = async () => {
      if (farm) {
        try {
          setLoading(true);
          const { data: companyUsersResult } = await getCompanyUsers(companyId);
          setCompanyUsers(companyUsersResult);

          if (!farmId)
            setFarmUsers(
              companyUsersResult.filter(
                (user) => user.user.externalId === currentUser.externalId
              )
            );
        } catch (error) {
          showFetchError({
            error: error as AxiosError | Error,
            customMsg: "API error while loading the company users.",
            object: "company users",
            objectName: "plural",
            operation: "loaded",
            dispatchAlert,
            dispatchPopup,
            onRetry: () => fetchData(),
          });
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [companyId, farm, farmId, currentUser]);

  const goBack = () => {
    return history.goBack();
  };

  const updateFarmRequest = async (payload: UpdateFarmRequest) => {
    try {
      await putUpdateFarm(companyId, farmId, payload);

      showTransientAlert(dispatchAlert, {
        type: ALERT_ACTION_KIND.SHOW_SUCCESS_ALERT,
        text: "Farm saved!",
      });
    } catch (error) {
      showFetchError({
        error: error as AxiosError | Error,
        customMsg: "API error while updating the farm.",
        object: "farm",
        objectName: payload.name,
        operation: "updated",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => updateFarmRequest(payload),
      });
    }
  };

  const createFarmRequest = async (payload: CreateFarmRequest) => {
    try {
      await postCreateFarm(companyId, payload);

      showTransientAlert(dispatchAlert, {
        type: ALERT_ACTION_KIND.SHOW_SUCCESS_ALERT,
        text: "Farm saved!",
      });
    } catch (error) {
      showFetchError({
        error: error as AxiosError | Error,
        customMsg: "API error while creating the farm.",
        object: "farm",
        objectName: payload.name,
        operation: "created",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => createFarmRequest(payload),
      });
    }
  };

  const handleSaveMainData = async (
    e: React.MouseEvent,
    payload: CreateFarmRequest | UpdateFarmRequest
  ) => {
    e.preventDefault();

    if (farm && farmId.length) {
      updateFarmRequest(payload);
    } else {
      createFarmRequest(payload);
    }
  };

  const handleSave = (e: React.MouseEvent) => {
    if (isCountryTyping) {
      showTransientAlert(dispatchAlert, {
        type: ALERT_ACTION_KIND.SHOW_ERROR_ALERT,
        text: "Select country from the list",
      });
      return;
    }

    if (isProvinceTyping) {
      showTransientAlert(dispatchAlert, {
        type: ALERT_ACTION_KIND.SHOW_ERROR_ALERT,
        text: "Select province from the list",
      });
      return;
    }

    if (isSpeciesTyping) {
      showTransientAlert(dispatchAlert, {
        type: ALERT_ACTION_KIND.SHOW_ERROR_ALERT,
        text: "Select species from the list",
      });
      return;
    }

    if (isCurrencyTyping) {
      showTransientAlert(dispatchAlert, {
        type: ALERT_ACTION_KIND.SHOW_ERROR_ALERT,
        text: "Select currency code from the list",
      });
      return;
    }

    setSaving(true);

    const fetchedFarmUsersIds = farmUsers.map((user) => user.user.externalId);
    const changedUsersIds = multiSelectUserList.map((user) => user.id);

    const payload = {
      userExtIds: !isEqual(fetchedFarmUsersIds, changedUsersIds)
        ? changedUsersIds
        : fetchedFarmUsersIds,
      name: form.name,
      description: form.description,
      farmArea: form.farmArea,
      growingArea: form.growingArea,
      latitude: form.latitude,
      longitude: form.longitude,
      surveyDate: form.surveyDate,
      tonnage: form.tonnage,
      stockingWeight: form.stockingWeight,
      harvestWeight: form.harvestWeight,
      stockingDensity: form.stockingDensity,
      animalCount: form.animalCount,
      species: form.species,
      startYear: form.startYear,
      duration: form.duration,
      siteId: form.siteId,
      siteType: form.siteType,
      structureCount: form.structureCount,
      maxValue: form.maxValue,
      avgValue: form.avgValue,
      actualValue: form.actualValue,
      currencyCode: form.currencyCode,
      country: form.country,
      stateProvince: form.stateProvince,
    };

    handleSaveMainData(e, payload);

    setSaving(false);

    goBack();
  };

  const renderDeleteModal = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleting(true);
    setDeleteModalShow(true);

    const farmWithSurveysNumber = { ...farm, numberOfSurveys: 0 };

    try {
      const { data } = await getActiveSurveysForFarm(
        companyId,
        farmWithSurveysNumber.externalId
      );

      const numberOfSurveys = data.companies[0]?.farms[0]?.surveys?.length;

      setFarm({ ...farmWithSurveysNumber, numberOfSurveys });
    } catch (error) {
      setDeleteModalShow(false);
      showFetchError({
        error: error as AxiosError | Error,
        customMsg: "API error while getting farm's active audits.",
        object: "farm audits",
        objectName: "plural",
        operation: "loaded",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => renderDeleteModal(e),
      });
    } finally {
      setDeleting(false);
    }
  };

  const handleDeleteFarm = async () => {
    setDeleting(true);
    try {
      await deleteFarm(companyId, farm.externalId);
      showTransientAlert(dispatchAlert, {
        type: ALERT_ACTION_KIND.SHOW_SUCCESS_ALERT,
        text: "Farm deleted!",
      });
      goBack();
    } catch (error) {
      showFetchError({
        error: error as AxiosError | Error,
        customMsg: "API error while deleting the farm.",
        object: "farm",
        objectName: farm.name,
        operation: "deleted",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => handleDeleteFarm(),
      });
    } finally {
      setDeleting(false);
      setDeleteModalShow(false);
    }
  };

  const handleCurrencyCodeSearchSelection = async ([selected]: string[]) => {
    if (!selected) {
      setIsCurrencyTyping(true);
      setSelectedCurrencyCode([]);
    } else {
      setSelectedCurrencyCode([selected]);
      setForm({ ...form, currencyCode: selected });
      setIsCurrencyTyping(false);
    }
  };

  const handleCountrySearchSelection = async (selected: string) => {
    if (!selected) {
      setIsCountryTyping(true);
      setSelectedCountry("");
    } else {
      setSelectedCountry(selected);
      setSelectedProvince("");
      setForm((prev) => ({ ...prev, country: selected, stateProvince: "" }));
      setIsCountryTyping(false);
    }
  };

  const handleProvinceSearchSelection = async (selected: string) => {
    if (!selected) {
      setIsProvinceTyping(true);
      setSelectedProvince("");
    } else {
      setSelectedProvince(selected);
      setForm((prev) => ({ ...prev, stateProvince: selected }));
      setIsProvinceTyping(false);
    }
  };

  const handleSpeciesSearchSelection = async ([selected]: string[]) => {
    if (!selected) {
      setIsSpeciesTyping(true);
      setSelectedSpecies([]);
    } else {
      setSelectedSpecies([selected]);
      setForm({ ...form, species: selected });
      setIsSpeciesTyping(false);
    }
  };

  const renderListValidationError = () => {
    return (
      <span className="error ms-auto text-lowercase">Select from the list</span>
    );
  };

  useEffect(() => {
    if (!selectedCountry) setSelectedProvince("");
  }, [selectedCountry]);

  return loading ? (
    <div className="loader-container">
      <Loader status="Loading farm" />
    </div>
  ) : saving ? (
    <div className="loader-container">
      <Loader status="Saving farm" />
    </div>
  ) : (
    <Container fluid data-cy="farm-container">
      <Row>
        <Col className="main pb-4 farms-section">
          <div className="header mb-5">
            <div className="w-100 d-flex align-items-start">
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="bottom"
                overlay={<Tooltip>Back</Tooltip>}
              >
                <Button variant="link" className="btn-back" onClick={goBack}>
                  <i className="arrow arrow-lg arrow-gray arrow-left"></i>
                </Button>
              </OverlayTrigger>
              <div className="filter-toolbar">
                {farmId ? (
                  <h4 className="header-title">Edit Farm</h4>
                ) : (
                  <h4 className="header-title">New Farm</h4>
                )}
              </div>
            </div>
          </div>

          <Form className="row mt-5 template-form">
            <Form.Group
              controlId="formGroupName"
              className="form-group col-12 col-sm-6"
            >
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="name"
                value={form.name}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group
              controlId="formGroupDescription"
              className="form-group col-12"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="description"
                value={form.description}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group
              controlId="formGroupLatitude"
              className="form-group col-6"
            >
              {/* TODO: make the validation. must be between -90 and 90 */}
              <Form.Label>Latitude</Form.Label>
              <Form.Control
                type="number"
                step=".0001"
                placeholder=""
                name="latitude"
                value={form.latitude}
                onChange={handleChange}
                data-cy="latitude"
              />
            </Form.Group>

            <Form.Group
              controlId="formGroupLongitude"
              className="form-group col-6"
            >
              <Form.Label>Longitude</Form.Label>
              <Form.Control
                type="number"
                step=".0001"
                placeholder=""
                name="longitude"
                value={form.longitude}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group
              controlId="formGroupStartYear"
              className="form-group col-6"
            >
              <Form.Label>Start Year</Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                name="startYear"
                value={form.startYear}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group
              controlId="formGroupSurveyDate"
              className="form-group col-6"
            >
              <Form.Label>Audit Date</Form.Label>
              <DatePicker
                selected={new Date(form.surveyDate)}
                onChange={(e) => {
                  if (e) setForm({ ...form, surveyDate: e.toISOString() });
                }}
                className="form-control"
                name="surveyDate"
              />
            </Form.Group>

            <Form.Group
              controlId="formGroupFarmArea"
              className="form-group col-6"
            >
              <Form.Label>
                Farm Area <span className="text-lowercase">(ha)</span>
              </Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                name="farmArea"
                value={form.farmArea}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group
              controlId="formGroupGrowingArea"
              className="form-group col-6"
            >
              <Form.Label>
                Growing Area <span className="text-lowercase">(ha)</span>
              </Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                name="growingArea"
                value={form.growingArea}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group
              controlId="formGroupTonnage"
              className="form-group col-6"
            >
              <Form.Label>Tonnage</Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                name="tonnage"
                value={form.tonnage}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group
              controlId="formGroupStockingWeight"
              className="form-group col-6"
            >
              <Form.Label>
                Stocking Weight <span className="text-lowercase">(g)</span>
              </Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                name="stockingWeight"
                value={form.stockingWeight}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group
              controlId="formGroupStockingDensity"
              className="form-group col-6"
            >
              <Form.Label>
                Density <span className="text-lowercase">(kg/m3)</span>
              </Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                name="stockingDensity"
                value={form.stockingDensity}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group
              controlId="formGroupHarvestWeight"
              className="form-group col-6"
            >
              <Form.Label>
                Harvest Weight <span className="text-lowercase">(g)</span>
              </Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                name="harvestWeight"
                value={form.harvestWeight}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group
              controlId="formGroupAnimalCount"
              className="form-group col-12 col-sm-6"
            >
              <Form.Label>Animal Count</Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                name="animalCount"
                value={form.animalCount}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group
              controlId="formGroupSpecies"
              className="form-group col-12 col-sm-6"
              data-cy="species"
            >
              <Form.Label className="d-flex">
                Species {isSpeciesTyping ? renderListValidationError() : null}
              </Form.Label>
              <AsyncTypeahead
                id="species-typeahead"
                className="right"
                useCache={false}
                isLoading={false}
                options={SPECIES_LIST}
                selected={selectedSpecies}
                onChange={handleSpeciesSearchSelection}
                onSearch={() => undefined}
                renderInput={({
                  inputRef,
                  // we need this because "react-bootstrap-typeahead" and "@types/react-bootstrap-typeahead" have conflicts in types
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  referenceElementRef,
                  ...inputProps
                }) => (
                  <div className="position-relative">
                    <svg
                      height={30}
                      viewBox="0 0 18 18"
                      className="position-absolute icon-search"
                    >
                      <path d={GEOCODER_SEARCH_ICON_SVG_PATH} />
                    </svg>
                    {/* we need this because "react-bootstrap-typeahead" and "@types/react-bootstrap-typeahead" have conflicts in types */}
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    <Form.Control
                      {...inputProps}
                      ref={(node) => {
                        // we need this because "react-bootstrap-typeahead" and "@types/react-bootstrap-typeahead" have conflicts in types
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        inputRef(node);
                        referenceElementRef(node);
                      }}
                      type="text"
                      name="species"
                    />
                  </div>
                )}
              />
            </Form.Group>

            <Form.Group
              controlId="formGroupDuration"
              className="form-group col-12 col-sm-6"
            >
              <Form.Label>
                Duration <span className="text-lowercase">(months)</span>
              </Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                name="duration"
                value={form.duration}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group
              controlId="formGroupSiteId"
              className="form-group col-12 col-sm-6"
            >
              <Form.Label>Site ID</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="siteId"
                value={form.siteId}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group
              controlId="formGroupSiteType"
              className="form-group col-12 col-sm-6"
            >
              <Form.Label>Site Type</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="siteType"
                value={form.siteType}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group
              controlId="formGroupStructureCount"
              className="form-group col-6"
            >
              <Form.Label>Structure Count</Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                name="structureCount"
                value={form.structureCount}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group
              controlId="formGroupMaxValue"
              className="form-group col-6"
            >
              <Form.Label>Max Value</Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                name="maxValue"
                value={form.maxValue}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group
              controlId="formGroupAvgValue"
              className="form-group col-6"
            >
              <Form.Label>Avg Value</Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                name="avgValue"
                value={form.avgValue}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group
              controlId="formGroupActualValue"
              className="form-group col-6"
            >
              <Form.Label>Actual Value</Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                name="actualValue"
                value={form.actualValue}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group
              controlId="formGroupCurrencyCode"
              className="form-group col-12 col-lg-6"
              data-cy="currency-code"
            >
              <Form.Label className="d-flex">
                Currency Code{" "}
                {isCurrencyTyping ? renderListValidationError() : null}
              </Form.Label>
              <AsyncTypeahead
                id="currency-code-typeahead"
                className="right"
                useCache={true}
                isLoading={false}
                options={CURRENCY_CODE_LIST}
                selected={selectedCurrencyCode}
                onChange={handleCurrencyCodeSearchSelection}
                onSearch={() => undefined}
                minLength={0}
              />
            </Form.Group>

            <Form.Group
              controlId="formGroupCountry"
              className="form-group col-12 col-lg-6"
              data-cy="country"
            >
              <Form.Label className="d-flex">
                Country {isCountryTyping ? renderListValidationError() : null}
              </Form.Label>
              <GeocoderInput
                selectedItem={selectedCountry}
                onItemSelect={(item) => handleCountrySearchSelection(item)}
                dataType="country"
              />
            </Form.Group>

            <Form.Group
              controlId="formGroupStateProvince"
              className="form-group col-12 col-lg-6"
              data-cy="region"
            >
              <Form.Label className="d-flex">
                Region {isProvinceTyping ? renderListValidationError() : null}
              </Form.Label>
              <GeocoderInput
                selectedItem={selectedProvince}
                onItemSelect={(item) => handleProvinceSearchSelection(item)}
                disabled={!selectedCountry}
                filter={selectedCountry}
                dataType="region,district"
              />
            </Form.Group>
          </Form>

          <Row>
            <Form.Group className="form-group col-12 mt-3 mb-0">
              <h4 className="header-title text-uppercase">Assigned users</h4>
            </Form.Group>
            {companyUsers.length ? (
              <Form.Group
                controlId="formGroupAssignedUsers"
                className="form-group col-12"
              >
                <UsersMultiSelect
                  itemsProp={companyUsers}
                  selectedItemsProp={farmUsers}
                  callback={setMultiSelectUserList}
                />
              </Form.Group>
            ) : (
              <Loader status="Loading users" />
            )}
          </Row>

          <Row>
            <ButtonToolbar className="col-12 form-group mt-3">
              {farm && farmId.length ? (
                <Button
                  variant="danger"
                  size="sm"
                  onClick={renderDeleteModal}
                  className="me-5"
                  data-cy="delete-farm-btn"
                >
                  Delete
                </Button>
              ) : null}
              <Button
                variant="secondary"
                size="sm"
                onClick={handleSave}
                data-cy="submit-btn"
              >
                Save
              </Button>
            </ButtonToolbar>
          </Row>
        </Col>
        <Col xs={5} lg={4} className="map px-0 border-left d-none d-md-block">
          <AquaMapBoxGl
            position={mapPosition}
            markers={[marker]}
            selectedMarkerId={farmId}
            onMarkerClick={() => undefined}
          />
        </Col>
      </Row>
      <DeleteConfirmationModal
        show={deleteModalShow}
        onClose={() => setDeleteModalShow(false)}
        onConfirm={handleDeleteFarm}
        loading={deleting}
        title="farm"
      >
        <>
          <p>
            {`${farm.name} contains ${
              farm.numberOfSurveys ? farm.numberOfSurveys : 0
            } audits.`}
          </p>
          <p className="mb-0"> Delete {farm.name}?</p>
        </>
      </DeleteConfirmationModal>
    </Container>
  );
};

export default FarmContainer;
