import { React } from "react";
import { Row, Col } from "react-bootstrap";
import { getRiskLevelClassName, getRiskLevelName } from "../../utils/string";

const RiskScoreTotalBlock = ({ stepsData, currentSurveyInfo }) => {
  let steps = { ...stepsData };
  let companyDataIsShowing = true;

  if (currentSurveyInfo?.properties?.steps) {
    steps = { ...currentSurveyInfo.properties.steps };
    companyDataIsShowing = false;
  }
  if ("UNKNOWN" in steps) delete steps.UNKNOWN;

  const maxRisk = Math.max(...Object.values(steps));

  return (
    <>
      {Object.keys(steps).map((key) => {
        const width = (steps[key] * 100) / (maxRisk > 50 ? maxRisk : 50);

        return (
          <div key={key} className="text-uppercase risk">
            <div className="fw-semibold">{getRiskLevelName(key)}</div>
            <Row className="align-items-center">
              <Col xs={9} className="pe-0">
                <div
                  className={`risk-${getRiskLevelClassName(
                    key
                  )} bar bar-xl rounded`}
                  style={{ width: `${width}%` }}
                ></div>
              </Col>
              <Col xs={3} className="text-end fw-lighter line-height-1">
                {steps[key]}
                {companyDataIsShowing && "%"}
              </Col>
            </Row>
          </div>
        );
      })}
    </>
  );
};

export default RiskScoreTotalBlock;
