import React, { useContext } from "react";
import { Alert } from "react-bootstrap";
import { AlertsContext } from "../../contexts/AlertsContext";
import { ALERT_ACTION_KIND } from "../../constants/enums";
import { AlertState } from "../../models";

const Alerts: React.FC = () => {
  const { alerts, dispatch } = useContext(AlertsContext);

  const clearAlert = (): void => {
    dispatch({ type: ALERT_ACTION_KIND.CLEAR_ALERTS });
  };

  return (
    <div className="alerts">
      {alerts.map((alert: AlertState) => {
        return (
          <Alert
            key={alert.text}
            variant={alert.variant}
            dismissible
            data-cy="alert"
            onClose={() => clearAlert()}
          >
            {alert.text}
          </Alert>
        );
      })}
    </div>
  );
};

export default Alerts;
