import React from "react";
import { Row, Col, Nav, Accordion, Badge } from "react-bootstrap";
import classNames from "classnames";
import { capitalize } from "lodash";
import { RISK_LEVELS_WITH_ALL, RISK_LEVELS_ENUM } from "../../constants/enums";
import { getRiskLevelClassName } from "../../utils/string";

import Icon from "../common/Icon";

type FilterByRiskLevelProps = {
  // Node children of the component
  children?: React.ReactNode;
  // Selected risk level in the filter
  filterActionPriority: RISK_LEVELS_ENUM | null;
  // Triggered when click on risk level
  handleActionPriorityClick: (item: RISK_LEVELS_ENUM | null) => void;
};

const FilterByRiskLevel: React.FC<
  React.PropsWithChildren<FilterByRiskLevelProps>
> = ({ filterActionPriority, handleActionPriorityClick, children }) => {
  const filterItemsClassName = (
    actionPriorityKey: string | null,
    device: "desktop" | "mobile"
  ) => {
    const isDesktop = device === "desktop";
    const priority = actionPriorityKey
      ? getRiskLevelClassName(actionPriorityKey)
      : null;

    const desktopFilterClass = classNames(
      `align-self-center cursor-pointer px-2 py-1 bg-white fw-normal filter-items filter-items-${priority}`,
      {
        "fw-semibold active-filter": actionPriorityKey === filterActionPriority,
        "mx-2": isDesktop,
      }
    );
    const mobileFilterClass = classNames(
      `align-self-center font-normal fw-normal bg-white filter-items-${priority}`,
      {
        "fw-semibold active-filter": actionPriorityKey === filterActionPriority,
      }
    );

    return device === "mobile" ? mobileFilterClass : desktopFilterClass;
  };

  const riskLevelString = (key: string | null) => {
    const riskLevelObj = RISK_LEVELS_WITH_ALL.find(
      (riskLevel) => riskLevel.key === key
    );
    return riskLevelObj ? riskLevelObj.display : "";
  };

  return (
    <div className="filters-by-risk-level px-2">
      <div className="d-none d-md-block">
        <Nav className="d-flex align-items-center justify-content-center mb-0">
          <div className="d-flex align-items-center">
            {RISK_LEVELS_WITH_ALL.map((actionPriority) => {
              if (actionPriority) {
                return (
                  <Badge
                    pill
                    text="primary"
                    key={actionPriority.key}
                    className={filterItemsClassName(
                      actionPriority.key,
                      "desktop"
                    )}
                    onClick={() =>
                      handleActionPriorityClick(actionPriority.key)
                    }
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      {actionPriority.key === filterActionPriority && (
                        <Icon iconString="check" className="me-1" />
                      )}
                      {capitalize(actionPriority.display)}
                    </div>
                  </Badge>
                );
              }
            })}
          </div>
        </Nav>

        {children}
      </div>

      <Row className="d-block d-md-none">
        <Accordion id="dropdown-actions-filter" className="filters-accordion">
          <Accordion.Item eventKey="filtersAccordion">
            <Accordion.Header className="action-filter-toggle text-decoration-none text-center px-0">
              <Row className="text-center align-items-center w-100 m-0">
                <Col xs={1} className="g-0 text-start">
                  <Icon iconString="filter" />
                </Col>
                <Col xs={10}>
                  <Badge
                    pill
                    text="primary"
                    className={filterItemsClassName(
                      filterActionPriority,
                      "mobile"
                    )}
                  >
                    <div className="d-flex flex-nowrap justify-content-center align-items-center px-2">
                      <Icon iconString="check" className="me-2" />
                      <h6 className="fw-light m-0 py-1">
                        {riskLevelString(filterActionPriority)}
                      </h6>
                    </div>
                  </Badge>
                </Col>
                <Col xs={1}></Col>
              </Row>
            </Accordion.Header>
            <Accordion.Body className="p-0 px-md-3">
              {RISK_LEVELS_WITH_ALL.map((actionPriority) => {
                if (actionPriority) {
                  return (
                    actionPriority.key !== filterActionPriority && (
                      <div
                        className="d-flex text-center justify-content-center mt-3"
                        key={actionPriority.key}
                        onClick={() =>
                          handleActionPriorityClick(actionPriority.key)
                        }
                      >
                        <Badge
                          pill
                          text="primary"
                          className={filterItemsClassName(
                            actionPriority.key,
                            "mobile"
                          )}
                          onClick={() =>
                            handleActionPriorityClick(actionPriority.key)
                          }
                        >
                          <h6 className="fw-light m-0 px-3 py-1">
                            {actionPriority.display}
                          </h6>
                        </Badge>
                      </div>
                    )
                  );
                }
              })}

              {children}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    </div>
  );
};

export default FilterByRiskLevel;
