import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Button, DropdownButton, Dropdown } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import Select, { components } from "react-select";

import { AlertsContext } from "../contexts/AlertsContext";
import { ErrorPopupContext } from "../contexts/ErrorPopupContext";
import { postGetLogs, getUsers } from "../network/request";
import { formatDateAndTime } from "../utils/date";
import { showFetchError } from "../utils/alerts";
import Pagination from "../uiComponents/Pagination";

import Loader from "./common/Loader";
import Icon from "./common/Icon";

const auditMethods = [
  { value: "ADD", label: "Add" },
  { value: "UPDATE", label: "Update" },
  { value: "DELETE", label: "Delete" },
  { value: "LAUNCH", label: "Launch" },
  { value: "UPLOAD", label: "Upload" },
  { value: "DOWNLOAD", label: "Download" },
  { value: "ADD_USER", label: "Add" },
  { value: "UPDATE_USER", label: "Update" },
  { value: "DELETE_USER", label: "Delete" },
];

const auditTarget = [
  { value: "SURVEY", label: "Audit" },
  { value: "TEMPLATE", label: "Template" },
  { value: "FARM", label: "Farm" },
  { value: "COMPANY", label: "Company" },
  { value: "USER", label: "User" },
  { value: "ACTION_ITEM", label: "Action item" },
  { value: "ACTION_ITEM_DETAIL", label: "Action item detail" },
  { value: "COMPANY_USER", label: "Company user" },
  { value: "COMPANY_FARM", label: "Company farm" },
  { value: "SURVEY_USER", label: "Audit user" },
];

const itemsPerPageOptions = [10, 25, 50, 100, 250];

const AccordionItem = ({ log, index }) => {
  const [activeRow, setActiveRow] = useState(false);
  const contentEl = useRef();
  const updates = log.updatedFieldsJson
    ? JSON.parse(log.updatedFieldsJson)
    : null;
  const logTarget =
    auditTarget.find((item) => item.value === log.target)?.label || "";
  const logMethod =
    auditMethods.find((item) => item.value === log.method)?.label || "";

  return (
    <div key={log.targetExtId + index || index}>
      <Row
        className={`${
          activeRow ? "active" : ""
        } log-table-row py-3 justify-content-between`}
        onClick={() => setActiveRow((prev) => !prev)}
      >
        <Col xs={3}>{log.targetUserEmail}</Col>
        <Col xs={5}>
          {logMethod} {logTarget} ({log.targetName})
        </Col>
        <Col xs={2}>{log.companyName || "Company Name"}</Col>
        <Col xs={2}>{formatDateAndTime(log.createdAt)}</Col>
      </Row>
      <Row
        className={`${index < 5 ? "active" : ""} log-table-dropdown-row`}
        ref={contentEl}
        style={
          activeRow
            ? { height: contentEl.current.scrollHeight }
            : { height: "0px" }
        }
      >
        <Col xs={3} className="py-3">
          {log.targetUserEmail || "no email"}
        </Col>
        <Col xs={5} className="py-3">
          {updates
            ? Object.keys(updates).map((key, index) => {
                return (
                  <div
                    key={index}
                  >{`${key} was: ${updates[key].first}, now: ${updates[key].second}`}</div>
                );
              })
            : ""}
        </Col>
        <Col xs={4} className="py-3">
          Target id: {log.targetExtId}
        </Col>
      </Row>
    </div>
  );
};

const ActivityLogContainer = () => {
  const [loading, setLoading] = useState(false);
  const [logsLoading, setLogsLoading] = useState(false);
  const [logs, setLogs] = useState([]);
  const [order, setOrder] = useState("asc");
  const [users, setUsers] = useState([]);

  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedTargets, setSelectedTargets] = useState([]);
  const [filters, setFilters] = useState({
    // "fromDate": "2020-01-01", // is not using now, can be implemented in future
    // "toDate": "2022-08-17", // is not using now, can be implemented in future
    // "auditMethods": [], // is not using now, can be implemented in future
    auditTargets: [],
    userEmails: [],
  });

  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationTitle, setPaginationTitle] = useState("");
  const [totalPages, setTotalPages] = useState(1);

  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);

  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data: result } = await getUsers();
        setUsers(
          result.map((user) => ({
            externalId: user.externalId,
            username: user.userInfo.username,
            fullName: user.userInfo.fullName,
            email: user.userInfo.email,
          }))
        );
      } catch (error) {
        showFetchError({
          error,
          customMsg: "API error while loading users list.",
          object: "users",
          objectName: "plural",
          operation: "loaded",
          dispatchAlert,
          dispatchPopup,
          onRetry: () => fetchData(),
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLogsLoading(true);
        //TODO: fix. set currentPage to 1 when filters have been just set
        const {
          data: { logs },
        } = await postGetLogs(currentPage, itemsPerPage, filters);

        const { pageSize, totalRecords, items } = logs;

        setTotalPages(Math.ceil(totalRecords / pageSize));

        const pagesFrom = currentPage * pageSize - pageSize + 1;
        const pagesTo = currentPage * pageSize;
        setPaginationTitle(
          `Showing ${pagesFrom}-${
            pagesTo > totalRecords ? totalRecords : pagesTo
          } of ${totalRecords} items`
        );

        setLogs(items);
        setLogsLoading(false);
      } catch (error) {
        setLogsLoading(false);
        showFetchError({
          error,
          customMsg: "API error while loading your activity information.",
          object: "activity logs",
          objectName: "plural",
          operation: "loaded",
          dispatchAlert,
          dispatchPopup,
          onRetry: () => fetchData(),
        });
      }
    };

    fetchData();
  }, [filters, currentPage, itemsPerPage]);

  const goBack = () => {
    return history.goBack();
  };

  const handelTimeSorting = () => {
    setOrder((prev) => (prev === "asc" ? "desc" : "asc"));
    setLogs(() => {
      const sortedLogs =
        order === "asc"
          ? logs.sort((a, b) =>
              new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1
            )
          : logs.sort((a, b) =>
              new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1
            );
      return sortedLogs;
    });
  };

  const handleTargetSelection = (selectedTargets) => {
    const targetsKeys = selectedTargets.map((item) => item.value);
    setFilters({ ...filters, auditTargets: targetsKeys });
    setSelectedTargets(selectedTargets);
  };

  const handleUserSelection = (selectedUser) => {
    const usersEmails = selectedUser.map((item) => item.email);
    setFilters({ ...filters, userEmails: usersEmails });
    setSelectedUser(selectedUser);
  };

  const renderTableSkeleton = useCallback((rowsNumber) => {
    let skeletonRow = [];
    for (let index = 0; index <= rowsNumber; index++) {
      skeletonRow.push(
        <div key={index}>
          <Row className={`log-table-row py-3 justify-content-between`}>
            <Col xs={3}>
              <div className="skeleton"></div>
            </Col>
            <Col xs={5}>
              <div className="skeleton"></div>
            </Col>
            <Col xs={2}>
              <div className="skeleton"></div>
            </Col>
            <Col xs={2}>
              <div className="skeleton"></div>
            </Col>
          </Row>
        </div>
      );
    }

    return skeletonRow;
  }, []);

  const LimitedChipsContainer = ({ children, hasValue, ...props }) => {
    if (!hasValue) {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }

    const CHIPS_LIMIT = 2;
    const [chips, otherChildren] = children;
    const overflowCounter = chips.slice(CHIPS_LIMIT).length;
    const displayChips = chips.slice(
      overflowCounter,
      overflowCounter + CHIPS_LIMIT
    );

    return (
      <components.ValueContainer {...props}>
        {displayChips}

        {otherChildren}

        {overflowCounter > 0 && (
          <div className="ms-auto text-nowrap">+ {overflowCounter}</div>
        )}
      </components.ValueContainer>
    );
  };

  return loading ? (
    <div className="loader-container">
      <Loader status="Loading audit logs" />
    </div>
  ) : (
    <div
      className="aqua-container main pt-4 justify-content-center audit-logs"
      data-cy="audit-logs-container"
    >
      <Row className="justify-content-center pb-5">
        <Col xs={10} className="header mb-5">
          <div className="w-100 d-flex align-items-start">
            <Button variant="link" className="btn-back" onClick={goBack}>
              <i className="arrow arrow-lg arrow-gray arrow-left"></i>
            </Button>
            <div className="filter-toolbar">
              <h4 className="header-title">Activity Log</h4>
              <p className="smaller mb-0">All companies</p>
            </div>
          </div>
        </Col>

        <Col xs={10}>
          <Row className="justify-content-end mb-4 filters">
            <Col></Col>
            <Col xs={2}>
              <DropdownButton
                title={`Results per page: ${itemsPerPage}`}
                variant="link"
                size="sm"
                className="d-flex justify-content-end"
              >
                {itemsPerPageOptions.map((itemsPerPageOption) => {
                  return (
                    <Dropdown.Item
                      key={itemsPerPageOption}
                      value={itemsPerPageOption}
                      active={itemsPerPageOption === itemsPerPage}
                      onClick={() => {
                        setItemsPerPage(itemsPerPageOption);
                        setCurrentPage(1);
                      }}
                    >
                      {itemsPerPageOption}
                    </Dropdown.Item>
                  );
                })}
              </DropdownButton>
            </Col>

            <Col xs={3}>
              <AsyncTypeahead
                id="user-typeahead"
                clearButton
                isLoading={false}
                labelKey="email"
                options={users}
                placeholder="User"
                minLength={1}
                onChange={(selectedUser) => handleUserSelection(selectedUser)}
                selected={selectedUser}
                onSearch={() => undefined}
              />
            </Col>

            <Col>
              <Select
                value={selectedTargets}
                onChange={(selectedTargets) =>
                  handleTargetSelection(selectedTargets)
                }
                options={auditTarget}
                placeholder="Event Type"
                isMulti
                hideSelectedOptions={false}
                allowSelectAll={true}
                classNamePrefix={"target-multiselect"}
                className={"target-multiselect"}
                components={{ ValueContainer: LimitedChipsContainer }}
              />
            </Col>
          </Row>
        </Col>

        <Col xs={10}>
          <div className="log-table">
            <Row className="log-table-header pt-3 pb-2 justify-content-between">
              <Col xs={3}>User</Col>
              <Col xs={5}>Event</Col>
              <Col xs={2}>Company Name</Col>
              <Col
                xs={2}
                onClick={() => handelTimeSorting()}
                className="d-flex justify-content-between align-items-center timestamp-column"
              >
                <span>Timestamp</span>
                <Icon
                  iconString={order === "asc" ? "caret-down" : "caret-up"}
                />
              </Col>
            </Row>
            {logsLoading
              ? renderTableSkeleton(itemsPerPage - 1)
              : logs.map((log, index) => (
                  <AccordionItem key={index} log={log} index={index} />
                ))}
          </div>
        </Col>

        {logs.length && !(currentPage === 1 && logs.length < itemsPerPage) ? (
          <Col xs={10}>
            <Row className="justify-content-between align-items-center pagination-row">
              <Col xs={4} className="pagination-summary">
                {paginationTitle}
              </Col>
              <Col>
                {/* TODO: Change this pagination to bootstrap one */}
                <Pagination
                  size="sm"
                  threeDots
                  totalPages={totalPages}
                  currentPage={currentPage}
                  showMax={3}
                  activeBgColor="#EFEFEF"
                  activeBorderColor="#33ACE3"
                  activeColor="#33ACE3"
                  bgColor="#ffffff"
                  disabledBgColor="#ffffff"
                  onClick={(e) => setCurrentPage(e)}
                />
              </Col>
            </Row>
          </Col>
        ) : null}
      </Row>
    </div>
  );
};

export default ActivityLogContainer;
