import React from "react";
import { Modal, Button, Spinner } from "react-bootstrap";

type DeleteConfirmationModalProps = {
  children?: React.ReactNode;
  /* Is Dialog currently visible? */
  show: boolean;
  /* Fired when Dialog is cancelled by the user, or when the user makes a negative selection. */
  onClose: () => void;
  /* Fired when the user makes a positive selection.  */
  onConfirm: () => void;
  /* Is the Dialog in a loading state? This will display a spinner.  */
  loading?: boolean;
  /* Dialog title.  */
  title: React.ReactNode;
};

const DeleteConfirmationModal: React.FC<
  React.PropsWithChildren<DeleteConfirmationModalProps>
> = ({ show, onClose, onConfirm, loading, title, children }) => {
  return (
    <Modal
      show={show}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      data-cy="delete-modal"
    >
      <Modal.Header className="justify-content-center">
        <Modal.Title className="fw-normal">Delete {title}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="py-10">
        <div
          className={`py-3 border-top border-bottom border-primary border-opacity-25 ${
            loading ? "d-flex justify-content-center" : ""
          }`}
        >
          {loading ? (
            <Spinner animation="border" className="wide-border" />
          ) : (
            children
          )}
        </div>
      </Modal.Body>

      <Modal.Footer className="justify-content-between">
        <Button
          variant="link"
          onClick={onClose}
          className="text-decoration-underline"
          data-cy="exit-btn"
        >
          Keep {title}
        </Button>
        <Button
          variant="secondary"
          onClick={onConfirm}
          disabled={loading}
          data-cy="delete-btn"
        >
          Delete {title}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfirmationModal;
