import React from "react";
import { Row, Col } from "react-bootstrap";

import { formatDate } from "../../utils/date";
import {
  getRiskLevelClassName,
  getRiskLevelName,
  changePdfHtmlTags,
} from "../../utils/string";

import ProfileImage from "../general/ProfileImage";

const ActionPdf = ({ action, actionImgSrc }) => {
  action.description = action.description
    ? changePdfHtmlTags(action.description)
    : action.description;
  action.closedDescription = action.closedDescription
    ? changePdfHtmlTags(action.closedDescription)
    : action.closedDescription;

  return (
    <div className="action pb-4 ps-3">
      <Row className="justify-content-between align-items-start">
        <Col lg={3} className="order-lg-1">
          <div
            className={`d-none d-md-flex bar bar-md action-section-name-bar risk-${getRiskLevelClassName(
              action.riskLevel
            )}`}
          ></div>
          <div className="d-flex justify-content-end">
            <img
              src={actionImgSrc}
              className="my-2 me-0 img-icon img-icon-lg"
              alt="sectionName"
            />
          </div>
        </Col>
        <Col lg={8} className="order-0 pt-3">
          <Row className="justify-content-between align-items-center flex-wrap">
            <Col
              xs={8}
              className="ps-0 pe-3 d-flex justify-content-between flex-nowrap"
            >
              <span className="card-name me-3">{action.title}</span>
            </Col>
            <Col
              xs={4}
              className="action-created smaller ps-3 pe-0 d-block text-end"
            >
              <span className="text-nowrap pdf-font-italic">
                {formatDate(action.createdAt)}
              </span>
            </Col>
            <Col xs={12} className="mt-2 px-0">
              <div
                className={`bar bar-xls risk-${getRiskLevelClassName(
                  action.riskLevel
                )}`}
              ></div>
              <div className={`d-block text-end`}>
                {getRiskLevelName(action.riskLevel)}
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={12} className="smaller mb-1 order-1 order-lg-2 ps-0">
          <span dangerouslySetInnerHTML={{ __html: action.description }}></span>
        </Col>
        {action.closedAt ? (
          <Col xs={12} className="smaller mb-1 order-1 order-lg-2 ps-0">
            <div className="my-2">
              <div className="d-flex align-items-center">
                <ProfileImage name={action.closedByName} />
                <p className="my-0 mx-3">{action.closedByName}</p>
                <p className="pdf-font-italic pdf-font-light my-0 mx-auto mx-md-2">
                  {formatDate(action.closedAt)}
                </p>
              </div>
            </div>
            <div className={`bg-white ms-5 ps-1`}>
              <p
                className="ma-2"
                dangerouslySetInnerHTML={{ __html: action.closedDescription }}
              ></p>
            </div>
          </Col>
        ) : null}
      </Row>
    </div>
  );
};

export default ActionPdf;
