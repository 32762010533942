import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Navbar, Button, Col } from "react-bootstrap";

import { AQUARISK_LOGO } from "../../constants/imagesConstants";

type HeaderGuestLandingProps = {
  /** Ref object with the landing container in the 'current' field.
   *  It is needed for listening scroll events on landing container
   *  and calculating the width of header
   */
  landingContainer: React.MutableRefObject<HTMLDivElement | null>;
};

const HeaderGuestLanding: React.FC<HeaderGuestLandingProps> = ({
  landingContainer,
}) => {
  const history = useHistory();

  const goHome = () => {
    history.push("/");
  };

  const goLogin = () => {
    history.push("/login");
  };

  const [position, setPosition] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [headerWidth, setHeaderWidth] = useState("100%");
  const [headerOnWindows, setHeaderOnWindows] = useState("");

  useEffect(() => {
    if (landingContainer.current) {
      const landingContainerElement = landingContainer.current;

      const handleScroll = () => {
        const moving = landingContainerElement.scrollTop;

        setVisible(position > moving);
        setPosition(moving);
      };

      landingContainerElement.addEventListener("scroll", handleScroll, {
        passive: true,
      });

      return () =>
        landingContainerElement.removeEventListener("scroll", handleScroll);
    }
  });

  useEffect(() => {
    if (landingContainer.current) {
      const landingContainerElement = landingContainer.current;

      if (window.navigator.appVersion.indexOf("Win") !== -1) {
        setHeaderOnWindows("header-on-windows");
      }

      setHeaderWidth(`${landingContainerElement.clientWidth}px`);

      const handleResize = () => {
        setHeaderWidth(`${landingContainerElement.clientWidth}px`);
      };

      window.addEventListener("resize", handleResize);
    }
  }, [setHeaderWidth, landingContainer]);

  const cls = visible ? "header-visible" : "header-hidden";

  return (
    <header
      className={`guest-header-landing ${cls} ${headerOnWindows}`}
      style={{ width: headerWidth }}
    >
      <Navbar className="layout-wrapper row py-2 justify-content-between">
        <Col xs={6} lg={3} className="text-start ps-0">
          <Navbar.Brand href="#">
            <img src={AQUARISK_LOGO} alt="AquaRisk Logo" onClick={goHome} />
          </Navbar.Brand>
        </Col>
        <Col xs={6} lg={3} className="sign-in text-end pe-0 align-self-center">
          <Button
            variant="primary"
            size="sm"
            className="btn-link px-3"
            onClick={goLogin}
            data-cy="sign-in"
          >
            Sign in
          </Button>
        </Col>
      </Navbar>
    </header>
  );
};

export default HeaderGuestLanding;
