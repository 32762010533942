import axios, { AxiosResponse } from "axios";
import { API, getUserHeaders } from "../constants/requestConstants";
import * as Model from "../models/be_models";

export const getSurveyReport = (
  surveyExtId: string
): Promise<AxiosResponse<Model.ReportDataResponse>> => {
  const url = `${API}/survey/${surveyExtId}/report`;

  return axios.get<Model.ReportDataResponse>(url, {
    headers: getUserHeaders(),
  });
};
