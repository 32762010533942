import React, { createContext, useReducer } from "react";
import { currentUserReducer } from "../reducers/currentUserReducer";
import { CurrentUserContextType } from "../models";

export const initialCurrentCompany = {
  address1: "",
  city: "",
  externalId: "",
  name: "",
  postCode: "",
  province: "",
};

export const initialCurrentUser = {
  authorities: "",
  email: "",
  externalId: "",
  fullName: "",
  username: "",
  currentCompany: initialCurrentCompany,
};

const initialValues = {
  currentUser: initialCurrentUser,
  dispatch: (): void => undefined,
};

export const CurrentUserContext =
  createContext<CurrentUserContextType>(initialValues);

const CurrentUserContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [currentUser, dispatch] = useReducer(
    currentUserReducer,
    localStorage.currentUser
      ? JSON.parse(localStorage.currentUser)
      : initialCurrentUser
  );

  return (
    <CurrentUserContext.Provider value={{ currentUser, dispatch }}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export default CurrentUserContextProvider;
