import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AxiosError } from "axios";

import { AlertsContext } from "../../contexts/AlertsContext";
import { ErrorPopupContext } from "../../contexts/ErrorPopupContext";
import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import { getSurveyFarm, getMyCompanyFarm } from "../../network/request";
import { DATE_ISO_DEFAULT } from "../../constants/constants";
import {
  INITIAL_FARM,
  INITIAL_MARKER,
  INITIAL_MAP_POSITION,
} from "../../constants/initialData";
import { isAdmin, isCompany, isSurveyor } from "../../utils/userUtils";
import { showFetchError } from "../../utils/alerts";
import { formatDate } from "../../utils/date";
import { FarmResponse, SurveyInfoResponse } from "../../models/be_models";

import Loader from "../common/Loader";
import Icon from "../common/Icon";
import AquaMapBoxGl from "../general/AquaMapBoxGl";

type SurveyDetailProps = {
  surveyWithCompany: {
    survey: SurveyInfoResponse;
    companyId: string;
  };
};

// surveyWithCompany - it is important to have one prop here for correct work of useEffect.
const SurveyDetail: React.FC<SurveyDetailProps> = ({ surveyWithCompany }) => {
  const selectedSurveyExtId = surveyWithCompany?.survey.externalId;
  const companyId = surveyWithCompany?.companyId;

  const history = useHistory();

  const { currentUser } = useContext(CurrentUserContext);
  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);

  const [loading, setLoading] = useState(false);
  const [mapPosition, setMapPosition] = useState(INITIAL_MAP_POSITION);
  const [farmMarker, setFarmMarker] = useState(INITIAL_MARKER);
  const [farmProps, setFarmProps] = useState<FarmResponse>(INITIAL_FARM);

  useEffect(() => {
    let isSubscribed = true;

    const fetchSurveyFarm = async () => {
      try {
        setLoading(true);
        const { data: farmResult } = await getSurveyFarm(selectedSurveyExtId);
        return farmResult;
      } catch (error) {
        showFetchError({
          error: error as Error | AxiosError,
          customMsg: "API error while loading the audit farm.",
          object: "audit farm",
          objectName: "unknown",
          operation: "loaded",
          dispatchAlert,
          dispatchPopup,
          onRetry: () => fetchSurveyFarm(),
        });
      } finally {
        setLoading(false);
      }
    };

    const fetchData = async () => {
      try {
        if (!selectedSurveyExtId || !selectedSurveyExtId.length) return;
        setLoading(true);

        const farmResult = await fetchSurveyFarm();
        if (farmResult) {
          const { data: result } = await getMyCompanyFarm(
            companyId,
            farmResult.externalId
          );

          if (isSubscribed) {
            setFarmProps(result);
            setFarmMarker((fm) => {
              return {
                ...fm,
                id: result.externalId,
                lat: result.latitude,
                lng: result.longitude,
              };
            });
            setMapPosition((mp) => {
              return {
                ...mp,
                lat: result.latitude,
                lng: result.longitude,
                zoom: 14,
              };
            });
          }
        }
      } catch (error) {
        showFetchError({
          error: error as Error | AxiosError,
          customMsg: "API error while loading the company farm.",
          object: "company farm",
          objectName: "unknown",
          operation: "loaded",
          dispatchAlert,
          dispatchPopup,
          onRetry: () => fetchData(),
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      isSubscribed = false;
    };
  }, [selectedSurveyExtId, companyId]);

  const goToEditFarm = () => {
    return history.push(
      `/companies/${companyId}/farms/${farmProps.externalId}`
    );
  };

  return selectedSurveyExtId ? (
    <Col xs={9} sm={5} lg={4} className="details-wrapper d-none d-lg-flex">
      {loading ? (
        <div className="survey-detail">
          <div className="loader-container">
            <Loader status="Loading audit details" />
          </div>
        </div>
      ) : (
        <div className="survey-detail">
          <Col className="map px-0">
            {farmMarker.id && (
              <AquaMapBoxGl
                position={mapPosition}
                markers={[farmMarker]}
                selectedMarkerId={farmMarker.id}
                onMarkerClick={() => undefined}
              />
            )}
          </Col>
          <div className="info">
            <div className="info-header my-3">
              <Row className="align-items-center justify-content-between">
                <h5 className="col-7">
                  <span>{farmProps.name}</span>
                  {isAdmin(currentUser) ||
                  isCompany(currentUser) ||
                  isSurveyor(currentUser) ? (
                    <OverlayTrigger
                      trigger={["hover", "focus"]}
                      overlay={<Tooltip>Edit Farm</Tooltip>}
                    >
                      <Button
                        variant="link"
                        size="sm"
                        onClick={() => goToEditFarm()}
                      >
                        <Icon
                          iconString="pen"
                          className="text-white bg-secondary pen-icon-round"
                        />
                      </Button>
                    </OverlayTrigger>
                  ) : null}
                </h5>
                <div className="coordinates-label col-5">
                  {farmProps.latitude}, {farmProps.longitude}
                </div>
              </Row>
            </div>
            <div className="info-body">
              <Row>
                {farmProps.siteId ? (
                  <Col xs={12} md={6} className="prop-item">
                    <div className="prop-label">Site ID</div>
                    <div className="prop-value">{farmProps.siteId}</div>
                  </Col>
                ) : null}

                {farmProps.siteType ? (
                  <Col xs={12} md={6} className="prop-item">
                    <div className="prop-label">Site Type</div>
                    <div className="prop-value">{farmProps.siteType}</div>
                  </Col>
                ) : null}

                {farmProps.structureCount ? (
                  <Col xs={12} md={6} className="prop-item">
                    <div className="prop-label">Structure Count</div>
                    <div className="prop-value">{farmProps.structureCount}</div>
                  </Col>
                ) : null}

                {farmProps.maxValue ? (
                  <Col xs={12} md={6} className="prop-item">
                    <div className="prop-label">
                      Max Value
                      {farmProps.currencyCode
                        ? ` (${farmProps.currencyCode})`
                        : ""}
                    </div>
                    <div className="prop-value">
                      {farmProps.maxValue.toLocaleString()}
                    </div>
                  </Col>
                ) : null}

                {farmProps.avgValue ? (
                  <Col xs={12} md={6} className="prop-item">
                    <div className="prop-label">
                      Avg Value
                      {farmProps.currencyCode
                        ? ` (${farmProps.currencyCode})`
                        : ""}
                    </div>
                    <div className="prop-value">
                      {farmProps.avgValue.toLocaleString()}
                    </div>
                  </Col>
                ) : null}

                {farmProps.actualValue ? (
                  <Col xs={12} md={6} className="prop-item">
                    <div className="prop-label">
                      Actual Value
                      {farmProps.currencyCode
                        ? ` (${farmProps.currencyCode})`
                        : ""}
                    </div>
                    <div className="prop-value">
                      {farmProps.actualValue.toLocaleString()}
                    </div>
                  </Col>
                ) : null}

                {farmProps.country ? (
                  <Col xs={12} md={6} className="prop-item">
                    <div className="prop-label">Country</div>
                    <div className="prop-value">{farmProps.country}</div>
                  </Col>
                ) : null}

                {farmProps.stateProvince ? (
                  <Col xs={12} md={6} className="prop-item">
                    <div className="prop-label">Region</div>
                    <div className="prop-value">{farmProps.stateProvince}</div>
                  </Col>
                ) : null}

                {farmProps.animalCount ? (
                  <Col xs={12} md={6} className="prop-item">
                    <div className="prop-label">Animal Count</div>
                    <div className="prop-value">
                      {farmProps.animalCount.toLocaleString()}
                    </div>
                  </Col>
                ) : null}

                {farmProps.harvestWeight ? (
                  <Col xs={12} md={6} className="prop-item">
                    <div className="prop-label">Harvest Weight (g)</div>
                    <div className="prop-value">
                      {farmProps.harvestWeight.toLocaleString()}
                    </div>
                  </Col>
                ) : null}

                {farmProps.species ? (
                  <Col xs={12} md={6} className="prop-item">
                    <div className="prop-label">Species</div>
                    <div className="prop-value">{farmProps.species}</div>
                  </Col>
                ) : null}

                {farmProps.stockingDensity ? (
                  <Col xs={12} md={6} className="prop-item">
                    <div className="prop-label">Density (kg/m3)</div>
                    <div className="prop-value">
                      {farmProps.stockingDensity.toLocaleString()}
                    </div>
                  </Col>
                ) : null}

                {farmProps.stockingWeight ? (
                  <Col xs={12} md={6} className="prop-item">
                    <div className="prop-label">Stocking Weight (g)</div>
                    <div className="prop-value">
                      {farmProps.stockingWeight.toLocaleString()}
                    </div>
                  </Col>
                ) : null}

                {farmProps.surveyDate &&
                farmProps.surveyDate !== DATE_ISO_DEFAULT ? (
                  <Col xs={12} md={6} className="prop-item">
                    <div className="prop-label">Audit Date</div>
                    <div className="prop-value">
                      {formatDate(new Date(farmProps.surveyDate))}
                    </div>
                  </Col>
                ) : null}

                {farmProps.tonnage ? (
                  <Col xs={12} md={6} className="prop-item">
                    <div className="prop-label">Tonnage</div>
                    <div className="prop-value">{farmProps.tonnage}</div>
                  </Col>
                ) : null}

                {farmProps.startYear ? (
                  <Col xs={12} md={6} className="prop-item">
                    <div className="prop-label">Start Year</div>
                    <div className="prop-value">{farmProps.startYear}</div>
                  </Col>
                ) : null}

                {farmProps.currencyCode ? (
                  <Col xs={12} md={6} className="prop-item">
                    <div className="prop-label">Currency Code</div>
                    <div className="prop-value">{farmProps.currencyCode}</div>
                  </Col>
                ) : null}

                {farmProps.farmArea ? (
                  <Col xs={12} md={6} className="prop-item">
                    <div className="prop-label">Farm area (ha)</div>
                    <div className="prop-value">{farmProps.farmArea}</div>
                  </Col>
                ) : null}

                {farmProps.growingArea ? (
                  <Col xs={12} md={6} className="prop-item">
                    <div className="prop-label">Growing area (ha)</div>
                    <div className="prop-value">{farmProps.growingArea}</div>
                  </Col>
                ) : null}
              </Row>
            </div>
          </div>
        </div>
      )}
    </Col>
  ) : null;
};

export default SurveyDetail;
