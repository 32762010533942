import axios, { AxiosResponse } from "axios";
import { API, getUserHeaders } from "../constants/requestConstants";
import * as Model from "../models/be_models";

export const postCloseActionItem = (
  itemExtId: string,
  request: Model.CloseActionItemRequest
): Promise<AxiosResponse> => {
  const url = `${API}/action/${itemExtId}/action`;

  return axios.post(url, request, {
    headers: getUserHeaders(),
  });
};

// GET /api/v1/action/company/{companyExtId}/farm
// getFarmsWithOpenActionItems

// GET /api/v1/action/company/{companyExtId}/farm/{farmExtId}
// getOpenActionItemsByFarm

export const getSurveysWithOpenActionItems = (
  companyExtId: string,
  params: Model.GetSurveysWithOpenActionItemsParams
): Promise<AxiosResponse<Model.PaginatedSurveyWithActionItemResponse>> => {
  const url = `${API}/action/company/${companyExtId}/survey`;

  return axios.get<Model.PaginatedSurveyWithActionItemResponse>(url, {
    headers: getUserHeaders(),
    params,
  });
};

export const getOpenActionItemsBySurvey = (
  surveyExtId: string,
  params: Model.GetOpenActionItemsBySurveyParams
): Promise<
  AxiosResponse<Model.PaginatedListResponseActionItemWithDetailResponse>
> => {
  const url = `${API}/action/survey/${surveyExtId}`;

  return axios.get<Model.PaginatedListResponseActionItemWithDetailResponse>(
    url,
    {
      headers: getUserHeaders(),
      params,
    }
  );
};

export const getOpenActionItemsWithScoringBySurvey = (
  surveyExtId: string,
  params: Model.GetOpenActionItemsWithScoringBySurveyParams
): Promise<AxiosResponse<Model.ActionItemWithDetailAndScoreResponse>> => {
  const url = `${API}/action/survey/${surveyExtId}/score`;

  return axios.get<Model.ActionItemWithDetailAndScoreResponse>(url, {
    headers: getUserHeaders(),
    params,
  });
};

// GET /api/v1/action/survey/{surveyExtId}/section/{sectionExtId}
// getOpenActionItemsSection

// GET /api/v1/action/survey/{surveyExtId}/section/{sectionExtId}/step/{stepExtId}
// getOpenActionItemsStep is not used
