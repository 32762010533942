import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { NavDropdown, Button } from "react-bootstrap";

import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import { isAdmin, isCompany } from "../../utils/userUtils";
import { CURRENT_USER_ACTION_KIND } from "../../constants/enums";
import { CurrentCompany } from "../../models";
import { CompanyResponse } from "../../models/be_models";

import Icon from "./Icon";

type HeaderCompaniesProps = {
  /* The list of companies fetched in the parent component */
  companies: CompanyResponse[];
};

const HeaderCompanies: React.FC<HeaderCompaniesProps> = ({ companies }) => {
  const { currentUser, dispatch } = useContext(CurrentUserContext);

  const history = useHistory();

  const setCurrentCompany = (company: CompanyResponse) => {
    dispatch({
      type: CURRENT_USER_ACTION_KIND.SET_CURRENT_COMPANY,
      company,
    });
    history.push("/");
  };

  const handleEditCompany = (e: React.MouseEvent, company: CurrentCompany) => {
    e.preventDefault();
    history.push(`/companies/${company.externalId}`);
  };

  const handleFarmsClick = (e: React.MouseEvent, company: CurrentCompany) => {
    e.preventDefault();
    history.push(`/companies/${company.externalId}/farms`);
  };

  const handleUsersClick = (e: React.MouseEvent, company: CurrentCompany) => {
    e.preventDefault();
    history.push(`/companies/${company.externalId}/users`);
  };

  const handleAddNewCompany = (e: React.MouseEvent) => {
    e.preventDefault();
    history.push("/companies/new");
  };

  return (
    <div className="d-flex align-items-center justify-content-center">
      {isCompany(currentUser) || isAdmin(currentUser) ? (
        <NavDropdown
          title={
            currentUser.currentCompany.name
              ? currentUser.currentCompany.name
              : ""
          }
          className="dropdown-companies"
          data-cy="current-company-btn"
          align="end"
        >
          <NavDropdown.Item
            key="editCurrentCompany"
            onClick={(e) => handleEditCompany(e, currentUser.currentCompany)}
            data-cy="edit-current-company-btn"
          >
            <span>Edit company</span>
          </NavDropdown.Item>
          <NavDropdown.Item
            key="currentCompanyFarms"
            onClick={(e) => handleFarmsClick(e, currentUser.currentCompany)}
            data-cy="company-farms-btn"
          >
            <span>Farms</span>
          </NavDropdown.Item>
          <NavDropdown.Item
            key="currentCompanyUsers"
            onClick={(e) => handleUsersClick(e, currentUser.currentCompany)}
            data-cy="company-users-btn"
          >
            <span>Users</span>
          </NavDropdown.Item>

          {isAdmin(currentUser) ? (
            <>
              <NavDropdown.Divider />

              <div className="companies-list">
                {companies.length > 1
                  ? companies.map((company) => {
                      return (
                        <NavDropdown.Item
                          key={company.externalId}
                          active={
                            company.externalId ===
                            currentUser.currentCompany.externalId
                          }
                          onClick={() => setCurrentCompany(company)}
                        >
                          <span>{company.name}</span>
                        </NavDropdown.Item>
                      );
                    })
                  : null}
              </div>

              <NavDropdown.Item
                key="addNewCompany"
                className="no-cursor text-end mt-2 px-2"
              >
                <Button
                  variant="link"
                  size="sm"
                  onClick={(e) => handleAddNewCompany(e)}
                  className="text-info fw-semibold text-decoration-none"
                  data-cy="add-company-btn"
                >
                  <Icon iconString="plus" className="me-1" />
                  Company
                </Button>
              </NavDropdown.Item>
            </>
          ) : null}
        </NavDropdown>
      ) : (
        <div className="dropdown-companies text-nowrap">
          {currentUser.currentCompany.name
            ? currentUser.currentCompany.name
            : ""}
        </div>
      )}
    </div>
  );
};

export default HeaderCompanies;
