import healthIcon from "../images/landing/Health.svg";
import environmentIcon from "../images/landing/Environment.svg";
import engineeringIcon from "../images/Engineering.svg";
import governanceIcon from "../images/landing/Governance.svg";
import husbandryIcon from "../images/landing/Husbandry.svg";
import salmon from "../images/landing/SALMON.svg";
import tilapia from "../images/landing/TILAPIA.svg";
import trout from "../images/landing/TROUT.svg";
import shrimp from "../images/landing/SHRIMP.svg";
import mussels from "../images/landing/MUSSELS.svg";
import barramundi from "../images/landing/BARRAMUNDI.svg";
import seaBream from "../images/landing/SEABREAM.svg";
import oysters from "../images/landing/OYSTERS.svg";
import stripedBass from "../images/landing/STRIPEDBASS.svg";
import seaBass from "../images/landing/SEABASS.svg";
import meagre from "../images/landing/MEAGRE.svg";
import tuna from "../images/landing/TUNA.svg";

export const riskCategories = [
  {
    name: "Health",
    description: `Understand disease, vaccination,
      treatment, pollution, health
      screening, quarantine and algal
      bloom risks.`,
    icon: healthIcon,
    iconAlt: "Risk Category - Health",
    indicators: [
      { name: "Bacterial diseases", level: "Moderate Low" },
      { name: "Parasite pressure", level: "Low" },
      { name: "Vaccination protocols", level: "Moderate High" },
      { name: "Control points", level: "Low" },
    ],
  },
  {
    name: "Environment",
    description: `Insights into location, atmospheric,
      oceanographic, water quality, and
      monitoring risks.`,
    icon: environmentIcon,
    iconAlt: "Risk Category - Environment",
    indicators: [
      { name: "Current Speed", level: "Moderate" },
      { name: "Water Temperature", level: "Low" },
      { name: "Oxygen Saturation", level: "High" },
      { name: "Algae Monitoring", level: "Moderate High" },
    ],
  },
  {
    name: "Engineering",
    description: `Determine cage, mooring design,
      inspection protocol, maintenance and
      technology risks.`,
    icon: engineeringIcon,
    iconAlt: "Risk Category - Engineering",
    indicators: [
      { name: "Equipment condition", level: "Moderate Low" },
      { name: "Mooring design", level: "Moderate" },
      { name: "Maintenance protocols", level: "High" },
      { name: "Sensors and technology", level: "Moderate High" },
    ],
  },
  {
    name: "Governance",
    description: `Assess regulatory environment,
      certification, human capital, record
      keeping and traceability risk.`,
    icon: governanceIcon,
    iconAlt: "Risk Category - Governance",
    indicators: [
      { name: "Regulatory oversight", level: "Low" },
      { name: "Certification status", level: "Moderate Low" },
      { name: "Area based management", level: "Moderate" },
      { name: "Record keeping", level: "High" },
    ],
  },
  {
    name: "Husbandry",
    description: `Get to know farm history, carrying
      capacity, disease detection, densities
      and mortality risk.`,
    icon: husbandryIcon,
    iconAlt: "Risk Category - Husbandry",
    indicators: [
      { name: "Veterinary protocols", level: "Moderate" },
      { name: "Stocking density", level: "Moderate High" },
      { name: "Monthly mortality", level: "Low" },
      { name: "Feeding protocols", level: "High" },
    ],
  },
];

export const riskLevels = [
  {
    level: "Low",
    barClass: "risk-low col-4 col-lg-6",
    explanation: `means that the risk level is highly unlikely to require action`,
  },
  {
    level: "Moderate Low",
    barClass: "risk-moderate-low col-10 col-lg-12",
    explanation: `means that the risk level may require action, but not likely`,
  },
  {
    level: "Moderate",
    barClass: "risk-moderate col-8 col-lg-9",
    explanation: `means that the risk level may require action`,
  },
  {
    level: "Moderate High",
    barClass: "risk-moderate-high col-12 col-lg-6",
    explanation: `means that the risk level is likely to require action`,
  },
  {
    level: "High",
    barClass: "risk-high col-5 col-lg-2",
    explanation: `means that the risk level is highly likely to require action in the near future`,
  },
];

export const species = [
  { name: "Salmon", img: salmon },
  { name: "Tilapia", img: tilapia },
  { name: "Trout", img: trout },
  { name: "Shrimp", img: shrimp },
  { name: "Mussels", img: mussels },
  { name: "Barramundi", img: barramundi },
  { name: "Sea Bream", img: seaBream },
  { name: "Oysters", img: oysters },
  { name: "Striped Bass", img: stripedBass },
  { name: "Sea Bass", img: seaBass },
  { name: "Meagre", img: meagre },
  { name: "Tuna", img: tuna },
];
