import React, { useContext, useEffect, useState } from "react";
import {
  ButtonToolbar,
  Button,
  Row,
  Col,
  Container,
  Spinner,
} from "react-bootstrap";
import { PDFExport } from "@progress/kendo-react-pdf";
import get from "lodash/get";

import { AlertsContext } from "../../contexts/AlertsContext";
import { ErrorPopupContext } from "../../contexts/ErrorPopupContext";
import {
  getOpenActionItemsWithScoringBySurvey,
  getSurveySections,
} from "../../network/request";
import {
  CATEGORY_ICONS,
  AQUARISK_REPORT_LOGO,
} from "../../constants/imagesConstants";
import { showFetchError } from "../../utils/alerts";
import { getRiskLevelClassName } from "../../utils/string";

import Loader from "../common/Loader";
import Icon from "../common/Icon";
import ActionPdf from "./ActionPdf";
import PdfFooter from "./PdfFooter";

const pdfRef = React.createRef();

const ActionsPdf = (props) => {
  let report;
  const [loading, setLoading] = useState(false);
  const [fileSaving, setFileSaving] = useState(false);
  const [actions, setActions] = useState([]);
  const [sections, setSections] = useState([]);
  const [reportHeader, setReportHeader] = useState([]);

  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);

  const surveyExtId = props.match.params.surveyExtId;

  useEffect(() => {
    const fetchData = async () => {
      const closedActions = true;
      const details = true;
      try {
        setLoading(true);
        const { data: result } = await getOpenActionItemsWithScoringBySurvey(
          surveyExtId,
          { closed: closedActions, detail: details }
        );
        setReportHeader({
          company: result.company,
          farm: result.farm,
          scoring: result.scoring,
        });
        setActions(result.actionItems.items);
      } catch (error) {
        showFetchError({
          error,
          customMsg: "API error while loading the actions.",
          object: "actions",
          objectName: "plural",
          operation: "loaded",
          dispatchAlert,
          dispatchPopup,
          onRetry: () => fetchData(),
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [surveyExtId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data: result } = await getSurveySections(surveyExtId);
        setSections(result);
      } catch (error) {
        showFetchError({
          error,
          customMsg: "API error while loading the audit sections.",
          object: "audit sections",
          objectName: "plural",
          operation: "loaded",
          dispatchAlert,
          dispatchPopup,
          onRetry: () => fetchData(),
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [surveyExtId]);

  const exportPDF = () => {
    setFileSaving(true);

    report.save(() => setFileSaving(false));
  };

  const pageTemplate = (props) => {
    return (
      <PdfFooter
        footerName={get(reportHeader?.farm, "name")}
        pdfProps={props}
      />
    );
  };

  const getSectionIcon = (action) => {
    const currentSection = sections.find(
      (section) => section.externalId === action.sectionId
    );
    return CATEGORY_ICONS[currentSection?.name.toLowerCase()];
  };

  const listOfActionsSortedBySection = () => {
    return sections.map((section) => {
      const sectionActions = actions?.filter(
        (action) => action.sectionId === section.externalId
      );
      return (
        !!sectionActions.length && (
          <div key={section.externalId} className="mt-4">
            <h6 key={section.externalId} className="mb-2 text-end">
              {section.name}
            </h6>
            {sectionActions?.map((action) => {
              return (
                <ActionPdf
                  key={action.externalId}
                  action={action}
                  actionImgSrc={getSectionIcon(action)}
                  resolvable
                />
              );
            })}
          </div>
        )
      );
    });
  };

  const generateFileName = () => {
    return (
      `AquaRisk ${get(reportHeader.scoring, "title")}`.trim() +
      ` Actions Report.pdf`
    );
  };

  return loading ? (
    <div className="loader-container">
      <Loader status="Generating report" />
    </div>
  ) : (
    <Container className="aqua-container report-container pt-4">
      <div className="header">
        <ButtonToolbar className="actions form-group">
          <Button
            variant="primary"
            size="sm"
            onClick={() => exportPDF()}
            className="save-pdf-btn"
          >
            {fileSaving ? (
              <>
                <Spinner animation="border" variant="light" size="sm" />
                Downloading
              </>
            ) : (
              <>
                <Icon className="me-1" iconString="download" />
                Download
              </>
            )}
          </Button>
        </ButtonToolbar>
      </div>

      <div className="pdf-export-wrapper mb-5">
        <PDFExport
          paperSize={"Letter"}
          fileName={generateFileName()}
          title=""
          subject=""
          keywords=""
          ref={(r) => (report = r)}
          keepTogether={".keep-together"}
          pageTemplate={pageTemplate}
          margin={{ bottom: 70, top: 20, left: 0, right: 0 }}
        >
          <div className="report" ref={pdfRef}>
            <div className="pdf-header">
              <div className="text-end">
                <img
                  src={AQUARISK_REPORT_LOGO}
                  height="40"
                  alt="AquaRisk Logo"
                  className="img-logo"
                />
              </div>

              <div className="ps-0">
                <h4 className="mb-2">{get(reportHeader?.scoring, "title")}</h4>
              </div>

              <Row className="align-items-end justify-content-start mb-4">
                <Col className="pe-0">
                  <div className="section-score">
                    <h2 className="pdf-title mb-0">
                      {reportHeader.farm?.name}
                    </h2>
                    <div className="pdf-subtitle mb-1">
                      {reportHeader.company?.name}
                    </div>
                    <div
                      className={`bar bar-xls mb-2 risk-${getRiskLevelClassName(
                        reportHeader.scoring?.risk
                      )}`}
                    ></div>
                    <div className="norm-score text-end">
                      Overall Score: {reportHeader.scoring?.normScore}%
                    </div>
                  </div>
                </Col>

                <Col className="d-flex ps-0">
                  {reportHeader.scoring?.children.map((sectionScore) => {
                    return (
                      <div
                        key={sectionScore.externalId}
                        className="section-score text-center ps-3 d-flex flex-column"
                      >
                        <div className="d-flex align-items-center justify-content-center flex-grow-1">
                          <img
                            alt={sectionScore.name}
                            src={
                              CATEGORY_ICONS[sectionScore.name.toLowerCase()]
                            }
                            className="mb-2 img-icon img-icon-sm"
                          />
                        </div>
                        <div
                          className={`bar bar-xls mb-2 risk-${getRiskLevelClassName(
                            sectionScore.risk
                          )}`}
                        ></div>
                        <div className="norm-score">
                          {Math.round(sectionScore.normScore)}%
                        </div>
                      </div>
                    );
                  })}
                </Col>
              </Row>
            </div>

            {!actions.length ? (
              <Loader status="Loading actions" />
            ) : (
              listOfActionsSortedBySection()
            )}
          </div>
        </PDFExport>
      </div>
    </Container>
  );
};

export default ActionsPdf;
