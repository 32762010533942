import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  ButtonGroup,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useHistory, RouteComponentProps } from "react-router-dom";
import { AxiosError } from "axios";

import { AlertsContext } from "../../contexts/AlertsContext";
import { ErrorPopupContext } from "../../contexts/ErrorPopupContext";
import {
  CurrentUserContext,
  initialCurrentCompany,
} from "../../contexts/CurrentUserContext";
import {
  getMyCompany,
  getCompanyUsers,
  getUserFarms,
  deleteCompanyUser,
} from "../../network/request";
import { ALERT_ACTION_KIND } from "../../constants/enums";
import { INITIAL_USER_AND_ROLES } from "../../constants/initialData";
import { showTransientAlert, showFetchError } from "../../utils/alerts";
import { isAdmin } from "../../utils/userUtils";
import { getOneLevelSmallerUrl } from "../../utils/string";
import { UserInfoAndRoles } from "../../models/be_models";
import { CurrentCompany } from "../../models";

import Loader from "../common/Loader";
import Icon from "../common/Icon";
import DeleteConfirmationModal from "../general/DeleteConfirmationModal";

type UsersContainerProps = {
  companyId: string;
};

type UserForDelete = UserInfoAndRoles & {
  numberOfFarms?: number;
};

const UsersContainer: React.FC<RouteComponentProps<UsersContainerProps>> = (
  props
) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(true);
  const [deleteModalShow, setDeleteModalShow] = useState<boolean>(false);

  const [company, setCompany] = useState<CurrentCompany>(initialCurrentCompany);
  const [users, setUsers] = useState<UserInfoAndRoles[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserInfoAndRoles>(
    INITIAL_USER_AND_ROLES
  );
  const [userForDelete, setUserForDelete] = useState<UserForDelete>(
    INITIAL_USER_AND_ROLES
  );

  const { currentUser } = useContext(CurrentUserContext);
  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);

  const companyId = props.match.params.companyId;
  const history = useHistory();

  const fetchMyCompany = async () => {
    try {
      const { data: companyResult } = await getMyCompany(companyId);
      setCompany(companyResult);
    } catch (error) {
      showFetchError({
        error: error as AxiosError | Error,
        customMsg: "API error while loading the company.",
        object: "company",
        objectName: "unknown",
        operation: "loaded",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => fetchMyCompany(),
      });
    }
  };

  const fetchCompanyUsers = async () => {
    try {
      const { data: result } = await getCompanyUsers(companyId);
      if (result.length) setSelectedUser(result[0]);
      setUsers(result);
    } catch (error) {
      showFetchError({
        error: error as AxiosError | Error,
        customMsg: "API error while loading the company users.",
        object: "company users",
        objectName: "plural",
        operation: "loaded",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => fetchCompanyUsers(),
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchMyCompany();
      await fetchCompanyUsers();
      setLoading(false);
    };

    fetchData();
  }, [companyId]);

  const handleUserClick = (user: UserInfoAndRoles) => {
    if (user === selectedUser) {
      setSelectedUser(INITIAL_USER_AND_ROLES);
    } else {
      setSelectedUser(user);
    }
  };

  const goToEditUser = (userId: string) => {
    return history.push(`/companies/${companyId}/users/${userId}`);
  };

  const goToCreateUser = () => {
    return history.push(`/companies/${companyId}/users/new`);
  };

  const renderDeleteModal = async (
    e: React.MouseEvent,
    user: UserInfoAndRoles
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleting(true);
    setDeleteModalShow(true);

    const userWithFarmsNumber = { ...user, numberOfFarms: 0 };
    setUserForDelete(userWithFarmsNumber);

    try {
      const { data: farmsResult } = await getUserFarms(
        userWithFarmsNumber.user.externalId
      );
      const numberOfFarms = farmsResult?.length;

      setUserForDelete({ ...userWithFarmsNumber, numberOfFarms });
    } catch (error) {
      showFetchError({
        error: error as AxiosError | Error,
        customMsg: "API error while loading user farms.",
        object: "farms",
        objectName: "plural",
        operation: "loaded",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => renderDeleteModal(e, user),
      });
    } finally {
      setDeleting(false);
    }
  };

  const handleDeleteUser = async () => {
    if (!userForDelete?.user?.externalId) return;

    try {
      setDeleting(true);
      await deleteCompanyUser(companyId, userForDelete.user.externalId);
      showTransientAlert(dispatchAlert, {
        type: ALERT_ACTION_KIND.SHOW_SUCCESS_ALERT,
        text: "User deleted!",
      });
      const existingUsers = users.filter(
        (u) => u.user.externalId !== userForDelete.user.externalId
      );
      setUsers(existingUsers);
      if (existingUsers.length) handleUserClick(existingUsers[0]);
    } catch (error) {
      showFetchError({
        error: error as AxiosError | Error,
        customMsg: "API error while deleting the user.",
        object: "user",
        objectName: userForDelete.user.email,
        operation: "deleted",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => handleDeleteUser(),
      });
    } finally {
      setDeleting(false);
    }

    setDeleteModalShow(false);
  };

  const onClose = () => {
    window.location.pathname = getOneLevelSmallerUrl();
  };

  return (
    <Container className="farms-container" fluid data-cy="users-container">
      <Row>
        <Col className="main pb-4">
          {loading ? (
            <Row className="loader-container">
              <Loader status="Loading users" />
            </Row>
          ) : (
            <Row>
              <Col xs={12}>
                <div className="header mb-5">
                  <div className="w-100 d-flex align-items-start">
                    <OverlayTrigger
                      trigger={["hover", "focus"]}
                      placement="bottom"
                      overlay={<Tooltip>Back</Tooltip>}
                    >
                      <Button
                        variant="link"
                        className="btn-back"
                        onClick={onClose}
                      >
                        <i className="arrow arrow-lg arrow-gray arrow-left"></i>
                      </Button>
                    </OverlayTrigger>
                    <div className="filter-toolbar">
                      {/* //TODO: get company.name from current company context. Don't use api for it */}
                      <h4 className="header-title">{company.name}</h4>
                      <p className="smaller mb-0">
                        Total no of users: {users.length}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="filter-toolbar">
                  <Button
                    variant="link"
                    className="mt-4 mb-4 d-flex align-items-center btn-white btn-355 rounded"
                    onClick={goToCreateUser}
                    data-cy="add-user-btn"
                  >
                    <span className="small">
                      <Icon iconString="plus" className="me-1" />
                    </span>
                    User
                  </Button>
                </div>
              </Col>
              <Col xs={12}>
                {users.map((user) => {
                  return (
                    <div
                      className={
                        user.user.externalId === selectedUser.user.externalId
                          ? "selected surveys-toggle btn btn-link mb-4 py-0"
                          : "surveys-toggle btn btn-link mb-4 py-0"
                      }
                      key={user.user.externalId}
                      onClick={() => handleUserClick(user)}
                    >
                      <div className="farm">
                        <span className="card-name d-flex flex-wrap">
                          <span className="text-nowrap me-3">
                            {user.user.fullName}
                          </span>
                          <span className="fw-light me-3">/</span>
                          <span className="fw-light me-3">
                            {user.user.email}
                          </span>
                        </span>
                        <ButtonGroup className="card-actions text-nowrap">
                          <Button
                            variant="link"
                            size="sm"
                            onClick={() => goToEditUser(user.user.externalId)}
                            title="Edit"
                            data-cy="edit-user-btn"
                          >
                            <Icon iconString="edit" />
                          </Button>
                          {isAdmin(currentUser) ? (
                            <Button
                              variant="link"
                              size="sm"
                              onClick={(e) => renderDeleteModal(e, user)}
                              title="Delete"
                              data-cy="delete-user-btn"
                            >
                              <Icon iconString="trash" />
                            </Button>
                          ) : null}
                        </ButtonGroup>
                      </div>
                    </div>
                  );
                })}
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      {userForDelete && (
        <DeleteConfirmationModal
          show={deleteModalShow}
          onClose={() => setDeleteModalShow(false)}
          onConfirm={handleDeleteUser}
          loading={deleting}
          title="user"
        >
          <>
            <p>
              {`${userForDelete.user.email} (${
                userForDelete.user.fullName
              }) has ${
                userForDelete.numberOfFarms ? userForDelete.numberOfFarms : 0
              } farms.`}
            </p>
            <p className="mb-0"> Delete {userForDelete.user.email}?</p>
          </>
        </DeleteConfirmationModal>
      )}
    </Container>
  );
};

export default UsersContainer;
