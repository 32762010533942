import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { Modal, Button, Row, Col, Spinner } from "react-bootstrap";

import {
  postUploadMediaToSurvey,
  getListMediaForSurvey,
  deleteMediaForSurvey,
} from "../../network/request";
import { ALERT_ACTION_KIND } from "../../constants/enums";
import { AlertsContext } from "../../contexts/AlertsContext";
import { ErrorPopupContext } from "../../contexts/ErrorPopupContext";
import { showTransientAlert, showFetchError } from "../../utils/alerts";
import { MEDIA_CATEGORY } from "../../constants/enums";

import ROVIcon_dis from "../../images/ROVIcon_dis.svg";
import DroneIcon_dis from "../../images/DroneIcon_dis.svg";
import Icon from "../common/Icon";
import { AxiosError } from "axios";
import { MediaInfoResponse } from "../../models/be_models";

type UploadVideoModalProps = {
  selectedSurveyExtId: string;
  // should the modal be shown?
  show: boolean;
  // triggered when close button is clicked
  onHide: () => void;
};

const UploadVideoModal: React.FC<UploadVideoModalProps> = ({
  selectedSurveyExtId,
  show,
  onHide,
}) => {
  const [droneVideoFile, setDroneVideoFile] = useState<File | null>(null);
  const [submarineVideoFile, setSubmarineVideoFile] = useState<File | null>(
    null
  );
  const [droneLoading, setDroneLoading] = useState(false);
  const [submarineLoading, setSubmarineLoading] = useState(false);
  const [droneMedia, setDroneMedia] = useState<MediaInfoResponse>();
  const [submarineMedia, setSubmarineMedia] = useState<MediaInfoResponse>();

  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);

  const fetchSurveyMedia = useCallback(async () => {
    try {
      const { data: farmResult } = await getListMediaForSurvey(
        selectedSurveyExtId
      );
      setDroneMedia(
        farmResult.find(
          (item) => item.category === MEDIA_CATEGORY.DRONE.toUpperCase()
        )
      );
      setSubmarineMedia(
        farmResult.find(
          (item) => item.category === MEDIA_CATEGORY.SUBMARINE.toUpperCase()
        )
      );
    } catch (error) {
      showFetchError({
        error: error as Error | AxiosError,
        customMsg: "API error while loading the audit media.",
        object: "audit media",
        objectName: "unknown",
        operation: "loaded",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => fetchSurveyMedia(),
      });
    }
  }, [selectedSurveyExtId]);

  useEffect(() => {
    fetchSurveyMedia();
  }, [fetchSurveyMedia]);

  const onChangeHandlerDrone = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const video = e.target.files[0];
      setDroneVideoFile(video);
    }
  };

  const onChangeHandlerSubmarine = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const video = e.target.files[0];
      setSubmarineVideoFile(video);
    }
  };

  const controller = useRef<AbortController | null>(null);

  const uploadFile = async ({
    file,
    category,
  }: {
    file: File;
    category: string;
  }) => {
    const isDrone = category === MEDIA_CATEGORY.DRONE;
    const setFile = isDrone ? setDroneVideoFile : setSubmarineVideoFile;

    try {
      isDrone ? setDroneLoading(true) : setSubmarineLoading(true);
      const formData = new FormData();
      formData.append("name", file.name.replace(/\s+/g, "-"));
      formData.append("file", file);

      controller.current = new AbortController();

      await postUploadMediaToSurvey(
        selectedSurveyExtId,
        category,
        formData,
        controller.current.signal
      );

      showTransientAlert(dispatchAlert, {
        type: ALERT_ACTION_KIND.SHOW_SUCCESS_ALERT,
        text: "Video Saved!",
      });
    } catch (error) {
      showFetchError({
        error: error as Error | AxiosError,
        customMsg: "API error while saving the video.",
        object: "video",
        objectName: "unknown",
        operation: "saved",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => uploadFile({ file, category }),
      });
    } finally {
      setFile(null);
      await fetchSurveyMedia();
      isDrone ? setDroneLoading(false) : setSubmarineLoading(false);
    }
  };

  const handleSubmit = (
    e: React.FormEvent<HTMLFormElement>,
    videoFile: File | null,
    type: string
  ) => {
    e.preventDefault();
    if (videoFile) {
      const payload = {
        file: videoFile,
        category: type,
      };
      uploadFile(payload);
    }
  };

  const deleteFile = async (fileId: string, category: string) => {
    const isDrone = category === MEDIA_CATEGORY.DRONE;
    try {
      isDrone ? setDroneLoading(true) : setSubmarineLoading(true);
      await deleteMediaForSurvey(selectedSurveyExtId, fileId);
      showTransientAlert(dispatchAlert, {
        type: ALERT_ACTION_KIND.SHOW_SUCCESS_ALERT,
        text: "Video was deleted!",
      });
    } catch (error) {
      showFetchError({
        error: error as Error | AxiosError,
        customMsg: "API error while deleting the video.",
        object: "video",
        objectName: "unknown",
        operation: "deleted",
        dispatchAlert,
        dispatchPopup,
        onRetry: () => deleteFile(fileId, category),
      });
    } finally {
      await fetchSurveyMedia();
      isDrone ? setDroneLoading(false) : setSubmarineLoading(false);
    }
  };

  const byteToMb = (size: number) => {
    const mb = size / (1024 * 1024);
    const roundMb = Math.round(mb * 100) / 100;
    return roundMb + "Mb";
  };

  const cancelUpload = (
    setFile: React.Dispatch<React.SetStateAction<File | null>>
  ) => {
    controller.current && controller.current.abort();
    setFile(null);
  };

  const uploadRow = (type: "drone" | "submarine") => {
    const isDrone = type === MEDIA_CATEGORY.DRONE;
    const loading = isDrone ? droneLoading : submarineLoading;
    const icon = isDrone ? DroneIcon_dis : ROVIcon_dis;
    const iconAlt = isDrone ? "Drone icon" : "Submarine icon";
    const inputName = isDrone ? "fileDrone" : "fileSubmarine";
    const inputId = isDrone ? "file-drone-input" : "file-submarine-input";
    const onChangeHandler = isDrone
      ? onChangeHandlerDrone
      : onChangeHandlerSubmarine;

    const uploadedMedia = isDrone ? droneMedia : submarineMedia;

    const file = isDrone ? droneVideoFile : submarineVideoFile;
    const setFile = isDrone ? setDroneVideoFile : setSubmarineVideoFile;
    const fileName = uploadedMedia ? uploadedMedia.name : file ? file.name : "";
    const fileSize = file ? byteToMb(file.size) : "";

    return (
      <div className="input-wrapper">
        <form onSubmit={(e) => handleSubmit(e, file, type)} className="form">
          <Row className="justify-content-between flex-nowrap">
            <Col xs={4} md={5} className="align-self-center py-2">
              <div className="d-flex align-items-center">
                <img alt={iconAlt} src={icon} width="30" />
                <div className="ps-4 file-name">{fileName}</div>
              </div>
            </Col>

            {loading ? (
              <Col className="px-0 d-flex justify-content-center">
                <Spinner animation="border" className="wide-border" />
              </Col>
            ) : !uploadedMedia ? (
              file ? (
                <Col className="px-0 d-flex justify-content-center">
                  <Button className="file-upload-btn modal-btn" type="submit">
                    <span>Upload</span>
                  </Button>
                </Col>
              ) : (
                <Col className="px-0 d-flex justify-content-center file-input">
                  <input
                    type="file"
                    name={inputName}
                    accept="video/mp4"
                    id={inputId}
                    className="file-input__input"
                    onChange={onChangeHandler}
                  />
                  <label
                    className="file-input__label modal-btn"
                    htmlFor={inputId}
                  >
                    <span>Browse</span>
                  </label>
                </Col>
              )
            ) : null}
            <Col xs={4} md={5} className="align-self-center">
              {uploadedMedia ? (
                <div className="d-flex align-items-center justify-content-end">
                  <Button
                    variant="link"
                    className="remove-button pe-0 me-0"
                    size="sm"
                    onClick={() => deleteFile(uploadedMedia.externalId, type)}
                  >
                    <Icon iconString="trash-alt" className="icon" />
                  </Button>
                </div>
              ) : (
                file && (
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="pe-4 font-italic fw-light">{fileSize}</div>
                    <Button
                      variant="link"
                      className="cancel-button pe-0 me-0"
                      size="sm"
                      onClick={() => cancelUpload(setFile)}
                    >
                      <Icon iconString="times-circle" className="icon" />
                    </Button>
                  </div>
                )
              )}
            </Col>
          </Row>
        </form>
      </div>
    );
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="justify-content-center">
        <Modal.Title className="fw-semi">Upload Footage</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-10">
        {uploadRow(MEDIA_CATEGORY.DRONE)}
        {uploadRow(MEDIA_CATEGORY.SUBMARINE)}
      </Modal.Body>
      <Modal.Footer className="justify-content-end">
        <Button variant="link" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadVideoModal;
