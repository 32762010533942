import React, { useState, useEffect, useCallback } from "react";
import { Col, Form } from "react-bootstrap";
import MultiSelect from "@kenshooui/react-multi-select";
import "@kenshooui/react-multi-select/dist/style.css";

import { UserFarmResponse, FarmResponse } from "../../models/be_models";
import {
  FormattedMultiSelectItem,
  SelectedMultiSelectItem,
} from "../../models";

type FarmsMultiSelectProps = {
  itemsProp: FarmResponse[];
  selectedItemsProp: UserFarmResponse[];
  callback: React.Dispatch<React.SetStateAction<FormattedMultiSelectItem[]>>;
};

const FarmsMultiSelect: React.FC<FarmsMultiSelectProps> = ({
  itemsProp,
  selectedItemsProp,
  callback,
}) => {
  const [initialItems, setItems] = useState<FormattedMultiSelectItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<
    FormattedMultiSelectItem[]
  >([]);

  const formatItems = useCallback(
    (items: UserFarmResponse[] | FarmResponse[]) => {
      return items?.map((farm) => {
        return {
          id: farm?.externalId || "",
          label: farm?.name || "",
        };
      });
    },
    []
  );

  useEffect(() => {
    const initialItemsFormatted = itemsProp && formatItems(itemsProp);
    setItems(initialItemsFormatted);
  }, [formatItems, itemsProp]);

  useEffect(() => {
    const selectedItemsFormatted =
      selectedItemsProp && formatItems(selectedItemsProp);
    handleChange(selectedItemsFormatted);
  }, [formatItems, selectedItemsProp]);

  const handleChange = (items: FormattedMultiSelectItem[]) => {
    setSelectedItems(items);
    callback(items);
  };

  const renderSelectedItem = (selectedItem: SelectedMultiSelectItem) => {
    const { item } = selectedItem;
    const { label, id } = item;
    const checked = (idParam: string) =>
      selectedItems.find((item) => item.id === idParam);

    return (
      <Form.Check type="checkbox" className="mx-3">
        <Form.Check.Input
          type="checkbox"
          value={item.id}
          id={item.id}
          checked={!!checked(id)}
          onChange={() => undefined}
        />
        <Form.Check.Label htmlFor={label}>{label}</Form.Check.Label>
      </Form.Check>
    );
  };

  return (
    <Col className="p-0 mt-3" data-cy="farms-multiselect">
      <MultiSelect
        items={initialItems}
        selectedItems={selectedItems}
        onChange={handleChange}
        wrapperClassName="multi-select-wrapper"
        itemRenderer={renderSelectedItem}
      />
    </Col>
  );
};

export default FarmsMultiSelect;
