import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

const UrlForm = ({ onSave, answer }) => {
  const [sandbox, setSandbox] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setSandbox(answer.answerStr);
  }, [answer]);

  const handleChange = (e) => {
    const value = encodeURIComponent(e.target.value.toString());
    setSandbox(value);
  };

  const isValid = () => {
    setError("");

    if (sandbox && sandbox.length > 0) return true;
    setError("The answer cannot be empty");
    return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isValid()) return;
    onSave(sandbox);
  };

  return (
    <Form
      id="question-form"
      className="survey-answer-form"
      onSubmit={handleSubmit}
    >
      <Form.Group
        controlId="formGroupAnswer"
        className="form-group px-0 col-12 col-md-6"
      >
        <Form.Control
          type="text"
          placeholder="https://www.longline.co.uk"
          name="inputOne"
          value={sandbox}
          onChange={handleChange}
        />
      </Form.Group>

      {error.length ? <div className="error">{error}</div> : null}
    </Form>
  );
};

export default UrlForm;
