import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

const FloatForm = ({ onSave, answer }) => {
  const [sandbox, setSandbox] = useState(0.0);
  const [error, setError] = useState("");

  const toFixed2 = (input) => {
    const precision = input.toString(10).includes(".")
      ? input.toString().split(".").pop().length
      : 0;

    if (precision > 2) {
      return parseFloat(input).toFixed(2);
    } else {
      return parseFloat(input);
    }
  };

  useEffect(() => {
    if (!answer || !answer.answerStr) return;
    const value = parseFloat(answer.answerStr).toFixed(2);
    setSandbox(value);
  }, [answer]);

  const handleChange = (e) => {
    const value = toFixed2(parseFloat(e.target.value));
    setSandbox(value);
  };

  const isValid = () => {
    setError("");

    if (sandbox && sandbox > 0) return true;
    setError("Answer must be in float format (i.e. 2.53)");
    return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isValid()) return;
    onSave(sandbox);
  };

  return (
    <Form
      id="question-form"
      className="survey-answer-form"
      onSubmit={handleSubmit}
    >
      <Form.Group
        controlId="formGroupAnswer"
        className="form-group px-0 col-12 col-sm-6 col-xl-4"
      >
        <Form.Control
          type="number"
          step=".01"
          placeholder="0.0"
          value={sandbox}
          onChange={handleChange}
        />
      </Form.Group>

      {error.length ? <div className="error">{error}</div> : null}
    </Form>
  );
};

export default FloatForm;
