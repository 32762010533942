import axios from "axios";
import {
  BASE_URL,
  unknownErrorTxt,
  apiErrorTxt,
  getGuestHeaders,
} from "../constants/requestConstants";
import * as ResponseModel from "../models/be_models";

export const postRefreshToken = async (
  refreshToken: string | null
): Promise<ResponseModel.TokenResponse> => {
  const url = `${BASE_URL}/oauth/token?grant_type=refresh_token&refresh_token=${refreshToken}`;
  const actionTxt = "token refresh";

  try {
    const { data } = await axios.post<ResponseModel.TokenResponse>(
      url,
      new FormData(),
      {
        headers: getGuestHeaders(),
      }
    );

    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(apiErrorTxt + actionTxt);
    } else {
      throw new Error(unknownErrorTxt + actionTxt);
    }
  }
};

export const postLogIn = async (
  email: string,
  password: string
): Promise<ResponseModel.TokenResponse> => {
  const url = `${BASE_URL}/oauth/token`;
  const actionTxt = "login";

  const formData = new FormData();
  formData.append("grant_type", "password");
  formData.append("username", email);
  formData.append("password", password);
  formData.append("scope", "read write");
  formData.append("client_id", "aquarisk-trusted-client");

  try {
    const { data } = await axios.post<ResponseModel.TokenResponse>(
      url,
      formData,
      {
        headers: getGuestHeaders(),
      }
    );

    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(apiErrorTxt + actionTxt);
    } else {
      throw new Error(unknownErrorTxt + actionTxt);
    }
  }
};

export * from "./userController";

export * from "./companyController";

export * from "./farmController";

export * from "./surveyController";

export * from "./registrationController";

export * from "./actionController";

export * from "./iqPortalController";

export * from "./surveyTemplateAdminController";

export * from "./mediaController";

export * from "./environmentalDataController";

export * from "./userAdminController";

export * from "./auditLogController";

export * from "./surveyTocController";

export * from "./passwordChangeController";

export * from "./reportController";
