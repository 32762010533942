import { AxiosHeaders } from "axios";

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const API = `${BASE_URL}/api/v1`;

export const ADMIN_API = `${BASE_URL}/admin/api/v1`;

export const unknownErrorTxt = "Something went wrong while ";

export const apiErrorTxt = "API error while ";

export const getHeaders = (): AxiosHeaders => {
  const guestHeader = new AxiosHeaders();
  guestHeader.set("Content-Type", "application/json");
  return guestHeader;
};

export const getUserHeaders = (): AxiosHeaders => {
  const userHeaders = new AxiosHeaders();
  userHeaders.set(
    "Authorization",
    "Bearer " + localStorage.getItem("accessToken")
  );
  userHeaders.set("Content-Type", "application/json");
  return userHeaders;
};

export const getGuestHeaders = (): AxiosHeaders => {
  const guestHeader = new AxiosHeaders();
  guestHeader.set(
    "Authorization",
    `Basic ${btoa("aquarisk-trusted-client:secret")}`
  );
  return guestHeader;
};

export const getGuestHeadersWithTypes = (): AxiosHeaders => {
  const guestHeader = new AxiosHeaders();
  guestHeader.set("Content-Type", "application/json");
  guestHeader.set("Accept", "*/*");
  guestHeader.set(
    "Authorization",
    "Basic dHdnLXRydXN0ZWQtY2xpZW50OnNlY3JldA=="
  );
  return guestHeader;
};

export const getAuthHeaders = (): AxiosHeaders => {
  const authHeaders = new AxiosHeaders();
  authHeaders.set(
    "Authorization",
    "Bearer " + localStorage.getItem("accessToken")
  );
  return authHeaders;
};
