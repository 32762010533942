import React, { useState, useEffect, useCallback } from "react";
import { Col, Form } from "react-bootstrap";
import MultiSelect from "@kenshooui/react-multi-select";
import "@kenshooui/react-multi-select/dist/style.css";

import { UserInfoAndRoles } from "../../models/be_models";
import {
  FormattedMultiSelectItem,
  SelectedMultiSelectItem,
} from "../../models";

type UsersMultiSelectProps = {
  // all users in the company (left side of Multiselect)
  itemsProp: UserInfoAndRoles[];
  // users set to this farm (right side of Multiselect)
  selectedItemsProp: UserInfoAndRoles[];
  // callback for setting the new list of users
  callback: React.Dispatch<React.SetStateAction<FormattedMultiSelectItem[]>>;
};

const UsersMultiSelect: React.FC<UsersMultiSelectProps> = ({
  itemsProp,
  selectedItemsProp,
  callback,
}) => {
  const [initialItems, setItems] = useState<FormattedMultiSelectItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<
    FormattedMultiSelectItem[]
  >([]);

  const formatItems = useCallback((items: UserInfoAndRoles[]) => {
    return items?.map(({ user }) => {
      return {
        id: user?.externalId || "",
        label: user?.fullName || user?.username,
      };
    });
  }, []);

  useEffect(() => {
    const initialItemsFormatted = itemsProp && formatItems(itemsProp);
    setItems(initialItemsFormatted);
  }, [formatItems, itemsProp]);

  useEffect(() => {
    const selectedItemsFormatted =
      selectedItemsProp && formatItems(selectedItemsProp);
    handleChange(selectedItemsFormatted);
  }, [formatItems, selectedItemsProp]);

  const handleChange = (items: FormattedMultiSelectItem[]) => {
    setSelectedItems(items);
    callback(items);
  };

  const renderSelectedItem = (selectedItem: SelectedMultiSelectItem) => {
    const { item } = selectedItem;
    const { label, id } = item;
    const checked = (idParam: string) =>
      selectedItems.find((item) => item.id === idParam);

    return (
      <Form.Check type="checkbox" className="mx-3">
        <Form.Check.Input
          type="checkbox"
          value={item.id}
          id={item.id}
          checked={!!checked(id)}
          onChange={() => undefined}
        />
        <Form.Check.Label htmlFor={label}>{label}</Form.Check.Label>
      </Form.Check>
    );
  };

  return (
    <Col className="p-0 mt-3">
      <MultiSelect
        items={initialItems}
        selectedItems={selectedItems}
        onChange={handleChange}
        wrapperClassName="multi-select-wrapper"
        itemRenderer={renderSelectedItem}
      />
    </Col>
  );
};

export default UsersMultiSelect;
