import React, { useState, useEffect, useRef } from "react";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import axios from "axios";

type GeocoderInputProps = {
  /* default|selected value in the input search */
  selectedItem: string;
  /* fired on item selection */
  onItemSelect: (item: string) => void;
  /* should the search input be disabled? */
  disabled?: boolean;
  /* country name for filtering search items*/
  filter?: string;
  /* list of searched geo types */
  dataType: "country" | "region,district" | "region"; // list of types: https://docs.mapbox.com/api/search/geocoding/#data-types
};

type GeocoderSearchResponse = {
  attribution: string;
  features: MapboxGeocoder.Result[];
  query: string[];
  type: string;
};

const GeocoderInput: React.FC<GeocoderInputProps> = ({
  selectedItem,
  onItemSelect,
  disabled,
  filter,
  dataType
}) => {
  const access_token = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
  const geocoderElRef = useRef<HTMLDivElement | null>(null);

  const mapboxBaseOptions = {
    accessToken: access_token as string,
    types: dataType,
    language: "en-uk",
    placeholder: "Type to search...",
    getItemValue: (feature: MapboxGeocoder.Result) => feature.text // render search input. Render 'text' field for avoid render of the country name
  };

  const [countryIsoCode, setCountryIsoCode] = useState<string>("");
  const [geocoder] = useState<MapboxGeocoder>(
    new MapboxGeocoder(mapboxBaseOptions)
  );

  useEffect(() => {
    const getIsoCode = async () => {
      if (filter) {
        const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${filter}.json`;

        const params = {
          limit: 1,
          types: "country",
          language: "en-uk",
          access_token
        };

        let isoCode = "";

        const { data: response } = await axios.get<GeocoderSearchResponse>(
          url,
          { params }
        );
        if (response.features[0].properties) {
          isoCode = response.features[0].properties.short_code;
        }

        setCountryIsoCode(isoCode);
        geocoder.setCountries(isoCode);
      }
    };

    getIsoCode();
  }, [filter]);

  useEffect(() => {
    const geocoderEl = geocoderElRef.current;

    if (!geocoderEl) return;

    // Render geocoder search component
    geocoderEl.innerHTML = "";
    geocoder.addTo(geocoderEl as HTMLElement);

    // Set initial value if exist in the input.
    if (selectedItem) {
      const [searchInput] = geocoderEl.getElementsByTagName("input");
      searchInput.value = selectedItem;
    }

    // Add geocoder result to container.
    geocoder.on("result", (e) => {
      onItemSelect(e.result.text); // default value - .place_name and it includes Country name.
    });

    geocoder.on("clear", () => {
      onItemSelect("");
    });
  }, [geocoder, selectedItem]);

  useEffect(() => {
    const geocoderEl = geocoderElRef.current;

    if (geocoderEl) {
      geocoderEl.setAttribute("class", disabled ? "disabled" : "enabled");
    }
  }, [disabled, countryIsoCode]);

  return (
    <div className="geocoder-container">
      <div id={`${dataType}-geocoder`} ref={geocoderElRef}></div>
    </div>
  );
};

export default GeocoderInput;
