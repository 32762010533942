import axios, { AxiosResponse } from "axios";
import { ADMIN_API, getUserHeaders } from "../constants/requestConstants";
import * as Model from "../models/be_models";

// is not used
// export const getAllRoles = (): Promise<AxiosResponse<string[]>> => {
//   const url = `${ADMIN_API}/roles`;
//   return axios.get(url, {
//     headers: getUserHeaders(),
//   });
// };

// GET /admin/api/v1/roles/{userExtId}/current
// getCurrentRolesForUser

export const postSetCurrentRolesForUser = (
  //setCurrentRolesForUser
  userExtId: string,
  body: Model.SetUserRolesRequest,
  name?: string
): Promise<AxiosResponse<Model.UserProfileAdminResponse>> => {
  const url = `${ADMIN_API}/roles/${userExtId}/current`;

  return axios.post<Model.UserProfileAdminResponse>(url, body, {
    headers: getUserHeaders(),
    params: { name },
  });
};

export const getUsers = (
  enabled = true
): Promise<AxiosResponse<Model.UserProfileAdminResponse[]>> => {
  const url = `${ADMIN_API}/user`;

  return axios.get<Model.UserProfileAdminResponse[]>(url, {
    headers: getUserHeaders(),
    params: { enabled },
  });
};

// POST /admin/api/v1/user
// createUser

// POST /admin/api/v1/user-ac
// getUsersAutoCorrect

// GET /admin/api/v1/user/{extid}
// getUser

// PUT /admin/api/v1/user/{extid}
// updateUser

// DELETE /admin/api/v1/user/{extId}
// deleteUser
