import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

const YesNoForm = ({ onSave, answer }) => {
  const [sandbox, setSandbox] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setSandbox(answer.answerStr);
  }, [answer]);

  const handleChange = (e) => {
    setSandbox(e.target.value);
  };

  const isValid = () => {
    setError("");

    if (sandbox && sandbox.length) return true;
    setError("An option must be selected");
    return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isValid()) return;
    onSave(sandbox);
  };

  return (
    <Form
      id="question-form"
      className="survey-answer-form"
      onSubmit={handleSubmit}
    >
      <Form.Check custom type="radio" className="mb-1">
        <Form.Check.Input
          value="YES"
          id="YES"
          checked={sandbox === "YES"}
          onChange={handleChange}
        />
        <Form.Check.Label htmlFor="YES">YES</Form.Check.Label>
      </Form.Check>

      <Form.Check custom type="radio">
        <Form.Check.Input
          value="NO"
          id="NO"
          checked={sandbox === "NO"}
          onChange={handleChange}
        />
        <Form.Check.Label htmlFor="NO">NO</Form.Check.Label>
      </Form.Check>

      {error.length ? <div className="error">{error}</div> : null}
    </Form>
  );
};

export default YesNoForm;
