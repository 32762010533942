import axios, { AxiosResponse } from "axios";
import { API, getUserHeaders } from "../constants/requestConstants";
import * as BdModel from "../models/be_models";

export const getMyCompanies = (): Promise<
  AxiosResponse<BdModel.CompanyResponse[]>
> => {
  const url = `${API}/company?details=false`;

  return axios.get<BdModel.CompanyResponse[]>(url, {
    headers: getUserHeaders(),
  });
};

export const postCreateCompany = (
  //createCompany
  companyBody: BdModel.CreateCompanyRequest
): Promise<AxiosResponse<BdModel.CompanyResponse>> => {
  const url = `${API}/company`;

  return axios.post<BdModel.CompanyResponse>(url, companyBody, {
    headers: getUserHeaders(),
  });
};

export const getMyCompany = (
  companyExtId: string
): Promise<AxiosResponse<BdModel.CompanyResponse>> => {
  const url = `${API}/company/${companyExtId}?details=true`;

  return axios.get<BdModel.CompanyResponse>(url, {
    headers: getUserHeaders(),
  });
};

export const putUpdateCompany = (
  //updateCompany
  companyExtId: string,
  companyBody: BdModel.UpdateCompanyRequest
): Promise<AxiosResponse<BdModel.CompanyResponse>> => {
  const url = `${API}/company/${companyExtId}`;

  return axios.put<BdModel.CompanyResponse>(url, companyBody, {
    headers: getUserHeaders(),
  });
};

export const deleteCompany = (
  companyExtId: string
): Promise<AxiosResponse<BdModel.CompanyResponse>> => {
  const url = `${API}/company/${companyExtId}`;

  return axios.delete<BdModel.CompanyResponse>(url, {
    headers: getUserHeaders(),
    data: {}, //empty data is needed for preventing 415 error
  });
};

export const getCompanyUsers = (
  companyExtId: string
): Promise<AxiosResponse<BdModel.UserInfoAndRoles[]>> => {
  const url = `${API}/company/${companyExtId}/user`;

  return axios.get<BdModel.UserInfoAndRoles[]>(url, {
    headers: getUserHeaders(),
  });
};

export const postCreateCompanyUser = (
  //createCompanyUser
  companyExtId: string,
  userBody: BdModel.CreateCompanyUser
): Promise<AxiosResponse<BdModel.UserInfoAndRoles>> => {
  const url = `${API}/company/${companyExtId}/user`;

  return axios.post<BdModel.UserInfoAndRoles>(url, userBody, {
    headers: getUserHeaders(),
  });
};

export const getCompanyUsersAutoCorrect = (
  companyExtId: string,
  userSearchBody: BdModel.CompanyUsersAutoCorrectRequest
): Promise<AxiosResponse<BdModel.NameSuggesterResponse[]>> => {
  const url = `${API}/company/${companyExtId}/user-ac`;

  return axios.post<BdModel.NameSuggesterResponse[]>(url, userSearchBody, {
    headers: getUserHeaders(),
  });
};

export const getCompanyUser = (
  companyExtId: string,
  userExtId: string
): Promise<AxiosResponse<BdModel.UserInfoAndRoles>> => {
  const url = `${API}/company/${companyExtId}/user/${userExtId}`;

  return axios.get<BdModel.UserInfoAndRoles>(url, {
    headers: getUserHeaders(),
  });
};

export const putUpdateCompanyUser = (
  //updateCompanyUser
  companyExtId: string,
  userExtId: string,
  userBody: BdModel.UpdateCompanyUser
): Promise<AxiosResponse<BdModel.UserInfoAndRoles>> => {
  const url = `${API}/company/${companyExtId}/user/${userExtId}`;

  return axios.put<BdModel.UserInfoAndRoles>(url, userBody, {
    headers: getUserHeaders(),
  });
};

export const deleteCompanyUser = (
  companyExtId: string,
  userExtId: string
): Promise<AxiosResponse<BdModel.FarmResponse>> => {
  const url = `${API}/company/${companyExtId}/user/${userExtId}`;

  return axios.delete<BdModel.FarmResponse>(url, {
    headers: getUserHeaders(),
    data: {}, //empty data is needed for preventing 415 error
  });
};
