import axios from "axios";

import { AUTH_ACTION_KIND, CURRENT_USER_ACTION_KIND } from "../constants/enums";
import { getBaseUrl } from "../utils/string";
import { authReducer } from "../reducers/authReducer";
import { currentUserReducer } from "../reducers/currentUserReducer";
import { initialCurrentUser } from "../contexts/CurrentUserContext";
import { postRefreshToken } from "./request";

let accessTokenPromise: Promise<string> | null = null; // this holds any in-progress token access requests

const refreshAccessToken = async (): Promise<string> => {
  try {
    const { access_token } = await postRefreshToken(
      localStorage.getItem("refreshToken")
    );

    if (access_token) localStorage.setItem("accessToken", access_token);

    return access_token;
  } catch {
    // if error clear user and company from the storage and go to login page
    authReducer(false, {
      type: AUTH_ACTION_KIND.LOGOUT,
      token: "",
      refreshToken: "",
    });
    currentUserReducer(initialCurrentUser, {
      type: CURRENT_USER_ACTION_KIND.CLEAR_USER,
      user: initialCurrentUser,
      company: initialCurrentUser.currentCompany,
    });
    window.location.pathname = getBaseUrl() + "/login";
    return Promise.reject();
  }
};

axios.interceptors.request.use(
  (request) => request,

  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,

  async (error) => {
    if (error.response) {
      const originalRequest = error.config;

      // you can't insert 403 code here, because this code is returned
      // when "Access is denied" because of permissions.
      // For example in the / admin / api / v1 / roles API
      if ([401].includes(error.response.status)) {
        if (!accessTokenPromise) {
          // check for an existing in-progress request
          // if nothing is in-progress, start a new access token request
          accessTokenPromise = refreshAccessToken().then((token) => {
            accessTokenPromise = null; // clear state
            return token; // resolve with the new token
          });
        }

        return accessTokenPromise.then((token: string) => {
          // resend all requests which failed with 401 error
          originalRequest.headers["Authorization"] = "Bearer " + token;

          return axios.request(originalRequest);
        });
      }
    }

    return Promise.reject(error);
  }
);
