import React, { useContext, useEffect, useState } from "react";
import { Button, Row, Col, Accordion } from "react-bootstrap";
import { AxiosError } from "axios";

import { getSurveySectionStepScore } from "../../network/request";
import { AlertsContext } from "../../contexts/AlertsContext";
import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import { ErrorPopupContext } from "../../contexts/ErrorPopupContext";
import { ANSWER_TYPES } from "../../constants/answerTypes";
import { showFetchError } from "../../utils/alerts";
import { getRiskLevelClassName, getRiskLevelName } from "../../utils/string";
import { isAdmin } from "../../utils/userUtils";
import { StepResponse, SurveyQuestion } from "../../models/be_models";

import Loader from "../common/Loader";
import Icon from "../common/Icon";

type SurveyResultProps = {
  surveyId: string;
  sectionId: string;
  step: StepResponse;
  onClose: () => void;
};

const SurveyResult: React.FC<SurveyResultProps> = ({
  surveyId,
  sectionId,
  step,
  onClose,
}) => {
  const [loading, setLoading] = useState(false);
  const [showAll, setShowAll] = useState(true);
  const [hideAll, setHideAll] = useState(false);
  const [stepQuestions, setStepQuestions] = useState<SurveyQuestion[]>([]);
  const [currentKey, setCurrentKey] = useState("");

  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { dispatch: dispatchPopup } = useContext(ErrorPopupContext);
  const { currentUser } = useContext(CurrentUserContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data: result } = await getSurveySectionStepScore(
          surveyId,
          sectionId,
          step.externalId
        );
        if (result.questions && result.questions.length) {
          setStepQuestions(result.questions);
          setCurrentKey(result.questions[0].id);
        } else {
          setStepQuestions([]);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        showFetchError({
          error: error as Error | AxiosError,
          customMsg: "API error while loading the steps.",
          object: "steps",
          objectName: "plural",
          operation: "loaded",
          dispatchAlert,
          dispatchPopup,
          onRetry: () => fetchData(),
        });
      }
    };

    fetchData();
  }, [surveyId, sectionId, step]);

  useEffect(() => {
    if (hideAll) {
      setShowAll(false);
    }
  }, [hideAll]);
  useEffect(() => {
    if (showAll) {
      setHideAll(false);
    }
  }, [showAll]);

  const handleToggleClick = (id: string) => {
    setShowAll(false);
    setHideAll(false);
    setCurrentKey(id);
  };

  return (
    <div className="survey-result bg-white mx-2 ms-md-5">
      <div className="header px-0 w-100 d-flex justify-content-between">
        <div className="ps-3">
          <h4 className="header-title">{step.title}</h4>
          <p className="header-description font-italic">{step.description}</p>
        </div>
        <Button
          variant="link"
          size="lg"
          className="text-muted py-0 pe-2 ms-auto"
          onClick={onClose}
        >
          <Icon iconString="times" />
        </Button>
      </div>

      <div className="content pe-5">
        <Row className="score-row mb-4">
          <Col className="score text-end text-uppercase">
            {getRiskLevelName(step.risk)}
            {isAdmin(currentUser) && `: ${step.score}`}
          </Col>
          <Col xs={12}>
            <div
              className={`bar bar-lg risk-${getRiskLevelClassName(step.risk)}`}
            ></div>
          </Col>
        </Row>

        {loading ? (
          <Loader status="Loading step questions" />
        ) : (
          <div className="questions mt-5">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <h5 className="fw-semi mb-0 mb-sm-3">Questions</h5>
              <div className="mb-3">
                {!showAll && (
                  <Button
                    variant="link"
                    size="sm"
                    className="ps-0 ps-sm-1 fw-light"
                    onClick={() => setShowAll(true)}
                  >
                    Expand all
                  </Button>
                )}
                <Button
                  variant="link"
                  size="sm"
                  className="fw-light"
                  onClick={() => setHideAll(true)}
                >
                  Collapse all
                </Button>
              </div>
            </div>

            <Accordion
              defaultActiveKey={currentKey}
              activeKey={hideAll ? "" : currentKey}
              className={`cards farm-with-survey ${showAll ? "show-all" : ""}`}
            >
              {stepQuestions.map((question) => {
                let answer = question.answer.answerStr;
                if (
                  answer !== undefined &&
                  answer.length > 0 &&
                  (question.answerType === ANSWER_TYPES.MULTI_SELECT ||
                    question.answerType === ANSWER_TYPES.SINGLE_SELECT)
                ) {
                  answer = "";
                  question.answer.answerStr
                    .replace(/\[/, "")
                    .replace(/\]/, "")
                    .replace(/\s+/, "")
                    .split(",")
                    .map((multiSelectOrdinal) => {
                      const ordinal = parseInt(multiSelectOrdinal);
                      const option = question.answerOptions.find(
                        (o) => o.ordinal === ordinal
                      );
                      if (option) answer += `${option.text}, `;
                      return answer;
                    });

                  if (answer.length > 1) {
                    answer = answer.slice(0, -2);
                  }
                }

                return (
                  <Accordion.Item
                    className="mb-4"
                    key={question.id}
                    eventKey={question.id}
                  >
                    <Accordion.Header
                      className="border-bottom"
                      onClick={() => handleToggleClick(question.id)}
                    >
                      {question.text}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="px-0 py-2">
                        <div dangerouslySetInnerHTML={{ __html: answer }}></div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </div>
        )}
      </div>
    </div>
  );
};

export default SurveyResult;
