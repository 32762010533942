import axios, { AxiosResponse } from "axios";
import { API, getUserHeaders } from "../constants/requestConstants";
import * as Model from "../models/be_models";

export const getSurveyToc = (
  //getLogs
  surveyExtId: string,
  depth = "ALL"
): Promise<AxiosResponse<Model.SurveyTocResponse>> => {
  const url = `${API}/toc/${surveyExtId}`;

  return axios.get<Model.SurveyTocResponse>(url, {
    headers: getUserHeaders(),
    params: {
      depth,
    },
  });
};

// GET /api/v1/toc/{surveyExtId}/section/{sectionExtId}
// getSurveyTocForSection

// GET /api/v1/toc/{surveyExtId}/section/{sectionExtId}/step/{stepExtId}
// getSurveyTocForStep

// GET /api/v1/toc/jump/{surveyExtId}/section/{sectionExtId}
// jumpToSection

// GET /api/v1/toc/jump/{surveyExtId}/section/{sectionExtId}/step/{stepExtId}
// jumpToStep

export const getJumpToQuestion = (
  //jumpToQuestion
  surveyExtId: string,
  sectionExtId: string,
  stepExtId: string,
  questionExtId: string
): Promise<AxiosResponse<Model.SurveyQuestionResponse>> => {
  const url = `${API}/toc/jump/${surveyExtId}/section/${sectionExtId}/step/${stepExtId}/question/${questionExtId}`;

  return axios.get<Model.SurveyQuestionResponse>(url, {
    headers: getUserHeaders(),
  });
};
