import "./network/interceptor";
import AuthContextProvider from "./contexts/AuthContext";
import CurrentUserContextProvider from "./contexts/CurrentUserContext";
import AlertsContextProvider from "./contexts/AlertsContext";
import ErrorPopupContextProvider from "./contexts/ErrorPopupContext";
import Main from "./components/Main";
import Alerts from "./components/common/Alerts";
import ErrorPopup from "./components/common/ErrorPopup";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowAltCircleRight,
  faArrowLeft,
  faArrowRight,
  faCalculator,
  faCaretDown,
  faCaretUp,
  faCaretRight,
  faCheck,
  faCheckCircle,
  faChevronRight,
  faChevronLeft,
  faClone,
  faHome,
  faChartBar,
  faClipboardList,
  faCogs,
  faDownload,
  faEnvelope,
  faExclamationTriangle,
  faFish,
  faFileArchive,
  faGavel,
  faHeartbeat,
  faInfoCircle,
  faPollH,
  faUsers,
  faLifeRing,
  faList,
  faCog,
  faPlus,
  faEdit,
  faPen,
  faTrash,
  faTrashAlt,
  faUserSlash,
  faUserCheck,
  faUserTimes,
  faFileDownload,
  faFileUpload,
  faFilePdf,
  faFilter,
  faTimes,
  faTimesCircle,
  faWater,
  faCloudUploadAlt,
  faEye,
  faEyeSlash,
  faVideo,
  faPlayCircle,
  faEllipsisV,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faArrowAltCircleRight,
  faArrowLeft,
  faArrowRight,
  faCalculator,
  faCaretDown,
  faCaretUp,
  faCaretRight,
  faCheck,
  faCheckCircle,
  faChevronRight,
  faChevronLeft,
  faClone,
  faHome,
  faChartBar,
  faClipboardList,
  faCogs,
  faDownload,
  faEnvelope,
  faExclamationTriangle,
  faFish,
  faFileArchive,
  faGavel,
  faHeartbeat,
  faInfoCircle,
  faPollH,
  faUsers,
  faLifeRing,
  faList,
  faCog,
  faPlus,
  faEdit,
  faPen,
  faTrash,
  faTrashAlt,
  faUserSlash,
  faUserCheck,
  faUserTimes,
  faFileDownload,
  faFileUpload,
  faFilePdf,
  faFilter,
  faTimes,
  faTimesCircle,
  faWater,
  faCloudUploadAlt,
  faEye,
  faEyeSlash,
  faVideo,
  faPlayCircle,
  faEllipsisV
);

function App() {
  return (
    <div className="App">
      <AuthContextProvider>
        <CurrentUserContextProvider>
          <ErrorPopupContextProvider>
            <AlertsContextProvider>
              <ErrorPopup />
              <Alerts />
              <Main />
            </AlertsContextProvider>
          </ErrorPopupContextProvider>
        </CurrentUserContextProvider>
      </AuthContextProvider>
    </div>
  );
}

export default App;
